import React, { Component } from 'react';
import { IntlStringProps, withIntlString } from 'components/IntlStringHoc';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { ReservationRequestsDataTable } from 'models/Reservations';
import Axios from 'axios';
import qs from 'qs';
import { IApi, IState, useApi } from 'services/apiStateManager';
import requestsApiParamsDefinition from './requestsApiParamsDefinition';
import debounce from 'lodash.debounce';
import { InlineResponse2007, Reservation, Proposal, ProposalApi } from 'services/apiService';
import { Button } from 'primereact/button';
import userImage from 'assets/img/user.webp';
import './RequestsTab.scss';
import TagsDropdown from 'components/tagsDropdown/TagsDropdown';
import DialogConfirmRequest from 'components/dialogConfirmRequest/DialogConfirmRequest';
import DialogDeleteRequest from 'components/dialogDeleteRequest/DialogDeleteRequest';
import MoveReservationDialog from 'components/moveReservationDialog/MoveReservationDialog';
import getClient from 'services/apiClientProvider';

type Props = IntlStringProps;

interface ReservationWrapper {
  location: any;
  reservation: Reservation;
  start: string;
  title: string;
  _id: string;
}

type State = {
  requestsObject: IState<ReservationRequestsDataTable>;
  selectedTags: InlineResponse2007[];
  params: typeof requestsApiParamsDefinition;
  isVisibleConfirmRequestModal: boolean;
  isVisibleDeleteRequestModal: boolean;
  isVisibleMoveRequestModal: boolean;
  proposalId: string | null;
  proposalGet: IState<Proposal>;
};

class RequestsTab extends Component<Props, State> {
  requestsManager: IApi<ReservationRequestsDataTable, any> = useApi<
    ReservationRequestsDataTable,
    any
  >(this, 'requestsObject', (params) => {
    const apiString = `/proposals/tables/datatable?${qs.stringify(params)}`;
    return Axios.get(apiString);
  });

  proposalGet: IApi<Proposal, any> = useApi<Proposal, any>(
    this,
    'proposalGet',
    ({ proposalId }: { proposalId: string }) =>
      getClient(ProposalApi).proposalsProposalIdGet(proposalId)
  );

  state: State = {
    requestsObject: this.requestsManager.initialState,
    selectedTags: [],
    params: requestsApiParamsDefinition,
    isVisibleConfirmRequestModal: false,
    isVisibleDeleteRequestModal: false,
    isVisibleMoveRequestModal: false,
    proposalId: null,
    proposalGet: this.proposalGet.initialState,
  };

  componentDidMount() {
    this.loadReservationRequests();
  }

  loadReservationRequests() {
    this.requestsManager.fetch(this.state.params);
  }

  loadReservationRequestsDebounced = debounce(() => {
    this.loadReservationRequests();
  }, 500);

  searchChange = (e: any) => {
    this.saveFilters(e.target.value, undefined);
  };

  saveFilters = (searchText: string | undefined, tagsValues: string[] | undefined) => {
    this.setState(
      (state: any) => ({
        ...state,
        params: {
          ...state.params,
          search:
            searchText === undefined
              ? state.params.search
              : {
                  ...state.params.search,
                  value: searchText,
                },
          tags: tagsValues ?? state.params.tags,
        },
      }),
      () => {
        this.loadReservationRequestsDebounced();
      }
    );
  };

  onValuesChanges = (values: InlineResponse2007[]) => {
    this.setState({ selectedTags: values });
    this.saveFilters(
      undefined,
      values.map((x) => x.text ?? '')
    );
  };

  onPageChanged = (e: { first: number; rows: number; page: number; pageCount: number }) => {
    if (this.state.requestsObject.loading) return;
    this.setState(
      (state: any) => ({
        ...state,
        params: {
          ...state.params,
          start: e.first,
          length: e.rows,
        },
      }),
      () => {
        this.loadReservationRequests();
      }
    );
  };

  toggleConfirmRequestModal(isVisible: boolean, id?: string, withSuccess?: boolean) {
    this.setState({
      ...this.state,
      isVisibleConfirmRequestModal: isVisible,
      proposalId: id ?? null,
    });

    if (!isVisible && withSuccess) {
      this.loadReservationRequests();
    }
  }

  toggleDeleteRequestModal(isVisible: boolean, id?: string, withSuccess?: boolean) {
    this.setState({
      ...this.state,
      isVisibleDeleteRequestModal: isVisible,
      proposalId: id ?? null,
    });

    if (!isVisible && withSuccess) {
      this.loadReservationRequests();
    }
  }

  toggleMoveRequestModal(
    isVisible: boolean,
    reservation?: ReservationWrapper,
    withSuccess?: boolean
  ) {
    if (isVisible) {
      this.proposalGet.fetch({ proposalId: reservation?._id });
    }

    this.setState({
      ...this.state,
      isVisibleMoveRequestModal: isVisible,
    });

    if (!isVisible && withSuccess) {
      this.loadReservationRequests();
    }
  }

  actionBodyTemplate = (rowData: ReservationWrapper) => {
    return (
      <>
        <Button
          icon="pi pi-thumbs-up"
          className="p-button-secondary"
          onClick={() => this.toggleConfirmRequestModal(true, rowData._id)}
        />
        <Button
          icon="pi pi-calendar"
          className="p-button-secondary p-button-outlined ml-2"
          onClick={() => this.toggleMoveRequestModal(true, rowData)}
        />
        <Button
          icon="pi pi-times"
          className="p-button-secondary p-button-outlined ml-2"
          onClick={() => this.toggleDeleteRequestModal(true, rowData._id)}
        />
      </>
    );
  };

  guestsBodyTemplate = (rowData: Reservation) => {
    return (
      <>
        <img src={userImage} alt="user" className="mr-2 mb-1" />
        {rowData.guests}
      </>
    );
  };

  dateBodyTemplate = (dateString: string) => {
    return (
      <>
        {new Date(dateString ?? '').toLocaleString(undefined, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
        })}
      </>
    );
  };

  render() {
    const { intlString } = this.props;
    const {
      requestsObject,
      params,
      selectedTags,
      isVisibleConfirmRequestModal,
      isVisibleDeleteRequestModal,
      isVisibleMoveRequestModal,
      proposalId,
      proposalGet,
    } = this.state;

    return (
      <div className="requests-tab">
        <div className="d-flex">
          <span className="p-input-icon-right">
            <InputText value={params.search.value} onChange={this.searchChange} />
            <i className="pi pi-search" />
          </span>
          <span className="d-flex align-items-center ml-5 flex-grow-1">
            {intlString('contactDialog.tag')}
            <div className="ml-3 tags-multiselect">
              <TagsDropdown
                handleChange={(values: InlineResponse2007[]) => this.onValuesChanges(values)}
                value={selectedTags}
              />
            </div>
          </span>
        </div>
        <hr className="mb-0" />
        <DataTable
          lazy
          value={requestsObject.response?.results}
          loading={requestsObject.loading}
          totalRecords={requestsObject.response?.recordsFiltered}
          first={params.start}
          className="p-datatable-requests"
          onPage={this.onPageChanged}
          rowHover
          paginator
          rows={params.length}
          emptyMessage={intlString('reservationsAndRequests.requests.table.noMessage')}
          currentPageReportTemplate={intlString(
            'reservationsAndRequests.requests.table.paginatorReport'
          )}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rowsPerPageOptions={[10, 20, 50, 100]}
          scrollable
          scrollHeight="calc(100vh - 27.8125rem)"
        >
          <Column
            field="title"
            header={intlString('reservationsAndRequests.reservations.table.event')}
          />
          <Column
            field="start"
            body={(res: any) => this.dateBodyTemplate(res.start)}
            header={intlString('reservationsAndRequests.reservations.table.eventDate')}
            headerStyle={{ width: '12em', textAlign: 'left' }}
            bodyStyle={{ textAlign: 'left', overflow: 'visible' }}
          />
          <Column
            field="reservation.app_user.last_nameXreservation.cellar_user.last_name"
            header={intlString('reservationsAndRequests.reservations.table.lastname')}
          />
          <Column
            field="reservation.app_user.first_nameXreservation.cellar_user.first_name"
            header={intlString('reservationsAndRequests.reservations.table.firstname')}
          />
          <Column
            field="reservation.app_user.emailXreservation.cellar_user.email"
            header={intlString('reservationsAndRequests.reservations.table.mail')}
          />
          <Column
            field="reservation.app_user.phoneXreservation.cellar_user.phone"
            header={intlString('reservationsAndRequests.reservations.table.phone')}
          />
          <Column
            field="reservation.guests"
            body={this.guestsBodyTemplate}
            header={intlString('reservationsAndRequests.reservations.table.guests')}
            headerStyle={{ width: '5em', textAlign: 'left' }}
            bodyStyle={{ textAlign: 'left', overflow: 'visible' }}
          />
          <Column
            field="reservation.language"
            header={intlString('reservationsAndRequests.reservations.table.language')}
            headerStyle={{ width: '7em', textAlign: 'left' }}
            bodyStyle={{ textAlign: 'left', overflow: 'visible' }}
          />
          <Column
            field="reservation.created"
            body={(res: any) => this.dateBodyTemplate(res.reservation.created)}
            header={intlString('reservationsAndRequests.reservations.table.reservationDate')}
            headerStyle={{ width: '12em', textAlign: 'left' }}
            bodyStyle={{ textAlign: 'left', overflow: 'visible' }}
          />
          <Column
            body={this.actionBodyTemplate}
            headerStyle={{ width: '12em', textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
          />
        </DataTable>
        <DialogConfirmRequest
          onHideDialog={(withSuccess: boolean) =>
            this.toggleConfirmRequestModal(false, undefined, withSuccess)
          }
          isVisible={isVisibleConfirmRequestModal}
          proposalId={proposalId ?? ''}
        ></DialogConfirmRequest>
        <DialogDeleteRequest
          onHideDialog={(withSuccess: boolean) =>
            this.toggleDeleteRequestModal(false, undefined, withSuccess)
          }
          isVisible={isVisibleDeleteRequestModal}
          proposalId={proposalId ?? ''}
        ></DialogDeleteRequest>
        <MoveReservationDialog
          isVisible={isVisibleMoveRequestModal}
          moveFromDate={new Date(proposalGet.response?.start ?? '')}
          reservation={proposalGet.response ?? undefined}
          onHideDialog={() => this.toggleMoveRequestModal(false, undefined, true)}
        ></MoveReservationDialog>
      </div>
    );
  }
}

export default withIntlString(RequestsTab);
