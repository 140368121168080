export default {
  draw: '1',
  columns: {
    '0': {
      data: '',
      name: '',
      searchable: 'true',
      orderable: 'false',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '1': {
      data: '_id',
      name: '',
      searchable: 'true',
      orderable: 'false',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '2': {
      data: 'cellar_user.first_name',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '3': {
      data: 'cellar_user.last_name',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '4': {
      data: 'cellar_user.phone',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '5': {
      data: 'cellar_user.email',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '6': {
      data: 'guests',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '7': {
      data: 'language',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '8': {
      data: 'event.titleXsurrogate_event.title',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '9': {
      data: 'event.startXsurrogate_event.start',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '10': {
      data: 'event._id',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '11': {
      data: 'created',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '12': {
      data: 'status',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '13': {
      data: 'tags',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '14': {
      data: 'event.location.timezoneXsurrogate_event.location.timezone',
      name: '',
      searchable: 'true',
      orderable: 'false',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '15': {
      data: 'event.location._idXsurrogate_event.location._id',
      name: '',
      searchable: 'true',
      orderable: 'false',
      search: {
        value: '',
        regex: 'false',
      },
    },
  },
  order: [
    {
      column: '0',
      dir: 'asc',
    },
  ],
  start: 0,
  length: 10,
  search: {
    value: '',
    regex: 'false',
  },
};
