import formValidation from './formValidation.en';
import { IntlMessage } from './models';

const messages: IntlMessage = {
  'general.winearound': 'WineAround',
  'general.cancel': 'Cancel',
  'general.previous': 'Back',
  'general.next': 'Forward',
  'general.end': 'End',
  'general.save': 'Save',
  'general.add': 'Aggiungi',
  'general.insert': 'Insert',
  'general.edit': 'Edit',
  'general.back': 'Back',
  'general.noResult': 'No records found',
  'general.max': 'Max',
  'general.min': 'Min',
  'general.start': 'Start',
  'general.title': 'Title',
  'general.confirm': 'Confirm',
  'general.hasChanges': 'You have unsaved changes',
  'general.hasChangesDesc': 'Attention. By continuing you will lose all changes',
  'general.confirmDelete': 'Remove confirm',
  'general.confirmDeleteReservation': 'Do you confirm the cancellation of the reservation?',
  'general.preview': 'Preview',
  'general.proceed': 'Proceed',
  'general.changesNotSaved': 'Attention. By continuing you will lose all changes!',

  'calendarToolbar.today': 'Today',
  'calendarToolbar.previous': 'Previous',
  'calendarToolbar.next': 'Next',
  'calendarToolbar.selectDate': 'Select date',
  'calendarToolbar.dayA': 'Day A (Card)',
  'calendarToolbar.dayB': 'Day B (Agenda)',
  'calendarToolbar.weekA': 'Week A (Card)',
  'calendarToolbar.weekB': 'Week B (Agenda)',
  'calendarToolbar.day': 'Day',
  'calendarToolbar.week': 'Week',
  'calendarToolbar.month': 'Month',
  'calendarToolbar.actions.newNote': 'New note',
  'calendarToolbar.actions.settings': 'Settings',
  'calendarToolbar.actions.editSettings': 'Edit settings',
  'calendarToolbar.actions.filters': 'Filters',
  'calendarToolbar.actions.refresh': 'Refresh',

  'calendarSettings.title': 'Calendar settings',
  'calendarSettings.visualisation': 'Default visualisation of calendar',
  'calendarSettings.timeSlot': 'Time slot',
  'calendarSettings.beginTime': 'Begin time',
  'calendarSettings.endTime': 'End time',
  'calendarSettings.minutes': 'minutes',
  'calendarSettings.hour': 'hour',

  'calendar.filters.setFilters': 'Set filters',
  'calendar.filters.filters': 'Filters',
  'calendar.filters.events': 'Events',
  'calendar.filters.languages': 'Languages',
  'calendar.filters.availableSeats': 'Available seats',
  'calendar.filters.onlyBookableEvents': 'Reserved events only',
  'calendar.filters.onlyWithAvailableOperator': 'Only events with operator available',
  'calendar.filters.onlyToConfirmBookings': 'Only events with reservations to be confirmed',
  'calendar.filters.same_start': 'Same_Start',
  'calendar.filters.OnlyVisible': 'Visible events only',
  'calendar.filters.cancelFilters': 'Cancel filters',
  'calendar.filters.selectedModels': '{0} selected models',
  'calendar.events.whichEventsToModify': 'Which events do you want to modify?',
  'calendar.events.modifyEventsWithBookings': 'Modify also the events with bookings',
  'calendar.events.period': 'Period:',
  'calendar.events.schedule': 'Schedule:',
  'calendar.events.days': 'Days:',
  'calendar.events.allFutureEvents': 'All future events',
  'calendar.events.dateRange': 'Date range',
  'calendar.events.allDay': 'All day',
  'calendar.events.timeRange': 'Time range',
  'calendar.events.dayNames.mon': 'mon',
  'calendar.events.dayNames.tue': 'tue',
  'calendar.events.dayNames.wed': 'wed',
  'calendar.events.dayNames.thu': 'thu',
  'calendar.events.dayNames.fri': 'fri',
  'calendar.events.dayNames.sat': 'sat',
  'calendar.events.dayNames.sun': 'sun',
  'calendar.events.selectedEvents': 'selected events',

  'eventDetail.eventInfo': 'EVENT INFO',
  'eventDetail.reservations': 'RESERVATIONS',
  'eventDetail.eventInformation': 'Event information',
  'eventDetail.start': 'Event start',
  'eventDetail.earlyClosing': 'Early closing',
  'eventDetail.pricePerson': 'Price per persona',
  'eventDetail.languages': 'Languages',
  'eventDetail.realTime': 'real-time',
  'eventDetail.onConfirm': 'upon confirmation',
  'eventDetail.visible': 'visible',
  'eventDetail.notVisible': 'not visible',
  'eventDetail.addReservation': 'Add reservation',
  'eventDetail.participants': 'participants',
  'eventDetail.requests': 'REQUESTS',
  'eventDetail.deleteEvent': 'delete this event',
  'eventDetail.note': 'Note',
  'eventDetail.extras': 'Extras',
  'eventDetail.questions': 'Questions',
  'eventDetail.addCellarNotePh': 'add notes here',

  'deleteEventDialog.title': 'Delete event',
  'deleteEventDialog.description':
    'Removing this event will <b>cancel all its reservations</b>. Are you sure you want to proceed? {linebreak} What percentage of the amount paid do you want to refund?',
  'deleteEventDialog.error': 'It is not possible to delete this event',

  'newReservation.reservation': 'Reservation',
  'newReservation.language': 'Language',
  'newReservation.guests': 'Guests',
  'newReservation.tags': 'Tags',
  'newReservation.notePlaceholder': 'add any notes here',
  'newReservation.booking': 'Prenotante',
  'newReservation.otherParticipants': 'Other Participants',
  'newReservation.otherParticipantsOptional': 'optional ({selected}/{total})',
  'newReservation.newContact': 'New contact',
  'newReservation.chooseFromAddressBook': 'Choose from address book',
  'newReservation.extra': 'Extra',
  'newReservation.additionalQuestions': 'Additional Questions',
  'newReservation.expense': 'Expense',
  'newReservation.expenseDesc': 'mark the amounts spent thereafter',
  'newReservation.deleteParticipant': 'Delete participant',
  'newReservation.deleteParticipantDesc':
    'Do you want to remove the participant from the reservation?',

  'contactDialog.title': 'Contact details',
  'contactDialog.firstName': 'First name',
  'contactDialog.lastName': 'Last name',
  'contactDialog.phone': 'Phone',
  'contactDialog.email': 'Mail',
  'contactDialog.country': 'Country',
  'contactDialog.tag': 'Tags',
  'contactDialog.saveContact': 'Save contact',

  'contactBookDialog.title': 'Inserisci contatto da rubrica',
  'contactBookDialog.noMessage': 'Nessun contatto',
  'contactBookDialog.paginatorReport': 'Showing {first} to {last} of {totalRecords} contacts',

  'navbar.goToDashboard': 'Go to dashboard',
  'navbar.calendar': 'Calendar',
  'navbar.reservations': 'Reservations',

  'navbar.menu.profile': 'Profile',
  'navbar.menu.billing': 'Billing',
  'navbar.menu.users': 'Users and permissions',
  'navbar.menu.logout': 'Logout',
  'navbar.menu.open': 'Open menu',
  'navbar.notifications': 'Notifications',
  'navbar.desktop': 'Desktop',

  'sidebar.title': 'Your WineAround',
  'sidebar.description': 'At any time you can check the result directly on your online page.',
  'sidebar.bookingButton': 'Booking Button',
  'sidebar.menu.company': 'Company',
  'sidebar.menu.places': 'Places',
  'sidebar.menu.operators': 'Operators',
  'sidebar.menu.address-book': 'Address book',
  'sidebar.menu.tags': 'Tags',
  'sidebar.menu.extras': 'Extras',
  'sidebar.menu.labels': 'Labels (beta)',
  'sidebar.menu.sealable': 'Sealable (beta)',
  'sidebar.menu.coupon': 'Coupon',
  'sidebar.menu.giftCards': 'Gift cards',
  'sidebar.menu.clubs': 'Clubs (beta)',
  'sidebar.menu.settings': 'Settings',
  'sidebar.menu.help': 'Help',
  'sidebar.menu.questions': 'Questions',

  'address-book.title': 'Guest address book',
  'address-book.newGuest': 'New guest',
  'address-book.deleteSelected': 'Delete selected',
  'address-book.tag': 'Tag',
  'address-book.tags': 'Tags',
  'address-book.name': 'First name',
  'address-book.surname': 'Last name',
  'address-book.phone': 'Phone',
  'address-book.email': 'Email',
  'address-book.actions': 'Actions',
  'address-book.selectedGuests': '<b>{guests}</b> guests selected',
  'address-book.deleteSelectedGuests': 'Delete selected guests',
  'address-book.deleteSelectedDesc':
    'The requested operation is not reversible. Do you confirm the deletion of the selected guests?',

  'newContactDialog.title': 'Guest',
  'newContactDialog.contactDetail': 'Contact details',
  'newContactDialog.additionalInfos': 'Additional infos',
  'newContactDialog.reservations': 'Reservations',
  'newContactDialog.expenses': 'Expenses',
  'newContactDialog.clientData': 'Client data',
  'newContactDialog.privateClient': 'Private client',
  'newContactDialog.firstName': 'FirstName',
  'newContactDialog.lastName': 'LastName',
  'newContactDialog.email': 'Email',
  'newContactDialog.phone': 'Phone',
  'newContactDialog.fiscalCodeIva': 'Fiscal code/VAT',
  'newContactDialog.company': 'Company',
  'newContactDialog.tags': 'Tags',
  'newContactDialog.shippingAddress': 'Shipping Address',
  'newContactDialog.billingAddress': 'Billing Address',
  'newContactDialog.street': 'Street',
  'newContactDialog.addressNumber': 'Number',
  'newContactDialog.city': 'City',
  'newContactDialog.province': 'Provincie',
  'newContactDialog.zip': 'ZIP',
  'newContactDialog.country': 'Country',
  'newContactDialog.deleteUser': 'Delete this guest',
  'newContactDialog.deleteSelectedGuests': 'Delete guest',
  'newContactDialog.deleteSelectedDesc':
    'The requested operation is not reversible. Do you confirm the deletion of the selected guest?',

  'locationSelector.selectLocation': 'Select location',
  'locationSelector.noLocationsFound': 'Locations not found',

  'eventModels.noModelsFound': 'Models not found',
  'eventModels.calendarModelsAll': 'All models',
  'eventModels.calendarModelsFav': 'Favorite models',
  'eventModels.show': 'show',
  'eventModels.hide': 'hide',

  'dashboard.addEventModel': 'Modello evento',

  'login.title': 'Login with your account',
  'login.forgotPassword': "I don't remember my password",
  'login.emailPlaceholder': 'Your email',
  'login.passwordPlaceholder': 'Your password',
  'login.btnSignIn': 'Sign in',
  'login.welcomeTitle': 'Welcome your customer in the best way',
  'login.welcomeDescription':
    'Offer impeccable customer service, from booking events to purchasing your products',
  'login.copyright': 'Copyright © 2020',
  'login.switchLanguage': 'Switch to italian',
  'login.invalidCredentials': 'Invalid email or password',
  'login.noAccount': 'Do not have an account?',
  'login.register': 'Register',

  'locations.title': 'Locations',
  'locations.newLocation': 'New location',
  'locations.name': 'Name',
  'locations.image': 'Image',
  'locations.address': 'Address',
  'locations.languages': 'Languages',
  'locations.actions': 'Actions',
  'locations.calendar': 'Calendar',
  'locations.rooms': 'Rooms',
  'locations.languagesDescription': 'Languages and Description',
  'locations.images': 'Images',
  'locations.position': 'Position',
  'locations.sale': 'Sale',
  'locations.chooseEventLanguage':
    'Indicate the languages in which you will hold your events (You can always modify or add them later)',
  'locations.removeLanguage': 'remove language',

  'locations.confirmDialog.title': 'Delete place',
  'locations.confirmDialog.text':
    '<strong>Warning!</strong> By deleting a place, you will no longer be able to manage the calendar and all related events<br /> Are you sure you want to proceed?',
  'locations.confirmDialog.confirmButton.text': 'Yes, delete place',

  'locationsDialog.generalInfo': 'General info',
  'locationsDialog.address': 'Address',
  'locationsDialog.addressNumber': 'Number',
  'locationsDialog.municipality': 'Municipality',
  'locationsDialog.province': 'Province',
  'locationsDialog.cap': 'CAP',
  'locationsDialog.languages': 'Languages',
  'locationsDialog.languagesDesc':
    'Choose the languages ​​in which you will hold your events (You can always modify or add them later)',
  'locationsDialog.languagesTranslations':
    'For each language you can change the name that will be displayed on your booking page',
  'locationsDialog.deleteLanguage': 'delete language',
  'locationsDialog.assignName':
    'Provide a name that allows you to identify the place (eg. Cantina Al Poggio)',
  'locationsDialog.charsLeft': '({number} chars left)',
  'locationsDialog.locationName': 'Place name',
  'locationsDialog.position': 'Position',
  'locationsDialog.addressDummy': 'eg. Via Roma 27, Milano',
  'locationsDialog.insertAddress':
    'Inserisci l’indirizzo qui sotto per visualizzarlo sulla mappa. Trascina l’indicatore per perfezionare la posizione',
  'locationsDialog.confirmAddress': 'Confirm address',
  'locationsDialog.confirmAddressDesc':
    'Complete, and possibly modify, the data collected by Google Maps of the address of your place. All fields must be filled in',
  'locationsDialog.multiSelectSelected': '{0} languages selected',
  'locationsDialog.selectLanguages': 'Select languages',

  'location.rooms.title': 'Add room to the place',
  'location.rooms.description':
    'You can add rooms to your venue and assign the seating capacity (slot) for each room',
  'location.rooms.newRoom': 'Create new room',
  'location.rooms.table.name': 'name',
  'location.rooms.table.slot': 'Slot',
  'location.rooms.table.actions': 'Actions',
  'location.rooms.table.sharedRoom.tooltip': 'Shared room',
  'location.rooms.delete.title': 'Delete room?',
  'location.rooms.delete.message': 'Confirm deletion of the room?',
  'location.rooms.delete.yes': 'Yes',

  'location.imageGallery.description':
    'Upload the images of your place and drag them to change the order in which they will be displayed',
  'location.imageGallery.chooseNewImage': 'Choose new image',

  'imageChooser.title': 'Choose image/s',
  'imageChooser.description':
    'Select one or more images from the list or upload a new one from your computer',
  'imageChooser.uploadButton': 'Upload another image',
  'imageChooser.selectedImages': ' selected images',

  'imageCrop.title': 'Define image aspect',
  'imageCrop.desc':
    'In the app, the photos may appear with the edges cut off compared to what is displayed below depending on the size of the smartphone, drag the photo trying to put the most representative details in the center of the rectangle to make sure they appear on any device',

  'location.roomEditDialog.title': 'Room and slot management',
  'location.roomEditDialog.name': 'Type a name for the room',
  'location.roomEditDialog.sharedRoom.title': 'Shared room',
  'location.roomEditDialog.sharedRoom.description':
    'this room can hold more than one event at the same time, the events assigned to this room will share its available seats',
  'location.roomEditDialog.newSlotLabel': 'Create new seat slot',
  'location.roomEditDialog.slot.tag': 'Tag',
  'location.roomEditDialog.slot.places': 'Places',

  'eventWizard.title': 'Create new event template',
  'eventWizard.name.label': 'Assign an identifying name',
  'eventWizard.color.label': 'Assign a color',
  'eventWizard.eventType.label': 'What kind of event template do you want to create?',
  'eventWizard.event.bookable': 'Bookable event',
  'eventWizard.event.readonly': 'Read only event',
  'eventWizard.event.liveRoom': 'Live Room',
  'eventWizard.charsLeft': '{number} chars left',
  'eventWizard.params': 'Params',
  'eventWizard.languages': 'Languages',
  'eventWizard.images': 'Images',
  'eventWizard.settings': 'Settings',
  'eventWizard.duration': 'Duration',
  'eventWizard.duration.label': 'Event duration',
  'eventWizard.closing.reservations': 'Closing reservations',
  'eventWizard.closing.reservations.tooltip':
    "Quanto tempo prima dell'inizio dell'evento vuoi chiudere le prenotazioni e oscurarne la visibilità su WineAround? Oltre a tale termine, l'evento non sarà più visibile né prenotabile sul circuito WineAround",
  'eventWizard.hide.duration': 'hide the duration of the event',
  'eventWizard.price': 'Price',
  'eventWizard.price.tooltip':
    'Dai un prezzo esatto o una indicazione di prezzo alla tua offerta. Il prezzo si intende per persona',
  'eventWizard.price.typology': 'Type',
  'eventWizard.euro.person': 'Euro/person',
  'eventWizard.available.seats': 'Available and bookable seats',
  'eventWizard.available.seats.label': 'Available seats',
  'eventWizard.available.seats.label.tooltip':
    'Qual è il numero massimo di persone che possono partecipare a questo tipo di evento? Indicalo qui',
  'eventWizard.minimum.reservations': 'Minimum reservations to activate the event',
  'eventWizard.minimum.reservations.tooltip':
    'Qual è il numero minimo di persone che possono partecipare a questo tipo di evento? Indicalo qui',
  'eventWizard.bookable.seats': 'Bookable seats',
  'eventWizard.bookable.seats.tooltip':
    'Qual è il numero massimo di persone che possono partecipare a questo tipo di evento? Indicalo qui, inserendo 0 questa funzione sarà disattivata',
  'eventWizard.onDemand': 'On demand',
  'eventWizard.fixed': 'Fixed',
  'eventWizard.closureTypes.0': 'Nessuna',
  'eventWizard.closureTypes.30': '30 minuti',
  'eventWizard.closureTypes.60': '1 ora',
  'eventWizard.closureTypes.120': '2 ore',
  'eventWizard.closureTypes.180': '3 ore',
  'eventWizard.closureTypes.360': '6 ore',
  'eventWizard.closureTypes.480': '8 ore',
  'eventWizard.closureTypes.720': '12 ore',
  'eventWizard.closureTypes.1440': '1 giorno',
  'eventWizard.closureTypes.2880': '2 giorni',
  'eventWizard.closureTypes.4320': '3 giorni',
  'eventWizard.closureTypes.5760': '4 giorni',
  'eventWizard.closureTypes.7200': '5 giorni',
  'eventWizard.closureTypes.8640': '6 giorni',
  'eventWizard.closureTypes.10080': '1 settimana',
  'eventWizard.images.title': 'Images',
  'eventWizard.images.description': 'Upload one or more images that represent this event',
  'eventWizard.settings.title': 'Modalità di prenotazione predefinita',
  'eventWizard.settings.description':
    'potrai sempre cambiarla direttamente da calendario sul singolo evento',
  'eventWizard.settings.bookingRealTime':
    'Prenotazione <strong>real-time</strong> (automaticamente confermate)',
  'eventWizard.settings.bookingConfirm':
    'Prenotazione <b>su conferma</b> (dovrai confermare la prenotazione, potrai rifiutarla o spostarla in altro orario )',
  'eventWizard.settings.modelSettings': 'Impostazioni del modello evento',
  'eventWizard.settings.languageManage': 'Gestione lingue evento',
  'eventWizard.settings.closeLanguageOnNewReservation':
    'quando ricevi la prima prenotazione in una data lingua <b>disattiva tutte le altre lingue</b>',
  'eventWizard.settings.closeLanguageOnNewReservation.tooltip': 'Da definire',
  'eventWizard.settings.bookingMode': 'Cambio modalità prenotazione',
  'eventWizard.settings.unpauseOnNewReservation':
    '<b>passa a prenotazioni realtime</b> dopo la prima prenotazione confermata',
  'eventWizard.settings.onNoReservations':
    '<b>torna a prenotazioni su conferma</b> dopo la cancellazione dell’ultima (unica) prenotazione (se tutti disdicono)',
  'eventWizard.settings.privateTour': 'Tour privato',
  'eventWizard.settings.privateTour.tooltip': 'Da definire',
  'eventWizard.settings.hideOnNewReservation': "dopo la prima prenotazione nascondi l'evento",
  'eventWizard.settings.showOnNoReservations':
    "se la prenotazione viene cancellata rendi nuovamente visibile l'evento",
  'eventWizard.settings.autoEventCreation': 'Crea evento uguale in automatico',
  'eventWizard.settings.newGroupOnNewReservation': 'quando viene prenotato',
  'eventWizard.settings.onNewReservation': 'quando hai',
  'eventWizard.settings.availableSeats': 'posti disponibili',
  'eventWizard.settings.upToTimes': 'fino a quante volte?',
  'eventWizard.settings.removeEmptyGroupOnNoReservations':
    'dopo la cancellazione dell’ultima prenotazione cancella eventi uguali che non hanno ancora prenotazioni, per evitare duplicati',

  'location.position.title': 'Address of the place',
  'location.position.mapDragDescription': 'Drag the pointer and place it in the exact spot',

  'models.parameters': 'Parameters',
  'models.languagesDescription': 'Languages and Descriptions',
  'models.images': 'Images',
  'models.imageGallery.description': 'Upload one or more images representing this event',
  'models.settings': 'Settings',
  'models.filters': 'Filters',
  'models.messages': 'Messages',
  'models.questions': 'Questions',
  'models.roomsOperator': 'Rooms and operators',
  'models.payments': 'Payments',
  'models.tags': 'Tags',
  'models.extras': 'Extras',
  'models.event.bookable': 'Bookable event',
  'models.event.readonly': 'Read only event',
  'models.event.liveRoom': 'Live Room',
  'models.filters.title': 'Calendar filters',
  'models.filters.description':
    'Set which filters should be applied to your calendar when your availability is shown to the public',
  'models.filters.sameStart': 'SAME_START: hide if reservation exists at the same start time',
  'models.filters.sameStart.description':
    'this event will be hidden if there is another event in the calendar with the same start time that was booked',
  'models.filters.sameLanguages':
    'SAME_LANGUAGE: hide (or show) if there is a reservation for the same language',
  'models.filters.sameLanguages.description':
    'this event will be hidden (or shown) for a specific language requested if there is another event of the same type (or any type) in the calendar with a reservation for that language; in a variable range from the start of the event',
  'models.filters.past': 'past',
  'models.filters.future': 'future',
  'models.filters.considerAllEvents': 'Consider events of <b>all kinds</b>',
  'models.filters.considerSameEvents': 'Only consider events of <b>this same type</b>',
  'models.filters.showExistsAnotherEvent':
    'the event will appear only if another event <b>exists</b> with a reservation in the requested language',
  'models.filters.showNotExistsAnotherEvent':
    'the event will appear only if there is <b>no other event</b> with a reservation in the requested language',
  'models.filters.eventPriority':
    'EVENT_PRIORITY: hide if there is no reservation for lower priority event',
  'models.filters.eventPriority.description':
    'this event will be hidden if there is no other event of the same type in the calendar containing reservations and with a slightly lower level of priority in the step; in a variable range from the start of the event',
  'models.filters.hidePast': 'hide past',
  'models.filters.hideFuture': 'hide future',
  'models.filters.offset.0': '0',
  'models.filters.offset.30': '30 minuti',
  'models.filters.offset.45': '45 minuti',
  'models.filters.offset.60': '1 ora',
  'models.filters.offset.75': '1 ora e 15 minuti',
  'models.filters.offset.90': '1 ora e mezza',
  'models.filters.offset.105': '1 ora e 45 minuti',
  'models.filters.offset.120': '2 ore',
  'models.filters.offset.150': '2 ore e mezza',
  'models.filters.offset.180': '3 ore',
  'models.filters.offset.210': '3 ore mezza',
  'models.filters.offset.240': '4 ore',
  'models.filters.offset.300': '5 ore',
  'models.filters.offset.360': '6 ore',
  'models.filters.offset.420': '7 ore',
  'models.filters.offset.480': '8 ore',
  'models.filters.offset.540': '9 ore',
  'models.filters.offset.600': '10 ore',
  'models.filters.offset.660': '11 ore',
  'models.filters.offset.720': '12 ore',
  'models.payments.title': 'Payments',
  'models.payments.description':
    'Remember that you need to link your <b>Stripe</b> account before you can get paid.',
  'models.payments.enablePayments': 'I want to activate credit card payments for this event',
  'models.payments.reservationAmount':
    'Percentage of the amount to be withdrawn at the time of booking',
  'models.payments.limitAnticipatedPayment':
    'I want to limit the prepayment within a certain threshold',
  'models.payments.maxAmount': 'Maximum amount in euros',
  'models.payments.refound':
    'I want the user to receive a refund on the amount paid in case they cancel the booking',
  'models.payments.noticeDays': 'Days notice of cancellation',
  'models.payments.refoundPercentage':
    'Percentage on the amount paid to be returned to the user in case of cancellation',
  'models.payments.refoundPercentageBefore': 'BEFORE the deadline',
  'models.payments.refoundPercentageAfter': 'AFTER the deadline',
  'models.payments.requestCard':
    'I want the card to be requested only beyond a certain number of participants',
  'models.payments.minGuestsNumber': 'Minimum number of participants',
  'models.payments.reservationAmountTooltip': 'string',
  'models.payments.maxAmountTooltip': 'string',
  'models.payments.noticeDaysTooltip': 'string',
  'models.payments.refoundPercentageTooltip': 'string',
  'models.payments.minGuestsNumberTooltip': 'string',
  'models.tags.title': 'Tags',
  'models.tags.description': 'You can assign tags to categorize your events',
  'models.rooms.title': 'Rooms',
  'models.rooms.description': 'Enable rooms for this event',
  'models.rooms.chooseDescription':
    'Choose which rooms and which slots are available to manage this event',
  'models.operators.title': 'Operators',
  'models.operators.description': 'Enable operators for this event',
  'models.operators.chooseDescription': 'Choose which operators are available to manage this event',
  'models.extras.title': 'Extras',
  'models.extras.description':
    'Choose the extras that can be added when booking this event. You can always <b>create new ones or modify them</b> in the <a>EXTRA</a> section',
  'models.extras.chooseExtra': 'Choose Extra',
  'models.extras.createExtras': 'You can create new extras in the section {children}',
  'models.extras.table.id': 'Id',
  'models.extras.table.languages': 'languages',
  'models.extras.table.price': 'Price',
  'models.extras.table.availability': 'Availability',
  'models.extras.table.chooseAvailability': 'Choose availability',
  'models.extras.table.actions': 'Actions',
  'models.extras.table.equalToGuestsNo': 'Equal to guests no.',
  'models.extras.selecredExtras': ' selected extras',
  'models.questions.description':
    'Choose the questions the user will be asked when booking this event. You can always create new ones in the <a>QUESTIONS</a> section',
  'models.questions.chooseQuestion': 'Choose the question',
  'models.questions.chooseExistingQuestion': 'Choose existing question',
  'models.questions.createQuestions':
    'You can always create new questions in the <a>QUESTIONS</a> section',
  'models.questions.selectedQuestions': ' selected questions',
  'models.questions.mandatory': 'Mandatory',
  'models.questions.optional': 'Optional',
  'models.delete.text': 'delete this model',
  'models.delete.title': 'Delete event model?',
  'models.delete.confirm': 'Do you confirm the deletion of the event model?',

  'extras.title': 'Extras',
  'extras.subtitle': 'Services or products that can be chosen when booking your events',
  'extras.newExtra': 'New extra',

  'models.messages.title': 'Messages',
  'models.messages.subtitle':
    'You can activate and customize the messages that are sent to the user',
  'models.messages.whenToSendMessage': 'When to send the message?',
  'models.messages.after': 'after',
  'models.messages.before': 'before',
  'models.messages.theEvent': 'the event',
  'models.messages.tab1.title': 'WAITING FOR CONFIRMATION',
  'models.messages.tab1.subtitle':
    'email notification to the user following a reservation for which confirmation is required',
  'models.messages.tab1.desc':
    'The User receives an e-mail notification every time he makes a reservation so <strong> will have to wait for your confirmation </strong>. You can write your message below which will be sent to the user together with the notification',
  'models.messages.tab2.title': 'BOOKING CONFIRMATION',
  'models.messages.tab2.subtitle': 'confirmed booking email',
  'models.messages.tab2.desc':
    'Every time you confirm a reservation the user receives an email notification. You can write your message below which will be <strong> added </strong> to the message to the user <strong> in case of confirmed booking </strong> (in case of acceptance from the calendar it will still be possible to modify it)',
  'models.messages.tab3.title': 'RESERVATION CANCELLATION',
  'models.messages.tab3.subtitle':
    'email notification to the user following a booking cancellation',
  'models.messages.tab3.desc':
    'Every time you confirm a reservation the user receives an email notification. You can write your message below which will be <strong> added </strong> to the message to the user <strong> in case of confirmed booking </strong> (in case of acceptance from the calendar it will still be possible to modify it)',
  'models.messages.tab4.title': 'FINALIZATION',
  'models.messages.tab4.subtitle': 'booking finalization email (direct link to payment)',
  'models.messages.tab4.desc':
    'This will be the default message when you send a finalization email to the user for events of this type',
  'models.messages.tab5.title': 'REMINDER',
  'models.messages.tab5.subtitle': 'reminder email before the start of the event you have booked',
  'models.messages.tab5.desc':
    'You can write your message below which will be sent to the user <strong> together with the reminder email </strong>',
  'models.messages.tab6.title': 'FOLLOW-UP',
  'models.messages.tab6.subtitle': 'follow-up email after the booked event',
  'models.messages.tab6.desc':
    'You can write below your message that will be sent to the user <strong> after the end of the event he booked. </strong> You can write below the subject of the email and the message that will be sent to the user',

  'questions.title': 'Questions',
  'questions.subtitle': 'Here you can add questions that will be asked when booking your events',
  'questions.newQuestion': 'New question',
  'questions.confirmDialog.title': 'Delete question',
  'questions.confirmDialog.text': 'Do you confirm the deletion of the selected question?',
  'questions.confirmDialog.confirmButton.text': 'Yes, delete question',

  'extras.dialog.id': 'Identifier',
  'extras.dialog.euro': 'Euro',
  'extras.dialog.languages': 'Languages',

  'questionDialog.title': 'Question',
  'questionDialog.form.identifier': 'Identifier',
  'questionDialog.form.addAnswer': 'Add answer',
  'questionDialog.form.type.radio': 'Single answer',
  'questionDialog.form.type.text': 'Textual',
  'questionDialog.form.type.checkbox': 'Multiple answer',
  'questionDialog.option.title': 'Answer',
  'questionDialog.option.identifier': 'Answer identifier',

  'extras.deleteDialog.title': 'Delete extra',
  'extras.deleteDialog.text': 'Do you confirm the deletion of the selected extra?',
  'extras.deleteDialog.confirmButton.text': 'Confirm',

  'notes.newDialog.header': 'Note',
  'notes.newDialog.title': 'Title',
  'notes.newDialog.period': 'Period:',
  'notes.newDialog.allDay': 'All day',
  'notes.newDialog.description': 'Description',
  'notes.newDialog.create': 'Create note',

  'events.move.reservationsError':
    'This event cannot be moved because there are already reservations',
  'events.move.error': 'Unable to move this event',
  'events.operators.chooseDescription':
    'Scegli quali operatori sono disponibili per gestire questo evento, trascina gli operatori per ordinarli in base alla loro priorità',

  'reservations.moveDialog.title': 'Move reservation of {date}',
  'reservations.moveDialog.description':
    'Choose a date to move the <b>{personName}</b> booking to:',
  'reservations.moveDialog.noEvents': 'No events found',
  'reservations.table.status': 'State',
  'reservations.table.name': 'Host',
  'reservations.table.guests': 'Guests',
  'reservations.table.language': 'Language',
  'reservations.table.mail': 'Email',
  'reservations.table.phone': 'Phone',
  'reservations.table.date': 'Reservation date',
  'reservations.table.actions': 'Actions',

  'reservations.status.unconfirmed': 'unconfirmed',
  'reservations.status.confirmed': 'confirmed',
  'reservations.status.finalizing': 'finalizing',
  'reservations.status.pending': 'pending',
  'reservations.status.deleted': 'deleted',

  'reservationsAndRequests.title': 'Reservations and requests',
  'reservationsAndRequests.reservations': 'RESERVATIONS',
  'reservationsAndRequests.requests': 'REQUESTS',
  'reservationsAndRequests.reservations.table.status': 'State',
  'reservationsAndRequests.reservations.table.event': 'Event',
  'reservationsAndRequests.reservations.table.eventDate': 'event Date',
  'reservationsAndRequests.reservations.table.lastname': 'Lastname',
  'reservationsAndRequests.reservations.table.firstname': 'Firstname',
  'reservationsAndRequests.reservations.table.mail': 'Email',
  'reservationsAndRequests.reservations.table.phone': 'Phone',
  'reservationsAndRequests.reservations.table.guests': 'Guests',
  'reservationsAndRequests.reservations.table.language': 'Language',
  'reservationsAndRequests.reservations.table.reservationDate': 'Reservation date',
  'reservationsAndRequests.reservations.table.actions': 'Actions',
  'reservationsAndRequests.reservations.table.noMessage': 'No reservations',
  'reservationsAndRequests.reservations.table.paginatorReport':
    'Showing {first} to {last} of {totalRecords} reservations',
  'reservationsAndRequests.requests.table.noMessage': 'No reservation request',
  'reservationsAndRequests.requests.table.paginatorReport':
    'Showing {first} to {last} of {totalRecords} requests',
  'reservationsAndRequests.acceptRequest': 'Accept request',
  'reservationsAndRequests.sendRequestEmail':
    'Do you want to send a confirmation email to the user after accepting the booking request? You can always send it even later',
  'reservationsAndRequests.doSendEmail': 'Yes, send confirmation',
  'reservationsAndRequests.doNotSendEmail': 'No, do not send',
  'reservationsAndRequests.deleteRequest': 'Delete request',
  'reservationsAndRequests.confirmDeleteRequest':
    'Are you really sure you want to decline this request? Remember that you can agree on a different time with the user and then move it',

  'guests.reservations.table.id': 'ID prenotazione',
  'guests.reservations.table.date': 'Data e ora evento',
  'guests.reservations.table.event': 'Evento',
  'guests.reservations.table.status': 'Stato',

  ...formValidation,
};

export default messages;
