import { ActionTypes, SidebarState } from './types';
import { SET_SIDEBAR_VISIBILITY } from './actions';

const initialState: SidebarState = {
  visible: false,
};

export default (state = initialState, action: ActionTypes): SidebarState => {
  switch (action.type) {
    case SET_SIDEBAR_VISIBILITY:
      return {
        visible: action.visible,
      };

    default: {
      return state;
    }
  }
};
