import { FormattedMessageProps } from '../components/IntlString';
import { LocaleObject, setLocale } from 'yup';
import { IntFormMessageId } from './models';

interface Values {
  path?: string;

  [key: string]: any;
}

/**
 * Create an object to be passed to the IntlString component.
 * Name of the function is voluntarily short.
 * @param id
 * @param values
 */
function c(id: keyof IntFormMessageId, values: Values): FormattedMessageProps {
  return { id, values };
}

const config = {
  mixed: {
    default: ({ path }) => c('formValidation.mixed.default', { path }),
    required: ({ path }) => c('formValidation.mixed.required', { path }),
    oneOf: ({ path, values }) => c('formValidation.mixed.oneOf', { path, values }),
    notOneOf: ({ path, values }) => c('formValidation.mixed.notOneOf', { path, values }),
  },
  number: {
    min: ({ path, min }) => c('formValidation.number.min', { path, min }),
    max: ({ path, max }) => c('formValidation.number.max', { path, max }),
    lessThan: ({ path, less }) => c('formValidation.number.lessThan', { path, less }),
    moreThan: ({ path, more }) => c('formValidation.number.moreThan', { path, more }),
    notEqual: ({ path, notEqual }: { path: string; notEqual: number }) =>
      c('formValidation.number.notEqual', {
        path,
        notEqual,
      }),
    positive: ({ path }) => c('formValidation.number.positive', { path }),
    negative: ({ path }) => c('formValidation.number.negative', { path }),
    integer: ({ path }) => c('formValidation.number.integer', { path }),
  },
  string: {
    length: ({ path, length }) => c('formValidation.string.length', { path, length }),
    min: ({ path, min }) => c('formValidation.string.min', { path, min }),
    max: ({ path, max }) => c('formValidation.string.max', { path, max }),
    matches: ({ path, regex }) => c('formValidation.string.matches', { path, regex }),
    email: ({ path }) => c('formValidation.string.email', { path }),
    url: ({ path }) => c('formValidation.string.url', { path }),
    lowercase: ({ path }) => c('formValidation.string.lowercase', { path }),
    uppercase: ({ path }) => c('formValidation.string.uppercase', { path }),
  },
  date: {
    min: ({ path, min }) => c('formValidation.date.min', { path, min }),
    max: ({ path, max }) => c('formValidation.date.max', { path, max }),
  },
  array: {
    min: ({ path, min }) => c('formValidation.date.min', { path, min }),
    max: ({ path, max }) => c('formValidation.date.max', { path, max }),
  },
} as LocaleObject;

export function configureFormValidation() {
  setLocale(config);
}
