import formValidation from './formValidation.it';
import { IntlMessage } from './models';

const messages: IntlMessage = {
  'general.winearound': 'WineAround',
  'general.cancel': 'Annulla',
  'general.previous': 'Indietro',
  'general.next': 'Avanti',
  'general.end': 'Fine',
  'general.save': 'Salva',
  'general.add': 'Aggiungi',
  'general.insert': 'Inserisci',
  'general.edit': 'Edit',
  'general.back': 'Back',
  'general.noResult': 'Nessun risultato trovato',
  'general.max': 'Massimo',
  'general.min': 'Minimo',
  'general.start': 'Inizia',
  'general.title': 'Titolo',
  'general.confirm': 'Conferma',
  'general.hasChanges': 'Hai modifiche non salvate',
  'general.hasChangesDesc': 'Attenzione. Procedendo perderai tutte le modifiche',
  'general.confirmDelete': 'Conferma eliminazione',
  'general.confirmDeleteReservation': 'Confermi la cancellazione della prenotazione?',
  'general.preview': 'Anteprima',
  'general.proceed': 'Procedi',
  'general.changesNotSaved': 'Hai modifiche non salvate. Procedendo perderai tutti i dati!',

  'calendarToolbar.today': 'Oggi',
  'calendarToolbar.previous': 'Precedente',
  'calendarToolbar.next': 'Successivo',
  'calendarToolbar.selectDate': 'Seleziona data',
  'calendarToolbar.dayA': 'Giorno A (Card)',
  'calendarToolbar.dayB': 'Giorno B (Agenda)',
  'calendarToolbar.weekA': 'Settimana A (Card)',
  'calendarToolbar.weekB': 'Settimana B (Agenda)',
  'calendarToolbar.day': 'Giorno',
  'calendarToolbar.week': 'Settimana',
  'calendarToolbar.month': 'Mese',
  'calendarToolbar.actions.newNote': 'Nuova nota',
  'calendarToolbar.actions.settings': 'Impostazioni',
  'calendarToolbar.actions.editSettings': 'Impostazioni di modifica',
  'calendarToolbar.actions.filters': 'Filtri',
  'calendarToolbar.actions.refresh': 'Aggiorna',

  'calendarSettings.title': 'Impostazioni calendario',
  'calendarSettings.visualisation': 'Visualizzazione default del calendario',
  'calendarSettings.timeSlot': 'Slot temporale',
  'calendarSettings.beginTime': 'Ora inizio',
  'calendarSettings.endTime': 'Ora fine',
  'calendarSettings.minutes': 'minuti',
  'calendarSettings.hour': 'ora',

  'calendar.filters.setFilters': 'Imposta filtri',
  'calendar.filters.filters': 'Filtri',
  'calendar.filters.events': 'Eventi',
  'calendar.filters.languages': 'Lingue',
  'calendar.filters.availableSeats': 'Posti disponibili',
  'calendar.filters.onlyBookableEvents': 'Solo eventi prenotati',
  'calendar.filters.onlyWithAvailableOperator': 'Solo eventi con operatore disponibile',
  'calendar.filters.onlyToConfirmBookings': 'Solo eventi con prenotazioni da confermare',
  'calendar.filters.same_start': 'Same_Start',
  'calendar.filters.OnlyVisible': 'Solo eventi visibili',
  'calendar.filters.cancelFilters': 'Cancella filtri',
  'calendar.filters.selectedModels': '{0} modelli selezionati',
  'calendar.events.whichEventsToModify': 'Quali eventi vuoi modificare?',
  'calendar.events.modifyEventsWithBookings': 'Modifica anche gli eventi con prenotazione',
  'calendar.events.period': 'Periodo:',
  'calendar.events.schedule': 'Orario:',
  'calendar.events.days': 'Giorni:',
  'calendar.events.allFutureEvents': 'Tutti gli eventi futuri',
  'calendar.events.dateRange': 'Range di dati',
  'calendar.events.allDay': 'Tutta la giornata',
  'calendar.events.timeRange': 'Range di orari',
  'calendar.events.dayNames.mon': 'lun',
  'calendar.events.dayNames.tue': 'mar',
  'calendar.events.dayNames.wed': 'mer',
  'calendar.events.dayNames.thu': 'gio',
  'calendar.events.dayNames.fri': 'ven',
  'calendar.events.dayNames.sat': 'sa',
  'calendar.events.dayNames.sun': 'dom',
  'calendar.events.selectedEvents': 'eventi selezionati',

  'eventDetail.eventInfo': 'INFO EVENTO',
  'eventDetail.reservations': 'PRENOTAZIONI',
  'eventDetail.eventInformation': 'Informazioni evento',
  'eventDetail.start': 'Inizio evento',
  'eventDetail.earlyClosing': 'Chiusura anticipata',
  'eventDetail.pricePerson': 'Prezzo a persona',
  'eventDetail.languages': 'Lingue',
  'eventDetail.realTime': 'real-time',
  'eventDetail.onConfirm': 'su conferma',
  'eventDetail.visible': 'visibile',
  'eventDetail.notVisible': 'non visibile',
  'eventDetail.addReservation': 'Nuova prenotazione',
  'eventDetail.participants': 'partecipanti',
  'eventDetail.requests': 'RICHIESTE',
  'eventDetail.deleteEvent': 'elimina questo evento',
  'eventDetail.note': 'Note prenotazione',
  'eventDetail.extras': 'Extra',
  'eventDetail.questions': 'Domande',
  'eventDetail.addCellarNotePh': 'aggiungi eventuali note qui',

  'deleteEventDialog.title': 'Elimina evento',
  'deleteEventDialog.description':
    'Rimuovendo questo evento <b>cancellerai tutte le sue prenotazioni</b>. Sei sicuro di voler procedere? {linebreak} Quale percentuale dell’importo versato vuoi rimborsare?',
  'deleteEventDialog.error': 'Non è possibile eliminare questo evento',

  'newReservation.reservation': 'Prenotazione',
  'newReservation.language': 'Lingua',
  'newReservation.guests': 'Ospiti',
  'newReservation.tags': 'Tag',
  'newReservation.notePlaceholder': 'aggiungi eventuali note qui',
  'newReservation.booking': 'Prenotante',
  'newReservation.otherParticipants': 'Altri partecipanti',
  'newReservation.otherParticipantsOptional': 'facoltativo ({selected}/{total})',
  'newReservation.newContact': 'Nuovo contatto',
  'newReservation.chooseFromAddressBook': 'Scegli da rubrica',
  'newReservation.extra': 'Extra',
  'newReservation.additionalQuestions': 'Domande aggiuntive',
  'newReservation.expense': 'Spesa',
  'newReservation.expenseDesc': 'segna gli importi spesi in seguito',
  'newReservation.deleteParticipant': 'Elimina partecipante',
  'newReservation.deleteParticipantDesc': 'Vuoi eliminare il partecipante dalla prenotazione?',

  'contactDialog.title': 'Contatto anagrafica',
  'contactDialog.firstName': 'Nome',
  'contactDialog.lastName': 'Cognome',
  'contactDialog.phone': 'Telefono',
  'contactDialog.email': 'Email',
  'contactDialog.country': 'Paese',
  'contactDialog.tag': 'Tag',
  'contactDialog.saveContact': 'Salva contatto',

  'contactBookDialog.title': 'Inserisci contatto da rubrica',
  'contactBookDialog.noMessage': 'Nessun contatto',
  'contactBookDialog.paginatorReport': 'Mostra {first} a {last} di {totalRecords} contatti',

  'navbar.goToDashboard': 'Vai alla dashboard',
  'navbar.calendar': 'Calendario',
  'navbar.reservations': 'Prenotazioni',

  'navbar.menu.profile': 'Profilo',
  'navbar.menu.billing': 'Fatturazione',
  'navbar.menu.users': 'Utenti e permessi',
  'navbar.menu.logout': 'Logout',
  'navbar.menu.open': 'Apri menu',
  'navbar.notifications': 'Notifiche',
  'navbar.desktop': 'Desktop',

  'sidebar.title': 'Il tuo WineAround',
  'sidebar.description':
    'In ogni momento puoi verificare il risultato direttamente sulla tua pagina online',
  'sidebar.bookingButton': 'Booking Button',
  'sidebar.menu.company': 'Azienda',
  'sidebar.menu.places': 'Luoghi',
  'sidebar.menu.operators': 'Operatori',
  'sidebar.menu.address-book': 'Rubrica ospiti',
  'sidebar.menu.tags': 'Tags',
  'sidebar.menu.extras': 'Extra',
  'sidebar.menu.labels': 'Etichette (beta)',
  'sidebar.menu.sealable': 'Vendibili (beta)',
  'sidebar.menu.coupon': 'Coupon',
  'sidebar.menu.giftCards': 'Carte regalo',
  'sidebar.menu.clubs': 'Clubs (beta)',
  'sidebar.menu.settings': 'Impostazioni',
  'sidebar.menu.help': 'Assistenza',
  'sidebar.menu.questions': 'Domande',

  'address-book.title': 'Rubrica ospiti',
  'address-book.newGuest': 'Nuovo ospite',
  'address-book.deleteSelected': 'Elimina selezionati',
  'address-book.tag': 'Tag',
  'address-book.tags': 'Tags',
  'address-book.name': 'Nome',
  'address-book.surname': 'Cognome',
  'address-book.phone': 'Telefono',
  'address-book.email': 'Email',
  'address-book.actions': 'Azioni',
  'address-book.selectedGuests': '<b>{guests}</b> ospiti selezionati',
  'address-book.deleteSelectedGuests': 'Elimina ospiti selezionati',
  'address-book.deleteSelectedDesc':
    "L'operazione richiesta non è reversibile. Confermi l'eliminazione degli ospiti selezionati?",

  'newContactDialog.title': 'Ospite',
  'newContactDialog.contactDetail': 'Anagrafica',
  'newContactDialog.additionalInfos': 'Info aggiuntive',
  'newContactDialog.reservations': 'Prenotazioni',
  'newContactDialog.expenses': 'Spese',
  'newContactDialog.clientData': 'Dati cliente',
  'newContactDialog.privateClient': 'Cliente privato',
  'newContactDialog.firstName': 'Nome',
  'newContactDialog.lastName': 'Cognome',
  'newContactDialog.email': 'Email',
  'newContactDialog.phone': 'Telefono',
  'newContactDialog.fiscalCodeIva': 'CF/Partita Iva',
  'newContactDialog.company': 'Azienda',
  'newContactDialog.tags': 'Tags',
  'newContactDialog.shippingAddress': 'Indirizzo spedizione',
  'newContactDialog.billingAddress': 'Indirizzo fatturazione',
  'newContactDialog.street': 'Via, strada/...',
  'newContactDialog.addressNumber': 'Numero',
  'newContactDialog.city': 'Città',
  'newContactDialog.province': 'Stato regione',
  'newContactDialog.zip': 'CAP',
  'newContactDialog.country': 'Nazione',
  'newContactDialog.deleteUser': 'Elimina questo ospite',
  'newContactDialog.deleteSelectedGuests': 'Elimina ospite',
  'newContactDialog.deleteSelectedDesc':
    "L'operazione richiesta non è reversibile. Confermi l'eliminazione di questo ospite?",

  'locationSelector.selectLocation': 'Seleziona luogo',
  'locationSelector.noLocationsFound': 'Luoghi non trovati',

  'eventModels.noModelsFound': 'Modelli non trovati',
  'eventModels.calendarModelsAll': 'Tutti i modelli',
  'eventModels.calendarModelsFav': 'Modelli preferiti',
  'eventModels.show': 'mostra',
  'eventModels.hide': 'nascondi',

  'dashboard.addEventModel': 'Modello evento',

  'login.title': 'Accedi con il tuo account',
  'login.forgotPassword': 'Non ricordo la password',
  'login.emailPlaceholder': 'La tua email',
  'login.passwordPlaceholder': 'La tua password',
  'login.btnSignIn': 'Accedi',
  'login.welcomeTitle': 'Welcome your customer in the best way',
  'login.welcomeDescription':
    'Offri un servizio impeccabile al cliente, dalla prenotazione di eventi all’acquisto dei tuoi prodotti',
  'login.copyright': 'Copyright © 2020',
  'login.switchLanguage': 'Switch to english',
  'login.invalidCredentials': 'Email o password non valida',
  'login.noAccount': 'Non hai un account?',
  'login.register': 'Registrati',

  'locations.title': 'Luoghi',
  'locations.newLocation': 'Nuovo luogo',
  'locations.name': 'Nome',
  'locations.image': 'Immagine',
  'locations.address': 'Indirizzo',
  'locations.languages': 'Lingue',
  'locations.actions': 'Azioni',
  'locations.calendar': 'Calendario',
  'locations.rooms': 'Sale',
  'locations.languagesDescription': 'Lingue e Descrizione',
  'locations.images': 'Immagini',
  'locations.position': 'Posizione',
  'locations.sale': 'Sale',
  'locations.chooseEventLanguage':
    'Indica le lingue in cui svolgerai i tuoi eventi (Potrai sempre modificarle o aggiungerle in seguito)',
  'locations.removeLanguage': 'elimina lingua',

  'locations.confirmDialog.title': 'Elimina luogo',
  'locations.confirmDialog.text':
    '<strong>Attenzione!</strong> Eliminando un luogo non si potranno più gestire il calendario e tutti gli eventi collegati<br /> Sei sicuro di voler procedere?',
  'locations.confirmDialog.confirmButton.text': 'Sì, elimina luogo',

  'locationsDialog.generalInfo': 'Informazioni generali',
  'locationsDialog.address': 'Indirizzo',
  'locationsDialog.addressNumber': 'Numero',
  'locationsDialog.municipality': 'Comune',
  'locationsDialog.province': 'Provincia',
  'locationsDialog.cap': 'CAP',
  'locationsDialog.languages': 'Lingue',
  'locationsDialog.languagesDesc':
    'Indica le lingue in cui svolgerai i tuoi eventi (Potrai sempre modificarle o aggiungerle in seguito)',
  'locationsDialog.languagesTranslations':
    'Per ogni lingua puoi modificare il nome che verrà visualizzato nella tua pagina booking',
  'locationsDialog.deleteLanguage': 'elimina lingua',
  'locationsDialog.assignName':
    'Assegna un nome che ti permetta di identificare il luogo (es. Cantina Al Poggio)',
  'locationsDialog.charsLeft': '({number} caratteri rimanenti)',
  'locationsDialog.locationName': 'Nome luogo',
  'locationsDialog.position': 'Posizione',
  'locationsDialog.insertAddress':
    'Inserisci l’indirizzo qui sotto per visualizzarlo sulla mappa. Trascina l’indicatore per perfezionare la posizione',
  'locationsDialog.addressDummy': 'es. Via Roma 27, Milano',
  'locationsDialog.confirmAddress': 'Conferma indirizzo',
  'locationsDialog.confirmAddressDesc':
    "Completa, ed eventualmente modifica, i dati rilevati da Google Maps dell'indirizzo del tuo luogo. Tutti i Campi devono essere compilati",
  'locationsDialog.multiSelectSelected': '{0} lingue selezionate',
  'locationsDialog.selectLanguages': 'Seleziona lingue',

  'location.rooms.title': 'Aggiungi sale al luogo',
  'location.rooms.description':
    'Puoi aggiungere delle sale al tuo luogo e assegnare per ogni sala la capacità di posti (slot)',
  'location.rooms.newRoom': 'Crea nuova sala',
  'location.rooms.table.name': 'Nome',
  'location.rooms.table.slot': 'Slot',
  'location.rooms.table.actions': 'Azioni',
  'location.rooms.table.sharedRoom.tooltip': 'Sala condivisa',
  'location.rooms.delete.title': 'Eliminare sala?',
  'location.rooms.delete.message': 'Confermi eliminazione della sala?',
  'location.rooms.delete.yes': 'Sì',

  'location.imageGallery.description':
    'Carica le immagini del tuo luogo e trascinale per modificare l’ordine con cui verranno visualizzate',
  'location.imageGallery.chooseNewImage': 'Scegli nuova immagine',

  'imageChooser.title': 'Scegli immagine/i',
  'imageChooser.description':
    'Seleziona una o più immagini dall’elenco oppure caricane una nuova dal tuo computer',
  'imageChooser.uploadButton': 'Carica altra immagine',
  'imageChooser.selectedImages': ' immagini selezionate',

  'imageCrop.title': 'Definisci aspetto immagine',
  'imageCrop.desc':
    'In app le foto potrebbero apparire con i bordi tagliati rispetto a quanto visualizzato sotto a seconda della dimensione dello smartphone, trascina la foto cercando di mettere i particolari più rappresentativi al centro del rettangolo per essere certo che appaiano su qualsiasi dispositivo',

  'eventWizard.title': 'Crea nuovo modello evento',
  'eventWizard.name.label': 'Assegna un nome identificativo',
  'eventWizard.color.label': 'Assegna un colore',
  'eventWizard.eventType.label': 'Che tipo di modello evento vuoi creare?',
  'eventWizard.event.bookable': 'Evento prenotabile',
  'eventWizard.event.readonly': 'Evento in sola lettura',
  'eventWizard.event.liveRoom': 'Live Room',
  'eventWizard.charsLeft': 'rimangono {number} caratteri',
  'eventWizard.params': 'Parametri',
  'eventWizard.languages': 'Lingue',
  'eventWizard.images': 'Immagini',
  'eventWizard.settings': 'Impostazioni',
  'eventWizard.duration': 'Durata',
  'eventWizard.duration.label': 'Durata evento',
  'eventWizard.closing.reservations': 'Chiusura prenotazioni',
  'eventWizard.closing.reservations.tooltip':
    "Quanto tempo prima dell'inizio dell'evento vuoi chiudere le prenotazioni e oscurarne la visibilità su WineAround? Oltre a tale termine, l'evento non sarà più visibile né prenotabile sul circuito WineAround",
  'eventWizard.hide.duration': "nascondi la durata dell'evento",
  'eventWizard.price': 'Prezzo',
  'eventWizard.price.tooltip':
    'Dai un prezzo esatto o una indicazione di prezzo alla tua offerta. Il prezzo si intende per persona',
  'eventWizard.price.typology': 'Tipologia',
  'eventWizard.euro.person': 'Euro/persona',
  'eventWizard.available.seats': 'Posti disponibili e prenotabili',
  'eventWizard.available.seats.label': 'Posti disponibili',
  'eventWizard.available.seats.label.tooltip':
    'Qual è il numero massimo di persone che possono partecipare a questo tipo di evento? Indicalo qui',
  'eventWizard.minimum.reservations': "Prenotazioni minime per attivare l'evento",
  'eventWizard.minimum.reservations.tooltip':
    'Qual è il numero minimo di persone che possono partecipare a questo tipo di evento? Indicalo qui',
  'eventWizard.bookable.seats': 'Posti prenotabili',
  'eventWizard.bookable.seats.tooltip':
    'Qual è il numero massimo di persone che possono partecipare a questo tipo di evento? Indicalo qui, inserendo 0 questa funzione sarà disattivata',
  'eventWizard.onDemand': 'Su richiesta',
  'eventWizard.fixed': 'Fisso',
  'eventWizard.closureTypes.0': 'Nessuna',
  'eventWizard.closureTypes.30': '30 minuti',
  'eventWizard.closureTypes.60': '1 ora',
  'eventWizard.closureTypes.120': '2 ore',
  'eventWizard.closureTypes.180': '3 ore',
  'eventWizard.closureTypes.360': '6 ore',
  'eventWizard.closureTypes.480': '8 ore',
  'eventWizard.closureTypes.720': '12 ore',
  'eventWizard.closureTypes.1440': '1 giorno',
  'eventWizard.closureTypes.2880': '2 giorni',
  'eventWizard.closureTypes.4320': '3 giorni',
  'eventWizard.closureTypes.5760': '4 giorni',
  'eventWizard.closureTypes.7200': '5 giorni',
  'eventWizard.closureTypes.8640': '6 giorni',
  'eventWizard.closureTypes.10080': '1 settimana',
  'eventWizard.images.title': 'Immagini',
  'eventWizard.images.description': 'Carica una o più immagini che rappresentino questo evento',
  'eventWizard.settings.title': 'Modalità di prenotazione predefinita',
  'eventWizard.settings.description':
    'potrai sempre cambiarla direttamente da calendario sul singolo evento',
  'eventWizard.settings.bookingRealTime':
    'Prenotazione <b>real-time</b> (automaticamente confermate)',
  'eventWizard.settings.bookingConfirm':
    'Prenotazione <b>su conferma</b> (dovrai confermare la prenotazione, potrai rifiutarla o spostarla in altro orario )',
  'eventWizard.settings.modelSettings': 'Impostazioni del modello evento',
  'eventWizard.settings.languageManage': 'Gestione lingue evento',
  'eventWizard.settings.closeLanguageOnNewReservation':
    'quando ricevi la prima prenotazione in una data lingua <b>disattiva tutte le altre lingue</b>',
  'eventWizard.settings.closeLanguageOnNewReservation.tooltip': 'Da definire',
  'eventWizard.settings.bookingMode': 'Cambio modalità prenotazione',
  'eventWizard.settings.unpauseOnNewReservation':
    '<b>passa a prenotazioni realtime</b> dopo la prima prenotazione confermata',
  'eventWizard.settings.onNoReservations':
    '<b>torna a prenotazioni su conferma</b> dopo la cancellazione dell’ultima (unica) prenotazione (se tutti disdicono)',
  'eventWizard.settings.privateTour': 'Tour privato',
  'eventWizard.settings.privateTour.tooltip': 'Da definire',
  'eventWizard.settings.hideOnNewReservation': "dopo la prima prenotazione nascondi l'evento",
  'eventWizard.settings.showOnNoReservations':
    "se la prenotazione viene cancellata rendi nuovamente visibile l'evento",
  'eventWizard.settings.autoEventCreation': 'Crea evento uguale in automatico',
  'eventWizard.settings.newGroupOnNewReservation': 'quando viene prenotato',
  'eventWizard.settings.onNewReservation': 'quando hai',
  'eventWizard.settings.availableSeats': 'posti disponibili',
  'eventWizard.settings.upToTimes': 'fino a quante volte?',
  'eventWizard.settings.removeEmptyGroupOnNoReservations':
    'dopo la cancellazione dell’ultima prenotazione cancella eventi uguali che non hanno ancora prenotazioni, per evitare duplicati',

  'location.roomEditDialog.title': 'Gestione sale e slot',
  'location.roomEditDialog.name': 'Assegna un nome alla sala',
  'location.roomEditDialog.sharedRoom.title': 'Sala condivisa',
  'location.roomEditDialog.sharedRoom.description':
    'questa sala può contenere più di un evento contemporaneamente, gli eventi assegnati a questa stanza condivideranno i suoi posti disponibili',
  'location.roomEditDialog.newSlotLabel': 'Crea nuovo slot di posti',
  'location.roomEditDialog.slot.tag': 'Tag',
  'location.roomEditDialog.slot.places': 'Posti',

  'location.position.title': 'Indirizzo del luogo',
  'location.position.mapDragDescription': 'Trascina il puntatore e posizionalo nel punto esatto',

  'models.parameters': 'Parametri',
  'models.languagesDescription': 'Lingue e Descrizione',
  'models.images': 'Immagini',
  'models.imageGallery.description': 'Carica una o più immagini che rappresentano questo evento',
  'models.settings': 'Impostazioni',
  'models.filters': 'Filtri',
  'models.messages': 'Messaggi',
  'models.questions': 'Domande',
  'models.roomsOperator': 'Sale e operatori',
  'models.payments': 'Pagamenti',
  'models.tags': 'Tags',
  'models.extras': 'Extra',
  'models.event.bookable': 'Evento prenotabile',
  'models.event.readonly': 'Evento in sola lettura',
  'models.event.liveRoom': 'Live Room',
  'models.filters.title': 'Filtri calendario',
  'models.filters.description':
    'Imposta quali filtri dovranno essere applicati al tuo calendario quando la tua disponibilità viene mostrata al pubblico',
  'models.filters.sameStart':
    "SAME_START: nascondi se esiste prenotazione alla stessa ora d'inizio",
  'models.filters.sameStart.description':
    "questo evento verrà nascosto se è presente in calendario un altro evento con la stessa ora d'inizio che è stato prenotato",
  'models.filters.sameLanguages':
    'SAME_LANGUAGE: nascondi (o mostra) se esiste una prenotazione per la stessa lingua',
  'models.filters.sameLanguages.description':
    "questo evento verrà nascosto (o mostrato) per una determinata lingua richiesta se è presente in calendario un altro evento dello stesso tipo (o di qualsiasi tipo) con una prenotazione per suddetta lingua; in un range variabile dall'inizio dell'evento",
  'models.filters.past': 'passato',
  'models.filters.future': 'futuro',
  'models.filters.considerAllEvents': 'Considera eventi di <b>tutti i tipi</b>',
  'models.filters.considerSameEvents': 'Considera solo eventi di <b>questo stesso tipo</b>',
  'models.filters.showExistsAnotherEvent':
    "l'evento comparirà solo <b>se esiste</b> un altro evento con una prenotazione nella lingua richiesta",
  'models.filters.showNotExistsAnotherEvent':
    "l'evento comparirà solo se <b>non esiste</b> un altro evento con una prenotazione nella lingua richiesta",
  'models.filters.eventPriority':
    'EVENT_PRIORITY: nascondi se non esiste prenotazione per evento con priorità inferiore',
  'models.filters.eventPriority.description':
    "questo evento verrà nascosto se non è presente in calendario un altro evento dello stesso tipo contenente prenotazioni e con un livello di priorità nel gradino appena inferiore; in un range variabile dall'inizio dell'evento",
  'models.filters.hidePast': 'Nascondi passato',
  'models.filters.hideFuture': 'Nascondi futuro',
  'models.filters.offset.0': '0',
  'models.filters.offset.30': '30 minuti',
  'models.filters.offset.45': '45 minuti',
  'models.filters.offset.60': '1 ora',
  'models.filters.offset.75': '1 ora e 15 minuti',
  'models.filters.offset.90': '1 ora e mezza',
  'models.filters.offset.105': '1 ora e 45 minuti',
  'models.filters.offset.120': '2 ore',
  'models.filters.offset.150': '2 ore e mezza',
  'models.filters.offset.180': '3 ore',
  'models.filters.offset.210': '3 ore mezza',
  'models.filters.offset.240': '4 ore',
  'models.filters.offset.300': '5 ore',
  'models.filters.offset.360': '6 ore',
  'models.filters.offset.420': '7 ore',
  'models.filters.offset.480': '8 ore',
  'models.filters.offset.540': '9 ore',
  'models.filters.offset.600': '10 ore',
  'models.filters.offset.660': '11 ore',
  'models.filters.offset.720': '12 ore',
  'models.payments.title': 'Pagamenti',
  'models.payments.description':
    'Ricorda che devi collegare il tuo account <b>Stripe</b> prima di poter ricevere pagamenti.',
  'models.payments.enablePayments':
    'Voglio attivare i pagamenti tramite carta di credito per questo evento',
  'models.payments.reservationAmount':
    "Percentuale dell'importo da prelevare all'atto della prenotazione",
  'models.payments.reservationAmountTooltip':
    "Che percentuale dell'importo gli utenti devono pagare per poter prenotare la visita? La percentuale viene calcolata sul totale del costo della visita, ossia il numero di partecipanti moltiplicato per il prezzo a persona",
  'models.payments.limitAnticipatedPayment':
    'Voglio limitare il pagamento anticipato entro una certa soglia',
  'models.payments.maxAmount': 'Importo massimo euro',
  'models.payments.maxAmountTooltip':
    "Impostando un limite al pagamento all'utente non verrà mai addebitata una cifra superiore a quella indicata",
  'models.payments.refound':
    "Voglio che l'utente riceva un rimborso sull'importo versato nel caso cancelli la prenotazione",
  'models.payments.noticeDays': 'Preavviso di cancellazione giorni',
  'models.payments.noticeDaysTooltip':
    "Entro quanto tempo gli utenti possono cancellare la prenotazione ricevendo il rimborso? Prima di questa scadenza verrà restituito un importo pari a quanto impostato nel campo apposito, oltre la scadenza l'utente non riceverà nessun rimborso in caso di cancellazione",
  'models.payments.refoundPercentage':
    "Percentuale sull'importo versato da restituire all'utente in caso di cancellazione:",
  'models.payments.refoundPercentageBefore': 'PRIMA della scadenza',
  'models.payments.refoundPercentageTooltip':
    "Che percentuale dell'importo versato vuoi restituire all'utente nel caso cancelli la prenotazione? Ricorda che la percentuale viene calcolata sull'importo versato dall'utente, non sull'importo totale",
  'models.payments.refoundPercentageAfter': 'DOPO la scadenza',
  'models.payments.requestCard':
    'Voglio che la carta sia richiesta solo oltre un certo numero di partecipanti',
  'models.payments.minGuestsNumber': 'Numero minimo partecipanti',
  'models.payments.minGuestsNumberTooltip':
    "Per gruppi minori di questo numero la carta non sarà richiesta all'atto della prenotazione mentre per gruppi maggiori o uguali sarà necessario il pagamento con carta di credito",
  'models.tags.title': 'Tags',
  'models.tags.description': 'Puoi assegnare dei tag per dare delle categorie ai tuoi eventi',
  'models.rooms.title': 'Sale',
  'models.rooms.description': 'Abilita le sale per questo evento',
  'models.rooms.chooseDescription':
    'Scegli quali sale e quali slot sono disponibili per gestire questo evento',
  'models.operators.title': 'Operatori',
  'models.operators.description': 'Abilita gli operatori per questo evento',
  'models.operators.chooseDescription':
    'Scegli quali operatori sono disponibili per gestire questo evento',
  'models.extras.title': 'Extra',
  'models.extras.description':
    'Scegli gli extra che potranno essere aggiunti durante la prenotazione di questo evento. Puoi sempre <b>crearne di nuovi o modificarli</b> nella sezione <a>EXTRA</a>',
  'models.extras.chooseExtra': 'Scegli Extra',
  'models.extras.createExtras': 'Puoi creare nuovi extra nella sezione {children}',
  'models.extras.table.id': 'Identificativo',
  'models.extras.table.languages': 'Lingue',
  'models.extras.table.price': 'Prezzo',
  'models.extras.table.chooseAvailability': 'Scegli disponibilità',
  'models.extras.table.availability': 'Disponibilità',
  'models.extras.table.actions': 'Azioni',
  'models.extras.table.equalToGuestsNo': 'Uguale a numero ospiti',
  'models.extras.selecredExtras': ' extra selezionati',
  'models.questions.description':
    "Scegli le domande che verranno chieste all'utente durante la prenotazione di questo evento. Puoi sempre crearne di nuove nella sezione <a>DOMANDE</a>",
  'models.questions.chooseQuestion': 'Scegli la domanda',
  'models.questions.chooseExistingQuestion': 'Scegli domanda esistente',
  'models.questions.createQuestions': 'Puoi creare nuove domande nella sezione <a>DOMANDE</a>',
  'models.questions.selectedQuestions': ' domande selezionata',
  'models.questions.mandatory': 'Obbligatoria',
  'models.questions.optional': 'Facoltativa',
  'models.delete.text': 'elimina questo modello',
  'models.delete.title': 'Eliminare modello di evento?',
  'models.delete.confirm': 'Confermi l’eliminazione del modello di evento?',

  'extras.title': 'Extra',
  'extras.subtitle':
    'Servizi o prodotti che possono essere scelti durante la prenotazione dei tuoi eventi',
  'extras.newExtra': 'Nuovo extra',

  'models.messages.title': 'Messaggi',
  'models.messages.subtitle':
    'Puoi attivare e personalizzare i messaggi che vengono inviati all’utente',
  'models.messages.whenToSendMessage': 'Quando vuoi mandare il messaggio?',
  'models.messages.after': 'dopo',
  'models.messages.before': 'prima',
  'models.messages.theEvent': "dell'evento",
  'models.messages.tab1.title': 'ATTESA DI CONFERMA',
  'models.messages.tab1.subtitle':
    'email di notifica all’utente a seguito di una prenotazione per la quale serve conferma',
  'models.messages.tab1.desc':
    "L'Utente riceve una notifica via mail ogni volta che effettua una prenotazione per cui <strong>dovrà aspettare la tua conferma</strong>. Puoi scrivere qui sotto un tuo messaggio che verrà inviato all'utente assieme alla notifica",
  'models.messages.tab2.title': 'CONFERMA DI PRENOTAZIONE',
  'models.messages.tab2.subtitle': 'email di prenotazione confermata',
  'models.messages.tab2.desc':
    "Ogni volta che confermi una prenotazione l'utente riceve una notifica via email. Puoi scrivere qui sotto un tuo messaggio che verrà <strong>aggiunto</strong> al messaggio all'utente <strong>in caso di prenotazione confermata</strong> (nel caso di accettazione dal calendario sarà comunque possibile modificarlo)",
  'models.messages.tab3.title': 'CANCELLAZIONE PRENOTAZIONE',
  'models.messages.tab3.subtitle':
    'email di notifica all’utente a seguito di una cancellazione di prenotazione',
  'models.messages.tab3.desc':
    "Ogni volta che cancelli una prenotazione l'utente riceve una notifica via email. Puoi scrivere qui sotto un tuo <strong>messaggio aggiuntivo</strong> che verrà inviato all'utente in caso di <strong>cancellazione</strong> (nel caso di cancellazione singola dal calendario sarà comunque possibile modificarlo)",
  'models.messages.tab4.title': 'FINALIZZAZIONE',
  'models.messages.tab4.subtitle':
    'email di finalizzazione prenotazione (link diretto al pagamento)',
  'models.messages.tab4.desc':
    "Questo sarà il messaggio di default quando invierai una mail di finalizzazione all'utente per eventi di questo tipo",
  'models.messages.tab5.title': 'PROMEMORIA',
  'models.messages.tab5.subtitle':
    "email di promemoria prima dell'inizio dell'evento che ha prenotato",
  'models.messages.tab5.desc':
    "Puoi scrivere qui sotto un tuo messaggio che verrà inviato all'utente <strong>assieme alla email di promemoria</strong>",
  'models.messages.tab6.title': 'FOLLOW-UP',
  'models.messages.tab6.subtitle': 'email di follw-up dopo l’evento prenotato',
  'models.messages.tab6.desc':
    "Puoi scrivere qui sotto un tuo messaggio che verrà inviato all'utente <strong>dopo la fine dell'evento che ha prenotato.</strong> Puoi scrivere qui sotto un l'oggetto della email e il messaggio che verrà inviato all'utente",

  'questions.title': 'Domande',
  'questions.subtitle':
    "Qui puoi aggiungere domande che verranno chieste all'utente durante la prenotazione dei tuoi eventi",
  'questions.newQuestion': 'Nuova domanda',
  'questions.confirmDialog.title': 'Elimina domanda',
  'questions.confirmDialog.text': 'Confermi la cancellazione della domanda selezionata?',
  'questions.confirmDialog.confirmButton.text': 'Sì, elimina domanda',

  'extras.dialog.id': 'Identificativo',
  'extras.dialog.euro': 'Euro',
  'extras.dialog.languages': 'Lingue',

  'questionDialog.title': 'Domanda',
  'questionDialog.form.identifier': 'Identificativo',
  'questionDialog.form.addAnswer': 'Aggiungi risposta',
  'questionDialog.form.type.radio': 'Risposta singola',
  'questionDialog.form.type.text': 'Testuale',
  'questionDialog.form.type.checkbox': 'Risposta multipla',
  'questionDialog.option.title': 'Risposta',
  'questionDialog.option.identifier': 'Identificativo risposta',

  'extras.deleteDialog.title': 'Elimina extra',
  'extras.deleteDialog.text': 'Confermi la cancellazione dell’Extra selezionato?',
  'extras.deleteDialog.confirmButton.text': 'Conferma',

  'notes.newDialog.header': 'Note',
  'notes.newDialog.title': 'Titolo',
  'notes.newDialog.period': 'Periodo:',
  'notes.newDialog.allDay': 'Tutto il giorno',
  'notes.newDialog.description': 'Descrizione',
  'notes.newDialog.create': 'Crea nota',

  'events.move.reservationsError':
    'Questo evento non può essere spostato perchè ci sono già delle prenotazioni',
  'events.move.error': 'Impossibile spostare questo evento',
  'events.operators.chooseDescription':
    'Scegli quali operatori sono disponibili per gestire questo evento, trascina gli operatori per ordinarli in base alla loro priorità',

  'reservations.moveDialog.title': 'Sposta prenotazione del {date}',
  'reservations.moveDialog.description':
    'Scegli una data in cui spostare la prenotazione di <b>{personName}</b>:',
  'reservations.moveDialog.noEvents': 'Nessun evento presente',
  'reservations.table.status': 'Stato',
  'reservations.table.name': 'Ospite',
  'reservations.table.guests': 'Altri',
  'reservations.table.language': 'Lingua',
  'reservations.table.mail': 'Email',
  'reservations.table.phone': 'Telefono',
  'reservations.table.date': 'Data prenotazione',
  'reservations.table.actions': 'Azioni',

  'reservations.status.unconfirmed': 'non confermato',
  'reservations.status.confirmed': 'confermato',
  'reservations.status.finalizing': 'finalizzazione',
  'reservations.status.pending': 'in attesa',
  'reservations.status.deleted': 'eliminato',

  'reservationsAndRequests.title': 'Prenotazioni e richieste',
  'reservationsAndRequests.reservations': 'PRENOTAZIONI',
  'reservationsAndRequests.requests': 'RICHIESTE',
  'reservationsAndRequests.reservations.table.status': 'Stato',
  'reservationsAndRequests.reservations.table.event': 'Evento',
  'reservationsAndRequests.reservations.table.eventDate': 'Data evento',
  'reservationsAndRequests.reservations.table.lastname': 'Cognome',
  'reservationsAndRequests.reservations.table.firstname': 'Nome',
  'reservationsAndRequests.reservations.table.mail': 'Email',
  'reservationsAndRequests.reservations.table.phone': 'Telefono',
  'reservationsAndRequests.reservations.table.guests': 'Altri',
  'reservationsAndRequests.reservations.table.language': 'Lingua',
  'reservationsAndRequests.reservations.table.reservationDate': 'Data prenotazione',
  'reservationsAndRequests.reservations.table.actions': 'Azioni',
  'reservationsAndRequests.reservations.table.noMessage': 'Nessuna prenotazione',
  'reservationsAndRequests.reservations.table.paginatorReport':
    'Mostra {first} a {last} di {totalRecords} prenotazioni',
  'reservationsAndRequests.requests.table.noMessage': 'Nessuna richiesta di prenotazione',
  'reservationsAndRequests.requests.table.paginatorReport':
    'Mostra {first} a {last} di {totalRecords} richieste',
  'reservationsAndRequests.acceptRequest': 'Accetazione richiesta',
  'reservationsAndRequests.sendRequestEmail':
    'Vuoi mandare una mail di conferma all’utente dopo aver accettato la richiesta di prenotazione? Potrai sempre mandarla anche dopo.',
  'reservationsAndRequests.doSendEmail': 'Sì, invia conferma',
  'reservationsAndRequests.doNotSendEmail': 'No, non inviare',
  'reservationsAndRequests.deleteRequest': 'Cancella richiesta',
  'reservationsAndRequests.confirmDeleteRequest':
    "Sei veramente sicuro di voler rifiutare questa proposta? Ricorda che puoi concordare un'orario diverso con l'utente e quindi spostarla",

  'guests.reservations.table.id': 'ID prenotazione',
  'guests.reservations.table.date': 'Data e ora evento',
  'guests.reservations.table.event': 'Evento',
  'guests.reservations.table.status': 'Stato',

  ...formValidation,
};

export default messages;
