import React, { Component, useEffect } from 'react';
import { IntlStringProps, withIntlString } from 'components/IntlStringHoc';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import './NewReservationDialog.scss';
import { IApi, IState, useApi } from 'services/apiStateManager';
import getClient from 'services/apiClientProvider';
import {
  CellarUser,
  ClubLanguages,
  EventApi,
  ModelExtras,
  ModelMetaData,
  Reservation,
  ReservationApi,
} from 'services/apiService';
import BlockLoader from 'components/blockLoader/BlockLoader';
import { Field, Form, Formik } from 'formik';
import IntlString from 'components/IntlString';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';

import * as Yup from 'yup';
import cx from 'classnames';

import { Dropdown } from 'primereact/dropdown';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'state/store';
import TagsDropdown from 'components/tagsDropdown/TagsDropdown';
import ContactDialog from 'components/contactDialog/ContactDialog';
import { Accordion, AccordionTab } from 'primereact/accordion';
import ReservationQuestions from './reservationQuestions/ReservationQuestions';
import ReservationExtra from './reservationExtra/ReservationExtra';
import ConfirmDialog from 'components/confirmDialog/ConfirmDialog';
import ContactBookDialog from 'components/contactBookDialog/ContactBookDialog';
import ContactBook from 'models/ContactBook';

const connector = connect((state: RootState) => ({
  languageTranslations: state.languageTranslations,
}));

type Props = IntlStringProps &
  ConnectedProps<typeof connector> & {
    eventId: string | null;
    isVisible: boolean;
    eventLanguages?: Array<ClubLanguages>;
    onHideDialog: Function;
    modelQuestions: Array<ModelMetaData>;
    modelExtras: Array<ModelExtras>;
    reservationId?: string;
  };

type State = {
  reservationPost: IState<Reservation>;
  reservationGet: IState<Reservation>;
  reservationPut: IState<Reservation>;
  isContactDialogVisible: boolean;
  isContactBookDialogVisible: boolean;
  disableSave: boolean;
  activeIndex: number | null;
  showConfirmDialog: boolean;
  participantToDeleteIndex: number | null;
  contactDialogInfo: {
    defaultValue: CellarUser;
    fieldName: 'cellar_user' | 'cellar_users';
    editPosition?: number;
  };
  contactChooserIsCellarUser: boolean;
  contactChooserIsCellarEditPosition?: number;
};

type ReservationPost = {
  eventId: string;
  reservation: Reservation;
};

const defaultValues: Reservation = {
  language: undefined,
  guests: 1,
  tags: [],
  note: '',
  cellar_user: {
    _id: undefined,
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    tags: [],
    country: '',
  },
  cellar_users: [],
  meta_data: {},
  extras: {},
};

const reservationSchema = Yup.object().shape({
  language: Yup.string().required(),
  cellar_user: Yup.object().shape({
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
  }),
});

class NewReservationDialog extends Component<Props, State> {
  submitMyForm: Function | null = null;

  reservationManagerPost: IApi<Reservation, ReservationPost> = useApi<Reservation, ReservationPost>(
    this,
    'reservationPost',
    ({ eventId, reservation }) =>
      getClient(EventApi).eventsEventIdReservationsPost(eventId, reservation)
  );

  reservationManagerPut: IApi<Reservation, any> = useApi<Reservation, any>(
    this,
    'reservationPut',
    ({ reservationId, reservation }) =>
      getClient(ReservationApi).reservationsReservationIdPut(reservationId, reservation)
  );

  reservationManagerGet: IApi<Reservation, any> = useApi<Reservation, any>(
    this,
    'reservationGet',
    ({ reservationId }) => getClient(ReservationApi).reservationsReservationIdGet(reservationId)
  );

  state: State = {
    showConfirmDialog: false,
    reservationPost: this.reservationManagerPost.initialState,
    reservationGet: this.reservationManagerGet.initialState,
    reservationPut: this.reservationManagerPut.initialState,
    isContactDialogVisible: false,
    isContactBookDialogVisible: false,
    participantToDeleteIndex: null,
    disableSave: true,
    activeIndex: null,
    contactDialogInfo: {
      defaultValue: { ...defaultValues.cellar_user! },
      fieldName: 'cellar_user',
      editPosition: undefined,
    },
    contactChooserIsCellarUser: true,
    contactChooserIsCellarEditPosition: undefined,
  };

  async componentDidUpdate(prevProps: Props) {
    const { reservationId } = this.props;
    if (prevProps.reservationId !== reservationId) {
      if (reservationId) {
        await this.reservationManagerGet.fetch({ reservationId });
      } else {
        this.setState((state: State) => ({
          reservationGet: {
            ...state.reservationGet,
            response: null,
          },
        }));
      }
    }
  }

  handleContactDialogToggle(
    isVisible: boolean = false,
    fieldName: any = null,
    defaultValue?: CellarUser,
    editPosition?: number
  ) {
    this.setState((state) => ({
      contactDialogInfo: fieldName
        ? {
            defaultValue: defaultValue ?? { ...defaultValues.cellar_user! },
            fieldName: fieldName,
            editPosition,
          }
        : state.contactDialogInfo,
      isContactDialogVisible: isVisible,
    }));
  }

  handleContactBookDialogToggle(
    isVisible: boolean = false,
    isCellarUser: boolean = true,
    editPosition?: number
  ) {
    this.setState({
      isContactBookDialogVisible: isVisible,
      contactChooserIsCellarUser: isCellarUser,
      contactChooserIsCellarEditPosition: editPosition,
    });
  }

  bindFormSubmit = (doSubmit: Function) => {
    this.submitMyForm = doSubmit;
  };

  submitForm = () => {
    if (this.submitMyForm) {
      this.submitMyForm();
    }
  };

  handleSubmit = async (reservation: Reservation) => {
    const { eventId, reservationId } = this.props;
    if (!eventId) return;
    try {
      !reservationId
        ? await this.reservationManagerPost.fetch({ eventId, reservation })
        : await this.reservationManagerPut.fetch({ reservationId, reservation });
      this.hideDialog(true);
    } catch (error) {
      console.log(error);
    }
  };

  renderLanguageTitle(langId: string) {
    const { languageTranslations } = this.props;
    if (!languageTranslations) return '';

    const langFound = languageTranslations.find((lang) => lang.value === langId);
    if (langFound) {
      return langFound.native_name;
    }

    return '';
  }

  hideDialog = (refresh: boolean) => {
    this.setState({
      activeIndex: null,
    });
    this.props.onHideDialog(refresh);
  };

  showConfirmDialog(index: number) {
    this.setState({
      showConfirmDialog: true,
      participantToDeleteIndex: index,
    });
  }

  deleteParticipant = (setFormField: Function, cellarUsers: CellarUser[]) => {
    const { participantToDeleteIndex } = this.state;
    if (participantToDeleteIndex === null) return;

    if (participantToDeleteIndex === -1) setFormField('cellar_user', defaultValues.cellar_user);
    else
      setFormField(
        'cellar_users',
        cellarUsers.filter((val, i) => i !== participantToDeleteIndex)
      );

    this.hideConfirmDialog();
  };

  hideConfirmDialog = () => {
    this.setState({
      showConfirmDialog: false,
      participantToDeleteIndex: null,
    });
  };

  getParsedLanguages() {
    const { eventLanguages } = this.props;

    if (eventLanguages?.length) {
      let result = eventLanguages
        .filter((el) => el.is_set)
        .map((el) => ({ text: this.renderLanguageTitle(el.name || ''), value: el.name }));
      return result;
    } else return [];
  }

  renderHeader() {
    return (
      <div className="new-reservation-dialog__title d-flex align-items-center">
        <IntlString id="newReservation.reservation" />
      </div>
    );
  }

  renderFooter() {
    const { intlString, reservationId } = this.props;
    const { reservationPost, reservationGet, reservationPut, disableSave } = this.state;

    return (
      <>
        <hr />
        <div className="event-dialog__footer d-flex">
          <div className="ml-auto">
            <Button
              label={intlString('general.cancel')}
              className="p-button-lg p-button-text p-button-secondary mr-auto"
              type="button"
              disabled={reservationPost.loading || reservationPut.loading}
              onClick={() => this.hideDialog(false)}
            ></Button>
            <Button
              label={reservationId ? intlString('general.save') : intlString('general.insert')}
              className="p-button-lg p-button-danger"
              type="button"
              onClick={this.submitForm}
              disabled={
                reservationPost.loading ||
                reservationGet.loading ||
                reservationPut.loading ||
                disableSave
              }
            />
          </div>
        </div>
      </>
    );
  }

  onFormStatusUpdated = (disableSave: boolean) => {
    this.setState({ disableSave });
  };

  handleTabChange = (e: { originalEvent: Event; index: number }) => {
    this.setState({
      activeIndex: e.index,
    });
  };

  contactBookOnClose = (
    contactBook: ContactBook,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    cellarUsers: CellarUser[]
  ) => {
    const { contactChooserIsCellarEditPosition } = this.state;
    this.handleContactBookDialogToggle(false);

    if (contactBook) {
      if (this.state.contactChooserIsCellarUser) {
        setFieldValue('cellar_user', contactBook);
      } else {
        const tmp = [...cellarUsers];
        if (contactChooserIsCellarEditPosition === undefined) tmp.push(contactBook);
        else tmp[contactChooserIsCellarEditPosition] = contactBook;
        setFieldValue('cellar_users', tmp);
      }
    }
  };

  render() {
    const { intlString } = this.props;
    const {
      reservationPost,
      reservationGet,
      reservationPut,
      isContactDialogVisible,
      isContactBookDialogVisible,
      activeIndex,
      contactDialogInfo,
    } = this.state;

    return (
      <>
        <Dialog
          header={this.renderHeader()}
          footer={this.renderFooter()}
          visible={this.props.isVisible}
          onHide={() => this.hideDialog(false)}
          className="new-reservation-dialog"
        >
          <BlockLoader
            loading={reservationPost.loading || reservationGet.loading || reservationPut.loading}
          >
            <Formik
              initialValues={reservationGet.response || { ...defaultValues }}
              validationSchema={reservationSchema}
              enableReinitialize={true}
              onSubmit={this.handleSubmit}
            >
              {({ errors, submitForm, values, setFieldValue, isValid }) => {
                const disableSave = !values.cellar_user?.first_name || !isValid;

                useEffect(() => {
                  this.onFormStatusUpdated(disableSave);
                  this.bindFormSubmit(submitForm);
                }, [disableSave, submitForm]);

                return (
                  <Form className="mb-2 p-fluid">
                    <div className="row">
                      <div className="col-4 p-field">
                        <label htmlFor="language">
                          <IntlString id="newReservation.language" />
                        </label>
                        <Field
                          component={Dropdown}
                          appendTo={document.body}
                          name="language"
                          value={values.language}
                          options={this.getParsedLanguages()}
                          onChange={(e: any) => setFieldValue('language', e.value)}
                          optionLabel="text"
                          dataKey="value"
                        />
                      </div>
                      <div className="col-2 p-field pl-0">
                        <label htmlFor="guests">
                          <IntlString id="newReservation.guests" />
                        </label>
                        <Field
                          component={InputNumber}
                          name="guests"
                          className={cx('', {
                            'p-invalid': errors.guests,
                          })}
                          showButtons
                          value={values.guests}
                          onChange={(e: any) => {
                            setFieldValue('guests', e.value);
                          }}
                          min={1 + values.cellar_users!.length}
                        />
                      </div>
                      <div className="col-6 p-field pl-0">
                        <label htmlFor="price">
                          <IntlString id="newReservation.tags" />
                        </label>
                        <Field
                          as={TagsDropdown}
                          name="tags"
                          handleChange={() => {}}
                          value={values.tags}
                          setFormField={setFieldValue}
                          fieldName="tags"
                        />
                      </div>
                    </div>
                    <div className="mt-3">
                      <Field
                        as={InputText}
                        name="note"
                        placeholder={intlString('newReservation.notePlaceholder')}
                        className={cx('', {
                          'p-invalid': errors.note,
                        })}
                      />
                    </div>
                    <div className="mt-3 new-reservation-dialog__guest">
                      <div className="mt-3 mb-3">
                        <div className="d-flex align-items-center new-reservation-dialog__guest-title">
                          <strong className="text-uppercase">
                            <IntlString id="newReservation.booking" />
                          </strong>

                          {!values.cellar_user?.first_name && (
                            <>
                              <Button
                                type="button"
                                icon="pi pi-plus"
                                label={intlString('newReservation.newContact')}
                                onClick={() => this.handleContactDialogToggle(true, 'cellar_user')}
                                className="ml-auto p-button-outlined p-button-secondary"
                              />
                              <Button
                                type="button"
                                icon="pi pi-users"
                                label={intlString('newReservation.chooseFromAddressBook')}
                                onClick={() => this.handleContactBookDialogToggle(true, true)}
                                className="ml-2 p-button-outlined p-button-secondary"
                              />
                            </>
                          )}
                        </div>
                        {values.cellar_user?.first_name && (
                          <div className="new-reservation-dialog__guest-info row mt-3">
                            <div className="col">
                              {values.cellar_user.first_name} {values.cellar_user.last_name}
                            </div>
                            <div className="col">
                              <i className="pi pi-phone mr-1" />
                              {values.cellar_user.phone}
                            </div>
                            <div className="col-4">
                              <i className="pi pi-envelope mr-1" />
                              {values.cellar_user.email}
                            </div>
                            <div className="col-2 d-flex align-items-center">
                              <i className="pi pi-tags" />
                              {values.cellar_user.tags?.map((el) => (
                                <span className="tag-item ml-1">{el.text}</span>
                              ))}
                            </div>
                            <div className="col-2 d-flex">
                              <Button
                                icon={values.cellar_user._id ? 'pi pi-users' : 'pi pi-pencil'}
                                type="button"
                                onClick={() =>
                                  values.cellar_user?._id
                                    ? this.handleContactBookDialogToggle(true, true)
                                    : this.handleContactDialogToggle(
                                        true,
                                        'cellar_user',
                                        values.cellar_user
                                      )
                                }
                                className="p-button-secondary ml-auto"
                              />
                              <Button
                                icon="pi pi-times"
                                type="button"
                                onClick={() => this.showConfirmDialog(-1)}
                                className="p-button-secondary p-button-outlined ml-2"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <hr className="mt-0 mb-0" />
                      <div className="mt-3 mb-3">
                        <div className="d-flex align-items-center new-reservation-dialog__guest-title mt-3 mb-3">
                          <strong className="text-uppercase mr-1">
                            <IntlString id="newReservation.otherParticipants" />
                          </strong>
                          {' - '}
                          <IntlString
                            id="newReservation.otherParticipantsOptional"
                            values={{
                              selected: values.cellar_users!.length,
                              total: values.guests ? values.guests - 1 : 0,
                            }}
                          />

                          <Button
                            type="button"
                            icon="pi pi-plus"
                            disabled={values.guests! - values.cellar_users!.length <= 1}
                            onClick={() => this.handleContactDialogToggle(true, 'cellar_users')}
                            label={intlString('newReservation.newContact')}
                            className="ml-auto p-button-outlined p-button-secondary"
                          />
                          <Button
                            type="button"
                            icon="pi pi-users"
                            disabled={values.guests! - values.cellar_users!.length <= 1}
                            label={intlString('newReservation.chooseFromAddressBook')}
                            className="ml-2 p-button-outlined p-button-secondary"
                            onClick={() => this.handleContactBookDialogToggle(true, false)}
                          />
                        </div>

                        {values.cellar_users?.map((user, index) => {
                          return (
                            <div
                              className="new-reservation-dialog__guest-info row mt-3"
                              key={`user_${user.last_name}_${user.first_name}`}
                            >
                              <div className="col">
                                {user.first_name} {user.last_name}
                              </div>
                              <div className="col">
                                <i className="pi pi-phone mr-1" />
                                {user.phone}
                              </div>
                              <div className="col-4">
                                <i className="pi pi-envelope mr-1" />
                                {user.email}
                              </div>
                              <div className="col-2 d-flex align-items-center">
                                <i className="pi pi-tags" />
                                {user.tags?.map((el) => (
                                  <span className="tag-item ml-1">{el.text}</span>
                                ))}
                              </div>
                              <div className="col-2 d-flex">
                                <Button
                                  icon={user._id ? 'pi pi-users' : 'pi pi-pencil'}
                                  type="button"
                                  onClick={() =>
                                    user._id
                                      ? this.handleContactBookDialogToggle(true, false, index)
                                      : this.handleContactDialogToggle(
                                          true,
                                          'cellar_users',
                                          user,
                                          index
                                        )
                                  }
                                  className="p-button-secondary ml-auto"
                                />
                                <Button
                                  icon="pi pi-times"
                                  type="button"
                                  onClick={() => this.showConfirmDialog(index)}
                                  className="p-button-secondary p-button-outlined ml-2"
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <Accordion
                      activeIndex={activeIndex}
                      onTabChange={this.handleTabChange}
                      className="mt-3 new-reservation-dialog__accordion"
                    >
                      <AccordionTab
                        header={
                          <span className="fs-14">
                            <strong className="fs-14 mr-2 text-uppercase">
                              {intlString('newReservation.extra')}
                            </strong>
                          </span>
                        }
                      >
                        <Field
                          as={ReservationExtra}
                          name="extras"
                          fieldName="extras"
                          modelExtras={this.props.modelExtras}
                          defaultValue={values.extras}
                          setFormField={setFieldValue}
                        />
                      </AccordionTab>
                      <AccordionTab
                        header={
                          <span className="fs-14">
                            <strong className="fs-14 mr-2 text-uppercase">
                              {intlString('newReservation.additionalQuestions')}
                            </strong>
                          </span>
                        }
                      >
                        <Field
                          as={ReservationQuestions}
                          name="meta_data"
                          fieldName="meta_data"
                          modelQuestions={this.props.modelQuestions}
                          defaultValue={values.meta_data}
                          setFormField={setFieldValue}
                        />
                      </AccordionTab>
                      <AccordionTab
                        header={
                          <span className="fs-14">
                            <strong className="fs-14 mr-2 text-uppercase">
                              {intlString('newReservation.expense')}
                            </strong>
                            {intlString('newReservation.expenseDesc')}
                          </span>
                        }
                      ></AccordionTab>
                    </Accordion>
                    <ConfirmDialog
                      isVisible={this.state.showConfirmDialog}
                      onHideDialog={this.hideConfirmDialog}
                      onConfirmDialog={() =>
                        this.deleteParticipant(setFieldValue, values.cellar_users!)
                      }
                      title={intlString('newReservation.deleteParticipant')}
                      contentText={intlString('newReservation.deleteParticipantDesc')}
                      confirmText={intlString('general.confirm')}
                    />
                    <Field
                      as={ContactDialog}
                      name="cellar_user"
                      currentCellarUsers={values.cellar_users}
                      defaultValue={contactDialogInfo.defaultValue}
                      setFormField={setFieldValue}
                      isVisible={isContactDialogVisible}
                      editPosition={contactDialogInfo.editPosition}
                      fieldName={contactDialogInfo.fieldName}
                      onHideDialog={() => this.handleContactDialogToggle(false)}
                    />
                    <ContactBookDialog
                      isVisible={isContactBookDialogVisible}
                      onHideDialog={(contactBook: ContactBook) =>
                        this.contactBookOnClose(
                          contactBook,
                          setFieldValue,
                          values.cellar_users ?? []
                        )
                      }
                    />
                  </Form>
                );
              }}
            </Formik>
          </BlockLoader>
        </Dialog>
      </>
    );
  }
}

export default connector(withIntlString(NewReservationDialog));
