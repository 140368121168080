// tslint:disable
/**
 * winearound-winery
 * Specification JSONs: [v2](/api-spec/v2), [v3](/api-spec/v3).  idk
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AppUser
 */
export interface AppUser {
  /**
   *
   * @type {string}
   * @memberof AppUser
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof AppUser
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof AppUser
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof AppUser
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AppUser
   */
  blocked?: string;
  /**
   *
   * @type {string}
   * @memberof AppUser
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof AppUser
   */
  confirm_token?: string;
  /**
   *
   * @type {string}
   * @memberof AppUser
   */
  token_expires?: string;
  /**
   *
   * @type {boolean}
   * @memberof AppUser
   */
  confirmed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AppUser
   */
  deleted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AppUser
   */
  add_to_agile?: boolean;
  /**
   *
   * @type {string}
   * @memberof AppUser
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface AuthRequest
 */
export interface AuthRequest {
  /**
   *
   * @type {string}
   * @memberof AuthRequest
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof AuthRequest
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof AuthRequest
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof AuthRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AuthRequest
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof AuthRequest
   */
  confirm_token?: string;
  /**
   *
   * @type {string}
   * @memberof AuthRequest
   */
  token_expires?: string;
  /**
   *
   * @type {Model}
   * @memberof AuthRequest
   */
  model?: Model;
  /**
   *
   * @type {User}
   * @memberof AuthRequest
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof AuthRequest
   */
  language?: string;
  /**
   *
   * @type {string}
   * @memberof AuthRequest
   */
  ui_language?: string;
  /**
   *
   * @type {string}
   * @memberof AuthRequest
   */
  note?: string;
  /**
   *
   * @type {string}
   * @memberof AuthRequest
   */
  start?: string;
  /**
   *
   * @type {string}
   * @memberof AuthRequest
   */
  status?: AuthRequestStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AuthRequest
   */
  _id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum AuthRequestStatusEnum {
  Requested = 'requested',
  Confirmed = 'confirmed',
  Consumed = 'consumed',
}

/**
 *
 * @export
 * @interface CalendarFilter
 */
export interface CalendarFilter {
  /**
   *
   * @type {User}
   * @memberof CalendarFilter
   */
  user?: User;
  /**
   *
   * @type {Place}
   * @memberof CalendarFilter
   */
  location?: Place;
  /**
   *
   * @type {Array<CalendarFilterModels>}
   * @memberof CalendarFilter
   */
  models?: Array<CalendarFilterModels>;
  /**
   *
   * @type {Array<CalendarFilterModels>}
   * @memberof CalendarFilter
   */
  languages?: Array<CalendarFilterModels>;
  /**
   *
   * @type {number}
   * @memberof CalendarFilter
   */
  places?: number;
  /**
   *
   * @type {boolean}
   * @memberof CalendarFilter
   */
  only_reserved?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CalendarFilter
   */
  only_max_concurrent?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CalendarFilter
   */
  only_visible?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CalendarFilter
   */
  only_future?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CalendarFilter
   */
  same_start?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CalendarFilter
   */
  is_filtered?: boolean;
  /**
   *
   * @type {string}
   * @memberof CalendarFilter
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface CalendarFilterModels
 */
export interface CalendarFilterModels {
  /**
   *
   * @type {string}
   * @memberof CalendarFilterModels
   */
  title?: string;
  /**
   *
   * @type {boolean}
   * @memberof CalendarFilterModels
   */
  is_set?: boolean;
}
/**
 *
 * @export
 * @interface CalendarTime
 */
export interface CalendarTime {
  /**
   *
   * @type {User}
   * @memberof CalendarTime
   */
  user?: User;
  /**
   *
   * @type {Place}
   * @memberof CalendarTime
   */
  location?: Place;
  /**
   *
   * @type {string}
   * @memberof CalendarTime
   */
  defaultView?: CalendarTimeDefaultViewEnum;
  /**
   *
   * @type {string}
   * @memberof CalendarTime
   */
  slotDuration?: CalendarTimeSlotDurationEnum;
  /**
   *
   * @type {string}
   * @memberof CalendarTime
   */
  startTime?: CalendarTimeStartTimeEnum;
  /**
   *
   * @type {string}
   * @memberof CalendarTime
   */
  endTime?: CalendarTimeEndTimeEnum;
  /**
   *
   * @type {string}
   * @memberof CalendarTime
   */
  _id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CalendarTimeDefaultViewEnum {
  AgendaDay = 'agendaDay',
  BasicDay = 'basicDay',
  AgendaWeek = 'agendaWeek',
  BasicWeek = 'basicWeek',
  Month = 'month',
}
/**
 * @export
 * @enum {string}
 */
export enum CalendarTimeSlotDurationEnum {
  _001000 = '00:10:00',
  _001500 = '00:15:00',
  _003000 = '00:30:00',
  _010000 = '01:00:00',
}
/**
 * @export
 * @enum {string}
 */
export enum CalendarTimeStartTimeEnum {
  _0000 = '00:00',
  _0100 = '01:00',
  _0200 = '02:00',
  _0300 = '03:00',
  _0400 = '04:00',
  _0500 = '05:00',
  _0600 = '06:00',
  _0700 = '07:00',
  _0800 = '08:00',
  _0900 = '09:00',
  _1000 = '10:00',
  _1100 = '11:00',
  _1200 = '12:00',
  _1300 = '13:00',
  _1400 = '14:00',
  _1500 = '15:00',
  _1600 = '16:00',
  _1700 = '17:00',
  _1800 = '18:00',
  _1900 = '19:00',
  _2000 = '20:00',
  _2100 = '21:00',
  _2200 = '22:00',
  _2300 = '23:00',
  _2400 = '24:00',
}
/**
 * @export
 * @enum {string}
 */
export enum CalendarTimeEndTimeEnum {
  _0000 = '00:00',
  _0100 = '01:00',
  _0200 = '02:00',
  _0300 = '03:00',
  _0400 = '04:00',
  _0500 = '05:00',
  _0600 = '06:00',
  _0700 = '07:00',
  _0800 = '08:00',
  _0900 = '09:00',
  _1000 = '10:00',
  _1100 = '11:00',
  _1200 = '12:00',
  _1300 = '13:00',
  _1400 = '14:00',
  _1500 = '15:00',
  _1600 = '16:00',
  _1700 = '17:00',
  _1800 = '18:00',
  _1900 = '19:00',
  _2000 = '20:00',
  _2100 = '21:00',
  _2200 = '22:00',
  _2300 = '23:00',
  _2400 = '24:00',
}

/**
 *
 * @export
 * @interface CellarUser
 */
export interface CellarUser {
  /**
   *
   * @type {User}
   * @memberof CellarUser
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof CellarUser
   */
  app_user?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUser
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUser
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUser
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUser
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUser
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUser
   */
  blocked?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUser
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUser
   */
  deleted?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUser
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUser
   */
  contact_language?: string;
  /**
   *
   * @type {Array<InlineResponse2007>}
   * @memberof CellarUser
   */
  tags?: Array<InlineResponse2007>;
  /**
   *
   * @type {CellarUserPasswordReset}
   * @memberof CellarUser
   */
  password_reset?: CellarUserPasswordReset;
  /**
   *
   * @type {CellarUserEmailChange}
   * @memberof CellarUser
   */
  email_change?: CellarUserEmailChange;
  /**
   *
   * @type {CellarUserInvitation}
   * @memberof CellarUser
   */
  invitation?: CellarUserInvitation;
  /**
   *
   * @type {CellarUserShippingAddress}
   * @memberof CellarUser
   */
  shipping_address?: CellarUserShippingAddress;
  /**
   *
   * @type {CellarUserShippingAddress}
   * @memberof CellarUser
   */
  billing_address?: CellarUserShippingAddress;
  /**
   *
   * @type {boolean}
   * @memberof CellarUser
   */
  different_billing?: boolean;
  /**
   *
   * @type {CellarUserFiscal}
   * @memberof CellarUser
   */
  fiscal?: CellarUserFiscal;
  /**
   *
   * @type {Array<CellarUserClubs>}
   * @memberof CellarUser
   */
  clubs?: Array<CellarUserClubs>;
  /**
   *
   * @type {Array<Reservation>}
   * @memberof CellarUser
   */
  reservations?: Array<Reservation>;
  /**
   *
   * @type {string}
   * @memberof CellarUser
   */
  stripe_id?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUser
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface CellarUserClubs
 */
export interface CellarUserClubs {
  /**
   *
   * @type {Club}
   * @memberof CellarUserClubs
   */
  club?: Club;
}
/**
 *
 * @export
 * @interface CellarUserEmailChange
 */
export interface CellarUserEmailChange {
  /**
   *
   * @type {string}
   * @memberof CellarUserEmailChange
   */
  requested?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUserEmailChange
   */
  token_request?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUserEmailChange
   */
  token_confirm?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUserEmailChange
   */
  email?: string;
}
/**
 *
 * @export
 * @interface CellarUserFiscal
 */
export interface CellarUserFiscal {
  /**
   *
   * @type {boolean}
   * @memberof CellarUserFiscal
   */
  person?: boolean;
  /**
   *
   * @type {string}
   * @memberof CellarUserFiscal
   */
  company?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUserFiscal
   */
  code?: string;
}
/**
 *
 * @export
 * @interface CellarUserInvitation
 */
export interface CellarUserInvitation {
  /**
   *
   * @type {string}
   * @memberof CellarUserInvitation
   */
  token?: string;
}
/**
 *
 * @export
 * @interface CellarUserPasswordReset
 */
export interface CellarUserPasswordReset {
  /**
   *
   * @type {string}
   * @memberof CellarUserPasswordReset
   */
  requested?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUserPasswordReset
   */
  token?: string;
}
/**
 *
 * @export
 * @interface CellarUserShippingAddress
 */
export interface CellarUserShippingAddress {
  /**
   *
   * @type {string}
   * @memberof CellarUserShippingAddress
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUserShippingAddress
   */
  number?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUserShippingAddress
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUserShippingAddress
   */
  state?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUserShippingAddress
   */
  zip?: string;
  /**
   *
   * @type {string}
   * @memberof CellarUserShippingAddress
   */
  country?: string;
}
/**
 *
 * @export
 * @interface Club
 */
export interface Club {
  /**
   *
   * @type {User}
   * @memberof Club
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof Club
   */
  title?: string;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof Club
   */
  languages?: Array<ClubLanguages>;
  /**
   *
   * @type {Array<ClubShops>}
   * @memberof Club
   */
  shops?: Array<ClubShops>;
  /**
   *
   * @type {Array<ClubPages>}
   * @memberof Club
   */
  pages?: Array<ClubPages>;
  /**
   *
   * @type {string}
   * @memberof Club
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof Club
   */
  deleted?: string;
  /**
   *
   * @type {ClubShipping}
   * @memberof Club
   */
  shipping?: ClubShipping;
  /**
   *
   * @type {ClubSettings}
   * @memberof Club
   */
  settings?: ClubSettings;
  /**
   *
   * @type {ClubDiscounts}
   * @memberof Club
   */
  discounts?: ClubDiscounts;
  /**
   *
   * @type {StripeAccount}
   * @memberof Club
   */
  stripe_account?: StripeAccount;
  /**
   *
   * @type {string}
   * @memberof Club
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface ClubDiscounts
 */
export interface ClubDiscounts {
  /**
   *
   * @type {ClubDiscountsFirstTime}
   * @memberof ClubDiscounts
   */
  first_time?: ClubDiscountsFirstTime;
  /**
   *
   * @type {ClubDiscountsFirstTime}
   * @memberof ClubDiscounts
   */
  every_time?: ClubDiscountsFirstTime;
  /**
   *
   * @type {ClubDiscountsOverAmount}
   * @memberof ClubDiscounts
   */
  over_amount?: ClubDiscountsOverAmount;
}
/**
 *
 * @export
 * @interface ClubDiscountsFirstTime
 */
export interface ClubDiscountsFirstTime {
  /**
   *
   * @type {boolean}
   * @memberof ClubDiscountsFirstTime
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof ClubDiscountsFirstTime
   */
  discount_type?: ClubDiscountsFirstTimeDiscountTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ClubDiscountsFirstTime
   */
  amount?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum ClubDiscountsFirstTimeDiscountTypeEnum {
  Fixed = 'fixed',
  Percent = 'percent',
}

/**
 *
 * @export
 * @interface ClubDiscountsOverAmount
 */
export interface ClubDiscountsOverAmount {
  /**
   *
   * @type {boolean}
   * @memberof ClubDiscountsOverAmount
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof ClubDiscountsOverAmount
   */
  discount_type?: ClubDiscountsOverAmountDiscountTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ClubDiscountsOverAmount
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof ClubDiscountsOverAmount
   */
  over_amount?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum ClubDiscountsOverAmountDiscountTypeEnum {
  Fixed = 'fixed',
  Percent = 'percent',
}

/**
 *
 * @export
 * @interface ClubLanguages
 */
export interface ClubLanguages {
  /**
   *
   * @type {string}
   * @memberof ClubLanguages
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ClubLanguages
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof ClubLanguages
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof ClubLanguages
   */
  is_set?: boolean;
}
/**
 *
 * @export
 * @interface ClubPages
 */
export interface ClubPages {
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof ClubPages
   */
  languages?: Array<ClubLanguages>;
  /**
   *
   * @type {string}
   * @memberof ClubPages
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ClubPages
   */
  url: string;
  /**
   *
   * @type {boolean}
   * @memberof ClubPages
   */
  is_home?: boolean;
}
/**
 *
 * @export
 * @interface ClubSettings
 */
export interface ClubSettings {
  /**
   *
   * @type {ClubSettingsExpense}
   * @memberof ClubSettings
   */
  expense?: ClubSettingsExpense;
}
/**
 *
 * @export
 * @interface ClubSettingsExpense
 */
export interface ClubSettingsExpense {
  /**
   *
   * @type {number}
   * @memberof ClubSettingsExpense
   */
  min?: number;
}
/**
 *
 * @export
 * @interface ClubShipping
 */
export interface ClubShipping {
  /**
   *
   * @type {string}
   * @memberof ClubShipping
   */
  mode?: ClubShippingModeEnum;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof ClubShipping
   */
  languages?: Array<ClubLanguages>;
  /**
   *
   * @type {ClubShippingPrice}
   * @memberof ClubShipping
   */
  price?: ClubShippingPrice;
  /**
   *
   * @type {ClubShippingSettings}
   * @memberof ClubShipping
   */
  settings?: ClubShippingSettings;
}

/**
 * @export
 * @enum {string}
 */
export enum ClubShippingModeEnum {
  Shipping = 'shipping',
  Pickup = 'pickup',
  Choice = 'choice',
}

/**
 *
 * @export
 * @interface ClubShippingPrice
 */
export interface ClubShippingPrice {
  /**
   *
   * @type {string}
   * @memberof ClubShippingPrice
   */
  mode?: ClubShippingPriceModeEnum;
  /**
   *
   * @type {number}
   * @memberof ClubShippingPrice
   */
  value?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum ClubShippingPriceModeEnum {
  Fixed = 'fixed',
  Item = 'item',
}

/**
 *
 * @export
 * @interface ClubShippingSettings
 */
export interface ClubShippingSettings {
  /**
   *
   * @type {string}
   * @memberof ClubShippingSettings
   */
  mode?: ClubShippingSettingsModeEnum;
  /**
   *
   * @type {number}
   * @memberof ClubShippingSettings
   */
  free_from?: number;
  /**
   *
   * @type {Array<ClubShippingSettingsQuantities>}
   * @memberof ClubShippingSettings
   */
  quantities?: Array<ClubShippingSettingsQuantities>;
}

/**
 * @export
 * @enum {string}
 */
export enum ClubShippingSettingsModeEnum {
  Fixed = 'fixed',
  Courier = 'courier',
}

/**
 *
 * @export
 * @interface ClubShippingSettingsQuantities
 */
export interface ClubShippingSettingsQuantities {
  /**
   *
   * @type {string}
   * @memberof ClubShippingSettingsQuantities
   */
  product_type?: ClubShippingSettingsQuantitiesProductTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ClubShippingSettingsQuantities
   */
  quantity?: number;
  /**
   *
   * @type {string}
   * @memberof ClubShippingSettingsQuantities
   */
  field?: ClubShippingSettingsQuantitiesFieldEnum;
  /**
   *
   * @type {number}
   * @memberof ClubShippingSettingsQuantities
   */
  field_value?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum ClubShippingSettingsQuantitiesProductTypeEnum {
  Wine = 'wine',
  Food = 'food',
  Liquid = 'liquid',
  Generic = 'generic',
}
/**
 * @export
 * @enum {string}
 */
export enum ClubShippingSettingsQuantitiesFieldEnum {
  Format = 'format',
  Weight = 'weight',
}

/**
 *
 * @export
 * @interface ClubShops
 */
export interface ClubShops {
  /**
   *
   * @type {Shop}
   * @memberof ClubShops
   */
  shop?: Shop;
}
/**
 *
 * @export
 * @interface ClubUser
 */
export interface ClubUser {
  /**
   *
   * @type {User}
   * @memberof ClubUser
   */
  user?: User;
  /**
   *
   * @type {AppUser}
   * @memberof ClubUser
   */
  app_user?: AppUser;
  /**
   *
   * @type {string}
   * @memberof ClubUser
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof ClubUser
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof ClubUser
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof ClubUser
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ClubUser
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof ClubUser
   */
  blocked?: string;
  /**
   *
   * @type {string}
   * @memberof ClubUser
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof ClubUser
   */
  deleted?: string;
  /**
   *
   * @type {string}
   * @memberof ClubUser
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof ClubUser
   */
  contact_language?: string;
  /**
   *
   * @type {Array<InlineResponse2007>}
   * @memberof ClubUser
   */
  tags?: Array<InlineResponse2007>;
  /**
   *
   * @type {CellarUserPasswordReset}
   * @memberof ClubUser
   */
  password_reset?: CellarUserPasswordReset;
  /**
   *
   * @type {CellarUserEmailChange}
   * @memberof ClubUser
   */
  email_change?: CellarUserEmailChange;
  /**
   *
   * @type {CellarUserInvitation}
   * @memberof ClubUser
   */
  invitation?: CellarUserInvitation;
  /**
   *
   * @type {CellarUserShippingAddress}
   * @memberof ClubUser
   */
  shipping_address?: CellarUserShippingAddress;
  /**
   *
   * @type {CellarUserShippingAddress}
   * @memberof ClubUser
   */
  billing_address?: CellarUserShippingAddress;
  /**
   *
   * @type {Array<CellarUserClubs>}
   * @memberof ClubUser
   */
  clubs?: Array<CellarUserClubs>;
  /**
   *
   * @type {string}
   * @memberof ClubUser
   */
  stripe_id?: string;
  /**
   *
   * @type {string}
   * @memberof ClubUser
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface Coupon
 */
export interface Coupon {
  /**
   *
   * @type {User}
   * @memberof Coupon
   */
  user?: User;
  /**
   *
   * @type {Array<Model>}
   * @memberof Coupon
   */
  models?: Array<Model>;
  /**
   *
   * @type {string}
   * @memberof Coupon
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof Coupon
   */
  target?: CouponTargetEnum;
  /**
   *
   * @type {string}
   * @memberof Coupon
   */
  type?: CouponTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Coupon
   */
  usability?: CouponUsabilityEnum;
  /**
   *
   * @type {string}
   * @memberof Coupon
   */
  validity?: CouponValidityEnum;
  /**
   *
   * @type {string}
   * @memberof Coupon
   */
  remaining?: CouponRemainingEnum;
  /**
   *
   * @type {string}
   * @memberof Coupon
   */
  owner?: CouponOwnerEnum;
  /**
   *
   * @type {CouponDiscount}
   * @memberof Coupon
   */
  discount?: CouponDiscount;
  /**
   *
   * @type {number}
   * @memberof Coupon
   */
  guests?: number;
  /**
   *
   * @type {number}
   * @memberof Coupon
   */
  usages?: number;
  /**
   *
   * @type {CouponPeriod}
   * @memberof Coupon
   */
  period?: CouponPeriod;
  /**
   *
   * @type {string}
   * @memberof Coupon
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof Coupon
   */
  deleted?: string;
  /**
   *
   * @type {string}
   * @memberof Coupon
   */
  _id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CouponTargetEnum {
  Generic = 'generic',
  Models = 'models',
}
/**
 * @export
 * @enum {string}
 */
export enum CouponTypeEnum {
  Guests = 'guests',
  Discount = 'discount',
  Mixed = 'mixed',
}
/**
 * @export
 * @enum {string}
 */
export enum CouponUsabilityEnum {
  Generic = 'generic',
  Single = 'single',
  Usages = 'usages',
}
/**
 * @export
 * @enum {string}
 */
export enum CouponValidityEnum {
  Generic = 'generic',
  Period = 'period',
}
/**
 * @export
 * @enum {string}
 */
export enum CouponRemainingEnum {
  Spendable = 'spendable',
  Keep = 'keep',
}
/**
 * @export
 * @enum {string}
 */
export enum CouponOwnerEnum {
  Generic = 'generic',
  System = 'system',
  Cellar = 'cellar',
  User = 'user',
}

/**
 *
 * @export
 * @interface CouponDiscount
 */
export interface CouponDiscount {
  /**
   *
   * @type {number}
   * @memberof CouponDiscount
   */
  expense?: number;
  /**
   *
   * @type {number}
   * @memberof CouponDiscount
   */
  percent?: number;
}
/**
 *
 * @export
 * @interface CouponPeriod
 */
export interface CouponPeriod {
  /**
   *
   * @type {string}
   * @memberof CouponPeriod
   */
  start?: string;
  /**
   *
   * @type {string}
   * @memberof CouponPeriod
   */
  end?: string;
}
/**
 *
 * @export
 * @interface CouponTicket
 */
export interface CouponTicket {
  /**
   *
   * @type {User}
   * @memberof CouponTicket
   */
  user?: User;
  /**
   *
   * @type {Coupon}
   * @memberof CouponTicket
   */
  coupon?: Coupon;
  /**
   *
   * @type {Array<CouponUsage>}
   * @memberof CouponTicket
   */
  coupon_usages?: Array<CouponUsage>;
  /**
   *
   * @type {string}
   * @memberof CouponTicket
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof CouponTicket
   */
  color?: CouponTicketColorEnum;
  /**
   *
   * @type {string}
   * @memberof CouponTicket
   */
  note?: string;
  /**
   *
   * @type {string}
   * @memberof CouponTicket
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof CouponTicket
   */
  deleted?: string;
  /**
   *
   * @type {string}
   * @memberof CouponTicket
   */
  expires?: string;
  /**
   *
   * @type {string}
   * @memberof CouponTicket
   */
  _id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CouponTicketColorEnum {
  Empty = '',
  Red = 'red',
  Blue = 'blue',
  Green = 'green',
  Yellow = 'yellow',
  Pink = 'pink',
  Orange = 'orange',
}

/**
 *
 * @export
 * @interface CouponUsage
 */
export interface CouponUsage {
  /**
   *
   * @type {User}
   * @memberof CouponUsage
   */
  user?: User;
  /**
   *
   * @type {Coupon}
   * @memberof CouponUsage
   */
  coupon?: Coupon;
  /**
   *
   * @type {CouponTicket}
   * @memberof CouponUsage
   */
  ticket?: CouponTicket;
  /**
   *
   * @type {AppUser}
   * @memberof CouponUsage
   */
  app_user?: AppUser;
  /**
   *
   * @type {Proposal}
   * @memberof CouponUsage
   */
  proposal?: Proposal;
  /**
   *
   * @type {Reservation}
   * @memberof CouponUsage
   */
  reservation?: Reservation;
  /**
   *
   * @type {string}
   * @memberof CouponUsage
   */
  status?: CouponUsageStatusEnum;
  /**
   *
   * @type {string}
   * @memberof CouponUsage
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof CouponUsage
   */
  deleted?: string;
  /**
   *
   * @type {string}
   * @memberof CouponUsage
   */
  _id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CouponUsageStatusEnum {
  Pending = 'pending',
  Created = 'created',
  Resolved = 'resolved',
}

/**
 *
 * @export
 * @interface Event
 */
export interface Event {
  /**
   *
   * @type {string}
   * @memberof Event
   */
  title?: string;
  /**
   *
   * @type {User}
   * @memberof Event
   */
  user?: User;
  /**
   *
   * @type {Place}
   * @memberof Event
   */
  location?: Place;
  /**
   *
   * @type {Model}
   * @memberof Event
   */
  model?: Model;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  uuid?: string;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof Event
   */
  languages?: Array<ClubLanguages>;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof Event
   */
  cancellation_messages?: Array<ClubLanguages>;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof Event
   */
  confirmation_messages?: Array<ClubLanguages>;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof Event
   */
  pending_messages?: Array<ClubLanguages>;
  /**
   *
   * @type {EventFollowup}
   * @memberof Event
   */
  followup?: EventFollowup;
  /**
   *
   * @type {EventReminder}
   * @memberof Event
   */
  reminder?: EventReminder;
  /**
   *
   * @type {EventPrice}
   * @memberof Event
   */
  price?: EventPrice;
  /**
   *
   * @type {EventDuration}
   * @memberof Event
   */
  duration?: EventDuration;
  /**
   *
   * @type {EventExpense}
   * @memberof Event
   */
  expense?: EventExpense;
  /**
   *
   * @type {number}
   * @memberof Event
   */
  places?: number;
  /**
   *
   * @type {number}
   * @memberof Event
   */
  min_guests?: number;
  /**
   *
   * @type {number}
   * @memberof Event
   */
  closure?: number;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  start?: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  end?: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  googleID?: string;
  /**
   *
   * @type {boolean}
   * @memberof Event
   */
  paused?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Event
   */
  mail_sent?: boolean;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  main_image?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Event
   */
  gallery_images?: Array<string>;
  /**
   *
   * @type {EventPayment}
   * @memberof Event
   */
  payment?: EventPayment;
  /**
   *
   * @type {boolean}
   * @memberof Event
   */
  payment_disabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Event
   */
  is_published?: boolean;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  cellar_note?: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  deleted?: string;
  /**
   *
   * @type {Array<Reservation>}
   * @memberof Event
   */
  reservations?: Array<Reservation>;
  /**
   *
   * @type {User}
   * @memberof Event
   */
  operator?: User;
  /**
   *
   * @type {Array<User>}
   * @memberof Event
   */
  operators?: Array<User>;
  /**
   *
   * @type {Array<Room>}
   * @memberof Event
   */
  selected_rooms?: Array<Room>;
  /**
   *
   * @type {Array<EventRooms>}
   * @memberof Event
   */
  rooms?: Array<EventRooms>;
  /**
   *
   * @type {number}
   * @memberof Event
   */
  priority?: number;
  /**
   *
   * @type {EventLiveRoom}
   * @memberof Event
   */
  live_room?: EventLiveRoom;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface EventDuration
 */
export interface EventDuration {
  /**
   *
   * @type {number}
   * @memberof EventDuration
   */
  hours?: number;
  /**
   *
   * @type {number}
   * @memberof EventDuration
   */
  minutes?: number;
}
/**
 *
 * @export
 * @interface EventExpense
 */
export interface EventExpense {
  /**
   *
   * @type {number}
   * @memberof EventExpense
   */
  value?: number;
  /**
   *
   * @type {boolean}
   * @memberof EventExpense
   */
  is_set?: boolean;
}
/**
 *
 * @export
 * @interface EventFollowup
 */
export interface EventFollowup {
  /**
   *
   * @type {number}
   * @memberof EventFollowup
   */
  timeout?: number;
  /**
   *
   * @type {boolean}
   * @memberof EventFollowup
   */
  email_sent?: boolean;
}
/**
 *
 * @export
 * @interface EventLiveRoom
 */
export interface EventLiveRoom {
  /**
   *
   * @type {boolean}
   * @memberof EventLiveRoom
   */
  enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EventLiveRoom
   */
  created?: boolean;
}
/**
 *
 * @export
 * @interface EventPayment
 */
export interface EventPayment {
  /**
   *
   * @type {boolean}
   * @memberof EventPayment
   */
  enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EventPayment
   */
  refundable?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof EventPayment
   */
  amounts?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof EventPayment
   */
  refunds?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof EventPayment
   */
  hours?: number;
  /**
   *
   * @type {boolean}
   * @memberof EventPayment
   */
  has_guests?: boolean;
  /**
   *
   * @type {number}
   * @memberof EventPayment
   */
  min_guests?: number;
  /**
   *
   * @type {boolean}
   * @memberof EventPayment
   */
  limit?: boolean;
  /**
   *
   * @type {number}
   * @memberof EventPayment
   */
  max_amount?: number;
}
/**
 *
 * @export
 * @interface EventPrice
 */
export interface EventPrice {
  /**
   *
   * @type {string}
   * @memberof EventPrice
   */
  mode?: string;
  /**
   *
   * @type {number}
   * @memberof EventPrice
   */
  value?: number;
}
/**
 *
 * @export
 * @interface EventReminder
 */
export interface EventReminder {
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof EventReminder
   */
  messages?: Array<ClubLanguages>;
  /**
   *
   * @type {number}
   * @memberof EventReminder
   */
  timeout?: number;
  /**
   *
   * @type {boolean}
   * @memberof EventReminder
   */
  email_sent?: boolean;
}
/**
 *
 * @export
 * @interface EventRooms
 */
export interface EventRooms {
  /**
   *
   * @type {Room}
   * @memberof EventRooms
   */
  room?: Room;
  /**
   *
   * @type {Array<string>}
   * @memberof EventRooms
   */
  slots?: Array<string>;
}
/**
 *
 * @export
 * @interface Expense
 */
export interface Expense {
  /**
   *
   * @type {User}
   * @memberof Expense
   */
  user?: User;
  /**
   *
   * @type {CellarUser}
   * @memberof Expense
   */
  cellar_user?: CellarUser;
  /**
   *
   * @type {Reservation}
   * @memberof Expense
   */
  reservation?: Reservation;
  /**
   *
   * @type {Order}
   * @memberof Expense
   */
  order?: Order;
  /**
   *
   * @type {number}
   * @memberof Expense
   */
  value?: number;
  /**
   *
   * @type {string}
   * @memberof Expense
   */
  date?: string;
  /**
   *
   * @type {string}
   * @memberof Expense
   */
  created?: string;
  /**
   *
   * @type {Array<ExpenseItems>}
   * @memberof Expense
   */
  items?: Array<ExpenseItems>;
  /**
   *
   * @type {Array<InlineResponse2007>}
   * @memberof Expense
   */
  tags?: Array<InlineResponse2007>;
  /**
   *
   * @type {string}
   * @memberof Expense
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface ExpenseItems
 */
export interface ExpenseItems {
  /**
   *
   * @type {ShopItem}
   * @memberof ExpenseItems
   */
  shop_item?: ShopItem;
  /**
   *
   * @type {number}
   * @memberof ExpenseItems
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof ExpenseItems
   */
  price?: number;
  /**
   *
   * @type {Array<ShopItemItems>}
   * @memberof ExpenseItems
   */
  items?: Array<ShopItemItems>;
}
/**
 *
 * @export
 * @interface GiftCard
 */
export interface GiftCard {
  /**
   *
   * @type {User}
   * @memberof GiftCard
   */
  user?: User;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof GiftCard
   */
  languages?: Array<ClubLanguages>;
  /**
   *
   * @type {Array<number>}
   * @memberof GiftCard
   */
  amounts?: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof GiftCard
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof GiftCard
   */
  position?: GiftCardPositionEnum;
  /**
   *
   * @type {string}
   * @memberof GiftCard
   */
  main_image?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GiftCard
   */
  gallery_images?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof GiftCard
   */
  expiry_months?: number;
  /**
   *
   * @type {string}
   * @memberof GiftCard
   */
  _id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum GiftCardPositionEnum {
  First = 'first',
  Last = 'last',
}

/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
  /**
   *
   * @type {string}
   * @memberof InlineObject
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject
   */
  password?: string;
}
/**
 *
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
  /**
   *
   * @type {Array<string>}
   * @memberof InlineObject1
   */
  ids?: Array<string>;
}
/**
 *
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  start?: string;
}
/**
 *
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
  /**
   * When accepting a proposal in an event that doesn\'t have an operator available \'force\' is required or the request will fail with NO_OPERATOR_AND_NO_FORCE
   * @type {boolean}
   * @memberof InlineObject3
   */
  force?: boolean;
  /**
   * Custom message to be sent in the confirmation email
   * @type {string}
   * @memberof InlineObject3
   */
  message?: string;
  /**
   * When true the confirmation email will not be sent
   * @type {boolean}
   * @memberof InlineObject3
   */
  noEmail?: boolean;
  /**
   * Id of the operator to be assigned, leaving this empty will cause the system to assign one (if available based on the event model config) or fail (if no operator on the config is available). FIXME: it let\'s you assign an operator that is occupied
   * @type {string}
   * @memberof InlineObject3
   */
  operator?: string;
}
/**
 *
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
  /**
   * Custom message to be sent in the confirmation email
   * @type {string}
   * @memberof InlineObject4
   */
  message?: string;
}
/**
 *
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
  /**
   *
   * @type {string}
   * @memberof InlineObject5
   */
  cellar_note?: string;
}
/**
 *
 * @export
 * @interface InlineObject6
 */
export interface InlineObject6 {
  /**
   *
   * @type {string}
   * @memberof InlineObject6
   */
  contact_language?: InlineObject6ContactLanguageEnum;
  /**
   *
   * @type {string}
   * @memberof InlineObject6
   */
  message?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum InlineObject6ContactLanguageEnum {
  En = 'en',
  It = 'it',
  De = 'de',
  Bg = 'bg',
}

/**
 *
 * @export
 * @interface InlineObject7
 */
export interface InlineObject7 {
  /**
   *
   * @type {string}
   * @memberof InlineObject7
   */
  contact_language?: InlineObject7ContactLanguageEnum;
  /**
   *
   * @type {string}
   * @memberof InlineObject7
   */
  message?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum InlineObject7ContactLanguageEnum {
  En = 'en',
  It = 'it',
  De = 'de',
  Bg = 'bg',
}

/**
 *
 * @export
 * @interface InlineObject8
 */
export interface InlineObject8 {
  /**
   * Target eventId
   * @type {string}
   * @memberof InlineObject8
   */
  target?: string;
}
/**
 *
 * @export
 * @interface InlineObject9
 */
export interface InlineObject9 {
  /**
   *
   * @type {string}
   * @memberof InlineObject9
   */
  status?: InlineObject9StatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum InlineObject9StatusEnum {
  Unconfirmed = 'unconfirmed',
  Confirmed = 'confirmed',
  Finalizing = 'finalizing',
  Pending = 'pending',
}

/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse200
   */
  token?: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse200
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse200
   */
  last_name?: string;
}
/**
 *
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
  /**
   *
   * @type {Place}
   * @memberof InlineResponse2001
   */
  place?: Place;
  /**
   *
   * @type {Model}
   * @memberof InlineResponse2001
   */
  modelsKeep?: Model;
  /**
   *
   * @type {Array<Model>}
   * @memberof InlineResponse2001
   */
  models?: Array<Model>;
  /**
   *
   * @type {Array<Event>}
   * @memberof InlineResponse2001
   */
  events?: Array<Event>;
  /**
   *
   * @type {Array<Note>}
   * @memberof InlineResponse2001
   */
  notes?: Array<Note>;
  /**
   *
   * @type {Array<Proposal>}
   * @memberof InlineResponse2001
   */
  proposals?: Array<Proposal>;
}
/**
 *
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
  /**
   * Ids of matching events
   * @type {Array<string>}
   * @memberof InlineResponse2002
   */
  ids?: Array<string>;
}
/**
 *
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse2003
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse2003
   */
  english_name: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse2003
   */
  native_name: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse2003
   */
  text: string;
}
/**
 *
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
  /**
   *
   * @type {number}
   * @memberof InlineResponse2004
   */
  pending?: number;
}
/**
 *
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse2005
   */
  _id?: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse2005
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse2005
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse2005
   */
  email?: string;
  /**
   * This field will only appear if you send \'eventId\' or \'start\'/\'end\', it indicates if an operator is available in the given time interval
   * @type {boolean}
   * @memberof InlineResponse2005
   */
  available?: boolean;
}
/**
 *
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse2006
   */
  _id?: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse2006
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof InlineResponse2006
   */
  places?: number;
  /**
   *
   * @type {string}
   * @memberof InlineResponse2006
   */
  start?: string;
  /**
   *
   * @type {Array<PlacesPlaceIdEventsReservations>}
   * @memberof InlineResponse2006
   */
  reservations?: Array<PlacesPlaceIdEventsReservations>;
}
/**
 *
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse2007
   */
  text?: string;
}
/**
 *
 * @export
 * @interface InlineResponse400
 */
export interface InlineResponse400 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse400
   */
  code?: InlineResponse400CodeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum InlineResponse400CodeEnum {
  NOOPERATORANDNOFORCE = 'NO_OPERATOR_AND_NO_FORCE',
  PROPOSALALREADYACCEPTED = 'PROPOSAL_ALREADY_ACCEPTED',
}

/**
 *
 * @export
 * @interface InviteToken
 */
export interface InviteToken {
  /**
   *
   * @type {string}
   * @memberof InviteToken
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof InviteToken
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof InviteToken
   */
  company?: string;
  /**
   *
   * @type {string}
   * @memberof InviteToken
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof InviteToken
   */
  unique_id?: string;
  /**
   *
   * @type {string}
   * @memberof InviteToken
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface KV
 */
export interface KV {
  /**
   *
   * @type {string}
   * @memberof KV
   */
  _id?: string;
  /**
   *
   * @type {string}
   * @memberof KV
   */
  value?: string;
}
/**
 *
 * @export
 * @interface LoginToken
 */
export interface LoginToken {
  /**
   *
   * @type {User}
   * @memberof LoginToken
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof LoginToken
   */
  user_type?: string;
  /**
   *
   * @type {string}
   * @memberof LoginToken
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof LoginToken
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof LoginToken
   */
  expires?: string;
  /**
   *
   * @type {string}
   * @memberof LoginToken
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface MetaAssignment
 */
export interface MetaAssignment {
  /**
   *
   * @type {User}
   * @memberof MetaAssignment
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof MetaAssignment
   */
  resource?: MetaAssignmentResourceEnum;
  /**
   *
   * @type {Array<MetaAssignmentFields>}
   * @memberof MetaAssignment
   */
  fields?: Array<MetaAssignmentFields>;
  /**
   *
   * @type {string}
   * @memberof MetaAssignment
   */
  _id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum MetaAssignmentResourceEnum {
  Customers = 'customers',
}

/**
 *
 * @export
 * @interface MetaAssignmentFields
 */
export interface MetaAssignmentFields {
  /**
   *
   * @type {MetaData}
   * @memberof MetaAssignmentFields
   */
  meta_data?: MetaData;
}
/**
 *
 * @export
 * @interface MetaData
 */
export interface MetaData {
  /**
   *
   * @type {string}
   * @memberof MetaData
   */
  title?: string;
  /**
   *
   * @type {User}
   * @memberof MetaData
   */
  user?: User;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof MetaData
   */
  languages?: Array<ClubLanguages>;
  /**
   *
   * @type {Array<MetaDataOptions>}
   * @memberof MetaData
   */
  options?: Array<MetaDataOptions>;
  /**
   *
   * @type {string}
   * @memberof MetaData
   */
  type?: MetaDataTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof MetaData
   */
  fronted?: boolean;
  /**
   *
   * @type {string}
   * @memberof MetaData
   */
  deleted?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof MetaData
   */
  warnings?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof MetaData
   */
  _id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum MetaDataTypeEnum {
  Text = 'text',
  Checkbox = 'checkbox',
  Radio = 'radio',
  Select = 'select',
  Date = 'date',
  DateTime = 'date-time',
  Autocomplete = 'autocomplete',
}

/**
 *
 * @export
 * @interface MetaDataOptions
 */
export interface MetaDataOptions {
  /**
   *
   * @type {string}
   * @memberof MetaDataOptions
   */
  title?: string;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof MetaDataOptions
   */
  languages?: Array<ClubLanguages>;
}
/**
 *
 * @export
 * @interface Model
 */
export interface Model {
  /**
   *
   * @type {string}
   * @memberof Model
   */
  title?: string;
  /**
   *
   * @type {User}
   * @memberof Model
   */
  user?: User;
  /**
   *
   * @type {Place}
   * @memberof Model
   */
  location?: Place;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof Model
   */
  languages?: Array<ClubLanguages>;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof Model
   */
  cancellation_messages?: Array<ClubLanguages>;
  /**
   *
   * @type {boolean}
   * @memberof Model
   */
  cancellation_hide_default?: boolean;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof Model
   */
  confirmation_messages?: Array<ClubLanguages>;
  /**
   *
   * @type {boolean}
   * @memberof Model
   */
  confirmation_hide_default?: boolean;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof Model
   */
  pending_messages?: Array<ClubLanguages>;
  /**
   *
   * @type {boolean}
   * @memberof Model
   */
  pending_hide_default?: boolean;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof Model
   */
  finalization_messages?: Array<ClubLanguages>;
  /**
   *
   * @type {boolean}
   * @memberof Model
   */
  finalization_hide_default?: boolean;
  /**
   *
   * @type {ModelFollowup}
   * @memberof Model
   */
  followup?: ModelFollowup;
  /**
   *
   * @type {ModelFollowup}
   * @memberof Model
   */
  reminder?: ModelFollowup;
  /**
   *
   * @type {ModelReminderSelf}
   * @memberof Model
   */
  reminder_self?: ModelReminderSelf;
  /**
   *
   * @type {ModelPrice}
   * @memberof Model
   */
  price?: ModelPrice;
  /**
   *
   * @type {EventDuration}
   * @memberof Model
   */
  duration?: EventDuration;
  /**
   *
   * @type {EventExpense}
   * @memberof Model
   */
  expense?: EventExpense;
  /**
   *
   * @type {EventPayment}
   * @memberof Model
   */
  payment?: EventPayment;
  /**
   *
   * @type {number}
   * @memberof Model
   */
  places?: number;
  /**
   *
   * @type {number}
   * @memberof Model
   */
  min_guests_to_start?: number;
  /**
   *
   * @type {number}
   * @memberof Model
   */
  min_guests?: number;
  /**
   *
   * @type {number}
   * @memberof Model
   */
  max_guests?: number;
  /**
   *
   * @type {number}
   * @memberof Model
   */
  closure?: number;
  /**
   *
   * @type {string}
   * @memberof Model
   */
  main_image?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Model
   */
  gallery_images?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof Model
   */
  visibility?: boolean;
  /**
   *
   * @type {string}
   * @memberof Model
   */
  event_type?: ModelEventTypeEnum;
  /**
   *
   * @type {object}
   * @memberof Model
   */
  steps?: object;
  /**
   *
   * @type {boolean}
   * @memberof Model
   */
  play_default?: boolean;
  /**
   *
   * @type {ModelApprovation}
   * @memberof Model
   */
  approvation?: ModelApprovation;
  /**
   *
   * @type {boolean}
   * @memberof Model
   */
  is_published?: boolean;
  /**
   *
   * @type {number}
   * @memberof Model
   */
  model_order?: number;
  /**
   *
   * @type {Array<User>}
   * @memberof Model
   */
  operators?: Array<User>;
  /**
   *
   * @type {boolean}
   * @memberof Model
   */
  activate_operators?: boolean;
  /**
   *
   * @type {Array<EventRooms>}
   * @memberof Model
   */
  rooms?: Array<EventRooms>;
  /**
   *
   * @type {Array<EventRooms>}
   * @memberof Model
   */
  available_rooms?: Array<EventRooms>;
  /**
   *
   * @type {boolean}
   * @memberof Model
   */
  activate_rooms?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Model
   */
  activate_auto_room?: boolean;
  /**
   *
   * @type {Array<ModelExtras>}
   * @memberof Model
   */
  extras?: Array<ModelExtras>;
  /**
   *
   * @type {ModelAfterReservation}
   * @memberof Model
   */
  after_reservation?: ModelAfterReservation;
  /**
   *
   * @type {ModelBeforeReservation}
   * @memberof Model
   */
  before_reservation?: ModelBeforeReservation;
  /**
   *
   * @type {ModelFilters}
   * @memberof Model
   */
  filters?: ModelFilters;
  /**
   *
   * @type {Array<ModelMetaData>}
   * @memberof Model
   */
  meta_data?: Array<ModelMetaData>;
  /**
   *
   * @type {ModelReservationMode}
   * @memberof Model
   */
  reservation_mode?: ModelReservationMode;
  /**
   *
   * @type {string}
   * @memberof Model
   */
  deleted?: string;
  /**
   *
   * @type {ModelColors}
   * @memberof Model
   */
  colors?: ModelColors;
  /**
   *
   * @type {Array<InlineResponse2007>}
   * @memberof Model
   */
  tags?: Array<InlineResponse2007>;
  /**
   *
   * @type {boolean}
   * @memberof Model
   */
  favorite?: boolean;
  /**
   *
   * @type {string}
   * @memberof Model
   */
  _id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ModelEventTypeEnum {
  Wine = 'wine',
  Around = 'around',
}

/**
 *
 * @export
 * @interface ModelAfterReservation
 */
export interface ModelAfterReservation {
  /**
   *
   * @type {boolean}
   * @memberof ModelAfterReservation
   */
  unpause_on_new_reservation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ModelAfterReservation
   */
  hide_on_new_reservation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ModelAfterReservation
   */
  new_group_on_new_reservation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ModelAfterReservation
   */
  new_group_on_places_left?: boolean;
  /**
   *
   * @type {number}
   * @memberof ModelAfterReservation
   */
  new_group_on_places_left_number?: number;
  /**
   *
   * @type {boolean}
   * @memberof ModelAfterReservation
   */
  pause_on_no_reservations?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ModelAfterReservation
   */
  show_on_no_reservations?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ModelAfterReservation
   */
  remove_empty_group_on_no_reservations?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ModelAfterReservation
   */
  close_all_others_on_new_reservation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ModelAfterReservation
   */
  close_other_languages_on_new_reservation?: boolean;
  /**
   *
   * @type {number}
   * @memberof ModelAfterReservation
   */
  groups_number?: number;
}
/**
 *
 * @export
 * @interface ModelApprovation
 */
export interface ModelApprovation {
  /**
   *
   * @type {boolean}
   * @memberof ModelApprovation
   */
  status?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ModelApprovation
   */
  requested?: boolean;
}
/**
 *
 * @export
 * @interface ModelBeforeReservation
 */
export interface ModelBeforeReservation {
  /**
   *
   * @type {boolean}
   * @memberof ModelBeforeReservation
   */
  match_to_empty_event?: boolean;
}
/**
 *
 * @export
 * @interface ModelColors
 */
export interface ModelColors {
  /**
   *
   * @type {ModelColorsRt}
   * @memberof ModelColors
   */
  rt?: ModelColorsRt;
  /**
   *
   * @type {ModelColorsRt}
   * @memberof ModelColors
   */
  proposal?: ModelColorsRt;
}
/**
 *
 * @export
 * @interface ModelColorsRt
 */
export interface ModelColorsRt {
  /**
   *
   * @type {string}
   * @memberof ModelColorsRt
   */
  empty?: string;
  /**
   *
   * @type {string}
   * @memberof ModelColorsRt
   */
  reserved?: string;
  /**
   *
   * @type {ModelColorsRtFonts}
   * @memberof ModelColorsRt
   */
  fonts?: ModelColorsRtFonts;
}
/**
 *
 * @export
 * @interface ModelColorsRtFonts
 */
export interface ModelColorsRtFonts {
  /**
   *
   * @type {string}
   * @memberof ModelColorsRtFonts
   */
  empty?: string;
  /**
   *
   * @type {string}
   * @memberof ModelColorsRtFonts
   */
  reserved?: string;
}
/**
 *
 * @export
 * @interface ModelExtras
 */
export interface ModelExtras {
  /**
   *
   * @type {ReservationExtra}
   * @memberof ModelExtras
   */
  extra?: ReservationExtra;
  /**
   *
   * @type {number}
   * @memberof ModelExtras
   */
  max_value?: number;
  /**
   *
   * @type {number}
   * @memberof ModelExtras
   */
  price?: number;
}
/**
 *
 * @export
 * @interface ModelFilters
 */
export interface ModelFilters {
  /**
   *
   * @type {boolean}
   * @memberof ModelFilters
   */
  same_start?: boolean;
  /**
   *
   * @type {ModelFiltersSameStartSettings}
   * @memberof ModelFilters
   */
  same_start_settings?: ModelFiltersSameStartSettings;
  /**
   *
   * @type {boolean}
   * @memberof ModelFilters
   */
  same_language?: boolean;
  /**
   *
   * @type {ModelFiltersSameLanguageSettings}
   * @memberof ModelFilters
   */
  same_language_settings?: ModelFiltersSameLanguageSettings;
  /**
   *
   * @type {boolean}
   * @memberof ModelFilters
   */
  event_priority?: boolean;
  /**
   *
   * @type {ModelFiltersSameStartSettings}
   * @memberof ModelFilters
   */
  event_priority_settings?: ModelFiltersSameStartSettings;
}
/**
 *
 * @export
 * @interface ModelFiltersSameLanguageSettings
 */
export interface ModelFiltersSameLanguageSettings {
  /**
   *
   * @type {number}
   * @memberof ModelFiltersSameLanguageSettings
   */
  offset_past?: number;
  /**
   *
   * @type {number}
   * @memberof ModelFiltersSameLanguageSettings
   */
  offset_future?: number;
  /**
   *
   * @type {boolean}
   * @memberof ModelFiltersSameLanguageSettings
   */
  invert?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ModelFiltersSameLanguageSettings
   */
  events?: boolean;
}
/**
 *
 * @export
 * @interface ModelFiltersSameStartSettings
 */
export interface ModelFiltersSameStartSettings {
  /**
   *
   * @type {number}
   * @memberof ModelFiltersSameStartSettings
   */
  offset_past?: number;
  /**
   *
   * @type {number}
   * @memberof ModelFiltersSameStartSettings
   */
  offset_future?: number;
}
/**
 *
 * @export
 * @interface ModelFollowup
 */
export interface ModelFollowup {
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof ModelFollowup
   */
  messages?: Array<ClubLanguages>;
  /**
   *
   * @type {number}
   * @memberof ModelFollowup
   */
  timeout?: number;
  /**
   *
   * @type {boolean}
   * @memberof ModelFollowup
   */
  hide_default?: boolean;
}
/**
 *
 * @export
 * @interface ModelMetaData
 */
export interface ModelMetaData {
  /**
   *
   * @type {MetaData}
   * @memberof ModelMetaData
   */
  meta_data?: MetaData;
  /**
   *
   * @type {boolean}
   * @memberof ModelMetaData
   */
  required?: boolean;
  /**
   *
   * @type {Array<MetaDataOptions>}
   * @memberof ModelMetaData
   */
  options?: Array<MetaDataOptions>;
}
/**
 *
 * @export
 * @interface ModelPrice
 */
export interface ModelPrice {
  /**
   *
   * @type {string}
   * @memberof ModelPrice
   */
  mode?: ModelPriceModeEnum;
  /**
   *
   * @type {number}
   * @memberof ModelPrice
   */
  value?: number;
  /**
   *
   * @type {Array<ModelPriceVariations>}
   * @memberof ModelPrice
   */
  variations?: Array<ModelPriceVariations>;
}

/**
 * @export
 * @enum {string}
 */
export enum ModelPriceModeEnum {
  Free = 'free',
  From = 'from',
  Fixed = 'fixed',
  Event = 'event',
  OnRequest = 'on_request',
}

/**
 *
 * @export
 * @interface ModelPriceVariations
 */
export interface ModelPriceVariations {
  /**
   *
   * @type {number}
   * @memberof ModelPriceVariations
   */
  from?: number;
  /**
   *
   * @type {number}
   * @memberof ModelPriceVariations
   */
  to?: number;
  /**
   *
   * @type {number}
   * @memberof ModelPriceVariations
   */
  value?: number;
}
/**
 *
 * @export
 * @interface ModelReminderSelf
 */
export interface ModelReminderSelf {
  /**
   *
   * @type {boolean}
   * @memberof ModelReminderSelf
   */
  enabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof ModelReminderSelf
   */
  hours?: number;
}
/**
 *
 * @export
 * @interface ModelReservationMode
 */
export interface ModelReservationMode {
  /**
   *
   * @type {string}
   * @memberof ModelReservationMode
   */
  mode?: ModelReservationModeModeEnum;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof ModelReservationMode
   */
  messages?: Array<ClubLanguages>;
  /**
   *
   * @type {boolean}
   * @memberof ModelReservationMode
   */
  authentication?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ModelReservationMode
   */
  hide_price?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ModelReservationMode
   */
  hide_duration?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum ModelReservationModeModeEnum {
  Book = 'book',
  View = 'view',
  Guests = 'guests',
  Video = 'video',
}

/**
 *
 * @export
 * @interface Note
 */
export interface Note {
  /**
   *
   * @type {string}
   * @memberof Note
   */
  title?: string;
  /**
   *
   * @type {User}
   * @memberof Note
   */
  user?: User;
  /**
   *
   * @type {Place}
   * @memberof Note
   */
  location?: Place;
  /**
   *
   * @type {boolean}
   * @memberof Note
   */
  is_note?: boolean;
  /**
   *
   * @type {string}
   * @memberof Note
   */
  text?: string;
  /**
   *
   * @type {EventDuration}
   * @memberof Note
   */
  duration?: EventDuration;
  /**
   *
   * @type {string}
   * @memberof Note
   */
  start?: string;
  /**
   *
   * @type {string}
   * @memberof Note
   */
  end?: string;
  /**
   *
   * @type {NoteColors}
   * @memberof Note
   */
  colors?: NoteColors;
  /**
   *
   * @type {string}
   * @memberof Note
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface NoteColors
 */
export interface NoteColors {
  /**
   *
   * @type {string}
   * @memberof NoteColors
   */
  background?: string;
  /**
   *
   * @type {string}
   * @memberof NoteColors
   */
  fonts?: string;
}
/**
 *
 * @export
 * @interface Option
 */
export interface Option {
  /**
   *
   * @type {string}
   * @memberof Option
   */
  application?: string;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof Option
   */
  version?: number;
  /**
   *
   * @type {object}
   * @memberof Option
   */
  value?: object;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface Order
 */
export interface Order {
  /**
   *
   * @type {User}
   * @memberof Order
   */
  user: User;
  /**
   *
   * @type {CellarUser}
   * @memberof Order
   */
  club_user: CellarUser;
  /**
   *
   * @type {Expense}
   * @memberof Order
   */
  expense: Expense;
  /**
   *
   * @type {Club}
   * @memberof Order
   */
  club: Club;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  currency: string;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  stripe_id?: string;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  fulfilled?: string;
  /**
   *
   * @type {OrderShipping}
   * @memberof Order
   */
  shipping?: OrderShipping;
  /**
   *
   * @type {StripeAccount}
   * @memberof Order
   */
  stripe_account: StripeAccount;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  _id?: string;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  createdAt?: string;
}
/**
 *
 * @export
 * @interface OrderShipping
 */
export interface OrderShipping {
  /**
   *
   * @type {string}
   * @memberof OrderShipping
   */
  mode?: OrderShippingModeEnum;
  /**
   *
   * @type {CellarUserShippingAddress}
   * @memberof OrderShipping
   */
  shipping_address?: CellarUserShippingAddress;
  /**
   *
   * @type {CellarUserShippingAddress}
   * @memberof OrderShipping
   */
  billing_address?: CellarUserShippingAddress;
  /**
   *
   * @type {boolean}
   * @memberof OrderShipping
   */
  different_billing?: boolean;
  /**
   *
   * @type {CellarUserFiscal}
   * @memberof OrderShipping
   */
  fiscal?: CellarUserFiscal;
  /**
   *
   * @type {string}
   * @memberof OrderShipping
   */
  phone?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum OrderShippingModeEnum {
  Shipping = 'shipping',
  Pickup = 'pickup',
}

/**
 *
 * @export
 * @interface PhoneBlock
 */
export interface PhoneBlock {
  /**
   *
   * @type {string}
   * @memberof PhoneBlock
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof PhoneBlock
   */
  expires?: string;
  /**
   *
   * @type {string}
   * @memberof PhoneBlock
   */
  created?: string;
  /**
   *
   * @type {User}
   * @memberof PhoneBlock
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof PhoneBlock
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface Picture
 */
export interface Picture {
  /**
   *
   * @type {string}
   * @memberof Picture
   */
  _id?: string;
  /**
   *
   * @type {User}
   * @memberof Picture
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof Picture
   */
  picture_type?: PicturePictureTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Picture
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof Picture
   */
  deleted?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PicturePictureTypeEnum {
  Logo = 'logo',
  Model = 'model',
  Place = 'place',
  Product = 'product',
}

/**
 *
 * @export
 * @interface Place
 */
export interface Place {
  /**
   *
   * @type {string}
   * @memberof Place
   */
  title?: string;
  /**
   *
   * @type {User}
   * @memberof Place
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof Place
   */
  googleCalendarId?: string;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof Place
   */
  languages?: Array<ClubLanguages>;
  /**
   *
   * @type {PlaceAddress}
   * @memberof Place
   */
  address?: PlaceAddress;
  /**
   *
   * @type {string}
   * @memberof Place
   */
  place_type?: PlacePlaceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Place
   */
  main_image?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Place
   */
  gallery_images?: Array<string>;
  /**
   *
   * @type {ModelApprovation}
   * @memberof Place
   */
  approvation?: ModelApprovation;
  /**
   *
   * @type {string}
   * @memberof Place
   */
  timezone?: string;
  /**
   *
   * @type {string}
   * @memberof Place
   */
  currency?: string;
  /**
   *
   * @type {Array<Room>}
   * @memberof Place
   */
  rooms?: Array<Room>;
  /**
   *
   * @type {object}
   * @memberof Place
   */
  steps?: object;
  /**
   *
   * @type {string}
   * @memberof Place
   */
  deleted?: string;
  /**
   *
   * @type {string}
   * @memberof Place
   */
  _id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PlacePlaceTypeEnum {
  Producer = 'producer',
  Other = 'other',
}

/**
 *
 * @export
 * @interface PlaceAddress
 */
export interface PlaceAddress {
  /**
   *
   * @type {PlaceAddressStreet}
   * @memberof PlaceAddress
   */
  street?: PlaceAddressStreet;
  /**
   *
   * @type {PlaceAddressStreet}
   * @memberof PlaceAddress
   */
  number?: PlaceAddressStreet;
  /**
   *
   * @type {PlaceAddressStreet}
   * @memberof PlaceAddress
   */
  city?: PlaceAddressStreet;
  /**
   *
   * @type {PlaceAddressStreet}
   * @memberof PlaceAddress
   */
  province?: PlaceAddressStreet;
  /**
   *
   * @type {PlaceAddressStreet}
   * @memberof PlaceAddress
   */
  region?: PlaceAddressStreet;
  /**
   *
   * @type {PlaceAddressStreet}
   * @memberof PlaceAddress
   */
  country?: PlaceAddressStreet;
  /**
   *
   * @type {PlaceAddressStreet}
   * @memberof PlaceAddress
   */
  zip?: PlaceAddressStreet;
  /**
   *
   * @type {PlaceAddressCoords}
   * @memberof PlaceAddress
   */
  coords?: PlaceAddressCoords;
}
/**
 *
 * @export
 * @interface PlaceAddressCoords
 */
export interface PlaceAddressCoords {
  /**
   *
   * @type {number}
   * @memberof PlaceAddressCoords
   */
  longitude?: number;
  /**
   *
   * @type {number}
   * @memberof PlaceAddressCoords
   */
  latitude?: number;
}
/**
 *
 * @export
 * @interface PlaceAddressStreet
 */
export interface PlaceAddressStreet {
  /**
   *
   * @type {string}
   * @memberof PlaceAddressStreet
   */
  long_name?: string;
  /**
   *
   * @type {string}
   * @memberof PlaceAddressStreet
   */
  short_name?: string;
}
/**
 *
 * @export
 * @interface PlacesPlaceIdEventsReservations
 */
export interface PlacesPlaceIdEventsReservations {
  /**
   *
   * @type {string}
   * @memberof PlacesPlaceIdEventsReservations
   */
  _id?: string;
  /**
   *
   * @type {string}
   * @memberof PlacesPlaceIdEventsReservations
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof PlacesPlaceIdEventsReservations
   */
  guests?: number;
  /**
   *
   * @type {string}
   * @memberof PlacesPlaceIdEventsReservations
   */
  language?: string;
}
/**
 *
 * @export
 * @interface Product
 */
export interface Product {
  /**
   *
   * @type {User}
   * @memberof Product
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  type?: ProductTypeEnum;
  /**
   *
   * @type {Array<InlineResponse2007>}
   * @memberof Product
   */
  tags?: Array<InlineResponse2007>;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof Product
   */
  languages?: Array<ClubLanguages>;
  /**
   *
   * @type {Array<string>}
   * @memberof Product
   */
  items?: Array<string>;
  /**
   *
   * @type {ProductFields}
   * @memberof Product
   */
  fields?: ProductFields;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  deleted?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  _id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ProductTypeEnum {
  Wine = 'wine',
  Food = 'food',
  Liquid = 'liquid',
  Generic = 'generic',
}

/**
 *
 * @export
 * @interface ProductFields
 */
export interface ProductFields {
  /**
   *
   * @type {string}
   * @memberof ProductFields
   */
  wine?: string;
}
/**
 *
 * @export
 * @interface ProductItem
 */
export interface ProductItem {
  /**
   *
   * @type {User}
   * @memberof ProductItem
   */
  user?: User;
  /**
   *
   * @type {Product}
   * @memberof ProductItem
   */
  product?: Product;
  /**
   *
   * @type {string}
   * @memberof ProductItem
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof ProductItem
   */
  nickname?: string;
  /**
   *
   * @type {number}
   * @memberof ProductItem
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof ProductItem
   */
  weight?: number;
  /**
   *
   * @type {ProductItemFields}
   * @memberof ProductItem
   */
  fields?: ProductItemFields;
  /**
   *
   * @type {string}
   * @memberof ProductItem
   */
  deleted?: string;
  /**
   *
   * @type {string}
   * @memberof ProductItem
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface ProductItemFields
 */
export interface ProductItemFields {
  /**
   *
   * @type {ProductItemFieldsWine}
   * @memberof ProductItemFields
   */
  wine?: ProductItemFieldsWine;
  /**
   *
   * @type {ProductItemFieldsLiquid}
   * @memberof ProductItemFields
   */
  liquid?: ProductItemFieldsLiquid;
}
/**
 *
 * @export
 * @interface ProductItemFieldsLiquid
 */
export interface ProductItemFieldsLiquid {
  /**
   *
   * @type {number}
   * @memberof ProductItemFieldsLiquid
   */
  format?: number;
}
/**
 *
 * @export
 * @interface ProductItemFieldsWine
 */
export interface ProductItemFieldsWine {
  /**
   *
   * @type {number}
   * @memberof ProductItemFieldsWine
   */
  format?: number;
  /**
   *
   * @type {number}
   * @memberof ProductItemFieldsWine
   */
  vintage?: number;
  /**
   *
   * @type {number}
   * @memberof ProductItemFieldsWine
   */
  production?: number;
  /**
   *
   * @type {number}
   * @memberof ProductItemFieldsWine
   */
  alcohol?: number;
}
/**
 *
 * @export
 * @interface Proposal
 */
export interface Proposal {
  /**
   *
   * @type {string}
   * @memberof Proposal
   */
  title?: string;
  /**
   *
   * @type {User}
   * @memberof Proposal
   */
  user?: User;
  /**
   *
   * @type {Place}
   * @memberof Proposal
   */
  location?: Place;
  /**
   *
   * @type {AppUser}
   * @memberof Proposal
   */
  app_user?: AppUser;
  /**
   *
   * @type {Reservation}
   * @memberof Proposal
   */
  reservation?: Reservation;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof Proposal
   */
  languages?: Array<ClubLanguages>;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof Proposal
   */
  cancellation_messages?: Array<ClubLanguages>;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof Proposal
   */
  confirmation_messages?: Array<ClubLanguages>;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof Proposal
   */
  pending_messages?: Array<ClubLanguages>;
  /**
   *
   * @type {ProposalFollowup}
   * @memberof Proposal
   */
  followup?: ProposalFollowup;
  /**
   *
   * @type {ProposalFollowup}
   * @memberof Proposal
   */
  reminder?: ProposalFollowup;
  /**
   *
   * @type {ModelReminderSelf}
   * @memberof Proposal
   */
  reminder_self?: ModelReminderSelf;
  /**
   *
   * @type {EventPrice}
   * @memberof Proposal
   */
  price?: EventPrice;
  /**
   *
   * @type {EventDuration}
   * @memberof Proposal
   */
  duration?: EventDuration;
  /**
   *
   * @type {EventExpense}
   * @memberof Proposal
   */
  expense?: EventExpense;
  /**
   *
   * @type {number}
   * @memberof Proposal
   */
  places?: number;
  /**
   *
   * @type {number}
   * @memberof Proposal
   */
  closure?: number;
  /**
   *
   * @type {string}
   * @memberof Proposal
   */
  start: string;
  /**
   *
   * @type {string}
   * @memberof Proposal
   */
  end: string;
  /**
   *
   * @type {string}
   * @memberof Proposal
   */
  linked_reservation?: string;
  /**
   *
   * @type {string}
   * @memberof Proposal
   */
  main_image?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Proposal
   */
  gallery_images?: Array<string>;
  /**
   *
   * @type {ProposalPayment}
   * @memberof Proposal
   */
  payment?: ProposalPayment;
  /**
   *
   * @type {string}
   * @memberof Proposal
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof Proposal
   */
  replied_at?: string;
  /**
   *
   * @type {string}
   * @memberof Proposal
   */
  next_reminder?: string;
  /**
   *
   * @type {string}
   * @memberof Proposal
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface ProposalFollowup
 */
export interface ProposalFollowup {
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof ProposalFollowup
   */
  messages?: Array<ClubLanguages>;
  /**
   *
   * @type {number}
   * @memberof ProposalFollowup
   */
  timeout?: number;
}
/**
 *
 * @export
 * @interface ProposalPayment
 */
export interface ProposalPayment {
  /**
   *
   * @type {boolean}
   * @memberof ProposalPayment
   */
  enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProposalPayment
   */
  refundable?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof ProposalPayment
   */
  amounts?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof ProposalPayment
   */
  refunds?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof ProposalPayment
   */
  hours?: number;
}
/**
 *
 * @export
 * @interface Purchase
 */
export interface Purchase {
  /**
   *
   * @type {User}
   * @memberof Purchase
   */
  user?: User;
  /**
   *
   * @type {AppUser}
   * @memberof Purchase
   */
  app_user?: AppUser;
  /**
   *
   * @type {Coupon}
   * @memberof Purchase
   */
  coupon?: Coupon;
  /**
   *
   * @type {CouponTicket}
   * @memberof Purchase
   */
  ticket?: CouponTicket;
  /**
   *
   * @type {PurchasePurchaser}
   * @memberof Purchase
   */
  purchaser?: PurchasePurchaser;
  /**
   *
   * @type {boolean}
   * @memberof Purchase
   */
  resolved?: boolean;
  /**
   *
   * @type {string}
   * @memberof Purchase
   */
  type?: string;
  /**
   *
   * @type {number}
   * @memberof Purchase
   */
  amount?: number;
  /**
   *
   * @type {object}
   * @memberof Purchase
   */
  charge?: object;
  /**
   *
   * @type {object}
   * @memberof Purchase
   */
  refund?: object;
  /**
   *
   * @type {string}
   * @memberof Purchase
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface PurchasePurchaser
 */
export interface PurchasePurchaser {
  /**
   *
   * @type {string}
   * @memberof PurchasePurchaser
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof PurchasePurchaser
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof PurchasePurchaser
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof PurchasePurchaser
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof PurchasePurchaser
   */
  contact_language?: PurchasePurchaserContactLanguageEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PurchasePurchaserContactLanguageEnum {
  En = 'en',
  It = 'it',
  Nl = 'nl',
  De = 'de',
}

/**
 *
 * @export
 * @interface Rating
 */
export interface Rating {
  /**
   *
   * @type {Place}
   * @memberof Rating
   */
  location?: Place;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  event?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  app_user?: string;
  /**
   *
   * @type {User}
   * @memberof Rating
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  created?: string;
  /**
   *
   * @type {number}
   * @memberof Rating
   */
  value?: number;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  reservation?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  tag?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface Reservation
 */
export interface Reservation {
  /**
   *
   * @type {string}
   * @memberof Reservation
   */
  event?: string;
  /**
   *
   * @type {User}
   * @memberof Reservation
   */
  user?: User;
  /**
   *
   * @type {AppUser}
   * @memberof Reservation
   */
  app_user?: AppUser;
  /**
   *
   * @type {CouponUsage}
   * @memberof Reservation
   */
  coupon_usage?: CouponUsage;
  /**
   *
   * @type {CellarUser}
   * @memberof Reservation
   */
  cellar_user?: CellarUser;
  /**
   *
   * @type {Array<CellarUser>}
   * @memberof Reservation
   */
  cellar_users?: Array<CellarUser>;
  /**
   *
   * @type {Array<Expense>}
   * @memberof Reservation
   */
  expenses?: Array<Expense>;
  /**
   *
   * @type {string}
   * @memberof Reservation
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof Reservation
   */
  note?: string;
  /**
   *
   * @type {string}
   * @memberof Reservation
   */
  cellar_note?: string;
  /**
   *
   * @type {number}
   * @memberof Reservation
   */
  guests?: number;
  /**
   *
   * @type {string}
   * @memberof Reservation
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof Reservation
   */
  language?: string;
  /**
   *
   * @type {string}
   * @memberof Reservation
   */
  contact_language?: ReservationContactLanguageEnum;
  /**
   *
   * @type {string}
   * @memberof Reservation
   */
  status?: ReservationStatusEnum;
  /**
   *
   * @type {string}
   * @memberof Reservation
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof Reservation
   */
  channel?: string;
  /**
   *
   * @type {string}
   * @memberof Reservation
   */
  timezone?: string;
  /**
   *
   * @type {string}
   * @memberof Reservation
   */
  cancellation_message?: string;
  /**
   *
   * @type {string}
   * @memberof Reservation
   */
  confirmation_message?: string;
  /**
   *
   * @type {string}
   * @memberof Reservation
   */
  finalization_message?: string;
  /**
   *
   * @type {string}
   * @memberof Reservation
   */
  stripe_customer_id?: string;
  /**
   *
   * @type {string}
   * @memberof Reservation
   */
  stripe_charge_id?: string;
  /**
   *
   * @type {number}
   * @memberof Reservation
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof Reservation
   */
  amount?: number;
  /**
   *
   * @type {object}
   * @memberof Reservation
   */
  surrogate_event?: object;
  /**
   *
   * @type {boolean}
   * @memberof Reservation
   */
  hide_from_list?: boolean;
  /**
   * The keys of this object must be the same as the meta_data id
   * @type {{ [key: string]: ReservationMetaData; }}
   * @memberof Reservation
   */
  meta_data?: { [key: string]: ReservationMetaData };
  /**
   * The keys of this object must be the same as the extra id
   * @type {{ [key: string]: ReservationExtras; }}
   * @memberof Reservation
   */
  extras?: { [key: string]: ReservationExtras };
  /**
   *
   * @type {Array<InlineResponse2007>}
   * @memberof Reservation
   */
  tags?: Array<InlineResponse2007>;
  /**
   *
   * @type {string}
   * @memberof Reservation
   */
  _id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ReservationContactLanguageEnum {
  En = 'en',
  It = 'it',
  Nl = 'nl',
  De = 'de',
}
/**
 * @export
 * @enum {string}
 */
export enum ReservationStatusEnum {
  Confirmed = 'confirmed',
  Unconfirmed = 'unconfirmed',
  Deleted = 'deleted',
  Pending = 'pending',
  Finalizing = 'finalizing',
  Rejected = 'rejected',
  Cancelled = 'cancelled',
}

/**
 *
 * @export
 * @interface ReservationExtra
 */
export interface ReservationExtra {
  /**
   *
   * @type {User}
   * @memberof ReservationExtra
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof ReservationExtra
   */
  title: string;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof ReservationExtra
   */
  languages?: Array<ClubLanguages>;
  /**
   *
   * @type {number}
   * @memberof ReservationExtra
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof ReservationExtra
   */
  quantity?: number;
  /**
   *
   * @type {string}
   * @memberof ReservationExtra
   */
  shared?: ReservationExtraSharedEnum;
  /**
   *
   * @type {string}
   * @memberof ReservationExtra
   */
  deleted?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ReservationExtra
   */
  warnings?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ReservationExtra
   */
  _id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ReservationExtraSharedEnum {
  No = 'no',
  Event = 'event',
  TimeSlot = 'time_slot',
}

/**
 *
 * @export
 * @interface ReservationExtras
 */
export interface ReservationExtras {
  /**
   *
   * @type {ReservationExtra}
   * @memberof ReservationExtras
   */
  extra?: ReservationExtra;
  /**
   * This property is ignored on POST and PUT
   * @type {number}
   * @memberof ReservationExtras
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof ReservationExtras
   */
  quantity?: number;
}
/**
 *
 * @export
 * @interface ReservationMetaData
 */
export interface ReservationMetaData {
  /**
   *
   * @type {MetaData}
   * @memberof ReservationMetaData
   */
  meta_data?: MetaData;
  /**
   *
   * @type {string}
   * @memberof ReservationMetaData
   */
  value?: string;
}
/**
 *
 * @export
 * @interface Room
 */
export interface Room {
  /**
   *
   * @type {string}
   * @memberof Room
   */
  title: string;
  /**
   *
   * @type {User}
   * @memberof Room
   */
  user?: User;
  /**
   *
   * @type {Place}
   * @memberof Room
   */
  location?: Place;
  /**
   *
   * @type {Array<RoomSlots>}
   * @memberof Room
   */
  slots?: Array<RoomSlots>;
  /**
   *
   * @type {boolean}
   * @memberof Room
   */
  shared?: boolean;
  /**
   *
   * @type {string}
   * @memberof Room
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface RoomSlots
 */
export interface RoomSlots {
  /**
   *
   * @type {string}
   * @memberof RoomSlots
   */
  title: string;
  /**
   *
   * @type {number}
   * @memberof RoomSlots
   */
  places?: number;
  /**
   *
   * @type {string}
   * @memberof RoomSlots
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface Shop
 */
export interface Shop {
  /**
   *
   * @type {User}
   * @memberof Shop
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof Shop
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof Shop
   */
  uuid: string;
  /**
   *
   * @type {Array<ShopCartItems>}
   * @memberof Shop
   */
  items?: Array<ShopCartItems>;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof Shop
   */
  languages?: Array<ClubLanguages>;
  /**
   *
   * @type {Array<string>}
   * @memberof Shop
   */
  images?: Array<string>;
  /**
   *
   * @type {Array<InlineResponse2007>}
   * @memberof Shop
   */
  tags?: Array<InlineResponse2007>;
  /**
   *
   * @type {string}
   * @memberof Shop
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface ShopCart
 */
export interface ShopCart {
  /**
   *
   * @type {User}
   * @memberof ShopCart
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof ShopCart
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof ShopCart
   */
  uuid: string;
  /**
   *
   * @type {Array<ShopCartItems>}
   * @memberof ShopCart
   */
  items?: Array<ShopCartItems>;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof ShopCart
   */
  languages?: Array<ClubLanguages>;
  /**
   *
   * @type {Array<InlineResponse2007>}
   * @memberof ShopCart
   */
  tags?: Array<InlineResponse2007>;
  /**
   *
   * @type {string}
   * @memberof ShopCart
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface ShopCartItems
 */
export interface ShopCartItems {
  /**
   *
   * @type {ShopItem}
   * @memberof ShopCartItems
   */
  shop_item?: ShopItem;
  /**
   *
   * @type {number}
   * @memberof ShopCartItems
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof ShopCartItems
   */
  price?: number;
}
/**
 *
 * @export
 * @interface ShopItem
 */
export interface ShopItem {
  /**
   *
   * @type {User}
   * @memberof ShopItem
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof ShopItem
   */
  title: string;
  /**
   *
   * @type {number}
   * @memberof ShopItem
   */
  price?: number;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof ShopItem
   */
  languages?: Array<ClubLanguages>;
  /**
   *
   * @type {Array<ShopItemItems>}
   * @memberof ShopItem
   */
  items?: Array<ShopItemItems>;
  /**
   *
   * @type {Array<ShopItemFilters>}
   * @memberof ShopItem
   */
  filters?: Array<ShopItemFilters>;
  /**
   *
   * @type {Array<InlineResponse2007>}
   * @memberof ShopItem
   */
  tags?: Array<InlineResponse2007>;
  /**
   *
   * @type {Array<string>}
   * @memberof ShopItem
   */
  images?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ShopItem
   */
  type?: ShopItemTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ShopItem
   */
  deleted?: string;
  /**
   *
   * @type {string}
   * @memberof ShopItem
   */
  quantity?: string;
  /**
   *
   * @type {number}
   * @memberof ShopItem
   */
  availability?: number;
  /**
   *
   * @type {string}
   * @memberof ShopItem
   */
  _id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ShopItemTypeEnum {
  Default = 'default',
  Composite = 'composite',
}

/**
 *
 * @export
 * @interface ShopItemFilters
 */
export interface ShopItemFilters {
  /**
   *
   * @type {string}
   * @memberof ShopItemFilters
   */
  product?: string;
  /**
   *
   * @type {string}
   * @memberof ShopItemFilters
   */
  product_item?: string;
}
/**
 *
 * @export
 * @interface ShopItemItems
 */
export interface ShopItemItems {
  /**
   *
   * @type {SystemShopItem}
   * @memberof ShopItemItems
   */
  shop_item?: SystemShopItem;
  /**
   *
   * @type {number}
   * @memberof ShopItemItems
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof ShopItemItems
   */
  price?: number;
}
/**
 *
 * @export
 * @interface StripeAccount
 */
export interface StripeAccount {
  /**
   *
   * @type {User}
   * @memberof StripeAccount
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof StripeAccount
   */
  title?: string;
  /**
   *
   * @type {object}
   * @memberof StripeAccount
   */
  stripe_account?: object;
  /**
   *
   * @type {string}
   * @memberof StripeAccount
   */
  deleted?: string;
  /**
   *
   * @type {string}
   * @memberof StripeAccount
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface SystemShopItem
 */
export interface SystemShopItem {
  /**
   *
   * @type {User}
   * @memberof SystemShopItem
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof SystemShopItem
   */
  title: string;
  /**
   *
   * @type {number}
   * @memberof SystemShopItem
   */
  price?: number;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof SystemShopItem
   */
  languages?: Array<ClubLanguages>;
  /**
   *
   * @type {Array<string>}
   * @memberof SystemShopItem
   */
  images?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof SystemShopItem
   */
  type?: SystemShopItemTypeEnum;
  /**
   *
   * @type {ProductItem}
   * @memberof SystemShopItem
   */
  product_item?: ProductItem;
  /**
   *
   * @type {string}
   * @memberof SystemShopItem
   */
  deleted?: string;
  /**
   *
   * @type {string}
   * @memberof SystemShopItem
   */
  _id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum SystemShopItemTypeEnum {
  ProductItem = 'product_item',
}

/**
 *
 * @export
 * @interface Tag
 */
export interface Tag {
  /**
   *
   * @type {User}
   * @memberof Tag
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof Tag
   */
  title: string;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof Tag
   */
  languages?: Array<ClubLanguages>;
  /**
   *
   * @type {string}
   * @memberof Tag
   */
  deleted?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Tag
   */
  warnings?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Tag
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface TempModel
 */
export interface TempModel {
  /**
   *
   * @type {string}
   * @memberof TempModel
   */
  title?: string;
  /**
   *
   * @type {User}
   * @memberof TempModel
   */
  user?: User;
  /**
   *
   * @type {Place}
   * @memberof TempModel
   */
  location?: Place;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof TempModel
   */
  languages?: Array<ClubLanguages>;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof TempModel
   */
  cancellation_messages?: Array<ClubLanguages>;
  /**
   *
   * @type {boolean}
   * @memberof TempModel
   */
  cancellation_hide_default?: boolean;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof TempModel
   */
  confirmation_messages?: Array<ClubLanguages>;
  /**
   *
   * @type {boolean}
   * @memberof TempModel
   */
  confirmation_hide_default?: boolean;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof TempModel
   */
  pending_messages?: Array<ClubLanguages>;
  /**
   *
   * @type {boolean}
   * @memberof TempModel
   */
  pending_hide_default?: boolean;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof TempModel
   */
  finalization_messages?: Array<ClubLanguages>;
  /**
   *
   * @type {boolean}
   * @memberof TempModel
   */
  finalization_hide_default?: boolean;
  /**
   *
   * @type {ModelFollowup}
   * @memberof TempModel
   */
  followup?: ModelFollowup;
  /**
   *
   * @type {ModelFollowup}
   * @memberof TempModel
   */
  reminder?: ModelFollowup;
  /**
   *
   * @type {ModelReminderSelf}
   * @memberof TempModel
   */
  reminder_self?: ModelReminderSelf;
  /**
   *
   * @type {ModelPrice}
   * @memberof TempModel
   */
  price?: ModelPrice;
  /**
   *
   * @type {EventDuration}
   * @memberof TempModel
   */
  duration?: EventDuration;
  /**
   *
   * @type {EventExpense}
   * @memberof TempModel
   */
  expense?: EventExpense;
  /**
   *
   * @type {EventPayment}
   * @memberof TempModel
   */
  payment?: EventPayment;
  /**
   *
   * @type {number}
   * @memberof TempModel
   */
  places?: number;
  /**
   *
   * @type {number}
   * @memberof TempModel
   */
  min_guests?: number;
  /**
   *
   * @type {number}
   * @memberof TempModel
   */
  max_guests?: number;
  /**
   *
   * @type {number}
   * @memberof TempModel
   */
  closure?: number;
  /**
   *
   * @type {string}
   * @memberof TempModel
   */
  main_image?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TempModel
   */
  gallery_images?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof TempModel
   */
  visibility?: boolean;
  /**
   *
   * @type {string}
   * @memberof TempModel
   */
  event_type?: TempModelEventTypeEnum;
  /**
   *
   * @type {Array<TempModelWeekdays>}
   * @memberof TempModel
   */
  weekdays?: Array<TempModelWeekdays>;
  /**
   *
   * @type {Array<TempModelWeekdays>}
   * @memberof TempModel
   */
  months?: Array<TempModelWeekdays>;
  /**
   *
   * @type {object}
   * @memberof TempModel
   */
  steps?: object;
  /**
   *
   * @type {boolean}
   * @memberof TempModel
   */
  dual_time_ranges?: boolean;
  /**
   *
   * @type {EventDuration}
   * @memberof TempModel
   */
  calendar_start?: EventDuration;
  /**
   *
   * @type {EventDuration}
   * @memberof TempModel
   */
  calendar_end?: EventDuration;
  /**
   *
   * @type {EventDuration}
   * @memberof TempModel
   */
  calendar_start_afternoon?: EventDuration;
  /**
   *
   * @type {EventDuration}
   * @memberof TempModel
   */
  calendar_end_afternoon?: EventDuration;
  /**
   *
   * @type {boolean}
   * @memberof TempModel
   */
  play_default?: boolean;
  /**
   *
   * @type {ModelApprovation}
   * @memberof TempModel
   */
  approvation?: ModelApprovation;
  /**
   *
   * @type {boolean}
   * @memberof TempModel
   */
  is_published?: boolean;
  /**
   *
   * @type {number}
   * @memberof TempModel
   */
  model_order?: number;
  /**
   *
   * @type {number}
   * @memberof TempModel
   */
  groups_number?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof TempModel
   */
  operators?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof TempModel
   */
  activate_operators?: boolean;
  /**
   *
   * @type {Array<TempModelRooms>}
   * @memberof TempModel
   */
  rooms?: Array<TempModelRooms>;
  /**
   *
   * @type {Array<TempModelRooms>}
   * @memberof TempModel
   */
  available_rooms?: Array<TempModelRooms>;
  /**
   *
   * @type {boolean}
   * @memberof TempModel
   */
  activate_rooms?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TempModel
   */
  activate_auto_room?: boolean;
  /**
   *
   * @type {Array<TempModelExtras>}
   * @memberof TempModel
   */
  extras?: Array<TempModelExtras>;
  /**
   *
   * @type {TempModelAfterReservation}
   * @memberof TempModel
   */
  after_reservation?: TempModelAfterReservation;
  /**
   *
   * @type {ModelBeforeReservation}
   * @memberof TempModel
   */
  before_reservation?: ModelBeforeReservation;
  /**
   *
   * @type {ModelFilters}
   * @memberof TempModel
   */
  filters?: ModelFilters;
  /**
   *
   * @type {Array<TempModelMetaData>}
   * @memberof TempModel
   */
  meta_data?: Array<TempModelMetaData>;
  /**
   *
   * @type {ModelReservationMode}
   * @memberof TempModel
   */
  reservation_mode?: ModelReservationMode;
  /**
   *
   * @type {string}
   * @memberof TempModel
   */
  deleted?: string;
  /**
   *
   * @type {ModelColors}
   * @memberof TempModel
   */
  colors?: ModelColors;
  /**
   *
   * @type {Array<InlineResponse2007>}
   * @memberof TempModel
   */
  tags?: Array<InlineResponse2007>;
  /**
   *
   * @type {string}
   * @memberof TempModel
   */
  _id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum TempModelEventTypeEnum {
  Wine = 'wine',
  Around = 'around',
}

/**
 *
 * @export
 * @interface TempModelAfterReservation
 */
export interface TempModelAfterReservation {
  /**
   *
   * @type {boolean}
   * @memberof TempModelAfterReservation
   */
  unpause_on_new_reservation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TempModelAfterReservation
   */
  hide_on_new_reservation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TempModelAfterReservation
   */
  new_group_on_new_reservation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TempModelAfterReservation
   */
  new_group_on_event_full?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TempModelAfterReservation
   */
  pause_on_no_reservations?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TempModelAfterReservation
   */
  show_on_no_reservations?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TempModelAfterReservation
   */
  remove_empty_group_on_no_reservations?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TempModelAfterReservation
   */
  close_all_others_on_new_reservation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TempModelAfterReservation
   */
  close_other_languages_on_new_reservation?: boolean;
}
/**
 *
 * @export
 * @interface TempModelExtras
 */
export interface TempModelExtras {
  /**
   *
   * @type {string}
   * @memberof TempModelExtras
   */
  extra?: string;
  /**
   *
   * @type {number}
   * @memberof TempModelExtras
   */
  max_value?: number;
}
/**
 *
 * @export
 * @interface TempModelMetaData
 */
export interface TempModelMetaData {
  /**
   *
   * @type {string}
   * @memberof TempModelMetaData
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof TempModelMetaData
   */
  type?: string;
  /**
   *
   * @type {boolean}
   * @memberof TempModelMetaData
   */
  required?: boolean;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof TempModelMetaData
   */
  languages?: Array<ClubLanguages>;
  /**
   *
   * @type {Array<MetaDataOptions>}
   * @memberof TempModelMetaData
   */
  options?: Array<MetaDataOptions>;
}
/**
 *
 * @export
 * @interface TempModelRooms
 */
export interface TempModelRooms {
  /**
   *
   * @type {string}
   * @memberof TempModelRooms
   */
  room?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TempModelRooms
   */
  slots?: Array<string>;
}
/**
 *
 * @export
 * @interface TempModelWeekdays
 */
export interface TempModelWeekdays {
  /**
   *
   * @type {number}
   * @memberof TempModelWeekdays
   */
  value?: number;
  /**
   *
   * @type {boolean}
   * @memberof TempModelWeekdays
   */
  selected?: boolean;
}
/**
 *
 * @export
 * @interface TempPlace
 */
export interface TempPlace {
  /**
   *
   * @type {string}
   * @memberof TempPlace
   */
  title?: string;
  /**
   *
   * @type {User}
   * @memberof TempPlace
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof TempPlace
   */
  googleCalendarId: string;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof TempPlace
   */
  languages?: Array<ClubLanguages>;
  /**
   *
   * @type {PlaceAddress}
   * @memberof TempPlace
   */
  address?: PlaceAddress;
  /**
   *
   * @type {string}
   * @memberof TempPlace
   */
  place_type?: TempPlacePlaceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof TempPlace
   */
  main_image?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TempPlace
   */
  gallery_images?: Array<string>;
  /**
   *
   * @type {ModelApprovation}
   * @memberof TempPlace
   */
  approvation?: ModelApprovation;
  /**
   *
   * @type {string}
   * @memberof TempPlace
   */
  timezone?: string;
  /**
   *
   * @type {string}
   * @memberof TempPlace
   */
  currency?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TempPlace
   */
  rooms?: Array<string>;
  /**
   *
   * @type {object}
   * @memberof TempPlace
   */
  steps?: object;
  /**
   *
   * @type {string}
   * @memberof TempPlace
   */
  deleted?: string;
  /**
   *
   * @type {string}
   * @memberof TempPlace
   */
  _id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum TempPlacePlaceTypeEnum {
  Producer = 'producer',
  Other = 'other',
}

/**
 *
 * @export
 * @interface Translation
 */
export interface Translation {
  /**
   *
   * @type {string}
   * @memberof Translation
   */
  _id?: string;
  /**
   *
   * @type {string}
   * @memberof Translation
   */
  locale?: string;
  /**
   *
   * @type {string}
   * @memberof Translation
   */
  value?: string;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  company?: string;
  /**
   *
   * @type {object}
   * @memberof User
   */
  hostname?: object;
  /**
   *
   * @type {string}
   * @memberof User
   */
  stripeId?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  activeUntil?: string;
  /**
   *
   * @type {UserFiscal}
   * @memberof User
   */
  fiscal?: UserFiscal;
  /**
   *
   * @type {Array<ClubLanguages>}
   * @memberof User
   */
  languages?: Array<ClubLanguages>;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  contact?: boolean;
  /**
   *
   * @type {Place}
   * @memberof User
   */
  main_location?: Place;
  /**
   *
   * @type {Array<Place>}
   * @memberof User
   */
  locations?: Array<Place>;
  /**
   *
   * @type {Array<string>}
   * @memberof User
   */
  stars?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof User
   */
  user_type?: UserUserTypeEnum;
  /**
   *
   * @type {string}
   * @memberof User
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  salt?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  provider: string;
  /**
   *
   * @type {object}
   * @memberof User
   */
  providerData?: object;
  /**
   *
   * @type {object}
   * @memberof User
   */
  additionalProvidersData?: object;
  /**
   *
   * @type {Array<string>}
   * @memberof User
   */
  roles?: Array<UserRolesEnum>;
  /**
   *
   * @type {string}
   * @memberof User
   */
  updated?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  resetPasswordToken?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  resetPasswordExpires?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  activationToken?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  activated?: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  blocked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  admin_block?: boolean;
  /**
   *
   * @type {UserSettings}
   * @memberof User
   */
  settings?: UserSettings;
  /**
   *
   * @type {UserEmailChange}
   * @memberof User
   */
  emailChange?: UserEmailChange;
  /**
   *
   * @type {string}
   * @memberof User
   */
  trial_status?: string;
  /**
   *
   * @type {object}
   * @memberof User
   */
  stripe_account?: object;
  /**
   *
   * @type {string}
   * @memberof User
   */
  parent?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof User
   */
  permissions?: Array<UserPermissionsEnum>;
  /**
   *
   * @type {UserWhiteLabel}
   * @memberof User
   */
  white_label?: UserWhiteLabel;
  /**
   *
   * @type {UserSubscription}
   * @memberof User
   */
  subscription?: UserSubscription;
  /**
   *
   * @type {UserClub}
   * @memberof User
   */
  club?: UserClub;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  receive_all_emails?: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  _id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum UserUserTypeEnum {
  Wine = 'Wine',
  Around = 'Around',
}
/**
 * @export
 * @enum {string}
 */
export enum UserRolesEnum {
  Operator = 'operator',
  User = 'user',
  Admin = 'admin',
  Owner = 'owner',
}
/**
 * @export
 * @enum {string}
 */
export enum UserPermissionsEnum {
  ReadCalendars = 'read_calendars',
  UseEditor = 'use_editor',
  CreateUsers = 'create_users',
  ReadUsers = 'read_users',
  UpdateUsers = 'update_users',
  DeleteUsers = 'delete_users',
  ListUsers = 'list_users',
  CreatePlaces = 'create_places',
  ReadPlaces = 'read_places',
  UpdatePlaces = 'update_places',
  DeletePlaces = 'delete_places',
  ListPlaces = 'list_places',
  ListPlaceReservations = 'list_place_reservations',
  CreateModels = 'create_models',
  ReadModels = 'read_models',
  UpdateModels = 'update_models',
  DeleteModels = 'delete_models',
  ListModels = 'list_models',
  OrderModels = 'order_models',
  CreateEvents = 'create_events',
  ReadEvents = 'read_events',
  UpdateEvents = 'update_events',
  DeleteEvents = 'delete_events',
  CreatePictures = 'create_pictures',
  DeletePictures = 'delete_pictures',
  ReadProposals = 'read_proposals',
  UpdateProposals = 'update_proposals',
  DeleteProposals = 'delete_proposals',
  ListProposals = 'list_proposals',
  ManipulateProposals = 'manipulate_proposals',
  CreateReservations = 'create_reservations',
  ReadReservations = 'read_reservations',
  UpdateReservations = 'update_reservations',
  DeleteReservations = 'delete_reservations',
  ListReservations = 'list_reservations',
  CsvReservations = 'csv_reservations',
  ManipulateReservations = 'manipulate_reservations',
  ListCoupons = 'list_coupons',
  CreateCoupons = 'create_coupons',
  ReadCoupons = 'read_coupons',
  DeleteCoupons = 'delete_coupons',
  CreateTickets = 'create_tickets',
  UpdateTickets = 'update_tickets',
  DeleteTickets = 'delete_tickets',
  ExportTickets = 'export_tickets',
  CreateGuests = 'create_guests',
  ReadGuests = 'read_guests',
  UpdateGuests = 'update_guests',
  DeleteGuests = 'delete_guests',
  ListGuests = 'list_guests',
  ReadWhitelabel = 'read_whitelabel',
  UpdateWhitelabel = 'update_whitelabel',
  ReadCompany = 'read_company',
  UpdateCompany = 'update_company',
  ReadGiftCards = 'read_gift_cards',
  UpdateGiftCards = 'update_gift_cards',
  ListGiftCards = 'list_gift_cards',
  CreateNotes = 'create_notes',
  UpdateNotes = 'update_notes',
  DeleteNotes = 'delete_notes',
}

/**
 *
 * @export
 * @interface UserCart
 */
export interface UserCart {
  /**
   *
   * @type {CellarUser}
   * @memberof UserCart
   */
  user: CellarUser;
  /**
   *
   * @type {object}
   * @memberof UserCart
   */
  cart?: object;
  /**
   *
   * @type {string}
   * @memberof UserCart
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface UserClub
 */
export interface UserClub {
  /**
   *
   * @type {UserClubSettings}
   * @memberof UserClub
   */
  settings?: UserClubSettings;
  /**
   *
   * @type {UserClubTheme}
   * @memberof UserClub
   */
  theme?: UserClubTheme;
  /**
   *
   * @type {Array<string>}
   * @memberof UserClub
   */
  hostname?: Array<string>;
}
/**
 *
 * @export
 * @interface UserClubSettings
 */
export interface UserClubSettings {
  /**
   *
   * @type {boolean}
   * @memberof UserClubSettings
   */
  requires_login?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserClubSettings
   */
  requires_invitation?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserClubSettings
   */
  homepage?: string;
  /**
   *
   * @type {Club}
   * @memberof UserClubSettings
   */
  default_club?: Club;
}
/**
 *
 * @export
 * @interface UserClubTheme
 */
export interface UserClubTheme {
  /**
   *
   * @type {object}
   * @memberof UserClubTheme
   */
  json?: object;
  /**
   *
   * @type {string}
   * @memberof UserClubTheme
   */
  css?: string;
}
/**
 *
 * @export
 * @interface UserEmailChange
 */
export interface UserEmailChange {
  /**
   *
   * @type {string}
   * @memberof UserEmailChange
   */
  token?: string;
  /**
   *
   * @type {string}
   * @memberof UserEmailChange
   */
  expires?: string;
  /**
   *
   * @type {string}
   * @memberof UserEmailChange
   */
  email?: string;
}
/**
 *
 * @export
 * @interface UserFiscal
 */
export interface UserFiscal {
  /**
   *
   * @type {string}
   * @memberof UserFiscal
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UserFiscal
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof UserFiscal
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof UserFiscal
   */
  zip?: string;
  /**
   *
   * @type {string}
   * @memberof UserFiscal
   */
  province?: string;
  /**
   *
   * @type {string}
   * @memberof UserFiscal
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof UserFiscal
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof UserFiscal
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof UserFiscal
   */
  website?: string;
  /**
   *
   * @type {string}
   * @memberof UserFiscal
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserFiscal
   */
  pa_codice?: string;
  /**
   *
   * @type {string}
   * @memberof UserFiscal
   */
  cf?: string;
}
/**
 *
 * @export
 * @interface UserSettings
 */
export interface UserSettings {
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  contact_language?: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  mail_booking?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserSettings
   */
  mail_at_every?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserSettings
   */
  mail_at_closure?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserSettings
   */
  ocm_enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserSettings
   */
  use_operator_languages?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserSettings
   */
  show_events_filters?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  custom_email?: string;
}
/**
 *
 * @export
 * @interface UserSubscription
 */
export interface UserSubscription {
  /**
   *
   * @type {string}
   * @memberof UserSubscription
   */
  plan?: string;
  /**
   *
   * @type {object}
   * @memberof UserSubscription
   */
  startup?: object;
}
/**
 *
 * @export
 * @interface UserWhiteLabel
 */
export interface UserWhiteLabel {
  /**
   *
   * @type {string}
   * @memberof UserWhiteLabel
   */
  style?: string;
  /**
   *
   * @type {string}
   * @memberof UserWhiteLabel
   */
  favicon?: string;
  /**
   *
   * @type {string}
   * @memberof UserWhiteLabel
   */
  facebook_id?: string;
  /**
   *
   * @type {string}
   * @memberof UserWhiteLabel
   */
  google_id?: string;
  /**
   *
   * @type {string}
   * @memberof UserWhiteLabel
   */
  smartlook_id?: string;
}
/**
 *
 * @export
 * @interface VideoRoom
 */
export interface VideoRoom {
  /**
   *
   * @type {string}
   * @memberof VideoRoom
   */
  room?: string;
  /**
   *
   * @type {Array<VideoRoomParticipants>}
   * @memberof VideoRoom
   */
  participants?: Array<VideoRoomParticipants>;
  /**
   *
   * @type {string}
   * @memberof VideoRoom
   */
  expirtes?: string;
  /**
   *
   * @type {string}
   * @memberof VideoRoom
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface VideoRoomParticipants
 */
export interface VideoRoomParticipants {
  /**
   *
   * @type {string}
   * @memberof VideoRoomParticipants
   */
  user?: string;
  /**
   *
   * @type {VideoRoomSettings}
   * @memberof VideoRoomParticipants
   */
  settings?: VideoRoomSettings;
}
/**
 *
 * @export
 * @interface VideoRoomSettings
 */
export interface VideoRoomSettings {
  /**
   *
   * @type {boolean}
   * @memberof VideoRoomSettings
   */
  mute?: boolean;
}
/**
 *
 * @export
 * @interface Webhook
 */
export interface Webhook {
  /**
   *
   * @type {User}
   * @memberof Webhook
   */
  user: User;
  /**
   *
   * @type {string}
   * @memberof Webhook
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Webhook
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof Webhook
   */
  description?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Webhook
   */
  events?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Webhook
   */
  deleted?: string;
  /**
   *
   * @type {string}
   * @memberof Webhook
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface WebhookExecution
 */
export interface WebhookExecution {
  /**
   *
   * @type {string}
   * @memberof WebhookExecution
   */
  webhook?: string;
  /**
   *
   * @type {User}
   * @memberof WebhookExecution
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof WebhookExecution
   */
  event: string;
  /**
   *
   * @type {string}
   * @memberof WebhookExecution
   */
  response?: string;
  /**
   *
   * @type {number}
   * @memberof WebhookExecution
   */
  statusCode?: number;
  /**
   *
   * @type {string}
   * @memberof WebhookExecution
   */
  statusMessage?: string;
  /**
   *
   * @type {string}
   * @memberof WebhookExecution
   */
  timestamp?: string;
  /**
   *
   * @type {string}
   * @memberof WebhookExecution
   */
  resource?: string;
  /**
   *
   * @type {string}
   * @memberof WebhookExecution
   */
  retryDate?: string;
  /**
   *
   * @type {number}
   * @memberof WebhookExecution
   */
  retryCount?: number;
  /**
   *
   * @type {string}
   * @memberof WebhookExecution
   */
  _id?: string;
}
/**
 *
 * @export
 * @interface WineAroundPlan
 */
export interface WineAroundPlan {
  /**
   *
   * @type {string}
   * @memberof WineAroundPlan
   */
  name?: string;
  /**
   *
   * @type {object}
   * @memberof WineAroundPlan
   */
  stripe?: object;
  /**
   *
   * @type {string}
   * @memberof WineAroundPlan
   */
  _id?: string;
}

/**
 * CalendarApi - axios parameter creator
 * @export
 */
export const CalendarApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdFiltersDelete: async (
      placeId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling calendarPlaceIdFiltersDelete.'
        );
      }
      const localVarPath = `/calendar/{placeId}/filters`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdFiltersGet: async (placeId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling calendarPlaceIdFiltersGet.'
        );
      }
      const localVarPath = `/calendar/{placeId}/filters`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {CalendarFilter} filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdFiltersPut: async (
      placeId: string,
      filter: CalendarFilter,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling calendarPlaceIdFiltersPut.'
        );
      }
      // verify required parameter 'filter' is not null or undefined
      if (filter === null || filter === undefined) {
        throw new RequiredError(
          'filter',
          'Required parameter filter was null or undefined when calling calendarPlaceIdFiltersPut.'
        );
      }
      const localVarPath = `/calendar/{placeId}/filters`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof filter !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(filter !== undefined ? filter : {})
        : filter || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /calendar/{placeId}
     * @param {string} placeId
     * @param {string} start
     * @param {string} end
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdGet: async (
      placeId: string,
      start: string,
      end: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling calendarPlaceIdGet.'
        );
      }
      // verify required parameter 'start' is not null or undefined
      if (start === null || start === undefined) {
        throw new RequiredError(
          'start',
          'Required parameter start was null or undefined when calling calendarPlaceIdGet.'
        );
      }
      // verify required parameter 'end' is not null or undefined
      if (end === null || end === undefined) {
        throw new RequiredError(
          'end',
          'Required parameter end was null or undefined when calling calendarPlaceIdGet.'
        );
      }
      const localVarPath = `/calendar/{placeId}`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] =
          (start as any) instanceof Date ? (start as any).toISOString().substr(0, 10) : start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] =
          (end as any) instanceof Date ? (end as any).toISOString().substr(0, 10) : end;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/times
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdTimesGet: async (placeId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling calendarPlaceIdTimesGet.'
        );
      }
      const localVarPath = `/calendar/{placeId}/times`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/times
     * @param {string} placeId
     * @param {CalendarTime} time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdTimesPut: async (
      placeId: string,
      time: CalendarTime,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling calendarPlaceIdTimesPut.'
        );
      }
      // verify required parameter 'time' is not null or undefined
      if (time === null || time === undefined) {
        throw new RequiredError(
          'time',
          'Required parameter time was null or undefined when calling calendarPlaceIdTimesPut.'
        );
      }
      const localVarPath = `/calendar/{placeId}/times`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof time !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(time !== undefined ? time : {})
        : time || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CalendarApi - functional programming interface
 * @export
 */
export const CalendarApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calendarPlaceIdFiltersDelete(
      placeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await CalendarApiAxiosParamCreator(
        configuration
      ).calendarPlaceIdFiltersDelete(placeId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calendarPlaceIdFiltersGet(
      placeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarFilter>> {
      const localVarAxiosArgs = await CalendarApiAxiosParamCreator(
        configuration
      ).calendarPlaceIdFiltersGet(placeId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {CalendarFilter} filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calendarPlaceIdFiltersPut(
      placeId: string,
      filter: CalendarFilter,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarFilter>> {
      const localVarAxiosArgs = await CalendarApiAxiosParamCreator(
        configuration
      ).calendarPlaceIdFiltersPut(placeId, filter, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /calendar/{placeId}
     * @param {string} placeId
     * @param {string} start
     * @param {string} end
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calendarPlaceIdGet(
      placeId: string,
      start: string,
      end: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
      const localVarAxiosArgs = await CalendarApiAxiosParamCreator(
        configuration
      ).calendarPlaceIdGet(placeId, start, end, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/times
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calendarPlaceIdTimesGet(
      placeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarTime>> {
      const localVarAxiosArgs = await CalendarApiAxiosParamCreator(
        configuration
      ).calendarPlaceIdTimesGet(placeId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/times
     * @param {string} placeId
     * @param {CalendarTime} time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calendarPlaceIdTimesPut(
      placeId: string,
      time: CalendarTime,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarTime>> {
      const localVarAxiosArgs = await CalendarApiAxiosParamCreator(
        configuration
      ).calendarPlaceIdTimesPut(placeId, time, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * CalendarApi - factory interface
 * @export
 */
export const CalendarApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdFiltersDelete(placeId: string, options?: any): AxiosPromise<void> {
      return CalendarApiFp(configuration)
        .calendarPlaceIdFiltersDelete(placeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdFiltersGet(placeId: string, options?: any): AxiosPromise<CalendarFilter> {
      return CalendarApiFp(configuration)
        .calendarPlaceIdFiltersGet(placeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {CalendarFilter} filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdFiltersPut(
      placeId: string,
      filter: CalendarFilter,
      options?: any
    ): AxiosPromise<CalendarFilter> {
      return CalendarApiFp(configuration)
        .calendarPlaceIdFiltersPut(placeId, filter, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /calendar/{placeId}
     * @param {string} placeId
     * @param {string} start
     * @param {string} end
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdGet(
      placeId: string,
      start: string,
      end: string,
      options?: any
    ): AxiosPromise<InlineResponse2001> {
      return CalendarApiFp(configuration)
        .calendarPlaceIdGet(placeId, start, end, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /calendar/{placeId}/times
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdTimesGet(placeId: string, options?: any): AxiosPromise<CalendarTime> {
      return CalendarApiFp(configuration)
        .calendarPlaceIdTimesGet(placeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /calendar/{placeId}/times
     * @param {string} placeId
     * @param {CalendarTime} time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdTimesPut(
      placeId: string,
      time: CalendarTime,
      options?: any
    ): AxiosPromise<CalendarTime> {
      return CalendarApiFp(configuration)
        .calendarPlaceIdTimesPut(placeId, time, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CalendarApi - object-oriented interface
 * @export
 * @class CalendarApi
 * @extends {BaseAPI}
 */
export class CalendarApi extends BaseAPI {
  /**
   *
   * @summary /calendar/{placeId}/filters
   * @param {string} placeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CalendarApi
   */
  public calendarPlaceIdFiltersDelete(placeId: string, options?: any) {
    return CalendarApiFp(this.configuration)
      .calendarPlaceIdFiltersDelete(placeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /calendar/{placeId}/filters
   * @param {string} placeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CalendarApi
   */
  public calendarPlaceIdFiltersGet(placeId: string, options?: any) {
    return CalendarApiFp(this.configuration)
      .calendarPlaceIdFiltersGet(placeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /calendar/{placeId}/filters
   * @param {string} placeId
   * @param {CalendarFilter} filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CalendarApi
   */
  public calendarPlaceIdFiltersPut(placeId: string, filter: CalendarFilter, options?: any) {
    return CalendarApiFp(this.configuration)
      .calendarPlaceIdFiltersPut(placeId, filter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /calendar/{placeId}
   * @param {string} placeId
   * @param {string} start
   * @param {string} end
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CalendarApi
   */
  public calendarPlaceIdGet(placeId: string, start: string, end: string, options?: any) {
    return CalendarApiFp(this.configuration)
      .calendarPlaceIdGet(placeId, start, end, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /calendar/{placeId}/times
   * @param {string} placeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CalendarApi
   */
  public calendarPlaceIdTimesGet(placeId: string, options?: any) {
    return CalendarApiFp(this.configuration)
      .calendarPlaceIdTimesGet(placeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /calendar/{placeId}/times
   * @param {string} placeId
   * @param {CalendarTime} time
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CalendarApi
   */
  public calendarPlaceIdTimesPut(placeId: string, time: CalendarTime, options?: any) {
    return CalendarApiFp(this.configuration)
      .calendarPlaceIdTimesPut(placeId, time, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CalendarFilterApi - axios parameter creator
 * @export
 */
export const CalendarFilterApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdFiltersDelete: async (
      placeId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling calendarPlaceIdFiltersDelete.'
        );
      }
      const localVarPath = `/calendar/{placeId}/filters`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdFiltersGet: async (placeId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling calendarPlaceIdFiltersGet.'
        );
      }
      const localVarPath = `/calendar/{placeId}/filters`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {CalendarFilter} filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdFiltersPut: async (
      placeId: string,
      filter: CalendarFilter,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling calendarPlaceIdFiltersPut.'
        );
      }
      // verify required parameter 'filter' is not null or undefined
      if (filter === null || filter === undefined) {
        throw new RequiredError(
          'filter',
          'Required parameter filter was null or undefined when calling calendarPlaceIdFiltersPut.'
        );
      }
      const localVarPath = `/calendar/{placeId}/filters`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof filter !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(filter !== undefined ? filter : {})
        : filter || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CalendarFilterApi - functional programming interface
 * @export
 */
export const CalendarFilterApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calendarPlaceIdFiltersDelete(
      placeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await CalendarFilterApiAxiosParamCreator(
        configuration
      ).calendarPlaceIdFiltersDelete(placeId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calendarPlaceIdFiltersGet(
      placeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarFilter>> {
      const localVarAxiosArgs = await CalendarFilterApiAxiosParamCreator(
        configuration
      ).calendarPlaceIdFiltersGet(placeId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {CalendarFilter} filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calendarPlaceIdFiltersPut(
      placeId: string,
      filter: CalendarFilter,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarFilter>> {
      const localVarAxiosArgs = await CalendarFilterApiAxiosParamCreator(
        configuration
      ).calendarPlaceIdFiltersPut(placeId, filter, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * CalendarFilterApi - factory interface
 * @export
 */
export const CalendarFilterApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdFiltersDelete(placeId: string, options?: any): AxiosPromise<void> {
      return CalendarFilterApiFp(configuration)
        .calendarPlaceIdFiltersDelete(placeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdFiltersGet(placeId: string, options?: any): AxiosPromise<CalendarFilter> {
      return CalendarFilterApiFp(configuration)
        .calendarPlaceIdFiltersGet(placeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {CalendarFilter} filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdFiltersPut(
      placeId: string,
      filter: CalendarFilter,
      options?: any
    ): AxiosPromise<CalendarFilter> {
      return CalendarFilterApiFp(configuration)
        .calendarPlaceIdFiltersPut(placeId, filter, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CalendarFilterApi - object-oriented interface
 * @export
 * @class CalendarFilterApi
 * @extends {BaseAPI}
 */
export class CalendarFilterApi extends BaseAPI {
  /**
   *
   * @summary /calendar/{placeId}/filters
   * @param {string} placeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CalendarFilterApi
   */
  public calendarPlaceIdFiltersDelete(placeId: string, options?: any) {
    return CalendarFilterApiFp(this.configuration)
      .calendarPlaceIdFiltersDelete(placeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /calendar/{placeId}/filters
   * @param {string} placeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CalendarFilterApi
   */
  public calendarPlaceIdFiltersGet(placeId: string, options?: any) {
    return CalendarFilterApiFp(this.configuration)
      .calendarPlaceIdFiltersGet(placeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /calendar/{placeId}/filters
   * @param {string} placeId
   * @param {CalendarFilter} filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CalendarFilterApi
   */
  public calendarPlaceIdFiltersPut(placeId: string, filter: CalendarFilter, options?: any) {
    return CalendarFilterApiFp(this.configuration)
      .calendarPlaceIdFiltersPut(placeId, filter, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CalendarTimeApi - axios parameter creator
 * @export
 */
export const CalendarTimeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /calendar/{placeId}/times
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdTimesGet: async (placeId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling calendarPlaceIdTimesGet.'
        );
      }
      const localVarPath = `/calendar/{placeId}/times`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/times
     * @param {string} placeId
     * @param {CalendarTime} time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdTimesPut: async (
      placeId: string,
      time: CalendarTime,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling calendarPlaceIdTimesPut.'
        );
      }
      // verify required parameter 'time' is not null or undefined
      if (time === null || time === undefined) {
        throw new RequiredError(
          'time',
          'Required parameter time was null or undefined when calling calendarPlaceIdTimesPut.'
        );
      }
      const localVarPath = `/calendar/{placeId}/times`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof time !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(time !== undefined ? time : {})
        : time || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CalendarTimeApi - functional programming interface
 * @export
 */
export const CalendarTimeApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /calendar/{placeId}/times
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calendarPlaceIdTimesGet(
      placeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarTime>> {
      const localVarAxiosArgs = await CalendarTimeApiAxiosParamCreator(
        configuration
      ).calendarPlaceIdTimesGet(placeId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/times
     * @param {string} placeId
     * @param {CalendarTime} time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calendarPlaceIdTimesPut(
      placeId: string,
      time: CalendarTime,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarTime>> {
      const localVarAxiosArgs = await CalendarTimeApiAxiosParamCreator(
        configuration
      ).calendarPlaceIdTimesPut(placeId, time, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * CalendarTimeApi - factory interface
 * @export
 */
export const CalendarTimeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary /calendar/{placeId}/times
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdTimesGet(placeId: string, options?: any): AxiosPromise<CalendarTime> {
      return CalendarTimeApiFp(configuration)
        .calendarPlaceIdTimesGet(placeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /calendar/{placeId}/times
     * @param {string} placeId
     * @param {CalendarTime} time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdTimesPut(
      placeId: string,
      time: CalendarTime,
      options?: any
    ): AxiosPromise<CalendarTime> {
      return CalendarTimeApiFp(configuration)
        .calendarPlaceIdTimesPut(placeId, time, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CalendarTimeApi - object-oriented interface
 * @export
 * @class CalendarTimeApi
 * @extends {BaseAPI}
 */
export class CalendarTimeApi extends BaseAPI {
  /**
   *
   * @summary /calendar/{placeId}/times
   * @param {string} placeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CalendarTimeApi
   */
  public calendarPlaceIdTimesGet(placeId: string, options?: any) {
    return CalendarTimeApiFp(this.configuration)
      .calendarPlaceIdTimesGet(placeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /calendar/{placeId}/times
   * @param {string} placeId
   * @param {CalendarTime} time
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CalendarTimeApi
   */
  public calendarPlaceIdTimesPut(placeId: string, time: CalendarTime, options?: any) {
    return CalendarTimeApiFp(this.configuration)
      .calendarPlaceIdTimesPut(placeId, time, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CellarUserApi - axios parameter creator
 * @export
 */
export const CellarUserApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /guests/{guestsId}
     * @param {string} guestsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsGuestsIdDelete: async (guestsId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'guestsId' is not null or undefined
      if (guestsId === null || guestsId === undefined) {
        throw new RequiredError(
          'guestsId',
          'Required parameter guestsId was null or undefined when calling guestsGuestsIdDelete.'
        );
      }
      const localVarPath = `/guests/{guestsId}`.replace(
        `{${'guestsId'}}`,
        encodeURIComponent(String(guestsId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint returns more data than GET /guests/{guestId}, also populated data about reservations and expenses
     * @summary /guests/{guestsId}/full
     * @param {string} guestsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsGuestsIdFullGet: async (guestsId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'guestsId' is not null or undefined
      if (guestsId === null || guestsId === undefined) {
        throw new RequiredError(
          'guestsId',
          'Required parameter guestsId was null or undefined when calling guestsGuestsIdFullGet.'
        );
      }
      const localVarPath = `/guests/{guestsId}/full`.replace(
        `{${'guestsId'}}`,
        encodeURIComponent(String(guestsId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /guests/{guestsId}
     * @param {string} guestsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsGuestsIdGet: async (guestsId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'guestsId' is not null or undefined
      if (guestsId === null || guestsId === undefined) {
        throw new RequiredError(
          'guestsId',
          'Required parameter guestsId was null or undefined when calling guestsGuestsIdGet.'
        );
      }
      const localVarPath = `/guests/{guestsId}`.replace(
        `{${'guestsId'}}`,
        encodeURIComponent(String(guestsId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /guests/{guestsId}
     * @param {string} guestsId
     * @param {CellarUser} guest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsGuestsIdPut: async (
      guestsId: string,
      guest: CellarUser,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'guestsId' is not null or undefined
      if (guestsId === null || guestsId === undefined) {
        throw new RequiredError(
          'guestsId',
          'Required parameter guestsId was null or undefined when calling guestsGuestsIdPut.'
        );
      }
      // verify required parameter 'guest' is not null or undefined
      if (guest === null || guest === undefined) {
        throw new RequiredError(
          'guest',
          'Required parameter guest was null or undefined when calling guestsGuestsIdPut.'
        );
      }
      const localVarPath = `/guests/{guestsId}`.replace(
        `{${'guestsId'}}`,
        encodeURIComponent(String(guestsId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof guest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(guest !== undefined ? guest : {})
        : guest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /guests/o/delete
     * @param {InlineObject1} guests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsODeletePost: async (guests: InlineObject1, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'guests' is not null or undefined
      if (guests === null || guests === undefined) {
        throw new RequiredError(
          'guests',
          'Required parameter guests was null or undefined when calling guestsODeletePost.'
        );
      }
      const localVarPath = `/guests/o/delete`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof guests !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(guests !== undefined ? guests : {})
        : guests || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /guests
     * @param {CellarUser} guest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsPost: async (guest: CellarUser, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'guest' is not null or undefined
      if (guest === null || guest === undefined) {
        throw new RequiredError(
          'guest',
          'Required parameter guest was null or undefined when calling guestsPost.'
        );
      }
      const localVarPath = `/guests`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof guest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(guest !== undefined ? guest : {})
        : guest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CellarUserApi - functional programming interface
 * @export
 */
export const CellarUserApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /guests/{guestsId}
     * @param {string} guestsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async guestsGuestsIdDelete(
      guestsId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await CellarUserApiAxiosParamCreator(
        configuration
      ).guestsGuestsIdDelete(guestsId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * This endpoint returns more data than GET /guests/{guestId}, also populated data about reservations and expenses
     * @summary /guests/{guestsId}/full
     * @param {string} guestsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async guestsGuestsIdFullGet(
      guestsId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CellarUser>> {
      const localVarAxiosArgs = await CellarUserApiAxiosParamCreator(
        configuration
      ).guestsGuestsIdFullGet(guestsId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /guests/{guestsId}
     * @param {string} guestsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async guestsGuestsIdGet(
      guestsId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CellarUser>> {
      const localVarAxiosArgs = await CellarUserApiAxiosParamCreator(
        configuration
      ).guestsGuestsIdGet(guestsId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /guests/{guestsId}
     * @param {string} guestsId
     * @param {CellarUser} guest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async guestsGuestsIdPut(
      guestsId: string,
      guest: CellarUser,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CellarUser>> {
      const localVarAxiosArgs = await CellarUserApiAxiosParamCreator(
        configuration
      ).guestsGuestsIdPut(guestsId, guest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /guests/o/delete
     * @param {InlineObject1} guests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async guestsODeletePost(
      guests: InlineObject1,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await CellarUserApiAxiosParamCreator(
        configuration
      ).guestsODeletePost(guests, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /guests
     * @param {CellarUser} guest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async guestsPost(
      guest: CellarUser,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CellarUser>> {
      const localVarAxiosArgs = await CellarUserApiAxiosParamCreator(configuration).guestsPost(
        guest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * CellarUserApi - factory interface
 * @export
 */
export const CellarUserApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary /guests/{guestsId}
     * @param {string} guestsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsGuestsIdDelete(guestsId: string, options?: any): AxiosPromise<void> {
      return CellarUserApiFp(configuration)
        .guestsGuestsIdDelete(guestsId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint returns more data than GET /guests/{guestId}, also populated data about reservations and expenses
     * @summary /guests/{guestsId}/full
     * @param {string} guestsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsGuestsIdFullGet(guestsId: string, options?: any): AxiosPromise<CellarUser> {
      return CellarUserApiFp(configuration)
        .guestsGuestsIdFullGet(guestsId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /guests/{guestsId}
     * @param {string} guestsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsGuestsIdGet(guestsId: string, options?: any): AxiosPromise<CellarUser> {
      return CellarUserApiFp(configuration)
        .guestsGuestsIdGet(guestsId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /guests/{guestsId}
     * @param {string} guestsId
     * @param {CellarUser} guest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsGuestsIdPut(
      guestsId: string,
      guest: CellarUser,
      options?: any
    ): AxiosPromise<CellarUser> {
      return CellarUserApiFp(configuration)
        .guestsGuestsIdPut(guestsId, guest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /guests/o/delete
     * @param {InlineObject1} guests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsODeletePost(guests: InlineObject1, options?: any): AxiosPromise<void> {
      return CellarUserApiFp(configuration)
        .guestsODeletePost(guests, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /guests
     * @param {CellarUser} guest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsPost(guest: CellarUser, options?: any): AxiosPromise<CellarUser> {
      return CellarUserApiFp(configuration)
        .guestsPost(guest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CellarUserApi - object-oriented interface
 * @export
 * @class CellarUserApi
 * @extends {BaseAPI}
 */
export class CellarUserApi extends BaseAPI {
  /**
   *
   * @summary /guests/{guestsId}
   * @param {string} guestsId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CellarUserApi
   */
  public guestsGuestsIdDelete(guestsId: string, options?: any) {
    return CellarUserApiFp(this.configuration)
      .guestsGuestsIdDelete(guestsId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint returns more data than GET /guests/{guestId}, also populated data about reservations and expenses
   * @summary /guests/{guestsId}/full
   * @param {string} guestsId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CellarUserApi
   */
  public guestsGuestsIdFullGet(guestsId: string, options?: any) {
    return CellarUserApiFp(this.configuration)
      .guestsGuestsIdFullGet(guestsId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /guests/{guestsId}
   * @param {string} guestsId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CellarUserApi
   */
  public guestsGuestsIdGet(guestsId: string, options?: any) {
    return CellarUserApiFp(this.configuration)
      .guestsGuestsIdGet(guestsId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /guests/{guestsId}
   * @param {string} guestsId
   * @param {CellarUser} guest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CellarUserApi
   */
  public guestsGuestsIdPut(guestsId: string, guest: CellarUser, options?: any) {
    return CellarUserApiFp(this.configuration)
      .guestsGuestsIdPut(guestsId, guest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /guests/o/delete
   * @param {InlineObject1} guests
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CellarUserApi
   */
  public guestsODeletePost(guests: InlineObject1, options?: any) {
    return CellarUserApiFp(this.configuration)
      .guestsODeletePost(guests, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /guests
   * @param {CellarUser} guest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CellarUserApi
   */
  public guestsPost(guest: CellarUser, options?: any) {
    return CellarUserApiFp(this.configuration)
      .guestsPost(guest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /auth/signin
     * @param {InlineObject} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authSigninPost: async (body: InlineObject, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling authSigninPost.'
        );
      }
      const localVarPath = `/auth/signin`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /languages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    languagesGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/languages`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /auth/signin
     * @param {InlineObject} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authSigninPost(
      body: InlineObject,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).authSigninPost(
        body,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /languages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async languagesGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InlineResponse2003>>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).languagesGet(
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary /auth/signin
     * @param {InlineObject} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authSigninPost(body: InlineObject, options?: any): AxiosPromise<InlineResponse200> {
      return DefaultApiFp(configuration)
        .authSigninPost(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /languages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    languagesGet(options?: any): AxiosPromise<Array<InlineResponse2003>> {
      return DefaultApiFp(configuration)
        .languagesGet(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary /auth/signin
   * @param {InlineObject} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public authSigninPost(body: InlineObject, options?: any) {
    return DefaultApiFp(this.configuration)
      .authSigninPost(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /languages
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public languagesGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .languagesGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * EventApi - axios parameter creator
 * @export
 */
export const EventApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /events/{eventId}
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderDelete: async (
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'startDate' is not null or undefined
      if (startDate === null || startDate === undefined) {
        throw new RequiredError(
          'startDate',
          'Required parameter startDate was null or undefined when calling eventFinderDelete.'
        );
      }
      // verify required parameter 'location' is not null or undefined
      if (location === null || location === undefined) {
        throw new RequiredError(
          'location',
          'Required parameter location was null or undefined when calling eventFinderDelete.'
        );
      }
      const localVarPath = `/event-finder`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString() : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString() : endDate;
      }

      if (tag !== undefined) {
        localVarQueryParameter['tag'] = tag;
      }

      if (location !== undefined) {
        localVarQueryParameter['location'] = location;
      }

      if (weekDays) {
        localVarQueryParameter['weekDays'] = weekDays.join(COLLECTION_FORMATS.csv);
      }

      if (times) {
        localVarQueryParameter['times'] = times.join(COLLECTION_FORMATS.csv);
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /event-finder
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderGet: async (
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'startDate' is not null or undefined
      if (startDate === null || startDate === undefined) {
        throw new RequiredError(
          'startDate',
          'Required parameter startDate was null or undefined when calling eventFinderGet.'
        );
      }
      // verify required parameter 'location' is not null or undefined
      if (location === null || location === undefined) {
        throw new RequiredError(
          'location',
          'Required parameter location was null or undefined when calling eventFinderGet.'
        );
      }
      const localVarPath = `/event-finder`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString() : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString() : endDate;
      }

      if (tag !== undefined) {
        localVarQueryParameter['tag'] = tag;
      }

      if (location !== undefined) {
        localVarQueryParameter['location'] = location;
      }

      if (weekDays) {
        localVarQueryParameter['weekDays'] = weekDays.join(COLLECTION_FORMATS.csv);
      }

      if (times) {
        localVarQueryParameter['times'] = times.join(COLLECTION_FORMATS.csv);
      }

      if (alsoReserved !== undefined) {
        localVarQueryParameter['alsoReserved'] = alsoReserved;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /event-finder/hide
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderHidePost: async (
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'startDate' is not null or undefined
      if (startDate === null || startDate === undefined) {
        throw new RequiredError(
          'startDate',
          'Required parameter startDate was null or undefined when calling eventFinderHidePost.'
        );
      }
      // verify required parameter 'location' is not null or undefined
      if (location === null || location === undefined) {
        throw new RequiredError(
          'location',
          'Required parameter location was null or undefined when calling eventFinderHidePost.'
        );
      }
      const localVarPath = `/event-finder/hide`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString() : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString() : endDate;
      }

      if (tag !== undefined) {
        localVarQueryParameter['tag'] = tag;
      }

      if (location !== undefined) {
        localVarQueryParameter['location'] = location;
      }

      if (weekDays) {
        localVarQueryParameter['weekDays'] = weekDays.join(COLLECTION_FORMATS.csv);
      }

      if (times) {
        localVarQueryParameter['times'] = times.join(COLLECTION_FORMATS.csv);
      }

      if (alsoReserved !== undefined) {
        localVarQueryParameter['alsoReserved'] = alsoReserved;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /event-finder/pause
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderPausePost: async (
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'startDate' is not null or undefined
      if (startDate === null || startDate === undefined) {
        throw new RequiredError(
          'startDate',
          'Required parameter startDate was null or undefined when calling eventFinderPausePost.'
        );
      }
      // verify required parameter 'location' is not null or undefined
      if (location === null || location === undefined) {
        throw new RequiredError(
          'location',
          'Required parameter location was null or undefined when calling eventFinderPausePost.'
        );
      }
      const localVarPath = `/event-finder/pause`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString() : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString() : endDate;
      }

      if (tag !== undefined) {
        localVarQueryParameter['tag'] = tag;
      }

      if (location !== undefined) {
        localVarQueryParameter['location'] = location;
      }

      if (weekDays) {
        localVarQueryParameter['weekDays'] = weekDays.join(COLLECTION_FORMATS.csv);
      }

      if (times) {
        localVarQueryParameter['times'] = times.join(COLLECTION_FORMATS.csv);
      }

      if (alsoReserved !== undefined) {
        localVarQueryParameter['alsoReserved'] = alsoReserved;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /event-finder/play
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderPlayPost: async (
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'startDate' is not null or undefined
      if (startDate === null || startDate === undefined) {
        throw new RequiredError(
          'startDate',
          'Required parameter startDate was null or undefined when calling eventFinderPlayPost.'
        );
      }
      // verify required parameter 'location' is not null or undefined
      if (location === null || location === undefined) {
        throw new RequiredError(
          'location',
          'Required parameter location was null or undefined when calling eventFinderPlayPost.'
        );
      }
      const localVarPath = `/event-finder/play`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString() : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString() : endDate;
      }

      if (tag !== undefined) {
        localVarQueryParameter['tag'] = tag;
      }

      if (location !== undefined) {
        localVarQueryParameter['location'] = location;
      }

      if (weekDays) {
        localVarQueryParameter['weekDays'] = weekDays.join(COLLECTION_FORMATS.csv);
      }

      if (times) {
        localVarQueryParameter['times'] = times.join(COLLECTION_FORMATS.csv);
      }

      if (alsoReserved !== undefined) {
        localVarQueryParameter['alsoReserved'] = alsoReserved;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /events/{eventId}
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {Event} event
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderPut: async (
      startDate: string,
      location: string,
      event: Event,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'startDate' is not null or undefined
      if (startDate === null || startDate === undefined) {
        throw new RequiredError(
          'startDate',
          'Required parameter startDate was null or undefined when calling eventFinderPut.'
        );
      }
      // verify required parameter 'location' is not null or undefined
      if (location === null || location === undefined) {
        throw new RequiredError(
          'location',
          'Required parameter location was null or undefined when calling eventFinderPut.'
        );
      }
      // verify required parameter 'event' is not null or undefined
      if (event === null || event === undefined) {
        throw new RequiredError(
          'event',
          'Required parameter event was null or undefined when calling eventFinderPut.'
        );
      }
      const localVarPath = `/event-finder`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString() : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString() : endDate;
      }

      if (tag !== undefined) {
        localVarQueryParameter['tag'] = tag;
      }

      if (location !== undefined) {
        localVarQueryParameter['location'] = location;
      }

      if (weekDays) {
        localVarQueryParameter['weekDays'] = weekDays.join(COLLECTION_FORMATS.csv);
      }

      if (times) {
        localVarQueryParameter['times'] = times.join(COLLECTION_FORMATS.csv);
      }

      if (alsoReserved !== undefined) {
        localVarQueryParameter['alsoReserved'] = alsoReserved;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof event !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(event !== undefined ? event : {})
        : event || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /event-finder/show
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderShowPost: async (
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'startDate' is not null or undefined
      if (startDate === null || startDate === undefined) {
        throw new RequiredError(
          'startDate',
          'Required parameter startDate was null or undefined when calling eventFinderShowPost.'
        );
      }
      // verify required parameter 'location' is not null or undefined
      if (location === null || location === undefined) {
        throw new RequiredError(
          'location',
          'Required parameter location was null or undefined when calling eventFinderShowPost.'
        );
      }
      const localVarPath = `/event-finder/show`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString() : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString() : endDate;
      }

      if (tag !== undefined) {
        localVarQueryParameter['tag'] = tag;
      }

      if (location !== undefined) {
        localVarQueryParameter['location'] = location;
      }

      if (weekDays) {
        localVarQueryParameter['weekDays'] = weekDays.join(COLLECTION_FORMATS.csv);
      }

      if (times) {
        localVarQueryParameter['times'] = times.join(COLLECTION_FORMATS.csv);
      }

      if (alsoReserved !== undefined) {
        localVarQueryParameter['alsoReserved'] = alsoReserved;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /events/{eventId}
     * @param {string} eventId
     * @param {number} refund
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsEventIdDelete: async (
      eventId: string,
      refund: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'eventId' is not null or undefined
      if (eventId === null || eventId === undefined) {
        throw new RequiredError(
          'eventId',
          'Required parameter eventId was null or undefined when calling eventsEventIdDelete.'
        );
      }
      // verify required parameter 'refund' is not null or undefined
      if (refund === null || refund === undefined) {
        throw new RequiredError(
          'refund',
          'Required parameter refund was null or undefined when calling eventsEventIdDelete.'
        );
      }
      const localVarPath = `/events/{eventId}`.replace(
        `{${'eventId'}}`,
        encodeURIComponent(String(eventId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (refund !== undefined) {
        localVarQueryParameter['refund'] = refund;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /events/{eventId}
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsEventIdGet: async (eventId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'eventId' is not null or undefined
      if (eventId === null || eventId === undefined) {
        throw new RequiredError(
          'eventId',
          'Required parameter eventId was null or undefined when calling eventsEventIdGet.'
        );
      }
      const localVarPath = `/events/{eventId}`.replace(
        `{${'eventId'}}`,
        encodeURIComponent(String(eventId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /events/{eventId}/language/toggle
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsEventIdLanguageTogglePost: async (
      eventId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'eventId' is not null or undefined
      if (eventId === null || eventId === undefined) {
        throw new RequiredError(
          'eventId',
          'Required parameter eventId was null or undefined when calling eventsEventIdLanguageTogglePost.'
        );
      }
      const localVarPath = `/events/{eventId}/language/toggle`.replace(
        `{${'eventId'}}`,
        encodeURIComponent(String(eventId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /events/{eventId}/pause/toggle
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsEventIdPauseTogglePost: async (
      eventId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'eventId' is not null or undefined
      if (eventId === null || eventId === undefined) {
        throw new RequiredError(
          'eventId',
          'Required parameter eventId was null or undefined when calling eventsEventIdPauseTogglePost.'
        );
      }
      const localVarPath = `/events/{eventId}/pause/toggle`.replace(
        `{${'eventId'}}`,
        encodeURIComponent(String(eventId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /events/{eventId}/places/down
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsEventIdPlacesDownPost: async (
      eventId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'eventId' is not null or undefined
      if (eventId === null || eventId === undefined) {
        throw new RequiredError(
          'eventId',
          'Required parameter eventId was null or undefined when calling eventsEventIdPlacesDownPost.'
        );
      }
      const localVarPath = `/events/{eventId}/places/down`.replace(
        `{${'eventId'}}`,
        encodeURIComponent(String(eventId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /events/{eventId}/places/up
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsEventIdPlacesUpPost: async (eventId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'eventId' is not null or undefined
      if (eventId === null || eventId === undefined) {
        throw new RequiredError(
          'eventId',
          'Required parameter eventId was null or undefined when calling eventsEventIdPlacesUpPost.'
        );
      }
      const localVarPath = `/events/{eventId}/places/up`.replace(
        `{${'eventId'}}`,
        encodeURIComponent(String(eventId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /events/{eventId}/publish/toggle
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsEventIdPublishTogglePost: async (
      eventId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'eventId' is not null or undefined
      if (eventId === null || eventId === undefined) {
        throw new RequiredError(
          'eventId',
          'Required parameter eventId was null or undefined when calling eventsEventIdPublishTogglePost.'
        );
      }
      const localVarPath = `/events/{eventId}/publish/toggle`.replace(
        `{${'eventId'}}`,
        encodeURIComponent(String(eventId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /events/{eventId}
     * @param {string} eventId
     * @param {Event} event
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsEventIdPut: async (
      eventId: string,
      event: Event,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'eventId' is not null or undefined
      if (eventId === null || eventId === undefined) {
        throw new RequiredError(
          'eventId',
          'Required parameter eventId was null or undefined when calling eventsEventIdPut.'
        );
      }
      // verify required parameter 'event' is not null or undefined
      if (event === null || event === undefined) {
        throw new RequiredError(
          'event',
          'Required parameter event was null or undefined when calling eventsEventIdPut.'
        );
      }
      const localVarPath = `/events/{eventId}`.replace(
        `{${'eventId'}}`,
        encodeURIComponent(String(eventId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof event !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(event !== undefined ? event : {})
        : event || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /events/{eventId}/reservations
     * @param {string} eventId
     * @param {Reservation} reservation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsEventIdReservationsPost: async (
      eventId: string,
      reservation: Reservation,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'eventId' is not null or undefined
      if (eventId === null || eventId === undefined) {
        throw new RequiredError(
          'eventId',
          'Required parameter eventId was null or undefined when calling eventsEventIdReservationsPost.'
        );
      }
      // verify required parameter 'reservation' is not null or undefined
      if (reservation === null || reservation === undefined) {
        throw new RequiredError(
          'reservation',
          'Required parameter reservation was null or undefined when calling eventsEventIdReservationsPost.'
        );
      }
      const localVarPath = `/events/{eventId}/reservations`.replace(
        `{${'eventId'}}`,
        encodeURIComponent(String(eventId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof reservation !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(reservation !== undefined ? reservation : {})
        : reservation || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /events/r/titles
     * @param {string} [location]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsRTitlesGet: async (
      location?: string,
      startDate?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/events/r/titles`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (location !== undefined) {
        localVarQueryParameter['location'] = location;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString() : startDate;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /models/{modelId}/events
     * @param {string} modelId
     * @param {InlineObject2} event
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modelsModelIdEventsPost: async (
      modelId: string,
      event: InlineObject2,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling modelsModelIdEventsPost.'
        );
      }
      // verify required parameter 'event' is not null or undefined
      if (event === null || event === undefined) {
        throw new RequiredError(
          'event',
          'Required parameter event was null or undefined when calling modelsModelIdEventsPost.'
        );
      }
      const localVarPath = `/models/{modelId}/events`.replace(
        `{${'modelId'}}`,
        encodeURIComponent(String(modelId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof event !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(event !== undefined ? event : {})
        : event || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/{placeId}/events
     * @param {string} placeId
     * @param {string} [date]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [language]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdEventsGet: async (
      placeId: string,
      date?: string,
      start?: string,
      end?: string,
      language?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling placesPlaceIdEventsGet.'
        );
      }
      const localVarPath = `/places/{placeId}/events`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] =
          (date as any) instanceof Date ? (date as any).toISOString() : date;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] =
          (start as any) instanceof Date ? (start as any).toISOString() : start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] =
          (end as any) instanceof Date ? (end as any).toISOString() : end;
      }

      if (language !== undefined) {
        localVarQueryParameter['language'] = language;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /events/{eventId}
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventFinderDelete(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await EventApiAxiosParamCreator(configuration).eventFinderDelete(
        startDate,
        location,
        endDate,
        tag,
        weekDays,
        times,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /event-finder
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventFinderGet(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
      const localVarAxiosArgs = await EventApiAxiosParamCreator(configuration).eventFinderGet(
        startDate,
        location,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /event-finder/hide
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventFinderHidePost(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await EventApiAxiosParamCreator(configuration).eventFinderHidePost(
        startDate,
        location,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /event-finder/pause
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventFinderPausePost(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await EventApiAxiosParamCreator(configuration).eventFinderPausePost(
        startDate,
        location,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /event-finder/play
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventFinderPlayPost(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await EventApiAxiosParamCreator(configuration).eventFinderPlayPost(
        startDate,
        location,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /events/{eventId}
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {Event} event
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventFinderPut(
      startDate: string,
      location: string,
      event: Event,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await EventApiAxiosParamCreator(configuration).eventFinderPut(
        startDate,
        location,
        event,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /event-finder/show
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventFinderShowPost(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await EventApiAxiosParamCreator(configuration).eventFinderShowPost(
        startDate,
        location,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /events/{eventId}
     * @param {string} eventId
     * @param {number} refund
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventsEventIdDelete(
      eventId: string,
      refund: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await EventApiAxiosParamCreator(configuration).eventsEventIdDelete(
        eventId,
        refund,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /events/{eventId}
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventsEventIdGet(
      eventId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
      const localVarAxiosArgs = await EventApiAxiosParamCreator(configuration).eventsEventIdGet(
        eventId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /events/{eventId}/language/toggle
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventsEventIdLanguageTogglePost(
      eventId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
      const localVarAxiosArgs = await EventApiAxiosParamCreator(
        configuration
      ).eventsEventIdLanguageTogglePost(eventId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /events/{eventId}/pause/toggle
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventsEventIdPauseTogglePost(
      eventId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
      const localVarAxiosArgs = await EventApiAxiosParamCreator(
        configuration
      ).eventsEventIdPauseTogglePost(eventId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /events/{eventId}/places/down
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventsEventIdPlacesDownPost(
      eventId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
      const localVarAxiosArgs = await EventApiAxiosParamCreator(
        configuration
      ).eventsEventIdPlacesDownPost(eventId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /events/{eventId}/places/up
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventsEventIdPlacesUpPost(
      eventId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
      const localVarAxiosArgs = await EventApiAxiosParamCreator(
        configuration
      ).eventsEventIdPlacesUpPost(eventId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /events/{eventId}/publish/toggle
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventsEventIdPublishTogglePost(
      eventId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
      const localVarAxiosArgs = await EventApiAxiosParamCreator(
        configuration
      ).eventsEventIdPublishTogglePost(eventId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /events/{eventId}
     * @param {string} eventId
     * @param {Event} event
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventsEventIdPut(
      eventId: string,
      event: Event,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
      const localVarAxiosArgs = await EventApiAxiosParamCreator(configuration).eventsEventIdPut(
        eventId,
        event,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /events/{eventId}/reservations
     * @param {string} eventId
     * @param {Reservation} reservation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventsEventIdReservationsPost(
      eventId: string,
      reservation: Reservation,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reservation>> {
      const localVarAxiosArgs = await EventApiAxiosParamCreator(
        configuration
      ).eventsEventIdReservationsPost(eventId, reservation, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /events/r/titles
     * @param {string} [location]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventsRTitlesGet(
      location?: string,
      startDate?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await EventApiAxiosParamCreator(configuration).eventsRTitlesGet(
        location,
        startDate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /models/{modelId}/events
     * @param {string} modelId
     * @param {InlineObject2} event
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async modelsModelIdEventsPost(
      modelId: string,
      event: InlineObject2,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
      const localVarAxiosArgs = await EventApiAxiosParamCreator(
        configuration
      ).modelsModelIdEventsPost(modelId, event, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/{placeId}/events
     * @param {string} placeId
     * @param {string} [date]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [language]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesPlaceIdEventsGet(
      placeId: string,
      date?: string,
      start?: string,
      end?: string,
      language?: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InlineResponse2006>>
    > {
      const localVarAxiosArgs = await EventApiAxiosParamCreator(
        configuration
      ).placesPlaceIdEventsGet(placeId, date, start, end, language, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary /events/{eventId}
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderDelete(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      options?: any
    ): AxiosPromise<void> {
      return EventApiFp(configuration)
        .eventFinderDelete(startDate, location, endDate, tag, weekDays, times, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /event-finder
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderGet(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): AxiosPromise<InlineResponse2002> {
      return EventApiFp(configuration)
        .eventFinderGet(startDate, location, endDate, tag, weekDays, times, alsoReserved, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /event-finder/hide
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderHidePost(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): AxiosPromise<void> {
      return EventApiFp(configuration)
        .eventFinderHidePost(
          startDate,
          location,
          endDate,
          tag,
          weekDays,
          times,
          alsoReserved,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /event-finder/pause
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderPausePost(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): AxiosPromise<void> {
      return EventApiFp(configuration)
        .eventFinderPausePost(
          startDate,
          location,
          endDate,
          tag,
          weekDays,
          times,
          alsoReserved,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /event-finder/play
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderPlayPost(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): AxiosPromise<void> {
      return EventApiFp(configuration)
        .eventFinderPlayPost(
          startDate,
          location,
          endDate,
          tag,
          weekDays,
          times,
          alsoReserved,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /events/{eventId}
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {Event} event
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderPut(
      startDate: string,
      location: string,
      event: Event,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): AxiosPromise<object> {
      return EventApiFp(configuration)
        .eventFinderPut(
          startDate,
          location,
          event,
          endDate,
          tag,
          weekDays,
          times,
          alsoReserved,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /event-finder/show
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderShowPost(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): AxiosPromise<void> {
      return EventApiFp(configuration)
        .eventFinderShowPost(
          startDate,
          location,
          endDate,
          tag,
          weekDays,
          times,
          alsoReserved,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /events/{eventId}
     * @param {string} eventId
     * @param {number} refund
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsEventIdDelete(eventId: string, refund: number, options?: any): AxiosPromise<void> {
      return EventApiFp(configuration)
        .eventsEventIdDelete(eventId, refund, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /events/{eventId}
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsEventIdGet(eventId: string, options?: any): AxiosPromise<Event> {
      return EventApiFp(configuration)
        .eventsEventIdGet(eventId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /events/{eventId}/language/toggle
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsEventIdLanguageTogglePost(eventId: string, options?: any): AxiosPromise<Event> {
      return EventApiFp(configuration)
        .eventsEventIdLanguageTogglePost(eventId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /events/{eventId}/pause/toggle
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsEventIdPauseTogglePost(eventId: string, options?: any): AxiosPromise<Event> {
      return EventApiFp(configuration)
        .eventsEventIdPauseTogglePost(eventId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /events/{eventId}/places/down
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsEventIdPlacesDownPost(eventId: string, options?: any): AxiosPromise<Event> {
      return EventApiFp(configuration)
        .eventsEventIdPlacesDownPost(eventId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /events/{eventId}/places/up
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsEventIdPlacesUpPost(eventId: string, options?: any): AxiosPromise<Event> {
      return EventApiFp(configuration)
        .eventsEventIdPlacesUpPost(eventId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /events/{eventId}/publish/toggle
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsEventIdPublishTogglePost(eventId: string, options?: any): AxiosPromise<Event> {
      return EventApiFp(configuration)
        .eventsEventIdPublishTogglePost(eventId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /events/{eventId}
     * @param {string} eventId
     * @param {Event} event
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsEventIdPut(eventId: string, event: Event, options?: any): AxiosPromise<Event> {
      return EventApiFp(configuration)
        .eventsEventIdPut(eventId, event, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /events/{eventId}/reservations
     * @param {string} eventId
     * @param {Reservation} reservation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsEventIdReservationsPost(
      eventId: string,
      reservation: Reservation,
      options?: any
    ): AxiosPromise<Reservation> {
      return EventApiFp(configuration)
        .eventsEventIdReservationsPost(eventId, reservation, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /events/r/titles
     * @param {string} [location]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsRTitlesGet(
      location?: string,
      startDate?: string,
      options?: any
    ): AxiosPromise<Array<string>> {
      return EventApiFp(configuration)
        .eventsRTitlesGet(location, startDate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /models/{modelId}/events
     * @param {string} modelId
     * @param {InlineObject2} event
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modelsModelIdEventsPost(
      modelId: string,
      event: InlineObject2,
      options?: any
    ): AxiosPromise<Event> {
      return EventApiFp(configuration)
        .modelsModelIdEventsPost(modelId, event, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/{placeId}/events
     * @param {string} placeId
     * @param {string} [date]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [language]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdEventsGet(
      placeId: string,
      date?: string,
      start?: string,
      end?: string,
      language?: string,
      options?: any
    ): AxiosPromise<Array<InlineResponse2006>> {
      return EventApiFp(configuration)
        .placesPlaceIdEventsGet(placeId, date, start, end, language, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
  /**
   *
   * @summary /events/{eventId}
   * @param {string} startDate
   * @param {string} location Id of the Model we want to narrow the search to
   * @param {string} [endDate]
   * @param {string} [tag] Title of the Model we want to narrow the search to
   * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
   * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventFinderDelete(
    startDate: string,
    location: string,
    endDate?: string,
    tag?: string,
    weekDays?: Array<boolean>,
    times?: Array<number>,
    options?: any
  ) {
    return EventApiFp(this.configuration)
      .eventFinderDelete(startDate, location, endDate, tag, weekDays, times, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /event-finder
   * @param {string} startDate
   * @param {string} location Id of the Model we want to narrow the search to
   * @param {string} [endDate]
   * @param {string} [tag] Title of the Model we want to narrow the search to
   * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
   * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
   * @param {boolean} [alsoReserved] Also include reserved events
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventFinderGet(
    startDate: string,
    location: string,
    endDate?: string,
    tag?: string,
    weekDays?: Array<boolean>,
    times?: Array<number>,
    alsoReserved?: boolean,
    options?: any
  ) {
    return EventApiFp(this.configuration)
      .eventFinderGet(startDate, location, endDate, tag, weekDays, times, alsoReserved, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /event-finder/hide
   * @param {string} startDate
   * @param {string} location Id of the Model we want to narrow the search to
   * @param {string} [endDate]
   * @param {string} [tag] Title of the Model we want to narrow the search to
   * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
   * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
   * @param {boolean} [alsoReserved] Also include reserved events
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventFinderHidePost(
    startDate: string,
    location: string,
    endDate?: string,
    tag?: string,
    weekDays?: Array<boolean>,
    times?: Array<number>,
    alsoReserved?: boolean,
    options?: any
  ) {
    return EventApiFp(this.configuration)
      .eventFinderHidePost(
        startDate,
        location,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /event-finder/pause
   * @param {string} startDate
   * @param {string} location Id of the Model we want to narrow the search to
   * @param {string} [endDate]
   * @param {string} [tag] Title of the Model we want to narrow the search to
   * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
   * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
   * @param {boolean} [alsoReserved] Also include reserved events
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventFinderPausePost(
    startDate: string,
    location: string,
    endDate?: string,
    tag?: string,
    weekDays?: Array<boolean>,
    times?: Array<number>,
    alsoReserved?: boolean,
    options?: any
  ) {
    return EventApiFp(this.configuration)
      .eventFinderPausePost(
        startDate,
        location,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /event-finder/play
   * @param {string} startDate
   * @param {string} location Id of the Model we want to narrow the search to
   * @param {string} [endDate]
   * @param {string} [tag] Title of the Model we want to narrow the search to
   * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
   * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
   * @param {boolean} [alsoReserved] Also include reserved events
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventFinderPlayPost(
    startDate: string,
    location: string,
    endDate?: string,
    tag?: string,
    weekDays?: Array<boolean>,
    times?: Array<number>,
    alsoReserved?: boolean,
    options?: any
  ) {
    return EventApiFp(this.configuration)
      .eventFinderPlayPost(
        startDate,
        location,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /events/{eventId}
   * @param {string} startDate
   * @param {string} location Id of the Model we want to narrow the search to
   * @param {Event} event
   * @param {string} [endDate]
   * @param {string} [tag] Title of the Model we want to narrow the search to
   * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
   * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
   * @param {boolean} [alsoReserved] Also include reserved events
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventFinderPut(
    startDate: string,
    location: string,
    event: Event,
    endDate?: string,
    tag?: string,
    weekDays?: Array<boolean>,
    times?: Array<number>,
    alsoReserved?: boolean,
    options?: any
  ) {
    return EventApiFp(this.configuration)
      .eventFinderPut(
        startDate,
        location,
        event,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /event-finder/show
   * @param {string} startDate
   * @param {string} location Id of the Model we want to narrow the search to
   * @param {string} [endDate]
   * @param {string} [tag] Title of the Model we want to narrow the search to
   * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
   * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
   * @param {boolean} [alsoReserved] Also include reserved events
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventFinderShowPost(
    startDate: string,
    location: string,
    endDate?: string,
    tag?: string,
    weekDays?: Array<boolean>,
    times?: Array<number>,
    alsoReserved?: boolean,
    options?: any
  ) {
    return EventApiFp(this.configuration)
      .eventFinderShowPost(
        startDate,
        location,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /events/{eventId}
   * @param {string} eventId
   * @param {number} refund
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventsEventIdDelete(eventId: string, refund: number, options?: any) {
    return EventApiFp(this.configuration)
      .eventsEventIdDelete(eventId, refund, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /events/{eventId}
   * @param {string} eventId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventsEventIdGet(eventId: string, options?: any) {
    return EventApiFp(this.configuration)
      .eventsEventIdGet(eventId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /events/{eventId}/language/toggle
   * @param {string} eventId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventsEventIdLanguageTogglePost(eventId: string, options?: any) {
    return EventApiFp(this.configuration)
      .eventsEventIdLanguageTogglePost(eventId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /events/{eventId}/pause/toggle
   * @param {string} eventId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventsEventIdPauseTogglePost(eventId: string, options?: any) {
    return EventApiFp(this.configuration)
      .eventsEventIdPauseTogglePost(eventId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /events/{eventId}/places/down
   * @param {string} eventId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventsEventIdPlacesDownPost(eventId: string, options?: any) {
    return EventApiFp(this.configuration)
      .eventsEventIdPlacesDownPost(eventId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /events/{eventId}/places/up
   * @param {string} eventId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventsEventIdPlacesUpPost(eventId: string, options?: any) {
    return EventApiFp(this.configuration)
      .eventsEventIdPlacesUpPost(eventId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /events/{eventId}/publish/toggle
   * @param {string} eventId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventsEventIdPublishTogglePost(eventId: string, options?: any) {
    return EventApiFp(this.configuration)
      .eventsEventIdPublishTogglePost(eventId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /events/{eventId}
   * @param {string} eventId
   * @param {Event} event
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventsEventIdPut(eventId: string, event: Event, options?: any) {
    return EventApiFp(this.configuration)
      .eventsEventIdPut(eventId, event, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /events/{eventId}/reservations
   * @param {string} eventId
   * @param {Reservation} reservation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventsEventIdReservationsPost(eventId: string, reservation: Reservation, options?: any) {
    return EventApiFp(this.configuration)
      .eventsEventIdReservationsPost(eventId, reservation, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /events/r/titles
   * @param {string} [location]
   * @param {string} [startDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventsRTitlesGet(location?: string, startDate?: string, options?: any) {
    return EventApiFp(this.configuration)
      .eventsRTitlesGet(location, startDate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /models/{modelId}/events
   * @param {string} modelId
   * @param {InlineObject2} event
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public modelsModelIdEventsPost(modelId: string, event: InlineObject2, options?: any) {
    return EventApiFp(this.configuration)
      .modelsModelIdEventsPost(modelId, event, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/{placeId}/events
   * @param {string} placeId
   * @param {string} [date]
   * @param {string} [start]
   * @param {string} [end]
   * @param {string} [language]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public placesPlaceIdEventsGet(
    placeId: string,
    date?: string,
    start?: string,
    end?: string,
    language?: string,
    options?: any
  ) {
    return EventApiFp(this.configuration)
      .placesPlaceIdEventsGet(placeId, date, start, end, language, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * EventFinderApi - axios parameter creator
 * @export
 */
export const EventFinderApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /event-finder
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderGet: async (
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'startDate' is not null or undefined
      if (startDate === null || startDate === undefined) {
        throw new RequiredError(
          'startDate',
          'Required parameter startDate was null or undefined when calling eventFinderGet.'
        );
      }
      // verify required parameter 'location' is not null or undefined
      if (location === null || location === undefined) {
        throw new RequiredError(
          'location',
          'Required parameter location was null or undefined when calling eventFinderGet.'
        );
      }
      const localVarPath = `/event-finder`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString() : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString() : endDate;
      }

      if (tag !== undefined) {
        localVarQueryParameter['tag'] = tag;
      }

      if (location !== undefined) {
        localVarQueryParameter['location'] = location;
      }

      if (weekDays) {
        localVarQueryParameter['weekDays'] = weekDays.join(COLLECTION_FORMATS.csv);
      }

      if (times) {
        localVarQueryParameter['times'] = times.join(COLLECTION_FORMATS.csv);
      }

      if (alsoReserved !== undefined) {
        localVarQueryParameter['alsoReserved'] = alsoReserved;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /event-finder/hide
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderHidePost: async (
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'startDate' is not null or undefined
      if (startDate === null || startDate === undefined) {
        throw new RequiredError(
          'startDate',
          'Required parameter startDate was null or undefined when calling eventFinderHidePost.'
        );
      }
      // verify required parameter 'location' is not null or undefined
      if (location === null || location === undefined) {
        throw new RequiredError(
          'location',
          'Required parameter location was null or undefined when calling eventFinderHidePost.'
        );
      }
      const localVarPath = `/event-finder/hide`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString() : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString() : endDate;
      }

      if (tag !== undefined) {
        localVarQueryParameter['tag'] = tag;
      }

      if (location !== undefined) {
        localVarQueryParameter['location'] = location;
      }

      if (weekDays) {
        localVarQueryParameter['weekDays'] = weekDays.join(COLLECTION_FORMATS.csv);
      }

      if (times) {
        localVarQueryParameter['times'] = times.join(COLLECTION_FORMATS.csv);
      }

      if (alsoReserved !== undefined) {
        localVarQueryParameter['alsoReserved'] = alsoReserved;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /event-finder/pause
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderPausePost: async (
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'startDate' is not null or undefined
      if (startDate === null || startDate === undefined) {
        throw new RequiredError(
          'startDate',
          'Required parameter startDate was null or undefined when calling eventFinderPausePost.'
        );
      }
      // verify required parameter 'location' is not null or undefined
      if (location === null || location === undefined) {
        throw new RequiredError(
          'location',
          'Required parameter location was null or undefined when calling eventFinderPausePost.'
        );
      }
      const localVarPath = `/event-finder/pause`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString() : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString() : endDate;
      }

      if (tag !== undefined) {
        localVarQueryParameter['tag'] = tag;
      }

      if (location !== undefined) {
        localVarQueryParameter['location'] = location;
      }

      if (weekDays) {
        localVarQueryParameter['weekDays'] = weekDays.join(COLLECTION_FORMATS.csv);
      }

      if (times) {
        localVarQueryParameter['times'] = times.join(COLLECTION_FORMATS.csv);
      }

      if (alsoReserved !== undefined) {
        localVarQueryParameter['alsoReserved'] = alsoReserved;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /event-finder/play
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderPlayPost: async (
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'startDate' is not null or undefined
      if (startDate === null || startDate === undefined) {
        throw new RequiredError(
          'startDate',
          'Required parameter startDate was null or undefined when calling eventFinderPlayPost.'
        );
      }
      // verify required parameter 'location' is not null or undefined
      if (location === null || location === undefined) {
        throw new RequiredError(
          'location',
          'Required parameter location was null or undefined when calling eventFinderPlayPost.'
        );
      }
      const localVarPath = `/event-finder/play`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString() : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString() : endDate;
      }

      if (tag !== undefined) {
        localVarQueryParameter['tag'] = tag;
      }

      if (location !== undefined) {
        localVarQueryParameter['location'] = location;
      }

      if (weekDays) {
        localVarQueryParameter['weekDays'] = weekDays.join(COLLECTION_FORMATS.csv);
      }

      if (times) {
        localVarQueryParameter['times'] = times.join(COLLECTION_FORMATS.csv);
      }

      if (alsoReserved !== undefined) {
        localVarQueryParameter['alsoReserved'] = alsoReserved;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /events/{eventId}
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {Event} event
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderPut: async (
      startDate: string,
      location: string,
      event: Event,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'startDate' is not null or undefined
      if (startDate === null || startDate === undefined) {
        throw new RequiredError(
          'startDate',
          'Required parameter startDate was null or undefined when calling eventFinderPut.'
        );
      }
      // verify required parameter 'location' is not null or undefined
      if (location === null || location === undefined) {
        throw new RequiredError(
          'location',
          'Required parameter location was null or undefined when calling eventFinderPut.'
        );
      }
      // verify required parameter 'event' is not null or undefined
      if (event === null || event === undefined) {
        throw new RequiredError(
          'event',
          'Required parameter event was null or undefined when calling eventFinderPut.'
        );
      }
      const localVarPath = `/event-finder`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString() : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString() : endDate;
      }

      if (tag !== undefined) {
        localVarQueryParameter['tag'] = tag;
      }

      if (location !== undefined) {
        localVarQueryParameter['location'] = location;
      }

      if (weekDays) {
        localVarQueryParameter['weekDays'] = weekDays.join(COLLECTION_FORMATS.csv);
      }

      if (times) {
        localVarQueryParameter['times'] = times.join(COLLECTION_FORMATS.csv);
      }

      if (alsoReserved !== undefined) {
        localVarQueryParameter['alsoReserved'] = alsoReserved;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof event !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(event !== undefined ? event : {})
        : event || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /event-finder/show
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderShowPost: async (
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'startDate' is not null or undefined
      if (startDate === null || startDate === undefined) {
        throw new RequiredError(
          'startDate',
          'Required parameter startDate was null or undefined when calling eventFinderShowPost.'
        );
      }
      // verify required parameter 'location' is not null or undefined
      if (location === null || location === undefined) {
        throw new RequiredError(
          'location',
          'Required parameter location was null or undefined when calling eventFinderShowPost.'
        );
      }
      const localVarPath = `/event-finder/show`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString() : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString() : endDate;
      }

      if (tag !== undefined) {
        localVarQueryParameter['tag'] = tag;
      }

      if (location !== undefined) {
        localVarQueryParameter['location'] = location;
      }

      if (weekDays) {
        localVarQueryParameter['weekDays'] = weekDays.join(COLLECTION_FORMATS.csv);
      }

      if (times) {
        localVarQueryParameter['times'] = times.join(COLLECTION_FORMATS.csv);
      }

      if (alsoReserved !== undefined) {
        localVarQueryParameter['alsoReserved'] = alsoReserved;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /events/r/titles
     * @param {string} [location]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsRTitlesGet: async (
      location?: string,
      startDate?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/events/r/titles`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (location !== undefined) {
        localVarQueryParameter['location'] = location;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString() : startDate;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EventFinderApi - functional programming interface
 * @export
 */
export const EventFinderApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /event-finder
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventFinderGet(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
      const localVarAxiosArgs = await EventFinderApiAxiosParamCreator(configuration).eventFinderGet(
        startDate,
        location,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /event-finder/hide
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventFinderHidePost(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await EventFinderApiAxiosParamCreator(
        configuration
      ).eventFinderHidePost(
        startDate,
        location,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /event-finder/pause
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventFinderPausePost(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await EventFinderApiAxiosParamCreator(
        configuration
      ).eventFinderPausePost(
        startDate,
        location,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /event-finder/play
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventFinderPlayPost(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await EventFinderApiAxiosParamCreator(
        configuration
      ).eventFinderPlayPost(
        startDate,
        location,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /events/{eventId}
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {Event} event
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventFinderPut(
      startDate: string,
      location: string,
      event: Event,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await EventFinderApiAxiosParamCreator(configuration).eventFinderPut(
        startDate,
        location,
        event,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /event-finder/show
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventFinderShowPost(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await EventFinderApiAxiosParamCreator(
        configuration
      ).eventFinderShowPost(
        startDate,
        location,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /events/r/titles
     * @param {string} [location]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventsRTitlesGet(
      location?: string,
      startDate?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await EventFinderApiAxiosParamCreator(
        configuration
      ).eventsRTitlesGet(location, startDate, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * EventFinderApi - factory interface
 * @export
 */
export const EventFinderApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary /event-finder
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderGet(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): AxiosPromise<InlineResponse2002> {
      return EventFinderApiFp(configuration)
        .eventFinderGet(startDate, location, endDate, tag, weekDays, times, alsoReserved, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /event-finder/hide
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderHidePost(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): AxiosPromise<void> {
      return EventFinderApiFp(configuration)
        .eventFinderHidePost(
          startDate,
          location,
          endDate,
          tag,
          weekDays,
          times,
          alsoReserved,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /event-finder/pause
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderPausePost(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): AxiosPromise<void> {
      return EventFinderApiFp(configuration)
        .eventFinderPausePost(
          startDate,
          location,
          endDate,
          tag,
          weekDays,
          times,
          alsoReserved,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /event-finder/play
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderPlayPost(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): AxiosPromise<void> {
      return EventFinderApiFp(configuration)
        .eventFinderPlayPost(
          startDate,
          location,
          endDate,
          tag,
          weekDays,
          times,
          alsoReserved,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /events/{eventId}
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {Event} event
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderPut(
      startDate: string,
      location: string,
      event: Event,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): AxiosPromise<object> {
      return EventFinderApiFp(configuration)
        .eventFinderPut(
          startDate,
          location,
          event,
          endDate,
          tag,
          weekDays,
          times,
          alsoReserved,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /event-finder/show
     * @param {string} startDate
     * @param {string} location Id of the Model we want to narrow the search to
     * @param {string} [endDate]
     * @param {string} [tag] Title of the Model we want to narrow the search to
     * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
     * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
     * @param {boolean} [alsoReserved] Also include reserved events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventFinderShowPost(
      startDate: string,
      location: string,
      endDate?: string,
      tag?: string,
      weekDays?: Array<boolean>,
      times?: Array<number>,
      alsoReserved?: boolean,
      options?: any
    ): AxiosPromise<void> {
      return EventFinderApiFp(configuration)
        .eventFinderShowPost(
          startDate,
          location,
          endDate,
          tag,
          weekDays,
          times,
          alsoReserved,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /events/r/titles
     * @param {string} [location]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsRTitlesGet(
      location?: string,
      startDate?: string,
      options?: any
    ): AxiosPromise<Array<string>> {
      return EventFinderApiFp(configuration)
        .eventsRTitlesGet(location, startDate, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * EventFinderApi - object-oriented interface
 * @export
 * @class EventFinderApi
 * @extends {BaseAPI}
 */
export class EventFinderApi extends BaseAPI {
  /**
   *
   * @summary /event-finder
   * @param {string} startDate
   * @param {string} location Id of the Model we want to narrow the search to
   * @param {string} [endDate]
   * @param {string} [tag] Title of the Model we want to narrow the search to
   * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
   * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
   * @param {boolean} [alsoReserved] Also include reserved events
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventFinderApi
   */
  public eventFinderGet(
    startDate: string,
    location: string,
    endDate?: string,
    tag?: string,
    weekDays?: Array<boolean>,
    times?: Array<number>,
    alsoReserved?: boolean,
    options?: any
  ) {
    return EventFinderApiFp(this.configuration)
      .eventFinderGet(startDate, location, endDate, tag, weekDays, times, alsoReserved, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /event-finder/hide
   * @param {string} startDate
   * @param {string} location Id of the Model we want to narrow the search to
   * @param {string} [endDate]
   * @param {string} [tag] Title of the Model we want to narrow the search to
   * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
   * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
   * @param {boolean} [alsoReserved] Also include reserved events
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventFinderApi
   */
  public eventFinderHidePost(
    startDate: string,
    location: string,
    endDate?: string,
    tag?: string,
    weekDays?: Array<boolean>,
    times?: Array<number>,
    alsoReserved?: boolean,
    options?: any
  ) {
    return EventFinderApiFp(this.configuration)
      .eventFinderHidePost(
        startDate,
        location,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /event-finder/pause
   * @param {string} startDate
   * @param {string} location Id of the Model we want to narrow the search to
   * @param {string} [endDate]
   * @param {string} [tag] Title of the Model we want to narrow the search to
   * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
   * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
   * @param {boolean} [alsoReserved] Also include reserved events
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventFinderApi
   */
  public eventFinderPausePost(
    startDate: string,
    location: string,
    endDate?: string,
    tag?: string,
    weekDays?: Array<boolean>,
    times?: Array<number>,
    alsoReserved?: boolean,
    options?: any
  ) {
    return EventFinderApiFp(this.configuration)
      .eventFinderPausePost(
        startDate,
        location,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /event-finder/play
   * @param {string} startDate
   * @param {string} location Id of the Model we want to narrow the search to
   * @param {string} [endDate]
   * @param {string} [tag] Title of the Model we want to narrow the search to
   * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
   * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
   * @param {boolean} [alsoReserved] Also include reserved events
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventFinderApi
   */
  public eventFinderPlayPost(
    startDate: string,
    location: string,
    endDate?: string,
    tag?: string,
    weekDays?: Array<boolean>,
    times?: Array<number>,
    alsoReserved?: boolean,
    options?: any
  ) {
    return EventFinderApiFp(this.configuration)
      .eventFinderPlayPost(
        startDate,
        location,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /events/{eventId}
   * @param {string} startDate
   * @param {string} location Id of the Model we want to narrow the search to
   * @param {Event} event
   * @param {string} [endDate]
   * @param {string} [tag] Title of the Model we want to narrow the search to
   * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
   * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
   * @param {boolean} [alsoReserved] Also include reserved events
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventFinderApi
   */
  public eventFinderPut(
    startDate: string,
    location: string,
    event: Event,
    endDate?: string,
    tag?: string,
    weekDays?: Array<boolean>,
    times?: Array<number>,
    alsoReserved?: boolean,
    options?: any
  ) {
    return EventFinderApiFp(this.configuration)
      .eventFinderPut(
        startDate,
        location,
        event,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /event-finder/show
   * @param {string} startDate
   * @param {string} location Id of the Model we want to narrow the search to
   * @param {string} [endDate]
   * @param {string} [tag] Title of the Model we want to narrow the search to
   * @param {Array<boolean>} [weekDays] Array of weekdays, position 0 is Sunday, 1 is Monday, etc...
   * @param {Array<number>} [times] [startHour, startMinutes, endHour, endMinutes]
   * @param {boolean} [alsoReserved] Also include reserved events
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventFinderApi
   */
  public eventFinderShowPost(
    startDate: string,
    location: string,
    endDate?: string,
    tag?: string,
    weekDays?: Array<boolean>,
    times?: Array<number>,
    alsoReserved?: boolean,
    options?: any
  ) {
    return EventFinderApiFp(this.configuration)
      .eventFinderShowPost(
        startDate,
        location,
        endDate,
        tag,
        weekDays,
        times,
        alsoReserved,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /events/r/titles
   * @param {string} [location]
   * @param {string} [startDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventFinderApi
   */
  public eventsRTitlesGet(location?: string, startDate?: string, options?: any) {
    return EventFinderApiFp(this.configuration)
      .eventsRTitlesGet(location, startDate, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MetaDataApi - axios parameter creator
 * @export
 */
export const MetaDataApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /custom-fields/resource/{customizationResource}
     * @param {'customers'} customizationResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customFieldsResourceCustomizationResourceGet: async (
      customizationResource: 'customers',
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'customizationResource' is not null or undefined
      if (customizationResource === null || customizationResource === undefined) {
        throw new RequiredError(
          'customizationResource',
          'Required parameter customizationResource was null or undefined when calling customFieldsResourceCustomizationResourceGet.'
        );
      }
      const localVarPath = `/custom-fields/resource/{customizationResource}`.replace(
        `{${'customizationResource'}}`,
        encodeURIComponent(String(customizationResource))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /metas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    metasGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/metas`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /metas/{metaId}
     * @param {string} metaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    metasMetaIdDelete: async (metaId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'metaId' is not null or undefined
      if (metaId === null || metaId === undefined) {
        throw new RequiredError(
          'metaId',
          'Required parameter metaId was null or undefined when calling metasMetaIdDelete.'
        );
      }
      const localVarPath = `/metas/{metaId}`.replace(
        `{${'metaId'}}`,
        encodeURIComponent(String(metaId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /metas/{metaId}
     * @param {string} metaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    metasMetaIdGet: async (metaId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'metaId' is not null or undefined
      if (metaId === null || metaId === undefined) {
        throw new RequiredError(
          'metaId',
          'Required parameter metaId was null or undefined when calling metasMetaIdGet.'
        );
      }
      const localVarPath = `/metas/{metaId}`.replace(
        `{${'metaId'}}`,
        encodeURIComponent(String(metaId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /metas/{metaId}
     * @param {string} metaId
     * @param {MetaData} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    metasMetaIdPut: async (
      metaId: string,
      body: MetaData,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'metaId' is not null or undefined
      if (metaId === null || metaId === undefined) {
        throw new RequiredError(
          'metaId',
          'Required parameter metaId was null or undefined when calling metasMetaIdPut.'
        );
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling metasMetaIdPut.'
        );
      }
      const localVarPath = `/metas/{metaId}`.replace(
        `{${'metaId'}}`,
        encodeURIComponent(String(metaId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /metas/{metaId}/restore
     * @param {string} metaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    metasMetaIdRestorePost: async (metaId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'metaId' is not null or undefined
      if (metaId === null || metaId === undefined) {
        throw new RequiredError(
          'metaId',
          'Required parameter metaId was null or undefined when calling metasMetaIdRestorePost.'
        );
      }
      const localVarPath = `/metas/{metaId}/restore`.replace(
        `{${'metaId'}}`,
        encodeURIComponent(String(metaId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /metas
     * @param {MetaData} meta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    metasPost: async (meta: MetaData, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'meta' is not null or undefined
      if (meta === null || meta === undefined) {
        throw new RequiredError(
          'meta',
          'Required parameter meta was null or undefined when calling metasPost.'
        );
      }
      const localVarPath = `/metas`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof meta !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(meta !== undefined ? meta : {})
        : meta || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MetaDataApi - functional programming interface
 * @export
 */
export const MetaDataApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /custom-fields/resource/{customizationResource}
     * @param {'customers'} customizationResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customFieldsResourceCustomizationResourceGet(
      customizationResource: 'customers',
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaAssignment>> {
      const localVarAxiosArgs = await MetaDataApiAxiosParamCreator(
        configuration
      ).customFieldsResourceCustomizationResourceGet(customizationResource, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /metas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async metasGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MetaData>>> {
      const localVarAxiosArgs = await MetaDataApiAxiosParamCreator(configuration).metasGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /metas/{metaId}
     * @param {string} metaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async metasMetaIdDelete(
      metaId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await MetaDataApiAxiosParamCreator(configuration).metasMetaIdDelete(
        metaId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /metas/{metaId}
     * @param {string} metaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async metasMetaIdGet(
      metaId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaData>> {
      const localVarAxiosArgs = await MetaDataApiAxiosParamCreator(configuration).metasMetaIdGet(
        metaId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /metas/{metaId}
     * @param {string} metaId
     * @param {MetaData} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async metasMetaIdPut(
      metaId: string,
      body: MetaData,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaData>> {
      const localVarAxiosArgs = await MetaDataApiAxiosParamCreator(configuration).metasMetaIdPut(
        metaId,
        body,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /metas/{metaId}/restore
     * @param {string} metaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async metasMetaIdRestorePost(
      metaId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await MetaDataApiAxiosParamCreator(
        configuration
      ).metasMetaIdRestorePost(metaId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /metas
     * @param {MetaData} meta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async metasPost(
      meta: MetaData,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaData>> {
      const localVarAxiosArgs = await MetaDataApiAxiosParamCreator(configuration).metasPost(
        meta,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * MetaDataApi - factory interface
 * @export
 */
export const MetaDataApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary /custom-fields/resource/{customizationResource}
     * @param {'customers'} customizationResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customFieldsResourceCustomizationResourceGet(
      customizationResource: 'customers',
      options?: any
    ): AxiosPromise<MetaAssignment> {
      return MetaDataApiFp(configuration)
        .customFieldsResourceCustomizationResourceGet(customizationResource, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /metas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    metasGet(options?: any): AxiosPromise<Array<MetaData>> {
      return MetaDataApiFp(configuration)
        .metasGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /metas/{metaId}
     * @param {string} metaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    metasMetaIdDelete(metaId: string, options?: any): AxiosPromise<void> {
      return MetaDataApiFp(configuration)
        .metasMetaIdDelete(metaId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /metas/{metaId}
     * @param {string} metaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    metasMetaIdGet(metaId: string, options?: any): AxiosPromise<MetaData> {
      return MetaDataApiFp(configuration)
        .metasMetaIdGet(metaId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /metas/{metaId}
     * @param {string} metaId
     * @param {MetaData} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    metasMetaIdPut(metaId: string, body: MetaData, options?: any): AxiosPromise<MetaData> {
      return MetaDataApiFp(configuration)
        .metasMetaIdPut(metaId, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /metas/{metaId}/restore
     * @param {string} metaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    metasMetaIdRestorePost(metaId: string, options?: any): AxiosPromise<void> {
      return MetaDataApiFp(configuration)
        .metasMetaIdRestorePost(metaId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /metas
     * @param {MetaData} meta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    metasPost(meta: MetaData, options?: any): AxiosPromise<MetaData> {
      return MetaDataApiFp(configuration)
        .metasPost(meta, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * MetaDataApi - object-oriented interface
 * @export
 * @class MetaDataApi
 * @extends {BaseAPI}
 */
export class MetaDataApi extends BaseAPI {
  /**
   *
   * @summary /custom-fields/resource/{customizationResource}
   * @param {'customers'} customizationResource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetaDataApi
   */
  public customFieldsResourceCustomizationResourceGet(
    customizationResource: 'customers',
    options?: any
  ) {
    return MetaDataApiFp(this.configuration)
      .customFieldsResourceCustomizationResourceGet(customizationResource, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /metas
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetaDataApi
   */
  public metasGet(options?: any) {
    return MetaDataApiFp(this.configuration)
      .metasGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /metas/{metaId}
   * @param {string} metaId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetaDataApi
   */
  public metasMetaIdDelete(metaId: string, options?: any) {
    return MetaDataApiFp(this.configuration)
      .metasMetaIdDelete(metaId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /metas/{metaId}
   * @param {string} metaId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetaDataApi
   */
  public metasMetaIdGet(metaId: string, options?: any) {
    return MetaDataApiFp(this.configuration)
      .metasMetaIdGet(metaId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /metas/{metaId}
   * @param {string} metaId
   * @param {MetaData} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetaDataApi
   */
  public metasMetaIdPut(metaId: string, body: MetaData, options?: any) {
    return MetaDataApiFp(this.configuration)
      .metasMetaIdPut(metaId, body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /metas/{metaId}/restore
   * @param {string} metaId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetaDataApi
   */
  public metasMetaIdRestorePost(metaId: string, options?: any) {
    return MetaDataApiFp(this.configuration)
      .metasMetaIdRestorePost(metaId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /metas
   * @param {MetaData} meta
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetaDataApi
   */
  public metasPost(meta: MetaData, options?: any) {
    return MetaDataApiFp(this.configuration)
      .metasPost(meta, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ModelApi - axios parameter creator
 * @export
 */
export const ModelApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /models
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modelsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/models`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /models/{modelId}
     * @param {string} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modelsModelIdDelete: async (modelId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling modelsModelIdDelete.'
        );
      }
      const localVarPath = `/models/{modelId}`.replace(
        `{${'modelId'}}`,
        encodeURIComponent(String(modelId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /models/{modelId}/events
     * @param {string} modelId
     * @param {InlineObject2} event
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modelsModelIdEventsPost: async (
      modelId: string,
      event: InlineObject2,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling modelsModelIdEventsPost.'
        );
      }
      // verify required parameter 'event' is not null or undefined
      if (event === null || event === undefined) {
        throw new RequiredError(
          'event',
          'Required parameter event was null or undefined when calling modelsModelIdEventsPost.'
        );
      }
      const localVarPath = `/models/{modelId}/events`.replace(
        `{${'modelId'}}`,
        encodeURIComponent(String(modelId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof event !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(event !== undefined ? event : {})
        : event || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /models/{modelId}
     * @param {string} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modelsModelIdGet: async (modelId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling modelsModelIdGet.'
        );
      }
      const localVarPath = `/models/{modelId}`.replace(
        `{${'modelId'}}`,
        encodeURIComponent(String(modelId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /models/{modelId}
     * @param {string} modelId
     * @param {Model} model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modelsModelIdPut: async (
      modelId: string,
      model: Model,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling modelsModelIdPut.'
        );
      }
      // verify required parameter 'model' is not null or undefined
      if (model === null || model === undefined) {
        throw new RequiredError(
          'model',
          'Required parameter model was null or undefined when calling modelsModelIdPut.'
        );
      }
      const localVarPath = `/models/{modelId}`.replace(
        `{${'modelId'}}`,
        encodeURIComponent(String(modelId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof model !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(model !== undefined ? model : {})
        : model || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/{placeId}/models
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdModelsGet: async (placeId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling placesPlaceIdModelsGet.'
        );
      }
      const localVarPath = `/places/{placeId}/models`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/{placeId}/models
     * @param {string} placeId
     * @param {Model} model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdModelsPost: async (
      placeId: string,
      model: Model,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling placesPlaceIdModelsPost.'
        );
      }
      // verify required parameter 'model' is not null or undefined
      if (model === null || model === undefined) {
        throw new RequiredError(
          'model',
          'Required parameter model was null or undefined when calling placesPlaceIdModelsPost.'
        );
      }
      const localVarPath = `/places/{placeId}/models`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof model !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(model !== undefined ? model : {})
        : model || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/{placeId}/models/r/temp
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdModelsRTempDelete: async (
      placeId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling placesPlaceIdModelsRTempDelete.'
        );
      }
      const localVarPath = `/places/{placeId}/models/r/temp`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/{placeId}/models/r/temp
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdModelsRTempGet: async (
      placeId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling placesPlaceIdModelsRTempGet.'
        );
      }
      const localVarPath = `/places/{placeId}/models/r/temp`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/{placeId}/models/r/temp
     * @param {string} placeId
     * @param {Model} model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdModelsRTempPut: async (
      placeId: string,
      model: Model,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling placesPlaceIdModelsRTempPut.'
        );
      }
      // verify required parameter 'model' is not null or undefined
      if (model === null || model === undefined) {
        throw new RequiredError(
          'model',
          'Required parameter model was null or undefined when calling placesPlaceIdModelsRTempPut.'
        );
      }
      const localVarPath = `/places/{placeId}/models/r/temp`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof model !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(model !== undefined ? model : {})
        : model || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ModelApi - functional programming interface
 * @export
 */
export const ModelApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /models
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async modelsGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Model>>> {
      const localVarAxiosArgs = await ModelApiAxiosParamCreator(configuration).modelsGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /models/{modelId}
     * @param {string} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async modelsModelIdDelete(
      modelId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await ModelApiAxiosParamCreator(configuration).modelsModelIdDelete(
        modelId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /models/{modelId}/events
     * @param {string} modelId
     * @param {InlineObject2} event
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async modelsModelIdEventsPost(
      modelId: string,
      event: InlineObject2,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
      const localVarAxiosArgs = await ModelApiAxiosParamCreator(
        configuration
      ).modelsModelIdEventsPost(modelId, event, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /models/{modelId}
     * @param {string} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async modelsModelIdGet(
      modelId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Model>> {
      const localVarAxiosArgs = await ModelApiAxiosParamCreator(configuration).modelsModelIdGet(
        modelId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /models/{modelId}
     * @param {string} modelId
     * @param {Model} model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async modelsModelIdPut(
      modelId: string,
      model: Model,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Model>> {
      const localVarAxiosArgs = await ModelApiAxiosParamCreator(configuration).modelsModelIdPut(
        modelId,
        model,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/{placeId}/models
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesPlaceIdModelsGet(
      placeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Model>>> {
      const localVarAxiosArgs = await ModelApiAxiosParamCreator(
        configuration
      ).placesPlaceIdModelsGet(placeId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/{placeId}/models
     * @param {string} placeId
     * @param {Model} model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesPlaceIdModelsPost(
      placeId: string,
      model: Model,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Model>> {
      const localVarAxiosArgs = await ModelApiAxiosParamCreator(
        configuration
      ).placesPlaceIdModelsPost(placeId, model, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/{placeId}/models/r/temp
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesPlaceIdModelsRTempDelete(
      placeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await ModelApiAxiosParamCreator(
        configuration
      ).placesPlaceIdModelsRTempDelete(placeId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/{placeId}/models/r/temp
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesPlaceIdModelsRTempGet(
      placeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Model>> {
      const localVarAxiosArgs = await ModelApiAxiosParamCreator(
        configuration
      ).placesPlaceIdModelsRTempGet(placeId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/{placeId}/models/r/temp
     * @param {string} placeId
     * @param {Model} model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesPlaceIdModelsRTempPut(
      placeId: string,
      model: Model,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Model>> {
      const localVarAxiosArgs = await ModelApiAxiosParamCreator(
        configuration
      ).placesPlaceIdModelsRTempPut(placeId, model, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ModelApi - factory interface
 * @export
 */
export const ModelApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary /models
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modelsGet(options?: any): AxiosPromise<Array<Model>> {
      return ModelApiFp(configuration)
        .modelsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /models/{modelId}
     * @param {string} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modelsModelIdDelete(modelId: string, options?: any): AxiosPromise<void> {
      return ModelApiFp(configuration)
        .modelsModelIdDelete(modelId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /models/{modelId}/events
     * @param {string} modelId
     * @param {InlineObject2} event
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modelsModelIdEventsPost(
      modelId: string,
      event: InlineObject2,
      options?: any
    ): AxiosPromise<Event> {
      return ModelApiFp(configuration)
        .modelsModelIdEventsPost(modelId, event, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /models/{modelId}
     * @param {string} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modelsModelIdGet(modelId: string, options?: any): AxiosPromise<Model> {
      return ModelApiFp(configuration)
        .modelsModelIdGet(modelId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /models/{modelId}
     * @param {string} modelId
     * @param {Model} model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modelsModelIdPut(modelId: string, model: Model, options?: any): AxiosPromise<Model> {
      return ModelApiFp(configuration)
        .modelsModelIdPut(modelId, model, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/{placeId}/models
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdModelsGet(placeId: string, options?: any): AxiosPromise<Array<Model>> {
      return ModelApiFp(configuration)
        .placesPlaceIdModelsGet(placeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/{placeId}/models
     * @param {string} placeId
     * @param {Model} model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdModelsPost(placeId: string, model: Model, options?: any): AxiosPromise<Model> {
      return ModelApiFp(configuration)
        .placesPlaceIdModelsPost(placeId, model, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/{placeId}/models/r/temp
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdModelsRTempDelete(placeId: string, options?: any): AxiosPromise<void> {
      return ModelApiFp(configuration)
        .placesPlaceIdModelsRTempDelete(placeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/{placeId}/models/r/temp
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdModelsRTempGet(placeId: string, options?: any): AxiosPromise<Model> {
      return ModelApiFp(configuration)
        .placesPlaceIdModelsRTempGet(placeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/{placeId}/models/r/temp
     * @param {string} placeId
     * @param {Model} model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdModelsRTempPut(placeId: string, model: Model, options?: any): AxiosPromise<Model> {
      return ModelApiFp(configuration)
        .placesPlaceIdModelsRTempPut(placeId, model, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ModelApi - object-oriented interface
 * @export
 * @class ModelApi
 * @extends {BaseAPI}
 */
export class ModelApi extends BaseAPI {
  /**
   *
   * @summary /models
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelApi
   */
  public modelsGet(options?: any) {
    return ModelApiFp(this.configuration)
      .modelsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /models/{modelId}
   * @param {string} modelId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelApi
   */
  public modelsModelIdDelete(modelId: string, options?: any) {
    return ModelApiFp(this.configuration)
      .modelsModelIdDelete(modelId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /models/{modelId}/events
   * @param {string} modelId
   * @param {InlineObject2} event
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelApi
   */
  public modelsModelIdEventsPost(modelId: string, event: InlineObject2, options?: any) {
    return ModelApiFp(this.configuration)
      .modelsModelIdEventsPost(modelId, event, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /models/{modelId}
   * @param {string} modelId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelApi
   */
  public modelsModelIdGet(modelId: string, options?: any) {
    return ModelApiFp(this.configuration)
      .modelsModelIdGet(modelId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /models/{modelId}
   * @param {string} modelId
   * @param {Model} model
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelApi
   */
  public modelsModelIdPut(modelId: string, model: Model, options?: any) {
    return ModelApiFp(this.configuration)
      .modelsModelIdPut(modelId, model, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/{placeId}/models
   * @param {string} placeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelApi
   */
  public placesPlaceIdModelsGet(placeId: string, options?: any) {
    return ModelApiFp(this.configuration)
      .placesPlaceIdModelsGet(placeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/{placeId}/models
   * @param {string} placeId
   * @param {Model} model
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelApi
   */
  public placesPlaceIdModelsPost(placeId: string, model: Model, options?: any) {
    return ModelApiFp(this.configuration)
      .placesPlaceIdModelsPost(placeId, model, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/{placeId}/models/r/temp
   * @param {string} placeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelApi
   */
  public placesPlaceIdModelsRTempDelete(placeId: string, options?: any) {
    return ModelApiFp(this.configuration)
      .placesPlaceIdModelsRTempDelete(placeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/{placeId}/models/r/temp
   * @param {string} placeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelApi
   */
  public placesPlaceIdModelsRTempGet(placeId: string, options?: any) {
    return ModelApiFp(this.configuration)
      .placesPlaceIdModelsRTempGet(placeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/{placeId}/models/r/temp
   * @param {string} placeId
   * @param {Model} model
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelApi
   */
  public placesPlaceIdModelsRTempPut(placeId: string, model: Model, options?: any) {
    return ModelApiFp(this.configuration)
      .placesPlaceIdModelsRTempPut(placeId, model, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NoteApi - axios parameter creator
 * @export
 */
export const NoteApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /places/{placeId}/notes
     * @param {string} placeId
     * @param {Note} note
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdNotesPost: async (
      placeId: string,
      note: Note,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling placesPlaceIdNotesPost.'
        );
      }
      // verify required parameter 'note' is not null or undefined
      if (note === null || note === undefined) {
        throw new RequiredError(
          'note',
          'Required parameter note was null or undefined when calling placesPlaceIdNotesPost.'
        );
      }
      const localVarPath = `/places/{placeId}/notes`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof note !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(note !== undefined ? note : {})
        : note || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NoteApi - functional programming interface
 * @export
 */
export const NoteApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /places/{placeId}/notes
     * @param {string} placeId
     * @param {Note} note
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesPlaceIdNotesPost(
      placeId: string,
      note: Note,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
      const localVarAxiosArgs = await NoteApiAxiosParamCreator(
        configuration
      ).placesPlaceIdNotesPost(placeId, note, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * NoteApi - factory interface
 * @export
 */
export const NoteApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary /places/{placeId}/notes
     * @param {string} placeId
     * @param {Note} note
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdNotesPost(placeId: string, note: Note, options?: any): AxiosPromise<Note> {
      return NoteApiFp(configuration)
        .placesPlaceIdNotesPost(placeId, note, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * NoteApi - object-oriented interface
 * @export
 * @class NoteApi
 * @extends {BaseAPI}
 */
export class NoteApi extends BaseAPI {
  /**
   *
   * @summary /places/{placeId}/notes
   * @param {string} placeId
   * @param {Note} note
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoteApi
   */
  public placesPlaceIdNotesPost(placeId: string, note: Note, options?: any) {
    return NoteApiFp(this.configuration)
      .placesPlaceIdNotesPost(placeId, note, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/notifications`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
      const localVarAxiosArgs = await NotificationApiAxiosParamCreator(
        configuration
      ).notificationsGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary /notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsGet(options?: any): AxiosPromise<InlineResponse2004> {
      return NotificationApiFp(configuration)
        .notificationsGet(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
  /**
   *
   * @summary /notifications
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public notificationsGet(options?: any) {
    return NotificationApiFp(this.configuration)
      .notificationsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OperatorsApi - axios parameter creator
 * @export
 */
export const OperatorsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /operators
     * @param {string} [start] If you send \&#39;start\&#39; you must also send \&#39;end\&#39;, when passing \&#39;start\&#39;/\&#39;end\&#39; the response will only contain the users that are available in the given time interval
     * @param {string} [end] If you send \&#39;end\&#39; you must also send \&#39;start\&#39;, when passing \&#39;start\&#39;/\&#39;end\&#39; the response will only contain the users that are available in the given time interval
     * @param {string} [locationId] Only list operators that have access to this location
     * @param {string} [eventId] When passing eventId you must not pass \&#39;start\&#39;/\&#39;end\&#39; nor \&#39;locationId\&#39; as they will be automatically filled based on the event data, the effect is the same as passing them singularly
     * @param {Array<string>} [dontFilter] When asking for a filtered list you can specify an array of operator ids that should not be filtered from the list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    operatorsGet: async (
      start?: string,
      end?: string,
      locationId?: string,
      eventId?: string,
      dontFilter?: Array<string>,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/operators`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] =
          (start as any) instanceof Date ? (start as any).toISOString().substr(0, 10) : start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] =
          (end as any) instanceof Date ? (end as any).toISOString().substr(0, 10) : end;
      }

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (eventId !== undefined) {
        localVarQueryParameter['eventId'] = eventId;
      }

      if (dontFilter) {
        localVarQueryParameter['dontFilter'] = dontFilter.join(COLLECTION_FORMATS.csv);
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OperatorsApi - functional programming interface
 * @export
 */
export const OperatorsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /operators
     * @param {string} [start] If you send \&#39;start\&#39; you must also send \&#39;end\&#39;, when passing \&#39;start\&#39;/\&#39;end\&#39; the response will only contain the users that are available in the given time interval
     * @param {string} [end] If you send \&#39;end\&#39; you must also send \&#39;start\&#39;, when passing \&#39;start\&#39;/\&#39;end\&#39; the response will only contain the users that are available in the given time interval
     * @param {string} [locationId] Only list operators that have access to this location
     * @param {string} [eventId] When passing eventId you must not pass \&#39;start\&#39;/\&#39;end\&#39; nor \&#39;locationId\&#39; as they will be automatically filled based on the event data, the effect is the same as passing them singularly
     * @param {Array<string>} [dontFilter] When asking for a filtered list you can specify an array of operator ids that should not be filtered from the list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async operatorsGet(
      start?: string,
      end?: string,
      locationId?: string,
      eventId?: string,
      dontFilter?: Array<string>,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InlineResponse2005>>
    > {
      const localVarAxiosArgs = await OperatorsApiAxiosParamCreator(configuration).operatorsGet(
        start,
        end,
        locationId,
        eventId,
        dontFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * OperatorsApi - factory interface
 * @export
 */
export const OperatorsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary /operators
     * @param {string} [start] If you send \&#39;start\&#39; you must also send \&#39;end\&#39;, when passing \&#39;start\&#39;/\&#39;end\&#39; the response will only contain the users that are available in the given time interval
     * @param {string} [end] If you send \&#39;end\&#39; you must also send \&#39;start\&#39;, when passing \&#39;start\&#39;/\&#39;end\&#39; the response will only contain the users that are available in the given time interval
     * @param {string} [locationId] Only list operators that have access to this location
     * @param {string} [eventId] When passing eventId you must not pass \&#39;start\&#39;/\&#39;end\&#39; nor \&#39;locationId\&#39; as they will be automatically filled based on the event data, the effect is the same as passing them singularly
     * @param {Array<string>} [dontFilter] When asking for a filtered list you can specify an array of operator ids that should not be filtered from the list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    operatorsGet(
      start?: string,
      end?: string,
      locationId?: string,
      eventId?: string,
      dontFilter?: Array<string>,
      options?: any
    ): AxiosPromise<Array<InlineResponse2005>> {
      return OperatorsApiFp(configuration)
        .operatorsGet(start, end, locationId, eventId, dontFilter, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OperatorsApi - object-oriented interface
 * @export
 * @class OperatorsApi
 * @extends {BaseAPI}
 */
export class OperatorsApi extends BaseAPI {
  /**
   *
   * @summary /operators
   * @param {string} [start] If you send \&#39;start\&#39; you must also send \&#39;end\&#39;, when passing \&#39;start\&#39;/\&#39;end\&#39; the response will only contain the users that are available in the given time interval
   * @param {string} [end] If you send \&#39;end\&#39; you must also send \&#39;start\&#39;, when passing \&#39;start\&#39;/\&#39;end\&#39; the response will only contain the users that are available in the given time interval
   * @param {string} [locationId] Only list operators that have access to this location
   * @param {string} [eventId] When passing eventId you must not pass \&#39;start\&#39;/\&#39;end\&#39; nor \&#39;locationId\&#39; as they will be automatically filled based on the event data, the effect is the same as passing them singularly
   * @param {Array<string>} [dontFilter] When asking for a filtered list you can specify an array of operator ids that should not be filtered from the list
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorsApi
   */
  public operatorsGet(
    start?: string,
    end?: string,
    locationId?: string,
    eventId?: string,
    dontFilter?: Array<string>,
    options?: any
  ) {
    return OperatorsApiFp(this.configuration)
      .operatorsGet(start, end, locationId, eventId, dontFilter, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PictureApi - axios parameter creator
 * @export
 */
export const PictureApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /pictures
     * @param {'model' | 'place' | 'logo' | 'product'} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    picturesGet: async (
      type?: 'model' | 'place' | 'logo' | 'product',
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/pictures`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /pictures/{pictureId}
     * @param {string} pictureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    picturesPictureIdDelete: async (pictureId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'pictureId' is not null or undefined
      if (pictureId === null || pictureId === undefined) {
        throw new RequiredError(
          'pictureId',
          'Required parameter pictureId was null or undefined when calling picturesPictureIdDelete.'
        );
      }
      const localVarPath = `/pictures/{pictureId}`.replace(
        `{${'pictureId'}}`,
        encodeURIComponent(String(pictureId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /pictures/{pictureId}
     * @param {string} pictureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    picturesPictureIdGet: async (pictureId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'pictureId' is not null or undefined
      if (pictureId === null || pictureId === undefined) {
        throw new RequiredError(
          'pictureId',
          'Required parameter pictureId was null or undefined when calling picturesPictureIdGet.'
        );
      }
      const localVarPath = `/pictures/{pictureId}`.replace(
        `{${'pictureId'}}`,
        encodeURIComponent(String(pictureId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /pictures
     * @param {'model' | 'place' | 'logo' | 'product'} [type]
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    picturesPost: async (
      type?: 'model' | 'place' | 'logo' | 'product',
      file?: any,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/pictures`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PictureApi - functional programming interface
 * @export
 */
export const PictureApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /pictures
     * @param {'model' | 'place' | 'logo' | 'product'} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async picturesGet(
      type?: 'model' | 'place' | 'logo' | 'product',
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Picture>>> {
      const localVarAxiosArgs = await PictureApiAxiosParamCreator(configuration).picturesGet(
        type,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /pictures/{pictureId}
     * @param {string} pictureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async picturesPictureIdDelete(
      pictureId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PictureApiAxiosParamCreator(
        configuration
      ).picturesPictureIdDelete(pictureId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /pictures/{pictureId}
     * @param {string} pictureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async picturesPictureIdGet(
      pictureId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PictureApiAxiosParamCreator(
        configuration
      ).picturesPictureIdGet(pictureId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /pictures
     * @param {'model' | 'place' | 'logo' | 'product'} [type]
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async picturesPost(
      type?: 'model' | 'place' | 'logo' | 'product',
      file?: any,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Picture>> {
      const localVarAxiosArgs = await PictureApiAxiosParamCreator(configuration).picturesPost(
        type,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * PictureApi - factory interface
 * @export
 */
export const PictureApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary /pictures
     * @param {'model' | 'place' | 'logo' | 'product'} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    picturesGet(
      type?: 'model' | 'place' | 'logo' | 'product',
      options?: any
    ): AxiosPromise<Array<Picture>> {
      return PictureApiFp(configuration)
        .picturesGet(type, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /pictures/{pictureId}
     * @param {string} pictureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    picturesPictureIdDelete(pictureId: string, options?: any): AxiosPromise<void> {
      return PictureApiFp(configuration)
        .picturesPictureIdDelete(pictureId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /pictures/{pictureId}
     * @param {string} pictureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    picturesPictureIdGet(pictureId: string, options?: any): AxiosPromise<void> {
      return PictureApiFp(configuration)
        .picturesPictureIdGet(pictureId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /pictures
     * @param {'model' | 'place' | 'logo' | 'product'} [type]
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    picturesPost(
      type?: 'model' | 'place' | 'logo' | 'product',
      file?: any,
      options?: any
    ): AxiosPromise<Picture> {
      return PictureApiFp(configuration)
        .picturesPost(type, file, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PictureApi - object-oriented interface
 * @export
 * @class PictureApi
 * @extends {BaseAPI}
 */
export class PictureApi extends BaseAPI {
  /**
   *
   * @summary /pictures
   * @param {'model' | 'place' | 'logo' | 'product'} [type]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PictureApi
   */
  public picturesGet(type?: 'model' | 'place' | 'logo' | 'product', options?: any) {
    return PictureApiFp(this.configuration)
      .picturesGet(type, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /pictures/{pictureId}
   * @param {string} pictureId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PictureApi
   */
  public picturesPictureIdDelete(pictureId: string, options?: any) {
    return PictureApiFp(this.configuration)
      .picturesPictureIdDelete(pictureId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /pictures/{pictureId}
   * @param {string} pictureId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PictureApi
   */
  public picturesPictureIdGet(pictureId: string, options?: any) {
    return PictureApiFp(this.configuration)
      .picturesPictureIdGet(pictureId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /pictures
   * @param {'model' | 'place' | 'logo' | 'product'} [type]
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PictureApi
   */
  public picturesPost(type?: 'model' | 'place' | 'logo' | 'product', file?: any, options?: any) {
    return PictureApiFp(this.configuration)
      .picturesPost(type, file, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PlaceApi - axios parameter creator
 * @export
 */
export const PlaceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdFiltersDelete: async (
      placeId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling calendarPlaceIdFiltersDelete.'
        );
      }
      const localVarPath = `/calendar/{placeId}/filters`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdFiltersGet: async (placeId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling calendarPlaceIdFiltersGet.'
        );
      }
      const localVarPath = `/calendar/{placeId}/filters`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {CalendarFilter} filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdFiltersPut: async (
      placeId: string,
      filter: CalendarFilter,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling calendarPlaceIdFiltersPut.'
        );
      }
      // verify required parameter 'filter' is not null or undefined
      if (filter === null || filter === undefined) {
        throw new RequiredError(
          'filter',
          'Required parameter filter was null or undefined when calling calendarPlaceIdFiltersPut.'
        );
      }
      const localVarPath = `/calendar/{placeId}/filters`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof filter !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(filter !== undefined ? filter : {})
        : filter || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /calendar/{placeId}
     * @param {string} placeId
     * @param {string} start
     * @param {string} end
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdGet: async (
      placeId: string,
      start: string,
      end: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling calendarPlaceIdGet.'
        );
      }
      // verify required parameter 'start' is not null or undefined
      if (start === null || start === undefined) {
        throw new RequiredError(
          'start',
          'Required parameter start was null or undefined when calling calendarPlaceIdGet.'
        );
      }
      // verify required parameter 'end' is not null or undefined
      if (end === null || end === undefined) {
        throw new RequiredError(
          'end',
          'Required parameter end was null or undefined when calling calendarPlaceIdGet.'
        );
      }
      const localVarPath = `/calendar/{placeId}`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] =
          (start as any) instanceof Date ? (start as any).toISOString().substr(0, 10) : start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] =
          (end as any) instanceof Date ? (end as any).toISOString().substr(0, 10) : end;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/times
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdTimesGet: async (placeId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling calendarPlaceIdTimesGet.'
        );
      }
      const localVarPath = `/calendar/{placeId}/times`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/times
     * @param {string} placeId
     * @param {CalendarTime} time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdTimesPut: async (
      placeId: string,
      time: CalendarTime,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling calendarPlaceIdTimesPut.'
        );
      }
      // verify required parameter 'time' is not null or undefined
      if (time === null || time === undefined) {
        throw new RequiredError(
          'time',
          'Required parameter time was null or undefined when calling calendarPlaceIdTimesPut.'
        );
      }
      const localVarPath = `/calendar/{placeId}/times`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof time !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(time !== undefined ? time : {})
        : time || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/places`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/{placeId}
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdDelete: async (placeId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling placesPlaceIdDelete.'
        );
      }
      const localVarPath = `/places/{placeId}`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/{placeId}/events
     * @param {string} placeId
     * @param {string} [date]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [language]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdEventsGet: async (
      placeId: string,
      date?: string,
      start?: string,
      end?: string,
      language?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling placesPlaceIdEventsGet.'
        );
      }
      const localVarPath = `/places/{placeId}/events`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] =
          (date as any) instanceof Date ? (date as any).toISOString() : date;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] =
          (start as any) instanceof Date ? (start as any).toISOString() : start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] =
          (end as any) instanceof Date ? (end as any).toISOString() : end;
      }

      if (language !== undefined) {
        localVarQueryParameter['language'] = language;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/{placeId}
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdGet: async (placeId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling placesPlaceIdGet.'
        );
      }
      const localVarPath = `/places/{placeId}`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/{placeId}/models
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdModelsGet: async (placeId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling placesPlaceIdModelsGet.'
        );
      }
      const localVarPath = `/places/{placeId}/models`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/{placeId}/models
     * @param {string} placeId
     * @param {Model} model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdModelsPost: async (
      placeId: string,
      model: Model,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling placesPlaceIdModelsPost.'
        );
      }
      // verify required parameter 'model' is not null or undefined
      if (model === null || model === undefined) {
        throw new RequiredError(
          'model',
          'Required parameter model was null or undefined when calling placesPlaceIdModelsPost.'
        );
      }
      const localVarPath = `/places/{placeId}/models`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof model !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(model !== undefined ? model : {})
        : model || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/{placeId}/models/r/temp
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdModelsRTempDelete: async (
      placeId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling placesPlaceIdModelsRTempDelete.'
        );
      }
      const localVarPath = `/places/{placeId}/models/r/temp`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/{placeId}/models/r/temp
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdModelsRTempGet: async (
      placeId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling placesPlaceIdModelsRTempGet.'
        );
      }
      const localVarPath = `/places/{placeId}/models/r/temp`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/{placeId}/models/r/temp
     * @param {string} placeId
     * @param {Model} model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdModelsRTempPut: async (
      placeId: string,
      model: Model,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling placesPlaceIdModelsRTempPut.'
        );
      }
      // verify required parameter 'model' is not null or undefined
      if (model === null || model === undefined) {
        throw new RequiredError(
          'model',
          'Required parameter model was null or undefined when calling placesPlaceIdModelsRTempPut.'
        );
      }
      const localVarPath = `/places/{placeId}/models/r/temp`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof model !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(model !== undefined ? model : {})
        : model || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/{placeId}/notes
     * @param {string} placeId
     * @param {Note} note
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdNotesPost: async (
      placeId: string,
      note: Note,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling placesPlaceIdNotesPost.'
        );
      }
      // verify required parameter 'note' is not null or undefined
      if (note === null || note === undefined) {
        throw new RequiredError(
          'note',
          'Required parameter note was null or undefined when calling placesPlaceIdNotesPost.'
        );
      }
      const localVarPath = `/places/{placeId}/notes`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof note !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(note !== undefined ? note : {})
        : note || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/{placeId}
     * @param {string} placeId
     * @param {Place} place
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdPut: async (
      placeId: string,
      place: Place,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling placesPlaceIdPut.'
        );
      }
      // verify required parameter 'place' is not null or undefined
      if (place === null || place === undefined) {
        throw new RequiredError(
          'place',
          'Required parameter place was null or undefined when calling placesPlaceIdPut.'
        );
      }
      const localVarPath = `/places/{placeId}`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof place !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(place !== undefined ? place : {})
        : place || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/{placeId}/reservations
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdReservationsGet: async (
      placeId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling placesPlaceIdReservationsGet.'
        );
      }
      const localVarPath = `/places/{placeId}/reservations`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places
     * @param {Place} place
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPost: async (place: Place, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'place' is not null or undefined
      if (place === null || place === undefined) {
        throw new RequiredError(
          'place',
          'Required parameter place was null or undefined when calling placesPost.'
        );
      }
      const localVarPath = `/places`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof place !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(place !== undefined ? place : {})
        : place || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/r/temp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesRTempDelete: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/places/r/temp`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/r/temp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesRTempGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/places/r/temp`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/r/temp
     * @param {Place} place
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesRTempPut: async (place: Place, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'place' is not null or undefined
      if (place === null || place === undefined) {
        throw new RequiredError(
          'place',
          'Required parameter place was null or undefined when calling placesRTempPut.'
        );
      }
      const localVarPath = `/places/r/temp`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof place !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(place !== undefined ? place : {})
        : place || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PlaceApi - functional programming interface
 * @export
 */
export const PlaceApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calendarPlaceIdFiltersDelete(
      placeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(
        configuration
      ).calendarPlaceIdFiltersDelete(placeId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calendarPlaceIdFiltersGet(
      placeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarFilter>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(
        configuration
      ).calendarPlaceIdFiltersGet(placeId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {CalendarFilter} filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calendarPlaceIdFiltersPut(
      placeId: string,
      filter: CalendarFilter,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarFilter>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(
        configuration
      ).calendarPlaceIdFiltersPut(placeId, filter, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /calendar/{placeId}
     * @param {string} placeId
     * @param {string} start
     * @param {string} end
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calendarPlaceIdGet(
      placeId: string,
      start: string,
      end: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(configuration).calendarPlaceIdGet(
        placeId,
        start,
        end,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/times
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calendarPlaceIdTimesGet(
      placeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarTime>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(
        configuration
      ).calendarPlaceIdTimesGet(placeId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /calendar/{placeId}/times
     * @param {string} placeId
     * @param {CalendarTime} time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calendarPlaceIdTimesPut(
      placeId: string,
      time: CalendarTime,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarTime>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(
        configuration
      ).calendarPlaceIdTimesPut(placeId, time, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Place>>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(configuration).placesGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/{placeId}
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesPlaceIdDelete(
      placeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(configuration).placesPlaceIdDelete(
        placeId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/{placeId}/events
     * @param {string} placeId
     * @param {string} [date]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [language]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesPlaceIdEventsGet(
      placeId: string,
      date?: string,
      start?: string,
      end?: string,
      language?: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InlineResponse2006>>
    > {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(
        configuration
      ).placesPlaceIdEventsGet(placeId, date, start, end, language, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/{placeId}
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesPlaceIdGet(
      placeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Place>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(configuration).placesPlaceIdGet(
        placeId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/{placeId}/models
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesPlaceIdModelsGet(
      placeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Model>>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(
        configuration
      ).placesPlaceIdModelsGet(placeId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/{placeId}/models
     * @param {string} placeId
     * @param {Model} model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesPlaceIdModelsPost(
      placeId: string,
      model: Model,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Model>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(
        configuration
      ).placesPlaceIdModelsPost(placeId, model, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/{placeId}/models/r/temp
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesPlaceIdModelsRTempDelete(
      placeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(
        configuration
      ).placesPlaceIdModelsRTempDelete(placeId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/{placeId}/models/r/temp
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesPlaceIdModelsRTempGet(
      placeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Model>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(
        configuration
      ).placesPlaceIdModelsRTempGet(placeId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/{placeId}/models/r/temp
     * @param {string} placeId
     * @param {Model} model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesPlaceIdModelsRTempPut(
      placeId: string,
      model: Model,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Model>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(
        configuration
      ).placesPlaceIdModelsRTempPut(placeId, model, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/{placeId}/notes
     * @param {string} placeId
     * @param {Note} note
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesPlaceIdNotesPost(
      placeId: string,
      note: Note,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(
        configuration
      ).placesPlaceIdNotesPost(placeId, note, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/{placeId}
     * @param {string} placeId
     * @param {Place} place
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesPlaceIdPut(
      placeId: string,
      place: Place,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Place>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(configuration).placesPlaceIdPut(
        placeId,
        place,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/{placeId}/reservations
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesPlaceIdReservationsGet(
      placeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Reservation>>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(
        configuration
      ).placesPlaceIdReservationsGet(placeId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places
     * @param {Place} place
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesPost(
      place: Place,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Place>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(configuration).placesPost(
        place,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/r/temp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesRTempDelete(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(configuration).placesRTempDelete(
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/r/temp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesRTempGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Place>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(configuration).placesRTempGet(
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/r/temp
     * @param {Place} place
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesRTempPut(
      place: Place,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Place>> {
      const localVarAxiosArgs = await PlaceApiAxiosParamCreator(configuration).placesRTempPut(
        place,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * PlaceApi - factory interface
 * @export
 */
export const PlaceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdFiltersDelete(placeId: string, options?: any): AxiosPromise<void> {
      return PlaceApiFp(configuration)
        .calendarPlaceIdFiltersDelete(placeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdFiltersGet(placeId: string, options?: any): AxiosPromise<CalendarFilter> {
      return PlaceApiFp(configuration)
        .calendarPlaceIdFiltersGet(placeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /calendar/{placeId}/filters
     * @param {string} placeId
     * @param {CalendarFilter} filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdFiltersPut(
      placeId: string,
      filter: CalendarFilter,
      options?: any
    ): AxiosPromise<CalendarFilter> {
      return PlaceApiFp(configuration)
        .calendarPlaceIdFiltersPut(placeId, filter, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /calendar/{placeId}
     * @param {string} placeId
     * @param {string} start
     * @param {string} end
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdGet(
      placeId: string,
      start: string,
      end: string,
      options?: any
    ): AxiosPromise<InlineResponse2001> {
      return PlaceApiFp(configuration)
        .calendarPlaceIdGet(placeId, start, end, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /calendar/{placeId}/times
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdTimesGet(placeId: string, options?: any): AxiosPromise<CalendarTime> {
      return PlaceApiFp(configuration)
        .calendarPlaceIdTimesGet(placeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /calendar/{placeId}/times
     * @param {string} placeId
     * @param {CalendarTime} time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calendarPlaceIdTimesPut(
      placeId: string,
      time: CalendarTime,
      options?: any
    ): AxiosPromise<CalendarTime> {
      return PlaceApiFp(configuration)
        .calendarPlaceIdTimesPut(placeId, time, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesGet(options?: any): AxiosPromise<Array<Place>> {
      return PlaceApiFp(configuration)
        .placesGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/{placeId}
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdDelete(placeId: string, options?: any): AxiosPromise<void> {
      return PlaceApiFp(configuration)
        .placesPlaceIdDelete(placeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/{placeId}/events
     * @param {string} placeId
     * @param {string} [date]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [language]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdEventsGet(
      placeId: string,
      date?: string,
      start?: string,
      end?: string,
      language?: string,
      options?: any
    ): AxiosPromise<Array<InlineResponse2006>> {
      return PlaceApiFp(configuration)
        .placesPlaceIdEventsGet(placeId, date, start, end, language, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/{placeId}
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdGet(placeId: string, options?: any): AxiosPromise<Place> {
      return PlaceApiFp(configuration)
        .placesPlaceIdGet(placeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/{placeId}/models
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdModelsGet(placeId: string, options?: any): AxiosPromise<Array<Model>> {
      return PlaceApiFp(configuration)
        .placesPlaceIdModelsGet(placeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/{placeId}/models
     * @param {string} placeId
     * @param {Model} model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdModelsPost(placeId: string, model: Model, options?: any): AxiosPromise<Model> {
      return PlaceApiFp(configuration)
        .placesPlaceIdModelsPost(placeId, model, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/{placeId}/models/r/temp
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdModelsRTempDelete(placeId: string, options?: any): AxiosPromise<void> {
      return PlaceApiFp(configuration)
        .placesPlaceIdModelsRTempDelete(placeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/{placeId}/models/r/temp
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdModelsRTempGet(placeId: string, options?: any): AxiosPromise<Model> {
      return PlaceApiFp(configuration)
        .placesPlaceIdModelsRTempGet(placeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/{placeId}/models/r/temp
     * @param {string} placeId
     * @param {Model} model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdModelsRTempPut(placeId: string, model: Model, options?: any): AxiosPromise<Model> {
      return PlaceApiFp(configuration)
        .placesPlaceIdModelsRTempPut(placeId, model, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/{placeId}/notes
     * @param {string} placeId
     * @param {Note} note
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdNotesPost(placeId: string, note: Note, options?: any): AxiosPromise<Note> {
      return PlaceApiFp(configuration)
        .placesPlaceIdNotesPost(placeId, note, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/{placeId}
     * @param {string} placeId
     * @param {Place} place
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdPut(placeId: string, place: Place, options?: any): AxiosPromise<Place> {
      return PlaceApiFp(configuration)
        .placesPlaceIdPut(placeId, place, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/{placeId}/reservations
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdReservationsGet(placeId: string, options?: any): AxiosPromise<Array<Reservation>> {
      return PlaceApiFp(configuration)
        .placesPlaceIdReservationsGet(placeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places
     * @param {Place} place
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPost(place: Place, options?: any): AxiosPromise<Place> {
      return PlaceApiFp(configuration)
        .placesPost(place, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/r/temp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesRTempDelete(options?: any): AxiosPromise<void> {
      return PlaceApiFp(configuration)
        .placesRTempDelete(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/r/temp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesRTempGet(options?: any): AxiosPromise<Place> {
      return PlaceApiFp(configuration)
        .placesRTempGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/r/temp
     * @param {Place} place
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesRTempPut(place: Place, options?: any): AxiosPromise<Place> {
      return PlaceApiFp(configuration)
        .placesRTempPut(place, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PlaceApi - object-oriented interface
 * @export
 * @class PlaceApi
 * @extends {BaseAPI}
 */
export class PlaceApi extends BaseAPI {
  /**
   *
   * @summary /calendar/{placeId}/filters
   * @param {string} placeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public calendarPlaceIdFiltersDelete(placeId: string, options?: any) {
    return PlaceApiFp(this.configuration)
      .calendarPlaceIdFiltersDelete(placeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /calendar/{placeId}/filters
   * @param {string} placeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public calendarPlaceIdFiltersGet(placeId: string, options?: any) {
    return PlaceApiFp(this.configuration)
      .calendarPlaceIdFiltersGet(placeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /calendar/{placeId}/filters
   * @param {string} placeId
   * @param {CalendarFilter} filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public calendarPlaceIdFiltersPut(placeId: string, filter: CalendarFilter, options?: any) {
    return PlaceApiFp(this.configuration)
      .calendarPlaceIdFiltersPut(placeId, filter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /calendar/{placeId}
   * @param {string} placeId
   * @param {string} start
   * @param {string} end
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public calendarPlaceIdGet(placeId: string, start: string, end: string, options?: any) {
    return PlaceApiFp(this.configuration)
      .calendarPlaceIdGet(placeId, start, end, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /calendar/{placeId}/times
   * @param {string} placeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public calendarPlaceIdTimesGet(placeId: string, options?: any) {
    return PlaceApiFp(this.configuration)
      .calendarPlaceIdTimesGet(placeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /calendar/{placeId}/times
   * @param {string} placeId
   * @param {CalendarTime} time
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public calendarPlaceIdTimesPut(placeId: string, time: CalendarTime, options?: any) {
    return PlaceApiFp(this.configuration)
      .calendarPlaceIdTimesPut(placeId, time, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placesGet(options?: any) {
    return PlaceApiFp(this.configuration)
      .placesGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/{placeId}
   * @param {string} placeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placesPlaceIdDelete(placeId: string, options?: any) {
    return PlaceApiFp(this.configuration)
      .placesPlaceIdDelete(placeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/{placeId}/events
   * @param {string} placeId
   * @param {string} [date]
   * @param {string} [start]
   * @param {string} [end]
   * @param {string} [language]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placesPlaceIdEventsGet(
    placeId: string,
    date?: string,
    start?: string,
    end?: string,
    language?: string,
    options?: any
  ) {
    return PlaceApiFp(this.configuration)
      .placesPlaceIdEventsGet(placeId, date, start, end, language, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/{placeId}
   * @param {string} placeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placesPlaceIdGet(placeId: string, options?: any) {
    return PlaceApiFp(this.configuration)
      .placesPlaceIdGet(placeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/{placeId}/models
   * @param {string} placeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placesPlaceIdModelsGet(placeId: string, options?: any) {
    return PlaceApiFp(this.configuration)
      .placesPlaceIdModelsGet(placeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/{placeId}/models
   * @param {string} placeId
   * @param {Model} model
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placesPlaceIdModelsPost(placeId: string, model: Model, options?: any) {
    return PlaceApiFp(this.configuration)
      .placesPlaceIdModelsPost(placeId, model, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/{placeId}/models/r/temp
   * @param {string} placeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placesPlaceIdModelsRTempDelete(placeId: string, options?: any) {
    return PlaceApiFp(this.configuration)
      .placesPlaceIdModelsRTempDelete(placeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/{placeId}/models/r/temp
   * @param {string} placeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placesPlaceIdModelsRTempGet(placeId: string, options?: any) {
    return PlaceApiFp(this.configuration)
      .placesPlaceIdModelsRTempGet(placeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/{placeId}/models/r/temp
   * @param {string} placeId
   * @param {Model} model
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placesPlaceIdModelsRTempPut(placeId: string, model: Model, options?: any) {
    return PlaceApiFp(this.configuration)
      .placesPlaceIdModelsRTempPut(placeId, model, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/{placeId}/notes
   * @param {string} placeId
   * @param {Note} note
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placesPlaceIdNotesPost(placeId: string, note: Note, options?: any) {
    return PlaceApiFp(this.configuration)
      .placesPlaceIdNotesPost(placeId, note, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/{placeId}
   * @param {string} placeId
   * @param {Place} place
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placesPlaceIdPut(placeId: string, place: Place, options?: any) {
    return PlaceApiFp(this.configuration)
      .placesPlaceIdPut(placeId, place, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/{placeId}/reservations
   * @param {string} placeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placesPlaceIdReservationsGet(placeId: string, options?: any) {
    return PlaceApiFp(this.configuration)
      .placesPlaceIdReservationsGet(placeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places
   * @param {Place} place
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placesPost(place: Place, options?: any) {
    return PlaceApiFp(this.configuration)
      .placesPost(place, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/r/temp
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placesRTempDelete(options?: any) {
    return PlaceApiFp(this.configuration)
      .placesRTempDelete(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/r/temp
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placesRTempGet(options?: any) {
    return PlaceApiFp(this.configuration)
      .placesRTempGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/r/temp
   * @param {Place} place
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placesRTempPut(place: Place, options?: any) {
    return PlaceApiFp(this.configuration)
      .placesRTempPut(place, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ProposalApi - axios parameter creator
 * @export
 */
export const ProposalApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /proposals/{proposalId}/accept
     * @param {string} proposalId
     * @param {InlineObject3} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proposalsProposalIdAcceptPost: async (
      proposalId: string,
      data: InlineObject3,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'proposalId' is not null or undefined
      if (proposalId === null || proposalId === undefined) {
        throw new RequiredError(
          'proposalId',
          'Required parameter proposalId was null or undefined when calling proposalsProposalIdAcceptPost.'
        );
      }
      // verify required parameter 'data' is not null or undefined
      if (data === null || data === undefined) {
        throw new RequiredError(
          'data',
          'Required parameter data was null or undefined when calling proposalsProposalIdAcceptPost.'
        );
      }
      const localVarPath = `/proposals/{proposalId}/accept`.replace(
        `{${'proposalId'}}`,
        encodeURIComponent(String(proposalId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof data !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(data !== undefined ? data : {})
        : data || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /proposals/{proposalId}
     * @param {string} proposalId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proposalsProposalIdDelete: async (
      proposalId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'proposalId' is not null or undefined
      if (proposalId === null || proposalId === undefined) {
        throw new RequiredError(
          'proposalId',
          'Required parameter proposalId was null or undefined when calling proposalsProposalIdDelete.'
        );
      }
      const localVarPath = `/proposals/{proposalId}`.replace(
        `{${'proposalId'}}`,
        encodeURIComponent(String(proposalId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /proposals/{proposalId}
     * @param {string} proposalId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proposalsProposalIdGet: async (proposalId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'proposalId' is not null or undefined
      if (proposalId === null || proposalId === undefined) {
        throw new RequiredError(
          'proposalId',
          'Required parameter proposalId was null or undefined when calling proposalsProposalIdGet.'
        );
      }
      const localVarPath = `/proposals/{proposalId}`.replace(
        `{${'proposalId'}}`,
        encodeURIComponent(String(proposalId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /proposals/{proposalId}
     * @param {string} proposalId
     * @param {Proposal} proposal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proposalsProposalIdPut: async (
      proposalId: string,
      proposal: Proposal,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'proposalId' is not null or undefined
      if (proposalId === null || proposalId === undefined) {
        throw new RequiredError(
          'proposalId',
          'Required parameter proposalId was null or undefined when calling proposalsProposalIdPut.'
        );
      }
      // verify required parameter 'proposal' is not null or undefined
      if (proposal === null || proposal === undefined) {
        throw new RequiredError(
          'proposal',
          'Required parameter proposal was null or undefined when calling proposalsProposalIdPut.'
        );
      }
      const localVarPath = `/proposals/{proposalId}`.replace(
        `{${'proposalId'}}`,
        encodeURIComponent(String(proposalId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof proposal !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(proposal !== undefined ? proposal : {})
        : proposal || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /proposals/{proposalId}/reject
     * @param {string} proposalId
     * @param {InlineObject4} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proposalsProposalIdRejectPost: async (
      proposalId: string,
      data: InlineObject4,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'proposalId' is not null or undefined
      if (proposalId === null || proposalId === undefined) {
        throw new RequiredError(
          'proposalId',
          'Required parameter proposalId was null or undefined when calling proposalsProposalIdRejectPost.'
        );
      }
      // verify required parameter 'data' is not null or undefined
      if (data === null || data === undefined) {
        throw new RequiredError(
          'data',
          'Required parameter data was null or undefined when calling proposalsProposalIdRejectPost.'
        );
      }
      const localVarPath = `/proposals/{proposalId}/reject`.replace(
        `{${'proposalId'}}`,
        encodeURIComponent(String(proposalId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof data !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(data !== undefined ? data : {})
        : data || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProposalApi - functional programming interface
 * @export
 */
export const ProposalApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /proposals/{proposalId}/accept
     * @param {string} proposalId
     * @param {InlineObject3} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async proposalsProposalIdAcceptPost(
      proposalId: string,
      data: InlineObject3,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reservation>> {
      const localVarAxiosArgs = await ProposalApiAxiosParamCreator(
        configuration
      ).proposalsProposalIdAcceptPost(proposalId, data, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /proposals/{proposalId}
     * @param {string} proposalId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async proposalsProposalIdDelete(
      proposalId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await ProposalApiAxiosParamCreator(
        configuration
      ).proposalsProposalIdDelete(proposalId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /proposals/{proposalId}
     * @param {string} proposalId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async proposalsProposalIdGet(
      proposalId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Proposal>> {
      const localVarAxiosArgs = await ProposalApiAxiosParamCreator(
        configuration
      ).proposalsProposalIdGet(proposalId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /proposals/{proposalId}
     * @param {string} proposalId
     * @param {Proposal} proposal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async proposalsProposalIdPut(
      proposalId: string,
      proposal: Proposal,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Proposal>> {
      const localVarAxiosArgs = await ProposalApiAxiosParamCreator(
        configuration
      ).proposalsProposalIdPut(proposalId, proposal, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /proposals/{proposalId}/reject
     * @param {string} proposalId
     * @param {InlineObject4} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async proposalsProposalIdRejectPost(
      proposalId: string,
      data: InlineObject4,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await ProposalApiAxiosParamCreator(
        configuration
      ).proposalsProposalIdRejectPost(proposalId, data, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ProposalApi - factory interface
 * @export
 */
export const ProposalApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary /proposals/{proposalId}/accept
     * @param {string} proposalId
     * @param {InlineObject3} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proposalsProposalIdAcceptPost(
      proposalId: string,
      data: InlineObject3,
      options?: any
    ): AxiosPromise<Reservation> {
      return ProposalApiFp(configuration)
        .proposalsProposalIdAcceptPost(proposalId, data, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /proposals/{proposalId}
     * @param {string} proposalId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proposalsProposalIdDelete(proposalId: string, options?: any): AxiosPromise<void> {
      return ProposalApiFp(configuration)
        .proposalsProposalIdDelete(proposalId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /proposals/{proposalId}
     * @param {string} proposalId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proposalsProposalIdGet(proposalId: string, options?: any): AxiosPromise<Proposal> {
      return ProposalApiFp(configuration)
        .proposalsProposalIdGet(proposalId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /proposals/{proposalId}
     * @param {string} proposalId
     * @param {Proposal} proposal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proposalsProposalIdPut(
      proposalId: string,
      proposal: Proposal,
      options?: any
    ): AxiosPromise<Proposal> {
      return ProposalApiFp(configuration)
        .proposalsProposalIdPut(proposalId, proposal, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /proposals/{proposalId}/reject
     * @param {string} proposalId
     * @param {InlineObject4} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proposalsProposalIdRejectPost(
      proposalId: string,
      data: InlineObject4,
      options?: any
    ): AxiosPromise<void> {
      return ProposalApiFp(configuration)
        .proposalsProposalIdRejectPost(proposalId, data, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ProposalApi - object-oriented interface
 * @export
 * @class ProposalApi
 * @extends {BaseAPI}
 */
export class ProposalApi extends BaseAPI {
  /**
   *
   * @summary /proposals/{proposalId}/accept
   * @param {string} proposalId
   * @param {InlineObject3} data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProposalApi
   */
  public proposalsProposalIdAcceptPost(proposalId: string, data: InlineObject3, options?: any) {
    return ProposalApiFp(this.configuration)
      .proposalsProposalIdAcceptPost(proposalId, data, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /proposals/{proposalId}
   * @param {string} proposalId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProposalApi
   */
  public proposalsProposalIdDelete(proposalId: string, options?: any) {
    return ProposalApiFp(this.configuration)
      .proposalsProposalIdDelete(proposalId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /proposals/{proposalId}
   * @param {string} proposalId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProposalApi
   */
  public proposalsProposalIdGet(proposalId: string, options?: any) {
    return ProposalApiFp(this.configuration)
      .proposalsProposalIdGet(proposalId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /proposals/{proposalId}
   * @param {string} proposalId
   * @param {Proposal} proposal
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProposalApi
   */
  public proposalsProposalIdPut(proposalId: string, proposal: Proposal, options?: any) {
    return ProposalApiFp(this.configuration)
      .proposalsProposalIdPut(proposalId, proposal, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /proposals/{proposalId}/reject
   * @param {string} proposalId
   * @param {InlineObject4} data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProposalApi
   */
  public proposalsProposalIdRejectPost(proposalId: string, data: InlineObject4, options?: any) {
    return ProposalApiFp(this.configuration)
      .proposalsProposalIdRejectPost(proposalId, data, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ReservationApi - axios parameter creator
 * @export
 */
export const ReservationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /events/{eventId}/reservations
     * @param {string} eventId
     * @param {Reservation} reservation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsEventIdReservationsPost: async (
      eventId: string,
      reservation: Reservation,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'eventId' is not null or undefined
      if (eventId === null || eventId === undefined) {
        throw new RequiredError(
          'eventId',
          'Required parameter eventId was null or undefined when calling eventsEventIdReservationsPost.'
        );
      }
      // verify required parameter 'reservation' is not null or undefined
      if (reservation === null || reservation === undefined) {
        throw new RequiredError(
          'reservation',
          'Required parameter reservation was null or undefined when calling eventsEventIdReservationsPost.'
        );
      }
      const localVarPath = `/events/{eventId}/reservations`.replace(
        `{${'eventId'}}`,
        encodeURIComponent(String(eventId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof reservation !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(reservation !== undefined ? reservation : {})
        : reservation || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /places/{placeId}/reservations
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdReservationsGet: async (
      placeId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling placesPlaceIdReservationsGet.'
        );
      }
      const localVarPath = `/places/{placeId}/reservations`.replace(
        `{${'placeId'}}`,
        encodeURIComponent(String(placeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /reservations/{reservationId}/cellar-note
     * @param {string} reservationId
     * @param {InlineObject5} cellarNote
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationsReservationIdCellarNotePatch: async (
      reservationId: string,
      cellarNote: InlineObject5,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'reservationId' is not null or undefined
      if (reservationId === null || reservationId === undefined) {
        throw new RequiredError(
          'reservationId',
          'Required parameter reservationId was null or undefined when calling reservationsReservationIdCellarNotePatch.'
        );
      }
      // verify required parameter 'cellarNote' is not null or undefined
      if (cellarNote === null || cellarNote === undefined) {
        throw new RequiredError(
          'cellarNote',
          'Required parameter cellarNote was null or undefined when calling reservationsReservationIdCellarNotePatch.'
        );
      }
      const localVarPath = `/reservations/{reservationId}/cellar-note`.replace(
        `{${'reservationId'}}`,
        encodeURIComponent(String(reservationId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof cellarNote !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cellarNote !== undefined ? cellarNote : {})
        : cellarNote || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /reservations/{reservationId}
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationsReservationIdDelete: async (
      reservationId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'reservationId' is not null or undefined
      if (reservationId === null || reservationId === undefined) {
        throw new RequiredError(
          'reservationId',
          'Required parameter reservationId was null or undefined when calling reservationsReservationIdDelete.'
        );
      }
      const localVarPath = `/reservations/{reservationId}`.replace(
        `{${'reservationId'}}`,
        encodeURIComponent(String(reservationId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /reservations/{reservationId}/emails/confirmation
     * @param {string} reservationId
     * @param {InlineObject6} message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationsReservationIdEmailsConfirmationPost: async (
      reservationId: string,
      message: InlineObject6,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'reservationId' is not null or undefined
      if (reservationId === null || reservationId === undefined) {
        throw new RequiredError(
          'reservationId',
          'Required parameter reservationId was null or undefined when calling reservationsReservationIdEmailsConfirmationPost.'
        );
      }
      // verify required parameter 'message' is not null or undefined
      if (message === null || message === undefined) {
        throw new RequiredError(
          'message',
          'Required parameter message was null or undefined when calling reservationsReservationIdEmailsConfirmationPost.'
        );
      }
      const localVarPath = `/reservations/{reservationId}/emails/confirmation`.replace(
        `{${'reservationId'}}`,
        encodeURIComponent(String(reservationId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof message !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(message !== undefined ? message : {})
        : message || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /reservations/{reservationId}/emails/finalization
     * @param {string} reservationId
     * @param {InlineObject7} message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationsReservationIdEmailsFinalizationPost: async (
      reservationId: string,
      message: InlineObject7,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'reservationId' is not null or undefined
      if (reservationId === null || reservationId === undefined) {
        throw new RequiredError(
          'reservationId',
          'Required parameter reservationId was null or undefined when calling reservationsReservationIdEmailsFinalizationPost.'
        );
      }
      // verify required parameter 'message' is not null or undefined
      if (message === null || message === undefined) {
        throw new RequiredError(
          'message',
          'Required parameter message was null or undefined when calling reservationsReservationIdEmailsFinalizationPost.'
        );
      }
      const localVarPath = `/reservations/{reservationId}/emails/finalization`.replace(
        `{${'reservationId'}}`,
        encodeURIComponent(String(reservationId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof message !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(message !== undefined ? message : {})
        : message || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /reservations/{reservationId}
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationsReservationIdGet: async (
      reservationId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'reservationId' is not null or undefined
      if (reservationId === null || reservationId === undefined) {
        throw new RequiredError(
          'reservationId',
          'Required parameter reservationId was null or undefined when calling reservationsReservationIdGet.'
        );
      }
      const localVarPath = `/reservations/{reservationId}`.replace(
        `{${'reservationId'}}`,
        encodeURIComponent(String(reservationId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /reservations/{reservationId}/guests/down
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationsReservationIdGuestsDownPost: async (
      reservationId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'reservationId' is not null or undefined
      if (reservationId === null || reservationId === undefined) {
        throw new RequiredError(
          'reservationId',
          'Required parameter reservationId was null or undefined when calling reservationsReservationIdGuestsDownPost.'
        );
      }
      const localVarPath = `/reservations/{reservationId}/guests/down`.replace(
        `{${'reservationId'}}`,
        encodeURIComponent(String(reservationId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /reservations/{reservationId}/guests/up
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationsReservationIdGuestsUpPost: async (
      reservationId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'reservationId' is not null or undefined
      if (reservationId === null || reservationId === undefined) {
        throw new RequiredError(
          'reservationId',
          'Required parameter reservationId was null or undefined when calling reservationsReservationIdGuestsUpPost.'
        );
      }
      const localVarPath = `/reservations/{reservationId}/guests/up`.replace(
        `{${'reservationId'}}`,
        encodeURIComponent(String(reservationId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /reservations/{reservationId}/move
     * @param {string} reservationId
     * @param {InlineObject8} target
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationsReservationIdMovePost: async (
      reservationId: string,
      target: InlineObject8,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'reservationId' is not null or undefined
      if (reservationId === null || reservationId === undefined) {
        throw new RequiredError(
          'reservationId',
          'Required parameter reservationId was null or undefined when calling reservationsReservationIdMovePost.'
        );
      }
      // verify required parameter 'target' is not null or undefined
      if (target === null || target === undefined) {
        throw new RequiredError(
          'target',
          'Required parameter target was null or undefined when calling reservationsReservationIdMovePost.'
        );
      }
      const localVarPath = `/reservations/{reservationId}/move`.replace(
        `{${'reservationId'}}`,
        encodeURIComponent(String(reservationId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof target !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(target !== undefined ? target : {})
        : target || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /reservations/{reservationId}
     * @param {string} reservationId
     * @param {Reservation} reservation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationsReservationIdPut: async (
      reservationId: string,
      reservation: Reservation,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'reservationId' is not null or undefined
      if (reservationId === null || reservationId === undefined) {
        throw new RequiredError(
          'reservationId',
          'Required parameter reservationId was null or undefined when calling reservationsReservationIdPut.'
        );
      }
      // verify required parameter 'reservation' is not null or undefined
      if (reservation === null || reservation === undefined) {
        throw new RequiredError(
          'reservation',
          'Required parameter reservation was null or undefined when calling reservationsReservationIdPut.'
        );
      }
      const localVarPath = `/reservations/{reservationId}`.replace(
        `{${'reservationId'}}`,
        encodeURIComponent(String(reservationId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof reservation !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(reservation !== undefined ? reservation : {})
        : reservation || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /reservations/{reservationId}/status
     * @param {string} reservationId
     * @param {InlineObject9} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationsReservationIdStatusPatch: async (
      reservationId: string,
      status: InlineObject9,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'reservationId' is not null or undefined
      if (reservationId === null || reservationId === undefined) {
        throw new RequiredError(
          'reservationId',
          'Required parameter reservationId was null or undefined when calling reservationsReservationIdStatusPatch.'
        );
      }
      // verify required parameter 'status' is not null or undefined
      if (status === null || status === undefined) {
        throw new RequiredError(
          'status',
          'Required parameter status was null or undefined when calling reservationsReservationIdStatusPatch.'
        );
      }
      const localVarPath = `/reservations/{reservationId}/status`.replace(
        `{${'reservationId'}}`,
        encodeURIComponent(String(reservationId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof status !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(status !== undefined ? status : {})
        : status || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReservationApi - functional programming interface
 * @export
 */
export const ReservationApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /events/{eventId}/reservations
     * @param {string} eventId
     * @param {Reservation} reservation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventsEventIdReservationsPost(
      eventId: string,
      reservation: Reservation,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reservation>> {
      const localVarAxiosArgs = await ReservationApiAxiosParamCreator(
        configuration
      ).eventsEventIdReservationsPost(eventId, reservation, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /places/{placeId}/reservations
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placesPlaceIdReservationsGet(
      placeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Reservation>>> {
      const localVarAxiosArgs = await ReservationApiAxiosParamCreator(
        configuration
      ).placesPlaceIdReservationsGet(placeId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /reservations/{reservationId}/cellar-note
     * @param {string} reservationId
     * @param {InlineObject5} cellarNote
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reservationsReservationIdCellarNotePatch(
      reservationId: string,
      cellarNote: InlineObject5,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reservation>> {
      const localVarAxiosArgs = await ReservationApiAxiosParamCreator(
        configuration
      ).reservationsReservationIdCellarNotePatch(reservationId, cellarNote, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /reservations/{reservationId}
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reservationsReservationIdDelete(
      reservationId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await ReservationApiAxiosParamCreator(
        configuration
      ).reservationsReservationIdDelete(reservationId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /reservations/{reservationId}/emails/confirmation
     * @param {string} reservationId
     * @param {InlineObject6} message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reservationsReservationIdEmailsConfirmationPost(
      reservationId: string,
      message: InlineObject6,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await ReservationApiAxiosParamCreator(
        configuration
      ).reservationsReservationIdEmailsConfirmationPost(reservationId, message, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /reservations/{reservationId}/emails/finalization
     * @param {string} reservationId
     * @param {InlineObject7} message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reservationsReservationIdEmailsFinalizationPost(
      reservationId: string,
      message: InlineObject7,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await ReservationApiAxiosParamCreator(
        configuration
      ).reservationsReservationIdEmailsFinalizationPost(reservationId, message, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /reservations/{reservationId}
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reservationsReservationIdGet(
      reservationId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reservation>> {
      const localVarAxiosArgs = await ReservationApiAxiosParamCreator(
        configuration
      ).reservationsReservationIdGet(reservationId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /reservations/{reservationId}/guests/down
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reservationsReservationIdGuestsDownPost(
      reservationId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reservation>> {
      const localVarAxiosArgs = await ReservationApiAxiosParamCreator(
        configuration
      ).reservationsReservationIdGuestsDownPost(reservationId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /reservations/{reservationId}/guests/up
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reservationsReservationIdGuestsUpPost(
      reservationId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reservation>> {
      const localVarAxiosArgs = await ReservationApiAxiosParamCreator(
        configuration
      ).reservationsReservationIdGuestsUpPost(reservationId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /reservations/{reservationId}/move
     * @param {string} reservationId
     * @param {InlineObject8} target
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reservationsReservationIdMovePost(
      reservationId: string,
      target: InlineObject8,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reservation>> {
      const localVarAxiosArgs = await ReservationApiAxiosParamCreator(
        configuration
      ).reservationsReservationIdMovePost(reservationId, target, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /reservations/{reservationId}
     * @param {string} reservationId
     * @param {Reservation} reservation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reservationsReservationIdPut(
      reservationId: string,
      reservation: Reservation,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reservation>> {
      const localVarAxiosArgs = await ReservationApiAxiosParamCreator(
        configuration
      ).reservationsReservationIdPut(reservationId, reservation, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /reservations/{reservationId}/status
     * @param {string} reservationId
     * @param {InlineObject9} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reservationsReservationIdStatusPatch(
      reservationId: string,
      status: InlineObject9,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reservation>> {
      const localVarAxiosArgs = await ReservationApiAxiosParamCreator(
        configuration
      ).reservationsReservationIdStatusPatch(reservationId, status, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ReservationApi - factory interface
 * @export
 */
export const ReservationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary /events/{eventId}/reservations
     * @param {string} eventId
     * @param {Reservation} reservation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsEventIdReservationsPost(
      eventId: string,
      reservation: Reservation,
      options?: any
    ): AxiosPromise<Reservation> {
      return ReservationApiFp(configuration)
        .eventsEventIdReservationsPost(eventId, reservation, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /places/{placeId}/reservations
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placesPlaceIdReservationsGet(placeId: string, options?: any): AxiosPromise<Array<Reservation>> {
      return ReservationApiFp(configuration)
        .placesPlaceIdReservationsGet(placeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /reservations/{reservationId}/cellar-note
     * @param {string} reservationId
     * @param {InlineObject5} cellarNote
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationsReservationIdCellarNotePatch(
      reservationId: string,
      cellarNote: InlineObject5,
      options?: any
    ): AxiosPromise<Reservation> {
      return ReservationApiFp(configuration)
        .reservationsReservationIdCellarNotePatch(reservationId, cellarNote, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /reservations/{reservationId}
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationsReservationIdDelete(reservationId: string, options?: any): AxiosPromise<void> {
      return ReservationApiFp(configuration)
        .reservationsReservationIdDelete(reservationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /reservations/{reservationId}/emails/confirmation
     * @param {string} reservationId
     * @param {InlineObject6} message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationsReservationIdEmailsConfirmationPost(
      reservationId: string,
      message: InlineObject6,
      options?: any
    ): AxiosPromise<void> {
      return ReservationApiFp(configuration)
        .reservationsReservationIdEmailsConfirmationPost(reservationId, message, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /reservations/{reservationId}/emails/finalization
     * @param {string} reservationId
     * @param {InlineObject7} message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationsReservationIdEmailsFinalizationPost(
      reservationId: string,
      message: InlineObject7,
      options?: any
    ): AxiosPromise<void> {
      return ReservationApiFp(configuration)
        .reservationsReservationIdEmailsFinalizationPost(reservationId, message, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /reservations/{reservationId}
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationsReservationIdGet(reservationId: string, options?: any): AxiosPromise<Reservation> {
      return ReservationApiFp(configuration)
        .reservationsReservationIdGet(reservationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /reservations/{reservationId}/guests/down
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationsReservationIdGuestsDownPost(
      reservationId: string,
      options?: any
    ): AxiosPromise<Reservation> {
      return ReservationApiFp(configuration)
        .reservationsReservationIdGuestsDownPost(reservationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /reservations/{reservationId}/guests/up
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationsReservationIdGuestsUpPost(
      reservationId: string,
      options?: any
    ): AxiosPromise<Reservation> {
      return ReservationApiFp(configuration)
        .reservationsReservationIdGuestsUpPost(reservationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /reservations/{reservationId}/move
     * @param {string} reservationId
     * @param {InlineObject8} target
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationsReservationIdMovePost(
      reservationId: string,
      target: InlineObject8,
      options?: any
    ): AxiosPromise<Reservation> {
      return ReservationApiFp(configuration)
        .reservationsReservationIdMovePost(reservationId, target, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /reservations/{reservationId}
     * @param {string} reservationId
     * @param {Reservation} reservation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationsReservationIdPut(
      reservationId: string,
      reservation: Reservation,
      options?: any
    ): AxiosPromise<Reservation> {
      return ReservationApiFp(configuration)
        .reservationsReservationIdPut(reservationId, reservation, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /reservations/{reservationId}/status
     * @param {string} reservationId
     * @param {InlineObject9} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationsReservationIdStatusPatch(
      reservationId: string,
      status: InlineObject9,
      options?: any
    ): AxiosPromise<Reservation> {
      return ReservationApiFp(configuration)
        .reservationsReservationIdStatusPatch(reservationId, status, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ReservationApi - object-oriented interface
 * @export
 * @class ReservationApi
 * @extends {BaseAPI}
 */
export class ReservationApi extends BaseAPI {
  /**
   *
   * @summary /events/{eventId}/reservations
   * @param {string} eventId
   * @param {Reservation} reservation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public eventsEventIdReservationsPost(eventId: string, reservation: Reservation, options?: any) {
    return ReservationApiFp(this.configuration)
      .eventsEventIdReservationsPost(eventId, reservation, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /places/{placeId}/reservations
   * @param {string} placeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public placesPlaceIdReservationsGet(placeId: string, options?: any) {
    return ReservationApiFp(this.configuration)
      .placesPlaceIdReservationsGet(placeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /reservations/{reservationId}/cellar-note
   * @param {string} reservationId
   * @param {InlineObject5} cellarNote
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationsReservationIdCellarNotePatch(
    reservationId: string,
    cellarNote: InlineObject5,
    options?: any
  ) {
    return ReservationApiFp(this.configuration)
      .reservationsReservationIdCellarNotePatch(reservationId, cellarNote, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /reservations/{reservationId}
   * @param {string} reservationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationsReservationIdDelete(reservationId: string, options?: any) {
    return ReservationApiFp(this.configuration)
      .reservationsReservationIdDelete(reservationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /reservations/{reservationId}/emails/confirmation
   * @param {string} reservationId
   * @param {InlineObject6} message
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationsReservationIdEmailsConfirmationPost(
    reservationId: string,
    message: InlineObject6,
    options?: any
  ) {
    return ReservationApiFp(this.configuration)
      .reservationsReservationIdEmailsConfirmationPost(reservationId, message, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /reservations/{reservationId}/emails/finalization
   * @param {string} reservationId
   * @param {InlineObject7} message
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationsReservationIdEmailsFinalizationPost(
    reservationId: string,
    message: InlineObject7,
    options?: any
  ) {
    return ReservationApiFp(this.configuration)
      .reservationsReservationIdEmailsFinalizationPost(reservationId, message, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /reservations/{reservationId}
   * @param {string} reservationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationsReservationIdGet(reservationId: string, options?: any) {
    return ReservationApiFp(this.configuration)
      .reservationsReservationIdGet(reservationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /reservations/{reservationId}/guests/down
   * @param {string} reservationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationsReservationIdGuestsDownPost(reservationId: string, options?: any) {
    return ReservationApiFp(this.configuration)
      .reservationsReservationIdGuestsDownPost(reservationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /reservations/{reservationId}/guests/up
   * @param {string} reservationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationsReservationIdGuestsUpPost(reservationId: string, options?: any) {
    return ReservationApiFp(this.configuration)
      .reservationsReservationIdGuestsUpPost(reservationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /reservations/{reservationId}/move
   * @param {string} reservationId
   * @param {InlineObject8} target
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationsReservationIdMovePost(
    reservationId: string,
    target: InlineObject8,
    options?: any
  ) {
    return ReservationApiFp(this.configuration)
      .reservationsReservationIdMovePost(reservationId, target, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /reservations/{reservationId}
   * @param {string} reservationId
   * @param {Reservation} reservation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationsReservationIdPut(
    reservationId: string,
    reservation: Reservation,
    options?: any
  ) {
    return ReservationApiFp(this.configuration)
      .reservationsReservationIdPut(reservationId, reservation, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /reservations/{reservationId}/status
   * @param {string} reservationId
   * @param {InlineObject9} status
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationsReservationIdStatusPatch(
    reservationId: string,
    status: InlineObject9,
    options?: any
  ) {
    return ReservationApiFp(this.configuration)
      .reservationsReservationIdStatusPatch(reservationId, status, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ReservationExtraApi - axios parameter creator
 * @export
 */
export const ReservationExtraApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /extras/{extraId}
     * @param {string} extraId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extrasExtraIdDelete: async (extraId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'extraId' is not null or undefined
      if (extraId === null || extraId === undefined) {
        throw new RequiredError(
          'extraId',
          'Required parameter extraId was null or undefined when calling extrasExtraIdDelete.'
        );
      }
      const localVarPath = `/extras/{extraId}`.replace(
        `{${'extraId'}}`,
        encodeURIComponent(String(extraId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /extras/{extraId}
     * @param {string} extraId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extrasExtraIdGet: async (extraId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'extraId' is not null or undefined
      if (extraId === null || extraId === undefined) {
        throw new RequiredError(
          'extraId',
          'Required parameter extraId was null or undefined when calling extrasExtraIdGet.'
        );
      }
      const localVarPath = `/extras/{extraId}`.replace(
        `{${'extraId'}}`,
        encodeURIComponent(String(extraId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /extras/{extraId}
     * @param {string} extraId
     * @param {ReservationExtra} extra
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extrasExtraIdPut: async (
      extraId: string,
      extra: ReservationExtra,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'extraId' is not null or undefined
      if (extraId === null || extraId === undefined) {
        throw new RequiredError(
          'extraId',
          'Required parameter extraId was null or undefined when calling extrasExtraIdPut.'
        );
      }
      // verify required parameter 'extra' is not null or undefined
      if (extra === null || extra === undefined) {
        throw new RequiredError(
          'extra',
          'Required parameter extra was null or undefined when calling extrasExtraIdPut.'
        );
      }
      const localVarPath = `/extras/{extraId}`.replace(
        `{${'extraId'}}`,
        encodeURIComponent(String(extraId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof extra !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(extra !== undefined ? extra : {})
        : extra || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /extras/{extraId}/restore
     * @param {string} extraId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extrasExtraIdRestorePost: async (extraId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'extraId' is not null or undefined
      if (extraId === null || extraId === undefined) {
        throw new RequiredError(
          'extraId',
          'Required parameter extraId was null or undefined when calling extrasExtraIdRestorePost.'
        );
      }
      const localVarPath = `/extras/{extraId}/restore`.replace(
        `{${'extraId'}}`,
        encodeURIComponent(String(extraId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /extras
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extrasGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/extras`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary /extras
     * @param {ReservationExtra} extra
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extrasPost: async (extra: ReservationExtra, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'extra' is not null or undefined
      if (extra === null || extra === undefined) {
        throw new RequiredError(
          'extra',
          'Required parameter extra was null or undefined when calling extrasPost.'
        );
      }
      const localVarPath = `/extras`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof extra !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(extra !== undefined ? extra : {})
        : extra || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReservationExtraApi - functional programming interface
 * @export
 */
export const ReservationExtraApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /extras/{extraId}
     * @param {string} extraId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async extrasExtraIdDelete(
      extraId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await ReservationExtraApiAxiosParamCreator(
        configuration
      ).extrasExtraIdDelete(extraId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /extras/{extraId}
     * @param {string} extraId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async extrasExtraIdGet(
      extraId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationExtra>> {
      const localVarAxiosArgs = await ReservationExtraApiAxiosParamCreator(
        configuration
      ).extrasExtraIdGet(extraId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /extras/{extraId}
     * @param {string} extraId
     * @param {ReservationExtra} extra
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async extrasExtraIdPut(
      extraId: string,
      extra: ReservationExtra,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationExtra>> {
      const localVarAxiosArgs = await ReservationExtraApiAxiosParamCreator(
        configuration
      ).extrasExtraIdPut(extraId, extra, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /extras/{extraId}/restore
     * @param {string} extraId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async extrasExtraIdRestorePost(
      extraId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await ReservationExtraApiAxiosParamCreator(
        configuration
      ).extrasExtraIdRestorePost(extraId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /extras
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async extrasGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReservationExtra>>
    > {
      const localVarAxiosArgs = await ReservationExtraApiAxiosParamCreator(configuration).extrasGet(
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary /extras
     * @param {ReservationExtra} extra
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async extrasPost(
      extra: ReservationExtra,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationExtra>> {
      const localVarAxiosArgs = await ReservationExtraApiAxiosParamCreator(
        configuration
      ).extrasPost(extra, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ReservationExtraApi - factory interface
 * @export
 */
export const ReservationExtraApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary /extras/{extraId}
     * @param {string} extraId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extrasExtraIdDelete(extraId: string, options?: any): AxiosPromise<void> {
      return ReservationExtraApiFp(configuration)
        .extrasExtraIdDelete(extraId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /extras/{extraId}
     * @param {string} extraId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extrasExtraIdGet(extraId: string, options?: any): AxiosPromise<ReservationExtra> {
      return ReservationExtraApiFp(configuration)
        .extrasExtraIdGet(extraId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /extras/{extraId}
     * @param {string} extraId
     * @param {ReservationExtra} extra
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extrasExtraIdPut(
      extraId: string,
      extra: ReservationExtra,
      options?: any
    ): AxiosPromise<ReservationExtra> {
      return ReservationExtraApiFp(configuration)
        .extrasExtraIdPut(extraId, extra, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /extras/{extraId}/restore
     * @param {string} extraId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extrasExtraIdRestorePost(extraId: string, options?: any): AxiosPromise<void> {
      return ReservationExtraApiFp(configuration)
        .extrasExtraIdRestorePost(extraId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /extras
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extrasGet(options?: any): AxiosPromise<Array<ReservationExtra>> {
      return ReservationExtraApiFp(configuration)
        .extrasGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary /extras
     * @param {ReservationExtra} extra
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extrasPost(extra: ReservationExtra, options?: any): AxiosPromise<ReservationExtra> {
      return ReservationExtraApiFp(configuration)
        .extrasPost(extra, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ReservationExtraApi - object-oriented interface
 * @export
 * @class ReservationExtraApi
 * @extends {BaseAPI}
 */
export class ReservationExtraApi extends BaseAPI {
  /**
   *
   * @summary /extras/{extraId}
   * @param {string} extraId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationExtraApi
   */
  public extrasExtraIdDelete(extraId: string, options?: any) {
    return ReservationExtraApiFp(this.configuration)
      .extrasExtraIdDelete(extraId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /extras/{extraId}
   * @param {string} extraId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationExtraApi
   */
  public extrasExtraIdGet(extraId: string, options?: any) {
    return ReservationExtraApiFp(this.configuration)
      .extrasExtraIdGet(extraId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /extras/{extraId}
   * @param {string} extraId
   * @param {ReservationExtra} extra
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationExtraApi
   */
  public extrasExtraIdPut(extraId: string, extra: ReservationExtra, options?: any) {
    return ReservationExtraApiFp(this.configuration)
      .extrasExtraIdPut(extraId, extra, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /extras/{extraId}/restore
   * @param {string} extraId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationExtraApi
   */
  public extrasExtraIdRestorePost(extraId: string, options?: any) {
    return ReservationExtraApiFp(this.configuration)
      .extrasExtraIdRestorePost(extraId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /extras
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationExtraApi
   */
  public extrasGet(options?: any) {
    return ReservationExtraApiFp(this.configuration)
      .extrasGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary /extras
   * @param {ReservationExtra} extra
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationExtraApi
   */
  public extrasPost(extra: ReservationExtra, options?: any) {
    return ReservationExtraApiFp(this.configuration)
      .extrasPost(extra, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TagApi - axios parameter creator
 * @export
 */
export const TagApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /tags/o/autocomplete
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsOAutocompleteGet: async (query?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/tags/o/autocomplete`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TagApi - functional programming interface
 * @export
 */
export const TagApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /tags/o/autocomplete
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagsOAutocompleteGet(
      query?: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InlineResponse2007>>
    > {
      const localVarAxiosArgs = await TagApiAxiosParamCreator(configuration).tagsOAutocompleteGet(
        query,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * TagApi - factory interface
 * @export
 */
export const TagApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary /tags/o/autocomplete
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsOAutocompleteGet(query?: string, options?: any): AxiosPromise<Array<InlineResponse2007>> {
      return TagApiFp(configuration)
        .tagsOAutocompleteGet(query, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TagApi - object-oriented interface
 * @export
 * @class TagApi
 * @extends {BaseAPI}
 */
export class TagApi extends BaseAPI {
  /**
   *
   * @summary /tags/o/autocomplete
   * @param {string} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApi
   */
  public tagsOAutocompleteGet(query?: string, options?: any) {
    return TagApiFp(this.configuration)
      .tagsOAutocompleteGet(query, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /users/me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersMeGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/users/me`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? await configuration.apiKey('authorization')
            : await configuration.apiKey;
        localVarHeaderParameter['authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary /users/me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersMeGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).usersMeGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary /users/me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersMeGet(options?: any): AxiosPromise<User> {
      return UserApiFp(configuration)
        .usersMeGet(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   *
   * @summary /users/me
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public usersMeGet(options?: any) {
    return UserApiFp(this.configuration)
      .usersMeGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
