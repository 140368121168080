import { ActionTypes, AuthState } from './types';
import { SET_USER } from './actions';

const initialState: AuthState = {};

export default (state = initialState, action: ActionTypes): AuthState => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user,
      };

    default: {
      return state;
    }
  }
};
