import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IntlMessageId } from 'lang/models';

export type FormattedMessageProps = {
  id?: IntlMessageId;
  defaultMessage?: string;
  values?: Record<string, React.ReactNode>;
  children?: () => React.ReactNode;
};

const IntlString = (props: FormattedMessageProps) => <FormattedMessage {...props} />;

export default IntlString;
