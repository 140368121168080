import { Middleware } from 'redux';
import { RootState } from '../store';
import { SET_USER } from './actions';
import { SetUserAction } from './types';
import { setAuthToken } from 'services/apiClientProvider';

// This middleware set the default bearer token in Axios when the user is set
const middleware: Middleware<{}, RootState> = (store) => (next) => (action) => {
  if (action.type === SET_USER) {
    const { user } = action as SetUserAction;
    setAuthToken(user?.token);
  }

  return next(action);
};

export default middleware;
