import React, { Component } from 'react';
import { IntlStringProps, withIntlString } from 'components/IntlStringHoc';
import { Button } from 'primereact/button';
import IntlString from 'components/IntlString';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ModelExtras, ReservationExtra, ReservationExtraApi } from 'services/apiService';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'state/store';

// import './TabExtras.scss';
import { IApi, IState, useApi } from 'services/apiStateManager';
import getClient from 'services/apiClientProvider';
import BlockLoader from 'components/blockLoader/BlockLoader';
import EditExtraDialog from './editExtraDialog/EditExtraDialog';
import ConfirmDialog from 'components/confirmDialog/ConfirmDialog';

const connector = connect((state: RootState) => ({
  languageTranslations: state.languageTranslations,
}));

type Props = ConnectedProps<typeof connector> & IntlStringProps;

type State = {
  allExtras: IState<ReservationExtra[]>;
  showEditExtraDialog: boolean;
  selectedExtra?: ReservationExtra;
  showDeleteConfirmDialog: boolean;
};

class Extras extends Component<Props> {
  extrasManager: IApi<ReservationExtra[], null> = useApi<ReservationExtra[], null>(
    this,
    'allExtras',
    () => getClient(ReservationExtraApi).extrasGet()
  );

  extrasManagerDelete: IApi<void, ReservationExtra> = useApi<void, ReservationExtra>(
    this,
    '',
    (extra) => getClient(ReservationExtraApi).extrasExtraIdDelete(extra._id || '')
  );

  state: State = {
    allExtras: this.extrasManager.initialState,
    showEditExtraDialog: false,
    selectedExtra: undefined,
    showDeleteConfirmDialog: false,
  };

  componentDidMount() {
    try {
      this.extrasManager.fetch(null);
    } catch (error) {
      console.log(error);
    }
  }

  languagesBodyTemplate = (rowData: ReservationExtra) => {
    const { languageTranslations } = this.props;

    return (
      <div className="d-flex flex-wrap align-items-center">
        {rowData.languages?.map((lang) => (
          <span key={lang.name} className="language-badge text-uppercase my-1">
            {languageTranslations.find((x) => x.value === lang.name)?.native_name}
          </span>
        ))}
        {rowData.warnings?.find((x) => x === 'missing_languages') && (
          <i className="pi pi-exclamation-triangle text-danger ml-2"></i>
        )}
      </div>
    );
  };

  priceBodyTemplate = (rowData: ReservationExtra) => {
    return rowData.price?.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' });
  };

  actionsTemplate = (rowData: ReservationExtra) => {
    return (
      <React.Fragment>
        <Button
          className="p-button-icon-only"
          icon="pi pi-pencil"
          onClick={() => this.handleEdit(rowData)}
        ></Button>
        <Button
          className="p-button-icon-only p-button-outlined ml-2"
          icon="pi pi-trash"
          onClick={() => this.showDeleteConfirmDialog(rowData)}
        ></Button>
      </React.Fragment>
    );
  };

  handleEdit(extra: ReservationExtra) {
    this.showEditExtraDialog(extra);
  }

  showDeleteConfirmDialog(rowData: ReservationExtra) {
    this.setState({
      showDeleteConfirmDialog: true,
      selectedExtra: rowData,
    });
  }

  hideDeleteConfirmDialog = () => {
    this.setState({
      showDeleteConfirmDialog: false,
      selectedExtra: undefined,
    });
  };

  deleteExtra = () => {
    if (!this.state.selectedExtra) return;

    this.extrasManagerDelete.fetch(this.state.selectedExtra).then(() => {
      this.extrasManager.fetch(null);
    });
    this.hideDeleteConfirmDialog();
  };

  showEditExtraDialog = (selectedExtra?: ReservationExtra) => {
    this.setState({
      showEditExtraDialog: true,
      selectedExtra: selectedExtra,
    });
  };

  hideEditExtraDialog = () => {
    this.setState({
      showEditExtraDialog: false,
      selectedExtra: undefined,
    });
  };

  onExtraEdited = () => {
    this.extrasManager.fetch(null);
  };

  render() {
    const { intlString } = this.props;
    const { allExtras } = this.state;

    return (
      <div className="page-extra mt-3">
        <header className="d-flex justify-content-between align-items-start">
          <div>
            <h1 className="h4">
              <IntlString id="extras.title" />
            </h1>
            <p>
              <IntlString id="extras.subtitle" />
            </p>
          </div>
          <Button
            className="p-button-danger px-2"
            label={intlString('extras.newExtra')}
            icon="pi pi-plus"
            onClick={() => this.showEditExtraDialog()}
          />
        </header>

        <BlockLoader loading={this.state.allExtras.loading}>
          <div className="bg-white p-3">
            <DataTable
              value={allExtras.response as ModelExtras[]}
              resizableColumns
              columnResizeMode="fit"
              className="extras-table mt-3"
            >
              <Column
                style={{ width: '40%' }}
                header={intlString('models.extras.table.id')}
                headerClassName="border-top font-weight-bold"
                field="title"
              />
              <Column
                style={{ width: '30%' }}
                header={intlString('models.extras.table.languages')}
                headerClassName="border-top font-weight-bold"
                body={this.languagesBodyTemplate}
              />
              <Column
                style={{ width: '20%' }}
                header={intlString('models.extras.table.price')}
                headerClassName="border-top font-weight-bold"
                body={this.priceBodyTemplate}
              />
              <Column
                style={{ width: '10%' }}
                header={intlString('models.extras.table.actions')}
                headerClassName="border-top font-weight-bold"
                body={this.actionsTemplate}
              />
            </DataTable>
          </div>
        </BlockLoader>
        <EditExtraDialog
          isVisible={this.state.showEditExtraDialog}
          onHideDialog={this.hideEditExtraDialog}
          onExtraEdited={this.onExtraEdited}
          extraValue={this.state.selectedExtra}
        />
        <ConfirmDialog
          isVisible={this.state.showDeleteConfirmDialog}
          onHideDialog={this.hideDeleteConfirmDialog}
          onConfirmDialog={this.deleteExtra}
          title={intlString('extras.deleteDialog.title')}
          contentText={intlString('extras.deleteDialog.text')}
          confirmText={intlString('extras.deleteDialog.confirmButton.text')}
        />
      </div>
    );
  }
}

export default connector(withIntlString(Extras));
