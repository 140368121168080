import BlockLoader from 'components/blockLoader/BlockLoader';
import { IntlStringProps, withIntlString } from 'components/IntlStringHoc';
import { MultiSelect } from 'primereact/multiselect';
import React, { Component } from 'react';
import getClient from 'services/apiClientProvider';
import { InlineResponse2007, TagApi } from 'services/apiService';
import { IApi, IState, useApi } from 'services/apiStateManager';

type Props = IntlStringProps & {
  className?: string;
  handleChange?: Function;
  setFormField?: Function;
  value: InlineResponse2007[];
  fieldName?: string;
};

type State = {
  tagsList: IState<InlineResponse2007[]>;
};

class TagsDropdown extends Component<Props> {
  tagsManager: IApi<InlineResponse2007[], null> = useApi<InlineResponse2007[], null>(
    this,
    'tagsList',
    () => getClient(TagApi).tagsOAutocompleteGet('')
  );

  handleMultiSelectChange = (tags: InlineResponse2007[]) => {
    const { setFormField, fieldName, handleChange } = this.props;

    if (setFormField && fieldName) {
      setFormField(
        fieldName,
        tags.map((v: any) => ({
          text: v.text,
        }))
      );
    } else if (handleChange) {
      handleChange(tags);
    }
  };

  state: State = {
    tagsList: this.tagsManager.initialState,
  };

  componentDidMount() {
    this.tagsManager.fetch(null);
  }

  selectedTagsTemplate = (option: InlineResponse2007) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <span>{option.text}</span>
        </div>
      );
    }
    return <div className="p-placeholder"></div>;
  };

  render() {
    const { tagsList } = this.state;
    const { value, intlString } = this.props;

    return (
      <BlockLoader loading={tagsList.loading}>
        <MultiSelect
          value={value}
          options={tagsList.response || undefined}
          onChange={(e: any) => this.handleMultiSelectChange(e.value)}
          optionLabel="text"
          className="multiselect-custom w-100"
          dataKey="text"
          maxSelectedLabels={6}
          emptyFilterMessage={intlString('general.noResult')}
          selectedItemTemplate={this.selectedTagsTemplate}
          appendTo={document.body}
        />
      </BlockLoader>
    );
  }
}

export default withIntlString(TagsDropdown);
