import { ActionTypes, LanguageState } from './types';
import { SET_LANGUAGE } from './actions';

const initialState: LanguageState = {
  language: 'it',
};

export default (state = initialState, action: ActionTypes): LanguageState => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        language: action.language,
      };

    default: {
      return state;
    }
  }
};
