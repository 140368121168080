import React, { Component, RefObject } from 'react';
import { IntlStringProps, withIntlString } from 'components/IntlStringHoc';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { ReservationDataTable, ReservationsDataTable } from 'models/Reservations';
import Axios from 'axios';
import qs from 'qs';
import { IApi, IState, useApi } from 'services/apiStateManager';
import reservationsApiParamsDefinition from './reservationsApiParamsDefinition';
import debounce from 'lodash.debounce';
import {
  EventApi,
  InlineResponse2007,
  Reservation,
  ReservationStatusEnum,
  Event,
  ReservationApi,
} from 'services/apiService';
import { Button } from 'primereact/button';
import userImage from 'assets/img/user.webp';
import './ReservationsTab.scss';
import TagsDropdown from 'components/tagsDropdown/TagsDropdown';
import NewReservationDialog from 'pages/dashboard/eventDetailDialog/eventReservations/newReservationDialog/NewReservationDialog';
import getClient from 'services/apiClientProvider';
import MoveReservationDialog from 'components/moveReservationDialog/MoveReservationDialog';
import { Toast } from 'primereact/components/toast/Toast';
import ConfirmDialog from 'components/confirmDialog/ConfirmDialog';

type Props = IntlStringProps;

type State = {
  reservationsObject: IState<ReservationsDataTable>;
  selectedTags: InlineResponse2007[];
  params: typeof reservationsApiParamsDefinition;
  isReservationEditDialogVisible: boolean;
  showConfirmDialog: boolean;
  isReservationMoveDialogVisible: boolean;
  selectedRow: ReservationDataTable | null;
  eventGet: IState<Event>;
  reservationGet: IState<Reservation>;
  reservationDelete: IState<void>;
};

class ReservationsTab extends Component<Props, State> {
  toast: RefObject<Toast>;

  reservationsManager: IApi<ReservationsDataTable, any> = useApi<ReservationsDataTable, any>(
    this,
    'reservationsObject',
    (params) => {
      const apiString = `/reservations/tables/datatable?${qs.stringify(params)}`;
      return Axios.get(apiString);
    }
  );

  eventManagerGet: IApi<Event, any> = useApi<Event, any>(
    this,
    'eventGet',
    ({ eventId }: { eventId: string }) => getClient(EventApi).eventsEventIdGet(eventId)
  );

  reservationManagerGet: IApi<Reservation, any> = useApi<Reservation, any>(
    this,
    'reservationGet',
    ({ reservationId }) => getClient(ReservationApi).reservationsReservationIdGet(reservationId)
  );

  reservationManagerDelete: IApi<void, any> = useApi<void, any>(this, '', ({ reservation }) =>
    getClient(ReservationApi).reservationsReservationIdDelete(reservation)
  );

  constructor(props: Props) {
    super(props);
    this.toast = React.createRef();
  }

  state: State = {
    reservationsObject: this.reservationsManager.initialState,
    eventGet: this.eventManagerGet.initialState,
    reservationGet: this.reservationManagerGet.initialState,
    reservationDelete: this.reservationManagerDelete.initialState,
    selectedTags: [],
    showConfirmDialog: false,
    params: reservationsApiParamsDefinition,
    isReservationEditDialogVisible: false,
    isReservationMoveDialogVisible: false,
    selectedRow: null,
  };

  componentDidMount() {
    this.loadReservations();
  }

  loadReservations() {
    this.reservationsManager.fetch(this.state.params);
  }

  loadReservationsDebounced = debounce(() => {
    this.loadReservations();
  }, 500);

  searchChange = (e: any) => {
    this.saveFilters(e.target.value, undefined);
  };

  saveFilters = (searchText: string | undefined, tagsValues: string[] | undefined) => {
    this.setState(
      (state: any) => ({
        ...state,
        params: {
          ...state.params,
          search:
            searchText === undefined
              ? state.params.search
              : {
                  ...state.params.search,
                  value: searchText,
                },
          tags: tagsValues ?? state.params.tags,
        },
      }),
      () => {
        this.loadReservationsDebounced();
      }
    );
  };

  onValuesChanges = (values: InlineResponse2007[]) => {
    this.setState({ selectedTags: values });
    this.saveFilters(
      undefined,
      values.map((x) => x.text ?? '')
    );
  };

  onPageChanged = (e: { first: number; rows: number; page: number; pageCount: number }) => {
    if (this.state.reservationsObject.loading) return;
    this.setState(
      (state: any) => ({
        ...state,
        params: {
          ...state.params,
          start: e.first,
          length: e.rows,
        },
      }),
      () => {
        this.loadReservations();
      }
    );
  };

  reservationEdit = (rowData: ReservationDataTable) => {
    this.setState({
      selectedRow: rowData,
      isReservationEditDialogVisible: true,
    });
    this.eventManagerGet.fetch({ eventId: rowData.event._id });
  };

  reservationMove = (rowData: ReservationDataTable) => {
    this.setState({
      selectedRow: rowData,
      isReservationMoveDialogVisible: true,
    });
    this.reservationManagerGet.fetch({ reservationId: rowData._id });
    this.eventManagerGet.fetch({ eventId: rowData.event._id });
  };

  handleEditDialogClose(refresh: boolean) {
    this.setState((state: State) => ({
      isReservationEditDialogVisible: false,
      isReservationMoveDialogVisible: false,
      selectedRow: null,
      reservationGet: {
        ...state.reservationGet,
        response: null,
      },
    }));

    if (refresh) {
      this.reservationsManager.fetch(this.state.params);
    }
  }

  deleteReservation = async () => {
    if (!this.state.selectedRow) return;

    try {
      this.setState((state: State) => ({
        reservationDelete: {
          ...state.reservationDelete,
          loading: true,
        },
      }));
      this.state.reservationDelete.loading = true;
      await this.reservationManagerDelete.fetch({
        reservation: this.state.selectedRow._id,
      });
      this.setState((state: State) => ({
        reservationDelete: {
          ...state.reservationDelete,
          loading: false,
        },
      }));
      this.loadReservations();
    } catch (error) {
      this.toast.current!.show({
        severity: 'error',
        summary: 'Error',
        detail: error.message,
      });
    } finally {
      this.showDeleteConfirmDialog(false);
    }
  };

  showDeleteConfirmDialog = (
    showConfirmDialog: boolean,
    selectedRow: ReservationDataTable | null = null
  ) => {
    this.setState({
      showConfirmDialog,
      selectedRow,
    });
  };

  actionBodyTemplate = (rowData: ReservationDataTable) => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-button-secondary"
          onClick={() => this.reservationEdit(rowData)}
        />
        <Button
          icon="pi pi-calendar"
          className="p-button-secondary p-button-outlined ml-2"
          onClick={() => this.reservationMove(rowData)}
        />
        <Button
          icon="pi pi-times"
          onClick={() => this.showDeleteConfirmDialog(true, rowData)}
          className="p-button-secondary p-button-outlined ml-2"
        />
      </>
    );
  };

  statusBodyTemplate = (rowData: ReservationDataTable) => {
    if (rowData.status === ReservationStatusEnum.Deleted) {
      return <Button icon="pi pi-trash" className="p-button-rounded custom-icon p-button-danger" />;
    }
    if (rowData.status === ReservationStatusEnum.Unconfirmed)
      return <Button icon="pi pi-pencil" className="p-button-rounded custom-icon p-button-info" />;
    if (rowData.status === ReservationStatusEnum.Pending)
      return (
        <Button icon="pi pi-ellipsis-h" className="p-button-rounded custom-icon p-button-warning" />
      );
    if (
      rowData.status === ReservationStatusEnum.Confirmed ||
      rowData.status === ReservationStatusEnum.Finalizing
    )
      return (
        <Button icon="pi pi-check" className="p-button-rounded custom-icon p-button-success" />
      );
  };

  guestsBodyTemplate = (rowData: ReservationDataTable) => {
    return (
      <>
        <img src={userImage} alt="user" className="mr-2 mb-1" />
        {rowData.guests}
      </>
    );
  };

  dateBodyTemplate = (dateString: string) => {
    return (
      <>
        {new Date(dateString ?? '').toLocaleString(undefined, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
        })}
      </>
    );
  };

  render() {
    const { intlString } = this.props;
    const {
      reservationsObject,
      params,
      selectedTags,
      isReservationEditDialogVisible,
      isReservationMoveDialogVisible,
      selectedRow,
      eventGet,
      reservationGet,
      reservationDelete,
      showConfirmDialog,
    } = this.state;

    return (
      <div className="reservations-tab">
        <Toast ref={this.toast} />
        <div className="d-flex">
          <span className="p-input-icon-right">
            <InputText value={params.search.value} onChange={this.searchChange} />
            <i className="pi pi-search" />
          </span>
          <span className="d-flex align-items-center ml-5 flex-grow-1">
            {intlString('contactDialog.tag')}
            <div className="ml-3 tags-multiselect">
              <TagsDropdown
                handleChange={(values: InlineResponse2007[]) => this.onValuesChanges(values)}
                value={selectedTags}
              />
            </div>
          </span>
        </div>
        <hr className="mb-0" />
        <DataTable
          lazy
          value={reservationsObject.response?.results}
          loading={reservationsObject.loading}
          totalRecords={reservationsObject.response?.recordsFiltered}
          first={params.start}
          className="p-datatable-reservations"
          onPage={this.onPageChanged}
          rowHover
          paginator
          rows={params.length}
          emptyMessage={intlString('reservationsAndRequests.reservations.table.noMessage')}
          currentPageReportTemplate={intlString(
            'reservationsAndRequests.reservations.table.paginatorReport'
          )}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rowsPerPageOptions={[10, 20, 50, 100]}
          scrollable
          scrollHeight="calc(100vh - 27.8125rem)"
        >
          <Column
            field="status"
            body={this.statusBodyTemplate}
            headerStyle={{ width: '5em', textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
            header={intlString('reservationsAndRequests.reservations.table.status')}
          />
          <Column
            field="event.titleXsurrogate_event.title"
            header={intlString('reservationsAndRequests.reservations.table.event')}
          />
          <Column
            field="event.startXsurrogate_event.start"
            body={(res: ReservationDataTable) =>
              this.dateBodyTemplate(res.event.startXsurrogate_event.start)
            }
            header={intlString('reservationsAndRequests.reservations.table.eventDate')}
            headerStyle={{ width: '12em', textAlign: 'left' }}
            bodyStyle={{ textAlign: 'left', overflow: 'visible' }}
          />
          <Column
            field="cellar_user.last_name"
            header={intlString('reservationsAndRequests.reservations.table.lastname')}
          />
          <Column
            field="cellar_user.first_name"
            header={intlString('reservationsAndRequests.reservations.table.firstname')}
          />
          <Column
            field="cellar_user.email"
            header={intlString('reservationsAndRequests.reservations.table.mail')}
          />
          <Column
            field="cellar_user.phone"
            header={intlString('reservationsAndRequests.reservations.table.phone')}
          />
          <Column
            field="guests"
            body={this.guestsBodyTemplate}
            header={intlString('reservationsAndRequests.reservations.table.guests')}
            headerStyle={{ width: '5em', textAlign: 'left' }}
            bodyStyle={{ textAlign: 'left', overflow: 'visible' }}
          />
          <Column
            field="language"
            header={intlString('reservationsAndRequests.reservations.table.language')}
            headerStyle={{ width: '7em', textAlign: 'left' }}
            bodyStyle={{ textAlign: 'left', overflow: 'visible' }}
          />
          <Column
            field="created"
            body={(res: any) => this.dateBodyTemplate(res.created)}
            header={intlString('reservationsAndRequests.reservations.table.reservationDate')}
            headerStyle={{ width: '12em', textAlign: 'left' }}
            bodyStyle={{ textAlign: 'left', overflow: 'visible' }}
          />
          <Column
            body={this.actionBodyTemplate}
            headerStyle={{ width: '12em', textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
          />
        </DataTable>
        <ConfirmDialog
          isVisible={showConfirmDialog}
          loading={reservationDelete.loading}
          onHideDialog={() => this.showDeleteConfirmDialog(false)}
          onConfirmDialog={this.deleteReservation}
          title={intlString('general.confirmDelete')}
          contentText={intlString('general.confirmDeleteReservation')}
          confirmText={intlString('general.confirm')}
        />
        <NewReservationDialog
          isVisible={isReservationEditDialogVisible}
          modelQuestions={eventGet.response?.model?.meta_data || []}
          modelExtras={eventGet.response?.model?.extras || []}
          eventId={selectedRow?.event._id ?? null}
          eventLanguages={eventGet.response?.languages}
          reservationId={selectedRow?._id}
          onHideDialog={(refresh: boolean) => this.handleEditDialogClose(refresh)}
        />
        <MoveReservationDialog
          isVisible={isReservationMoveDialogVisible}
          onHideDialog={(refresh: boolean) => this.handleEditDialogClose(refresh)}
          reservation={reservationGet.response ?? undefined}
          moveFromDate={new Date(eventGet.response?.start ?? '')}
        />
      </div>
    );
  }
}

export default withIntlString(ReservationsTab);
