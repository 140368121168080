import React, { Component } from 'react';
import { IntlStringProps, withIntlString } from 'components/IntlStringHoc';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import './ConfirmDialog.scss';
import BlockLoader from 'components/blockLoader/BlockLoader';

type Props = IntlStringProps & {
  isVisible: boolean;
  loading?: boolean;
  title: string;
  contentText: string;
  confirmText: string;
  onHideDialog: Function;
  onConfirmDialog: Function;
};

class ConfirmDialog extends Component<Props> {
  renderContent() {
    const { contentText } = this.props;
    return <div dangerouslySetInnerHTML={{ __html: contentText }}></div>;
  }

  renderHeader() {
    return <div className="confirm-dialog__header">{this.props.title}</div>;
  }

  renderFooter() {
    const { intlString, onHideDialog, confirmText, onConfirmDialog, loading } = this.props;
    return (
      <div className="confirm-dialog__footer">
        <Button
          label={intlString('general.cancel')}
          className="p-button-text p-button-secondary p-button--m mr-auto"
          type="button"
          onClick={() => onHideDialog()}
        ></Button>
        <Button
          label={confirmText}
          type="button"
          disabled={loading ?? false}
          className="p-button-danger p-button--m p-button-icon-right ml-2"
          onClick={() => onConfirmDialog()}
        ></Button>
      </div>
    );
  }

  render() {
    return (
      <Dialog
        header={this.renderHeader()}
        footer={this.renderFooter()}
        visible={this.props.isVisible}
        onHide={() => this.props.onHideDialog()}
        className="confirm-dialog"
      >
        <BlockLoader loading={this.props.loading ?? false}>
          <div className="confirm-dialog__content">{this.renderContent()}</div>
        </BlockLoader>
      </Dialog>
    );
  }
}

export default withIntlString(ConfirmDialog);
