import React from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { IntlStringProps, withIntlString } from 'components/IntlStringHoc';
import { setVisible } from 'state/sidebar/actionCreators';

import { ReactComponent as LogoWA } from 'assets/img/logo.svg';
import './Navbar.scss';

import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import IntlString from 'components/IntlString';
import { logout, setUser } from 'state/auth/actionCreators';
import { RootState } from 'state/store';

type Props = ConnectedProps<typeof connector> & IntlStringProps & RouteComponentProps;

const connector = connect(
  (state: RootState) => ({
    notification: state.notifications.notification,
  }),
  {
    setVisibility: setVisible,
    setUser,
    logout,
  }
);

class Navbar extends React.Component<Props> {
  profileOverlay = React.createRef<OverlayPanel>();

  handleSetVisibility = (isVisible: boolean) => {
    this.props.setVisibility(isVisible);
  };

  toggleDropdown = (e: React.MouseEvent) => {
    this.profileOverlay.current?.toggle(e);
  };

  render() {
    const { intlString, notification } = this.props;

    return (
      <nav className="navbar navbar--main navbar-expand navbar-dark">
        <Button
          onClick={() => this.handleSetVisibility(true)}
          className="p-button-rounded p-button-secondary mr-3"
          icon="pi pi-bars"
          aria-label={intlString('navbar.menu.open')}
        />

        <NavLink className="navbar-brand" to="/">
          <span className="sr-only">
            <IntlString id="navbar.goToDashboard" />
          </span>
          <LogoWA className="navbar-brand-logo" />
        </NavLink>

        <div className="navbar-collapse collapse">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink
                className="p-button text-uppercase"
                activeClassName="p-button-secondary"
                to="/"
                exact
              >
                <IntlString id="navbar.calendar" />
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="p-button text-uppercase"
                activeClassName="p-button-secondary"
                to="/reservations"
              >
                <IntlString id="navbar.reservations" />
                <span className="ml-2 badge badge-reservation-requests">
                  {notification.pending}
                </span>
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="ml-5">
          <Button
            className="p-button-rounded p-button-danger mr-2"
            icon="pi pi-desktop"
            aria-label={intlString('navbar.desktop')}
          />

          <Button
            className="p-button-rounded p-button-secondary"
            icon="pi pi-bell"
            aria-label={intlString('navbar.notifications')}
          />
        </div>

        <Button
          className="navbar--main__round-btn p-button-icon-only p-button-avatar p-button-rounded ml-4 p-button--with-dropdown"
          onClick={this.toggleDropdown}
        >
          <img src="https://via.placeholder.com/40x40" alt="x" />
        </Button>

        <OverlayPanel
          ref={this.profileOverlay}
          style={{ width: '11.875rem' }}
          className="p-overlaypanel-dropdown p-overlaypanel-no-arrow"
        >
          <nav className="menu-vertical">
            <ul className="menu-vertical__list">
              <li>
                <NavLink to="/profile" className="menu-vertical__item">
                  <span className="menu-vertical__icon">
                    <i className="pi pi-user" aria-hidden={true} />
                  </span>
                  <span className="menu-vertical__label">
                    <IntlString id="navbar.menu.profile" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/bills" className="menu-vertical__item">
                  <span className="menu-vertical__icon">
                    <i className="pi pi-dollar" aria-hidden={true} />
                  </span>
                  <span className="menu-vertical__label">
                    <IntlString id="navbar.menu.billing" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/users" className="menu-vertical__item menu-vertical__item--divider">
                  <span className="menu-vertical__icon">
                    <i className="pi pi-users" aria-hidden={true} />
                  </span>
                  <span className="menu-vertical__label">
                    <IntlString id="navbar.menu.users" />
                  </span>
                </NavLink>
              </li>
              <hr className="menu-vertical__divider" />
              <li>
                <button
                  onClick={this.props.logout}
                  className="menu-vertical__item navbar--main--btn-logout"
                >
                  <span className="menu-vertical__icon">
                    <i className="pi pi-sign-out" aria-hidden={true} />
                  </span>
                  <span className="menu-vertical__label">
                    <IntlString id="navbar.menu.logout" />
                  </span>
                </button>
              </li>
            </ul>
          </nav>
        </OverlayPanel>
      </nav>
    );
  }
}

export default connector(withRouter(withIntlString(Navbar)));
