import { injectIntl, WrappedComponentProps } from 'react-intl';
import React, { Component, ComponentType } from 'react';
import { IntlMessageId } from 'lang/models';
import { PrimitiveType, FormatXMLElementFn } from 'intl-messageformat';

export interface IntlStringProps {
  intlString(
    id: IntlMessageId,
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>
  ): string;
}

export function withIntlString<P extends IntlStringProps>(WrappedComponent: ComponentType<P>) {
  class IntlStringHoc extends Component<Omit<P & WrappedComponentProps, keyof IntlStringProps>> {
    intlString = (id: IntlMessageId) => {
      return this.props.intl.formatMessage({ id });
    };

    render() {
      return <WrappedComponent {...(this.props as P)} intlString={this.intlString} />;
    }
  }

  return injectIntl(IntlStringHoc);
}
