import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'state/store';
import { Route, Redirect } from 'react-router-dom';
import { RouteProps } from 'react-router';

const connector = connect((state: RootState) => ({
  isAuthenticated: state.auth.user?.token,
}));

type Props = ConnectedProps<typeof connector> & RouteProps;

const ProtectedRoute: FunctionComponent<Props> = ({ children, isAuthenticated, ...rest }) => {
  if (isAuthenticated) {
    return <Route {...rest}>{children}</Route>;
  }

  return <Redirect to="/login" />;
};

export default connector(ProtectedRoute);
