export default {
  draw: '3',
  columns: {
    '0': {
      data: '',
      name: '',
      searchable: 'true',
      orderable: 'false',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '1': {
      data: '_id',
      name: '',
      searchable: 'true',
      orderable: 'false',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '2': {
      data: 'first_name',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '3': {
      data: 'last_name',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '4': {
      data: 'phone',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '5': {
      data: 'email',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '6': {
      data: 'tags',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
  },
  order: [
    {
      column: '0',
      dir: 'asc',
    },
  ],
  start: 0,
  length: 10,
  search: {
    value: '',
    regex: 'false',
  },
  tags: [],
};
