import React, { Component, useEffect } from 'react';
import { IntlStringProps, withIntlString } from 'components/IntlStringHoc';
import { Dialog } from 'primereact/dialog';
import IntlString from 'components/IntlString';
import { Button } from 'primereact/button';
import { ClubLanguages, ReservationExtra, ReservationExtraApi } from 'services/apiService/api';
import { InputText } from 'primereact/inputtext';
import { Field, Form, Formik } from 'formik';
import cx from 'classnames';
import * as Yup from 'yup';
import { InputNumber } from 'primereact/inputnumber';
import LanguageMultiSelector from 'components/languageMultiSelector/LanguageMultiSelector';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'state/store';

import './EditExtraDialog.scss';
import { IApi, IState, useApi } from 'services/apiStateManager';
import getClient from 'services/apiClientProvider';
import BlockLoader from 'components/blockLoader/BlockLoader';

const connector = connect((state: RootState) => ({
  languageTranslations: state.languageTranslations,
}));

type State = {
  extrasPost: IState<ReservationExtra>;
  extrasPut: IState<ReservationExtra>;
};

type Props = IntlStringProps &
  ConnectedProps<typeof connector> & {
    isVisible: boolean;
    onHideDialog: () => void;
    onExtraEdited: (extra: ReservationExtra) => void;
    extraValue?: ReservationExtra;
  };

const ExtraSchema = Yup.object().shape({
  title: Yup.string().required().min(1),
  languages: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(),
        title: Yup.string().required(),
      })
    )
    .min(1),
});

class EditExtraDialog extends Component<Props, State> {
  extraManagerPost: IApi<ReservationExtra, ReservationExtra> = useApi<
    ReservationExtra,
    ReservationExtra
  >(this, '', (params) => getClient(ReservationExtraApi).extrasPost(params));

  extraManagerPut: IApi<ReservationExtra, ReservationExtra> = useApi<
    ReservationExtra,
    ReservationExtra
  >(this, '', (params) =>
    getClient(ReservationExtraApi).extrasExtraIdPut(params._id || '', params)
  );

  submitMyForm: Function | null = null;
  setFieldValueMyForm: Function | null = null;

  state = {
    extrasPost: this.extraManagerPost.initialState,
    extrasPut: this.extraManagerPut.initialState,
  };

  bindFormSubmit = (doSubmit: Function) => {
    this.submitMyForm = doSubmit;
  };

  bindSetFieldValue = (setFieldValue: Function) => {
    this.setFieldValueMyForm = setFieldValue;
  };

  submitForm = () => {
    if (this.submitMyForm) {
      this.submitMyForm();
    }
  };

  handleSubmit = (extra: ReservationExtra) => {
    const apiFunction = this.props.extraValue ? this.extraManagerPut : this.extraManagerPost;
    apiFunction.fetch(extra).then(() => {
      this.props.onExtraEdited(extra);
      this.props.onHideDialog();
    });
  };

  onLanguageSelectedChanged = (languages: string[]) => {
    const tmp = [...(this.props.extraValue?.languages || [])];
    tmp.filter((x) => languages.find((l) => l === x.name) !== undefined);

    languages.forEach((val) => {
      const index = tmp.findIndex((x) => x.name === val);
      if (index === -1) {
        tmp.push({
          name: val,
          title: '',
          is_set: true,
        });
      }
    });
    if (this.setFieldValueMyForm) this.setFieldValueMyForm('languages', tmp);
  };

  renderLanguageTitle(langId: string) {
    const { languageTranslations } = this.props;
    if (!languageTranslations) return '';

    const langFound = languageTranslations.find((lang) => lang.value === langId);
    if (langFound) {
      return langFound.native_name;
    }

    return '';
  }

  renderHeader() {
    return (
      <h6 className="font-weight-bold">
        <IntlString id="models.extras.title" />
      </h6>
    );
  }

  renderFooter() {
    const { intlString } = this.props;
    return (
      <div className="extra-edit-dialog__footer">
        <Button
          label={intlString('general.cancel')}
          className="p-button-text p-button-secondary mr-auto"
          type="button"
          onClick={this.props.onHideDialog}
        />
        <Button
          label={intlString('general.save')}
          className="p-button-danger mr-0"
          type="button"
          onClick={this.submitForm}
        />
      </div>
    );
  }

  render() {
    const { extraValue } = this.props;
    const { extrasPost, extrasPut } = this.state;

    return (
      <Dialog
        header={this.renderHeader()}
        footer={this.renderFooter()}
        visible={this.props.isVisible}
        onHide={this.props.onHideDialog}
        className="extra-edit-dialog"
      >
        <BlockLoader loading={extrasPost.loading || extrasPut.loading}>
          <Formik
            initialValues={
              extraValue || {
                title: '',
                price: 0,
                languages: [],
              }
            }
            enableReinitialize={true}
            validationSchema={ExtraSchema}
            onSubmit={this.handleSubmit}
          >
            {({ errors, submitForm, values, setFieldValue }) => {
              useEffect(() => {
                this.bindFormSubmit(submitForm);
                this.bindSetFieldValue(setFieldValue);
              }, [submitForm, setFieldValue]);
              return (
                <Form className="mb-2 p-fluid">
                  <div className="row">
                    <div className="col-9 p-field">
                      <label htmlFor="title">
                        <IntlString id="extras.dialog.id" />
                      </label>
                      <Field
                        as={InputText}
                        name="title"
                        maxLength="20"
                        className={cx('', {
                          'p-invalid': errors.title,
                        })}
                      />
                    </div>
                    <div className="col-3 p-field pl-0">
                      <label htmlFor="price">
                        <IntlString id="extras.dialog.euro" />
                      </label>
                      <Field
                        component={InputNumber}
                        name="price"
                        className={cx('', {
                          'p-invalid': errors.price,
                        })}
                        showButtons
                        value={values.price}
                        onChange={(e: any) => {
                          setFieldValue('price', e.value);
                        }}
                        min={0}
                      />
                    </div>
                  </div>
                  <div className="mt-2">
                    <label htmlFor="languages">
                      <IntlString id="extras.dialog.languages" />
                    </label>
                    <LanguageMultiSelector
                      selectedLanguages={this.props.extraValue?.languages?.map((x) => x.name || '')}
                      onLanguageSelectedChanged={this.onLanguageSelectedChanged}
                    />
                  </div>
                  <hr />
                  <div className="mt-2">
                    {values.languages?.map((lang: ClubLanguages, index: number) => {
                      return (
                        <div key={lang.name} className="languages-list">
                          <div className="row mt-2 ">
                            <div className="col-3 d-flex align-items-center">
                              <div className="languages-list__title">
                                {this.renderLanguageTitle(lang.name || '')}
                              </div>
                            </div>
                            <div className="col-9 d-flex align-items-center">
                              <Field
                                as={InputText}
                                name={`languages.${index}.title`}
                                className={cx('w-100', {
                                  'p-invalid': errors.languages,
                                })}
                              />
                            </div>
                          </div>
                          <div className="locations-dialog__divider mt-2"></div>
                          <hr />
                        </div>
                      );
                    })}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </BlockLoader>
      </Dialog>
    );
  }
}

export default connector(withIntlString(EditExtraDialog));
