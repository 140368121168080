import React, { Component } from 'react';
import { IntlStringProps, withIntlString } from 'components/IntlStringHoc';
import { Dialog } from 'primereact/dialog';
import IntlString from 'components/IntlString';
import { InlineResponse2007 } from 'services/apiService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IApi, IState, useApi } from 'services/apiStateManager';
import { Button } from 'primereact/button';
import Axios from 'axios';
import qs from 'qs';
import contactApiParamsDefinition from './contactApiParamsDefinition';
import debounce from 'lodash.debounce';
import ContactBook, { ContactBookDataTable } from 'models/ContactBook';

import './ContactBookDialog.scss';
import TagsDropdown from 'components/tagsDropdown/TagsDropdown';

type Props = IntlStringProps & {
  isVisible: boolean;
  onHideDialog: Function;
};

type State = {
  contactsObject: IState<ContactBookDataTable>;
  selectedTags: InlineResponse2007[];
  params: typeof contactApiParamsDefinition;
};

class ContactBookDialog extends Component<Props, State> {
  contactsManager: IApi<ContactBookDataTable, any> = useApi<ContactBookDataTable, any>(
    this,
    'contactsObject',
    (params) => {
      const apiString = `/guests?${qs.stringify(params)}`;
      return Axios.get(apiString);
    }
  );

  state = {
    contactsObject: this.contactsManager.initialState,
    selectedTags: [],
    params: contactApiParamsDefinition,
  };

  componentDidMount() {
    this.loadContacts();
  }

  loadContacts() {
    this.contactsManager.fetch(this.state.params);
  }

  loadContactsDebounced = debounce(() => {
    this.loadContacts();
  }, 500);

  renderHeader() {
    return (
      <div className="d-flex align-items-center">
        <IntlString id="contactBookDialog.title" />
      </div>
    );
  }

  tagsBodyTemplate = (rowData: ContactBook) => {
    if (rowData.tags)
      return (
        <div>
          {rowData.tags.map((tag: { text: string }, index: number) => (
            <span key={index} className="badge-tag text-uppercase mr-1">
              {tag.text}
            </span>
          ))}
        </div>
      );
    return;
  };

  actionBodyTemplate = (rowData: ContactBook) => {
    return (
      <Button
        type="button"
        icon="pi pi-arrow-right"
        className="p-button-secondary"
        onClick={() => this.props.onHideDialog(rowData)}
      />
    );
  };

  onValuesChanges = (values: InlineResponse2007[]) => {
    this.setState({ selectedTags: values });
    this.saveFilters(
      undefined,
      values.map((x) => x.text ?? '')
    );
  };

  searchChange = (e: any) => {
    this.saveFilters(e.target.value, undefined);
  };

  saveFilters = (searchText: string | undefined, tagsValues: string[] | undefined) => {
    this.setState(
      (state: any) => ({
        ...state,
        params: {
          ...state.params,
          search:
            searchText === undefined
              ? state.params.search
              : {
                  ...state.params.search,
                  value: searchText,
                },
          tags: tagsValues ?? state.params.tags,
        },
      }),
      () => {
        this.loadContactsDebounced();
      }
    );
  };

  onPageChanged = (e: { first: number; rows: number; page: number; pageCount: number }) => {
    if (this.state.contactsObject.loading) return;
    this.setState(
      (state: any) => ({
        ...state,
        params: {
          ...state.params,
          start: e.first,
          length: e.rows,
        },
      }),
      () => {
        this.loadContacts();
      }
    );
  };

  render() {
    const { intlString } = this.props;
    const { contactsObject, selectedTags, params } = this.state;
    return (
      <Dialog
        header={this.renderHeader()}
        visible={this.props.isVisible}
        onHide={() => this.props.onHideDialog(false)}
        className="contact-book-dialog"
      >
        <div className="d-flex">
          <span className="p-input-icon-right">
            <InputText value={params.search.value} onChange={this.searchChange} />
            <i className="pi pi-search" />
          </span>
          <span className="d-flex align-items-center justify-content-center ml-5 flex-grow-1">
            {intlString('contactDialog.tag')}
            <div className="ml-2 w-100">
              <TagsDropdown
                handleChange={(values: InlineResponse2007[]) => this.onValuesChanges(values)}
                value={selectedTags}
              />
            </div>
          </span>
        </div>

        <hr className="mb-0" />
        <DataTable
          lazy
          value={contactsObject.response?.results}
          loading={contactsObject.loading}
          totalRecords={contactsObject.response?.recordsFiltered}
          first={params.start}
          className="p-datatable-contacts"
          onPage={this.onPageChanged}
          rowHover
          paginator
          rows={params.length}
          emptyMessage={intlString('contactBookDialog.noMessage')}
          currentPageReportTemplate={intlString('contactBookDialog.paginatorReport')}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50, 100]}
        >
          <Column field="last_name" header={intlString('contactDialog.lastName')} />
          <Column field="first_name" header={intlString('contactDialog.firstName')} />
          <Column field="phone" header={intlString('contactDialog.phone')} />
          <Column field="email" header={intlString('contactDialog.email')} />
          <Column body={this.tagsBodyTemplate} header={intlString('contactDialog.tag')} />
          <Column
            body={this.actionBodyTemplate}
            headerStyle={{ width: '5em', textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
          />
        </DataTable>
      </Dialog>
    );
  }
}

export default withIntlString(ContactBookDialog);
