import { Component } from 'react';
import { AxiosResponse } from 'axios';

export interface IState<T> {
  loading: boolean;
  error: string | null;
  done: boolean;
  response: T | null;
}

export interface IApi<T, TParams> {
  initialState: IState<T>;
  fetch: (fetchParams: TParams) => Promise<T>;
}

type ComponentType = Component<unknown, { [key: string]: any }>;
type CallApiFnType<TParams, TResult> = (params: TParams) => Promise<AxiosResponse<TResult>>;

function getSetState<T>(component: ComponentType, stateKey: string) {
  return (stateFn: (state: T) => Partial<T>) => {
    component.setState((state) => {
      const ownState = state[stateKey];

      return {
        [stateKey]: {
          ...ownState,
          ...stateFn(ownState),
        },
      };
    });
  };
}

export function useApi<T, TParams>(
  component: ComponentType,
  stateKey: string,
  callApiFn: CallApiFnType<TParams, T>
): IApi<T, TParams> {
  const initialState: IState<T> = {
    loading: false,
    response: null,
    error: null,
    done: false,
  };

  const setState = getSetState<IState<T>>(component, stateKey);

  const fetchData = (params: TParams) => {
    setState(() => ({
      loading: true,
      response: null,
      error: null,
      done: false,
    }));

    return new Promise<T>((resolve, reject) => {
      return callApiFn(params)
        .then((response) => {
          setState(() => ({
            loading: false,
            response: response.data,
            done: true,
          }));

          resolve(response.data);
        })
        .catch((error) => {
          setState(() => ({
            loading: false,
            error: error.message,
          }));

          reject(error);
        });
    });
  };

  return {
    initialState,
    fetch: fetchData,
  };
}
