export default {
  draw: '1',
  columns: {
    '0': {
      data: '',
      name: '',
      searchable: 'true',
      orderable: 'false',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '1': {
      data: '_id',
      name: '',
      searchable: 'true',
      orderable: 'false',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '2': {
      data: 'reservation.app_user.first_nameXreservation.cellar_user.first_name',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '3': {
      data: 'reservation.app_user.last_nameXreservation.cellar_user.last_name',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '4': {
      data: 'reservation.app_user.phoneXreservation.cellar_user.phone',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '5': {
      data: 'reservation.app_user.emailXreservation.cellar_user.email',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '6': {
      data: 'reservation.guests',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '7': {
      data: 'reservation.language',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '8': {
      data: 'title',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '9': {
      data: 'start',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '10': {
      data: 'reservation.created',
      name: '',
      searchable: 'true',
      orderable: 'true',
      search: {
        value: '',
        regex: 'false',
      },
    },
    '11': {
      data: 'location.timezone',
      name: '',
      searchable: 'true',
      orderable: 'false',
      search: {
        value: '',
        regex: 'false',
      },
    },
  },
  order: [
    {
      column: '0',
      dir: 'asc',
    },
  ],
  start: 0,
  length: 10,
  search: {
    value: '',
    regex: 'false',
  },
};
