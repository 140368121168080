import React, { Component } from 'react';
import { IntlStringProps, withIntlString } from 'components/IntlStringHoc';
import { InlineResponse2003 } from 'services/apiService';
import { MultiSelect } from 'primereact/multiselect';
import { connect, ConnectedProps } from 'react-redux';
import IntlString from 'components/IntlString';
import { MultiSelectChangeEvent } from 'pages/locations/locationsDialog/LocationFormModels';
import { RootState } from 'state/store';

const connector = connect((state: RootState) => ({
  languageTranslations: state.languageTranslations,
}));

type Props = IntlStringProps &
  ConnectedProps<typeof connector> & {
    selectedLanguages?: string[];
    onLanguageSelectedChanged: (languages: string[]) => void;
  };

type State = {
  selectedLanguages: string[];
};

class LanguageMultiSelector extends Component<Props, State> {
  state: State = {
    selectedLanguages: this.props.selectedLanguages || [],
  };

  languagesTemplate = (option: InlineResponse2003) => {
    return <div className="country-item">{option.text}</div>;
  };

  selectedLanguagesTemplate = (option: string) => {
    const { languageTranslations } = this.props;
    if (!languageTranslations) return;

    const lang = languageTranslations.find((lang) => lang.value === option);

    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{lang?.native_name}</div>
        </div>
      );
    }
    return (
      <div className="p-placeholder">
        <IntlString id="locationsDialog.selectLanguages" />
      </div>
    );
  };

  getParsedLanguages() {
    const { languageTranslations } = this.props;

    if (languageTranslations.length) {
      let result = languageTranslations.map((el) => ({ text: el.text, value: el.value }));
      result = result.filter(
        ({ value }) =>
          value !== 'en' && value !== 'fr' && value !== 'de' && value !== 'it' && value !== 'es'
      );

      result.unshift({ value: 'es', text: 'Spanish (Espanol)' });
      result.unshift({ value: 'it', text: 'Italian (Italiano)' });
      result.unshift({ value: 'de', text: 'German (Deutsch)' });
      result.unshift({ value: 'fr', text: 'French (Français)' });
      result.unshift({ value: 'en', text: 'English (English)' });
      return result;
    } else return [];
  }

  addSelectedLanguage = (event: MultiSelectChangeEvent) => {
    const { value } = event;
    this.setState({ selectedLanguages: value });
    this.props.onLanguageSelectedChanged(value);
  };

  removeLanguage = (event: MultiSelectChangeEvent) => {
    const { value } = event;
    this.props.onLanguageSelectedChanged(value);
  };

  render() {
    const { intlString } = this.props;
    const { selectedLanguages } = this.state;

    return (
      <MultiSelect
        name="languages"
        value={selectedLanguages}
        options={this.getParsedLanguages()}
        onChange={this.addSelectedLanguage}
        optionLabel="name"
        filter
        filterBy="value,text"
        className="multiselect-custom"
        dataKey="value"
        itemTemplate={this.languagesTemplate}
        selectedItemTemplate={this.selectedLanguagesTemplate}
        selectedItemsLabel={intlString('locationsDialog.multiSelectSelected')}
        emptyFilterMessage={intlString('general.noResult')}
        appendTo={document.body}
      />
    );
  }
}

export default connector(withIntlString(LanguageMultiSelector));
