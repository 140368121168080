export default {
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['link'],
      ['clean'],
    ],
  },
  formats: ['bold', 'italic', 'underline', 'list', 'bullet', 'align', 'link', 'image'],
};
