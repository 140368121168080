import React, { Component, useEffect } from 'react';
import { IntlStringProps, withIntlString } from 'components/IntlStringHoc';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import './ContactDialog.scss';
import * as Yup from 'yup';

import { Field, Formik } from 'formik';
import IntlString from 'components/IntlString';
import { InputText } from 'primereact/inputtext';

import cx from 'classnames';
import { CellarUser } from 'services/apiService';
import TagsDropdown from 'components/tagsDropdown/TagsDropdown';
import { Dropdown } from 'primereact/dropdown';

type Props = IntlStringProps & {
  isVisible: boolean;
  defaultValue: CellarUser;
  setFormField: Function;
  fieldName: string;
  onHideDialog: Function;
  editPosition?: number;
  currentCellarUsers: CellarUser[];
};

const countries = [
  {
    text: 'Italia',
    value: 'IT',
  },
  {
    text: 'Inghilterra',
    value: 'EN',
  },
];

const ContactSchema = Yup.object().shape({
  first_name: Yup.string().required(),
  last_name: Yup.string().required(),
  phone: Yup.number(),
  email: Yup.string().email(),
});

class ContactDialog extends Component<Props> {
  submitMyForm: Function | null = null;

  state = {
    disableSave: true,
  };

  renderHeader() {
    return (
      <div className="new-reservation-dialog__title d-flex align-items-center">
        <IntlString id="contactDialog.title" />
      </div>
    );
  }

  renderFooter() {
    const { intlString } = this.props;

    return (
      <>
        <hr />
        <div className="event-dialog__footer d-flex">
          <div className="ml-auto">
            <Button
              label={intlString('general.cancel')}
              className="p-button-lg p-button-text p-button-secondary mr-auto"
              type="button"
              onClick={() => this.props.onHideDialog(false)}
            ></Button>
            <Button
              label={intlString('contactDialog.saveContact')}
              className="p-button-lg p-button-danger"
              type="button"
              onClick={this.submitForm}
              disabled={this.state.disableSave}
            />
          </div>
        </div>
      </>
    );
  }

  bindFormSubmit = (doSubmit: Function) => {
    this.submitMyForm = doSubmit;
  };

  submitForm = () => {
    if (this.submitMyForm) {
      this.submitMyForm();
    }
  };

  handleSubmit = async (user: CellarUser) => {
    const { fieldName, onHideDialog, currentCellarUsers, editPosition } = this.props;

    if (fieldName === 'cellar_users') {
      const tmp = [...currentCellarUsers];
      if (editPosition === undefined) tmp.push(user);
      else tmp[editPosition] = user;

      this.props.setFormField(fieldName, tmp);
    } else {
      this.props.setFormField(fieldName, user);
    }

    onHideDialog();
  };

  onFormStatusUpdated = (disableSave: boolean) => {
    this.setState({ disableSave });
  };

  render() {
    const { defaultValue } = this.props;

    return (
      <>
        <Dialog
          header={this.renderHeader()}
          footer={this.renderFooter()}
          visible={this.props.isVisible}
          onHide={() => this.props.onHideDialog(false)}
          className="contact-dialog"
        >
          <Formik
            initialValues={defaultValue}
            enableReinitialize={true}
            validationSchema={ContactSchema}
            onSubmit={this.handleSubmit}
          >
            {({ errors, submitForm, values, setFieldValue, isValid, touched }) => {
              const isTouched = Object.keys(touched).length !== 0;
              const disableSave = !isTouched || (isTouched && !isValid);

              useEffect(() => {
                this.onFormStatusUpdated(disableSave);
                this.bindFormSubmit(submitForm);
              }, [disableSave, submitForm]);

              return (
                <>
                  <div className="row">
                    <div className="col-6 p-field">
                      <label htmlFor="first_name">
                        <IntlString id="contactDialog.firstName" />
                      </label>
                      <Field
                        as={InputText}
                        name="first_name"
                        className={cx('', {
                          'p-invalid': errors.first_name,
                        })}
                      />
                    </div>
                    <div className="col-6 p-field">
                      <label htmlFor="last_name">
                        <IntlString id="contactDialog.lastName" />
                      </label>
                      <Field
                        as={InputText}
                        name="last_name"
                        className={cx('', {
                          'p-invalid': errors.last_name,
                        })}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6 p-field">
                      <label htmlFor="phone">
                        <IntlString id="contactDialog.phone" />
                      </label>
                      <Field
                        as={InputText}
                        name="phone"
                        className={cx('', {
                          'p-invalid': errors.phone,
                        })}
                      />
                    </div>
                    <div className="col-6 p-field">
                      <label htmlFor="email">
                        <IntlString id="contactDialog.email" />
                      </label>
                      <Field
                        as={InputText}
                        name="email"
                        className={cx('', {
                          'p-invalid': errors.email,
                        })}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12 p-field">
                      <label htmlFor="country">
                        <IntlString id="contactDialog.country" />
                      </label>
                      <Field
                        component={Dropdown}
                        appendTo={document.body}
                        name="country"
                        value={values.country}
                        options={countries}
                        onChange={(e: any) => setFieldValue('country', e.value)}
                        optionLabel="text"
                        dataKey="value"
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12 p-field">
                      <label htmlFor="tags">
                        <IntlString id="contactDialog.tag" />
                      </label>
                      <Field
                        as={TagsDropdown}
                        name="tags"
                        handleChange={() => {}}
                        value={values.tags}
                        setFormField={setFieldValue}
                        fieldName="tags"
                      />
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        </Dialog>
      </>
    );
  }
}

export default withIntlString(ContactDialog);
