import React, { Component } from 'react';
import { IntlStringProps, withIntlString } from 'components/IntlStringHoc';

import { Field, FieldArray, Formik } from 'formik';
import { InputText } from 'primereact/inputtext';

import { MetaDataTypeEnum, ModelMetaData, ReservationMetaData } from 'services/apiService';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';

interface ModelMetaDataExtended extends ModelMetaData {
  userResponse: any;
}

type Props = IntlStringProps & {
  defaultValue: { [key: string]: ReservationMetaData };
  setFormField: Function;
  fieldName: string;
  modelQuestions: Array<ModelMetaData>;
};

type State = {
  parsedQuestion: Array<ModelMetaDataExtended>;
};

class ReservationQuestions extends Component<Props, State> {
  formChanged = ({ questions }: { questions: Array<ModelMetaDataExtended> }) => {
    let tmp: { [key: string]: ReservationMetaData } = {};

    for (const quest of questions) {
      if (quest.userResponse && quest.meta_data?._id) {
        tmp = {
          ...tmp,
          [quest.meta_data._id]: {
            meta_data: quest.meta_data,
            value: Array.isArray(quest.userResponse)
              ? quest.userResponse.map((k) => k._id).join(',')
              : quest.userResponse._id ?? quest.userResponse,
          },
        };
      }
    }

    this.props.setFormField(this.props.fieldName, tmp);
  };

  state: State = {
    parsedQuestion: this.props.modelQuestions.map((q) => ({
      ...q,
      userResponse: this.getQuestionUserResponse(q),
    })),
  };

  getQuestionUserResponse(q: ModelMetaData) {
    if (q.meta_data?.type === MetaDataTypeEnum.Text)
      return this.props.defaultValue[q.meta_data!._id!]?.value ?? '';

    if (q.meta_data?.type === MetaDataTypeEnum.Radio) {
      return (
        q.meta_data.options?.find(
          (k: any) => k._id === this.props.defaultValue[q.meta_data!._id!]?.value ?? null
        ) ?? null
      );
    }

    if (q.meta_data?.type === MetaDataTypeEnum.Checkbox) {
      const values = this.props.defaultValue[q.meta_data!._id!]?.value ?? null;
      if (values) {
        const splitIds = values.split(',');
        return q.meta_data.options?.filter((opt: any) => splitIds.includes(opt._id));
      }
    }

    return null;
  }

  render() {
    const { parsedQuestion } = this.state;

    return (
      <Formik
        initialValues={{ questions: parsedQuestion }}
        validate={this.formChanged}
        onSubmit={() => {}}
      >
        {({ values, setFieldValue }) => {
          return (
            <>
              <FieldArray name="questions">
                {() =>
                  values.questions.map((quest, i: number) => {
                    return (
                      <div key={`quest-${quest.meta_data?._id}`}>
                        <div className="row mb-2">
                          <div className="col-5 d-flex align-items-center p-field">
                            {quest.meta_data?.title}
                          </div>
                          <div className="col-7 p-field">
                            {quest.meta_data?.type === MetaDataTypeEnum.Text && (
                              <Field
                                as={InputText}
                                id={`quest-${quest.meta_data?._id}`}
                                value={quest.userResponse}
                                name={`questions.${i}.userResponse`}
                              />
                            )}
                            {quest.meta_data?.type === MetaDataTypeEnum.Radio && (
                              <Field
                                component={Dropdown}
                                appendTo={document.body}
                                id={`quest-${quest.meta_data?._id}`}
                                options={quest.meta_data?.options}
                                value={quest.userResponse}
                                onChange={(e: any) =>
                                  setFieldValue(`questions.${i}.userResponse`, e.value)
                                }
                                optionLabel="title"
                                dataKey="_id"
                              />
                            )}
                            {quest.meta_data?.type === MetaDataTypeEnum.Checkbox && (
                              <Field
                                component={MultiSelect}
                                appendTo={document.body}
                                id={`quest-${quest.meta_data?._id}`}
                                options={quest.meta_data?.options}
                                value={quest.userResponse}
                                onChange={(e: any) =>
                                  setFieldValue(`questions.${i}.userResponse`, e.value)
                                }
                                optionLabel="title"
                                dataKey="_id"
                              />
                            )}
                          </div>
                        </div>
                        <div className="divider mb-2" />
                      </div>
                    );
                  })
                }
              </FieldArray>
            </>
          );
        }}
      </Formik>
    );
  }
}

export default withIntlString(ReservationQuestions);
