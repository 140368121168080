import React, { FunctionComponent } from 'react';
import { ErrorMessage } from 'formik';
import { ErrorMessageProps } from 'formik/dist/ErrorMessage';
import cx from 'classnames';
import IntlString, { FormattedMessageProps } from './IntlString';

const FormFieldError: FunctionComponent<ErrorMessageProps> = ({ name, className, ...rest }) => (
  <ErrorMessage name={name}>
    {(message: any) => {
      return (
        <div className={cx('p-invalid', className)} {...rest}>
          <IntlString {...(message as FormattedMessageProps)} />
        </div>
      );
    }}
  </ErrorMessage>
);

export default FormFieldError;
