import { IntFormMessageId } from './models';

const messages: IntFormMessageId = {
  'formValidation.mixed.default': '{path} is invalid',
  'formValidation.mixed.required': '{path} is a required field',
  'formValidation.mixed.oneOf': '{path} must be one of the following values: {values}',
  'formValidation.mixed.notOneOf': '{path} must not be one of the following values: {values}',

  'formValidation.number.min': '{path} must be greater than or equal to {min}',
  'formValidation.number.max': '{path} must be less than or equal to {max}',
  'formValidation.number.lessThan': '{path} must be less than {less}',
  'formValidation.number.moreThan': '{path} must be greater than {more}',
  'formValidation.number.notEqual': '{path} must be not equal to {notEqual}',
  'formValidation.number.positive': '{path} must be a positive number',
  'formValidation.number.negative': '{path} must be a negative number',
  'formValidation.number.integer': '{path} must be an integer',

  'formValidation.string.length': '{path} must be exactly {length} characters',
  'formValidation.string.min': '{path} must be at least {min} characters',
  'formValidation.string.max': '{path} must be at most {max} characters',
  'formValidation.string.matches': '{path} must match the following: "{regex}"',
  'formValidation.string.email': '{path} must be a valid email',
  'formValidation.string.url': '{path} must be a valid URL',
  'formValidation.string.uuid': '{path} must be a valid UUID',
  'formValidation.string.trim': '{path} must be a trimmed string',
  'formValidation.string.lowercase': '{path} must be a lowercase string',
  'formValidation.string.uppercase': '{path} must be a upper case string',

  'formValidation.date.min': '{path} field must be later than {min}',
  'formValidation.date.max': '{path} field must be at earlier than {max}',
};

export default messages;
