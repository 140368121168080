import React, { Component } from 'react';
import { IntlStringProps, withIntlString } from 'components/IntlStringHoc';

import { Field, FieldArray, Formik } from 'formik';

import { ModelExtras, ReservationExtras } from 'services/apiService';

import { InputNumber } from 'primereact/inputnumber';

interface ModelExtrasExtended extends ModelExtras {
  userResponse: number | null;
}

type Props = IntlStringProps & {
  defaultValue: { [key: string]: ReservationExtras };
  setFormField: Function;
  fieldName: string;
  modelExtras: Array<ModelExtras>;
};

type State = {
  parsedExtras: Array<ModelExtrasExtended>;
};

class ReservationExtra extends Component<Props, State> {
  formChanged = ({ extras }: { extras: Array<ModelExtrasExtended> }) => {
    let tmp: { [key: string]: ReservationExtras } = {};

    for (const ext of extras) {
      if (ext.userResponse != null && ext.extra?._id) {
        tmp = {
          ...tmp,
          [ext.extra?._id]: {
            extra: ext.extra,
            price: ext.price,
            quantity: ext.userResponse,
          },
        };
      }
    }

    this.props.setFormField(this.props.fieldName, tmp);
  };

  state: State = {
    parsedExtras: this.props.modelExtras.map((e) => ({
      ...e,
      userResponse: this.props.defaultValue[e.extra!._id!]?.quantity || null,
    })),
  };

  render() {
    const { parsedExtras } = this.state;

    return (
      <Formik
        initialValues={{ extras: parsedExtras }}
        validate={this.formChanged}
        onSubmit={() => {}}
      >
        {({ values, setFieldValue }) => {
          return (
            <>
              <FieldArray name="extras">
                {() =>
                  values.extras.map((extra, i: number) => {
                    return (
                      <div key={`extra-${extra.extra?._id}`}>
                        <div className="row mb-2">
                          <div className="col-4 d-flex align-items-center p-field">
                            {extra.extra?.title}
                          </div>
                          <div className="col-8 p-field">
                            <Field
                              component={InputNumber}
                              id={`extra-${extra.extra?._id}`}
                              value={extra.userResponse}
                              style={{ width: '8em' }}
                              showButtons
                              onChange={(e: any) => {
                                setFieldValue(`extras.${i}.userResponse`, e.value);
                              }}
                              min={0}
                              max={extra.max_value === 0 ? 1 : extra.max_value}
                            />
                          </div>
                        </div>
                        <div className="divider mb-2" />
                      </div>
                    );
                  })
                }
              </FieldArray>
            </>
          );
        }}
      </Formik>
    );
  }
}

export default withIntlString(ReservationExtra);
