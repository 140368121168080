import React, { Component } from 'react';
import { IntlStringProps, withIntlString } from 'components/IntlStringHoc';
import { Dialog } from 'primereact/dialog';
import IntlString from 'components/IntlString';
import { Calendar } from 'primereact/calendar';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'state/store';
import calendarMessages from 'lang/calendar';
import getClient from 'services/apiClientProvider';
import {
  InlineResponse2006,
  PlaceApi,
  Proposal,
  ProposalApi,
  Reservation,
  ReservationApi,
} from 'services/apiService';
import { IApi, IState, useApi } from 'services/apiStateManager';
import { Event } from 'services/apiService';
import BlockLoader from 'components/blockLoader/BlockLoader';
import { RadioButton } from 'primereact/radiobutton';
import userImage from 'assets/img/user.webp';
import EventReservationCounter from 'components/eventReservationCounter/EventReservationCounter';
import { Button } from 'primereact/button';

import './MoveReservationDialog.scss';

const connector = connect((state: RootState) => ({
  language: state.language.language,
  placeId: state.location.selectedLocation?._id,
}));

type Props = IntlStringProps &
  ConnectedProps<typeof connector> & {
    isVisible: boolean;
    onHideDialog: Function;
    reservation: Reservation | Proposal | undefined;
    moveFromDate: Date;
  };

type State = {
  selectedDate: Date | undefined;
  eventsList: IState<InlineResponse2006[]>;
  moveReservationPost: IState<Reservation>;
  selectedEvent: Event | null;
};

type EventSearchParams = {
  placeId: string;
  date?: string;
  start?: string;
  end?: string;
  language?: string;
};

interface ProposalMoveParams {
  proposalId: string;
  proposal: Proposal;
}

class MoveReservationDialog extends Component<Props, State> {
  eventsManager: IApi<InlineResponse2006[], EventSearchParams> = useApi<
    InlineResponse2006[],
    EventSearchParams
  >(this, 'eventsList', (params) =>
    getClient(PlaceApi).placesPlaceIdEventsGet(
      params.placeId,
      params.date,
      params.start,
      params.end,
      params.language
    )
  );

  reservationMoveManagerPost: IApi<Reservation, string> = useApi<Reservation, string>(
    this,
    'moveReservationPost',
    (moveToEventId) =>
      getClient(ReservationApi).reservationsReservationIdMovePost(
        this.props.reservation?._id ?? '',
        {
          target: moveToEventId,
        }
      )
  );

  proposalMoveManagerPost: IApi<Proposal, ProposalMoveParams> = useApi<
    Proposal,
    ProposalMoveParams
  >(this, 'moveReservationPost', (p) =>
    getClient(ProposalApi).proposalsProposalIdPut(p.proposalId, p.proposal)
  );

  state: State = {
    selectedDate: undefined,
    eventsList: this.eventsManager.initialState,
    moveReservationPost: this.reservationMoveManagerPost.initialState,
    selectedEvent: null,
  };

  get isReservation() {
    let isReservation = false;

    const reservation = this.props.reservation;
    isReservation = !!(reservation as Reservation).cellar_user;

    return isReservation;
  }

  componentDidUpdate(lastProps: Props) {
    if (this.props.isVisible !== lastProps.isVisible) {
      this.setState({
        selectedDate: undefined,
        selectedEvent: null,
      });
    }
  }

  renderHeader() {
    const date = this.props.moveFromDate;
    return (
      <div className="d-flex align-items-center font-weight-bold">
        <IntlString
          id="reservations.moveDialog.title"
          values={{
            date: !isNaN(date.getTime())
              ? date.toLocaleDateString(undefined, {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })
              : '',
          }}
        />
      </div>
    );
  }

  moveReservation = async () => {
    if (this.isReservation) {
      this.reservationMoveManagerPost.fetch(this.state.selectedEvent?._id ?? '').then(() => {
        this.props.onHideDialog(true);
      });
    } else {
      const updatedProposal: Proposal = JSON.parse(JSON.stringify(this.props.reservation));

      if (updatedProposal.reservation) {
        updatedProposal.reservation.event = this.state.selectedEvent?._id as string;
      }

      this.proposalMoveManagerPost
        .fetch({
          proposalId: (this.props.reservation as Proposal)._id ?? '',
          proposal: updatedProposal,
        })
        .then(() => {
          this.props.onHideDialog(true);
        });
    }
  };

  renderFooter() {
    const { intlString, onHideDialog } = this.props;
    const { selectedEvent } = this.state;

    return (
      <div>
        <Button
          label={intlString('general.cancel')}
          className="p-button-text p-button-lg p-button-secondary mr-auto"
          type="button"
          onClick={() => onHideDialog()}
        ></Button>
        <Button
          label={intlString('general.save')}
          type="button"
          className="p-button-danger p-button-lg ml-2"
          disabled={!selectedEvent}
          onClick={this.moveReservation}
        ></Button>
      </div>
    );
  }

  selectDate = (e: any) => {
    const date: Date = e.value;
    const dateEnd = new Date(date);
    dateEnd.setDate(dateEnd.getDate() + 1);

    this.eventsManager.fetch({
      placeId: this.props.placeId ?? '',
      start: date.toISOString(),
      end: dateEnd.toISOString(),
    });

    this.setState({ selectedDate: date, selectedEvent: null });
  };

  selectEvent = (e: any) => this.setState({ selectedEvent: e.value });

  render() {
    const { language, reservation } = this.props;
    const { selectedDate, eventsList, selectedEvent, moveReservationPost } = this.state;
    let personName = '';
    let r: Reservation | null = null;

    if (reservation) {
      if (this.isReservation) {
        r = reservation as Reservation;
      } else {
        r = (reservation as Proposal).reservation as Reservation;
      }

      if (r) {
        personName = `${r?.cellar_user?.first_name ?? ''} ${r?.cellar_user?.last_name ?? ''}`;
      }
    }

    return (
      <Dialog
        header={this.renderHeader()}
        footer={this.renderFooter()}
        visible={this.props.isVisible}
        onHide={() => this.props.onHideDialog(false)}
        className="move-reservation-dialog"
      >
        <p>
          <IntlString
            id="reservations.moveDialog.description"
            values={{
              b: (chunks: string) => <b>{chunks}</b>,
              personName,
            }}
          />
        </p>

        <BlockLoader loading={moveReservationPost.loading || !reservation}>
          <div className="d-flex">
            <Calendar
              value={selectedDate}
              onChange={this.selectDate}
              locale={calendarMessages[language]}
              inline
            />
            <div className="event-hour-chooser px-3">
              {selectedDate && (
                <>
                  <div className="h6 font-weight-bold">
                    {selectedDate?.toLocaleString([], {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                      weekday: 'long',
                    })}
                  </div>
                  <hr className="mt-4 mb-2" />
                  <BlockLoader loading={eventsList.loading}>
                    {eventsList.response?.map((event, index) => (
                      <div key={index}>
                        <div className="p-field-radiobutton d-flex align-items-center pl-1">
                          <RadioButton
                            name="selectedEvent"
                            inputId={`event_${index}`}
                            value={event}
                            onChange={this.selectEvent}
                            checked={selectedEvent === event}
                          />
                          <label htmlFor={`event_${index}`} className="m-0 ml-2">
                            <span className="font-weight-bold">
                              {new Date(event.start ?? '').toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                              })}
                            </span>
                            <span> - </span>
                            <span>{event.title}</span>
                          </label>
                          <div className="ml-auto d-flex align-items-center justify-content-center">
                            <EventReservationCounter event={event} />
                            <span>
                              <img src={userImage} alt="user" className="mr-2 mb-1" />
                              {event.places}
                            </span>
                          </div>
                        </div>
                        <hr className="my-2" />
                      </div>
                    ))}
                    {eventsList.response?.length === 0 && (
                      <div>
                        <IntlString id="reservations.moveDialog.noEvents" />
                      </div>
                    )}
                  </BlockLoader>
                </>
              )}
            </div>
          </div>
        </BlockLoader>
      </Dialog>
    );
  }
}

export default connector(withIntlString(MoveReservationDialog));
