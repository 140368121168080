import { Dispatch } from 'redux';
import getClient from 'services/apiClientProvider';
import { InlineResponse2004, NotificationApi } from 'services/apiService';
import { SET_NOTIFICATIONS } from './actions';
import { SetNotifications } from './types';

export const setNotifications = (notification: InlineResponse2004): SetNotifications => ({
  type: SET_NOTIFICATIONS,
  notification,
});

export const fetchNotifications = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await getClient(NotificationApi).notificationsGet();
      dispatch(setNotifications(response.data));
    } catch (error) {
      console.log(error);
    }
  };
};
