import React, { Component, useEffect } from 'react';
import { IntlStringProps, withIntlString } from 'components/IntlStringHoc';
import { Dialog } from 'primereact/dialog';

import IntlString from 'components/IntlString';

import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'state/store';
import { Button } from 'primereact/button';
import { Field, Form, Formik } from 'formik';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import cx from 'classnames';
import * as Yup from 'yup';
import BlockLoader from 'components/blockLoader/BlockLoader';

import './DialogDeleteRequest.scss';
import FormFieldError from 'components/FormFieldError';
import { IApi, IState, useApi } from 'services/apiStateManager';
import getClient from 'services/apiClientProvider';
import { InlineObject4, ProposalApi } from 'services/apiService';
import quillConfig from 'util/quillConfig';

const connector = connect((state: RootState) => ({
  languageTranslations: state.languageTranslations,
}));

type Props = IntlStringProps &
  ConnectedProps<typeof connector> & {
    isVisible: boolean;
    onHideDialog: (withSuccess: boolean) => void;
    proposalId: string;
  };

interface State {
  disableSubmit: boolean;
  proposalPost: IState<any>;
  isLoading: boolean;
}

const DeleteRequestSchema = Yup.object().shape({
  message: Yup.string().required(),
});

interface APICallParams {
  proposalId: string;
  data: InlineObject4;
}

class DialogDeleteRequest extends Component<Props, State> {
  proposalManagerPost: IApi<void, APICallParams> = useApi<void, APICallParams>(
    this,
    'propostalPost',
    (params: APICallParams) =>
      getClient(ProposalApi).proposalsProposalIdRejectPost(params.proposalId, params.data)
  );

  state: State = {
    proposalPost: this.proposalManagerPost.initialState,
    disableSubmit: true,
    isLoading: false,
  };

  doSubmitForm: Function = () => {};

  handleDialogSubmit = (values: { message: string }) => {
    this.setState({
      isLoading: true,
    });

    this.proposalManagerPost
      .fetch({
        proposalId: this.props.proposalId,
        data: {
          ...values,
        },
      })
      .then(() => {
        this.hideDialog(true);
      })
      .catch((error) => {
        alert(error.toString());
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  bindFormSubmit = (doSubmitCurrentForm: Function) => {
    this.doSubmitForm = doSubmitCurrentForm;
  };

  renderHeader() {
    return (
      <h6>
        <IntlString id="reservationsAndRequests.deleteRequest" />
      </h6>
    );
  }

  hideDialog = (withSuccess?: boolean) => {
    this.props.onHideDialog(withSuccess ?? false);
  };

  renderFooter() {
    const { intlString } = this.props;

    return (
      <>
        <div className="d-flex">
          <Button
            label={intlString('general.cancel')}
            className="p-button-text p-button-secondary p-button--m"
            type="button"
            onClick={() => this.hideDialog()}
          />
          <Button
            type="submit"
            label={intlString('general.proceed')}
            className="p-button-danger p-button--m p-button-icon-right ml-auto mr-0"
            onClick={() => this.doSubmitForm()}
          />
        </div>
      </>
    );
  }

  render() {
    const { isVisible } = this.props;
    const { isLoading } = this.state;

    return (
      <Dialog
        header={this.renderHeader()}
        footer={this.renderFooter()}
        visible={isVisible}
        onHide={() => this.hideDialog()}
        className="dialog-delete-request"
      >
        <p>
          <IntlString id="reservationsAndRequests.confirmDeleteRequest" />
        </p>
        <BlockLoader loading={isLoading}>
          <Formik
            initialValues={{
              message: '',
            }}
            enableReinitialize={true}
            validationSchema={DeleteRequestSchema}
            onSubmit={this.handleDialogSubmit}
          >
            {({ errors, submitForm }) => {
              useEffect(() => {
                this.bindFormSubmit(submitForm);
              }, [submitForm]);

              return (
                <Form>
                  <div className="p-field mt-4">
                    <Field name="message">
                      {(a: any) => {
                        return (
                          <ReactQuill
                            theme="snow"
                            value={a.field.value}
                            onChange={a.field.onChange(a.field.name)}
                            modules={quillConfig.modules}
                            formats={quillConfig.formats}
                            className={cx({
                              'p-invalid': errors.message,
                            })}
                          />
                        );
                      }}
                    </Field>
                    <FormFieldError name="message" className="mt-1" />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </BlockLoader>
      </Dialog>
    );
  }
}

export default connector(withIntlString(DialogDeleteRequest));
