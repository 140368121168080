import { ActionTypes } from './types';
import { LanguageTranslationsState } from './types';
const initialState: LanguageTranslationsState = [];

export default (state = initialState, action: ActionTypes): LanguageTranslationsState => {
  switch (action.type) {
    case 'SET_LANGUAGE_TRANSLATIONS': {
      return action.languageTranslations;
    }

    default:
      return state;
  }
};
