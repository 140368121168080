import React, { FunctionComponent, useEffect } from 'react';
import { Sidebar as PrimeSidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'state/store';
import { setVisible } from 'state/sidebar/actionCreators';
import { useLocation } from 'react-router-dom';
import { ReactComponent as LogoWA } from 'assets/img/logo.svg';

import { faFlag } from '@fortawesome/pro-light-svg-icons';

import './Sidebar.scss';
import IntlString from '../../IntlString';
import SidebarLink from './SidebarLink';

const connector = connect(
  (state: RootState) => ({
    ...state.sidebar,
  }),
  {
    setVisibility: setVisible,
  }
);

type Props = ConnectedProps<typeof connector>;

const Sidebar: FunctionComponent<Props> = ({ visible, setVisibility }) => {
  let location = useLocation();

  useEffect(() => {
    setVisibility(false);
  }, [location, setVisibility]);

  return (
    <PrimeSidebar
      visible={visible}
      onHide={() => setVisibility(false)}
      className="sidebar-main"
      icons={() => (
        <React.Fragment>
          <LogoWA className="sidebar-main__logo" />
        </React.Fragment>
      )}
    >
      <h6 className="sidebar-main__title">
        <IntlString id="sidebar.title" />
      </h6>

      <nav className="menu-vertical">
        <ul className="sidebar-main__menu menu-vertical__list">
          <SidebarLink to="/" icon={faFlag} label="sidebar.menu.company" />
          <SidebarLink to="/locations" icon="pi pi-compass" label="sidebar.menu.places" />
          <SidebarLink to="/" icon="pi pi-user-plus" label="sidebar.menu.operators" />
          <SidebarLink to="/address-book" icon="pi pi-id-card" label="sidebar.menu.address-book" />
          <SidebarLink to="/" icon="pi pi-tags" label="sidebar.menu.tags" />
          <SidebarLink to="/extras" icon="pi pi-plus" label="sidebar.menu.extras" />
          <SidebarLink to="/questions" icon="pi pi-comments" label="sidebar.menu.questions" />
        </ul>
        <hr className="menu-vertical__divider" />
        <ul className="sidebar-main__menu menu-vertical__list">
          <SidebarLink to="/" icon="pi pi-bookmark" label="sidebar.menu.labels" />
          <SidebarLink to="/" icon="pi pi-money-bill" label="sidebar.menu.sealable" />
          <SidebarLink to="/" icon="pi pi-ticket" label="sidebar.menu.coupon" />
          <SidebarLink to="/" icon="pi pi-star-o" label="sidebar.menu.giftCards" />
          <SidebarLink to="/" icon="pi pi-id-card" label="sidebar.menu.clubs" />
        </ul>
        <hr className="menu-vertical__divider" />
        <ul className="menu-vertical__list">
          <SidebarLink to="/" icon="pi pi-cog" label="sidebar.menu.settings" />
          <SidebarLink to="/" icon="pi pi-question" label="sidebar.menu.help" />
        </ul>
      </nav>
      <footer className="mt-auto text-center">
        <p>
          <IntlString id="sidebar.description" />
        </p>
        <Button className="p-button-danger p-1 p-2 mr-2 text-uppercase">
          <i className="pi pi-desktop mr-2" aria-hidden={true}></i>
          <span>
            <IntlString id="sidebar.bookingButton" />
          </span>
        </Button>
      </footer>
    </PrimeSidebar>
  );
};

export default connector(Sidebar);
