import itMessages from './messages.it';
import enMessages from './messages.en';
import { configureFormValidation } from './formValidation';

configureFormValidation();

export default ({
  it: itMessages,
  en: enMessages,
} as unknown) as { [key: string]: { [key: string]: string } };
