import { IntFormMessageId } from './models';

const messages: IntFormMessageId = {
  'formValidation.mixed.default': '{path} non è valido',
  'formValidation.mixed.required': '{path} è un campo obbligatorio',
  'formValidation.mixed.oneOf': '{path} deve essere uno dei seguenti valori: {values}',
  'formValidation.mixed.notOneOf': '{path} non deve essere uno dei seguenti valori: {values}',

  'formValidation.number.min': '{path} deve essere maggiore o uguale a {min}',
  'formValidation.number.max': '{path} deve essere minore o uguale a {max}',
  'formValidation.number.lessThan': '{path} deve essere minore di {less}',
  'formValidation.number.moreThan': '{path} deve essere maggiore di {more}',
  'formValidation.number.notEqual': '{path} deve essere diverso da {notEqual}',
  'formValidation.number.positive': '{path} deve essere un numero positivo',
  'formValidation.number.negative': '{path} deve essere un numero negativo',
  'formValidation.number.integer': '{path} deve essere un numero',

  'formValidation.string.length': '{path} deve essere di {length} caratteri',
  'formValidation.string.min': '{path} deve essere di almeno {length} caratteri',
  'formValidation.string.max': '{path} deve essere di massimo {length} caratteri',
  'formValidation.string.matches': '{path} deve rispettare il formato {regex}',
  'formValidation.string.email': '{path} deve essere un email valida',
  'formValidation.string.url': '{path} deve essere un URL valido',
  'formValidation.string.uuid': '{path} deve essere un UUID valido',
  'formValidation.string.trim': '{path} non deve contenere spazi agli estremi',
  'formValidation.string.lowercase': '{path} deve contenere solo caratteri minuscoli',
  'formValidation.string.uppercase': '{path} deve contenere solo caratteri maiuscoli',

  'formValidation.date.min': '{path} deve avere almeno {min} elementi',
  'formValidation.date.max': '{path} deve avere massimo {max} elementi',
};

export default messages;
