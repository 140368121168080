import { applyMiddleware, combineReducers, createStore } from 'redux';
import authReducer from './auth/reducer';
import languageReducer from './language/reducer';
import sidebarReducer from './sidebar/reducer';
import locationReducer from './location/reducer';
import notificationsReducer from './notifications/reducer';
import languageTranslationsReducer from './languageTranslations/reducer';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import authMiddleware from './auth/middleware';
import { load, save } from 'redux-localstorage-simple';
import { setAuthToken } from '../services/apiClientProvider';
import storagePersistance from './storagePersistance';

const rootReducer = combineReducers({
  auth: authReducer,
  language: languageReducer,
  languageTranslations: languageTranslationsReducer,
  sidebar: sidebarReducer,
  location: locationReducer,
  notifications: notificationsReducer,
});

const middlewares = applyMiddleware(thunk, authMiddleware, save(storagePersistance));

const initialState = load(storagePersistance) as RootState;

// Configure Auth token if is there
setAuthToken(initialState.auth?.user?.token);

const store = createStore(rootReducer, initialState, composeWithDevTools(middlewares));

export default store;
export type RootState = ReturnType<typeof rootReducer>;
