import { SET_USER } from './actions';
import { SetUserAction } from './types';
import { UserAccount } from 'models/UserAccount';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../store';
import { history } from 'router/router';

export const setUser = (user?: UserAccount): SetUserAction => ({
  type: SET_USER,
  user,
});

export const logout = (): ThunkAction<void, RootState, unknown, SetUserAction> => (dispatch) => {
  dispatch(setUser());
  history.push('/login');
};
