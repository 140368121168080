import React, { FunctionComponent } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import './BlockLoader.scss';
import cx from 'classnames';

type Props = {
  className?: string;
  tag?: string;
  loading: boolean;
};

const BlockLoader: FunctionComponent<Props> = ({ className, children, loading }) => (
  <div className={cx('block-loader', className)}>
    {children}
    <div className="block-loader__spinner-wrapper" hidden={!loading}>
      <ProgressSpinner className="block-loader__spinner" />
    </div>
  </div>
);

export default BlockLoader;
