import React, { FunctionComponent } from 'react';
import { InlineResponse2006, ReservationStatusEnum } from 'services/apiService';
import { Event as WineEvent } from 'services/apiService/api';

import './EventReservationCounter.scss';

type Props = {
  event: WineEvent | InlineResponse2006 | null;
};

const EventReservationCounter: FunctionComponent<Props> = ({ event }) => {
  const reservationConfirmed =
    event?.reservations?.filter((x) => x.status === ReservationStatusEnum.Confirmed).length ?? 0;
  const reservationUnconfirmed =
    event?.reservations?.filter((x) => x.status !== ReservationStatusEnum.Confirmed).length ?? 0;

  return (
    <div className="event-participants-counter d-flex align-items-center">
      {reservationConfirmed > 0 && (
        <span className="p-tag p-tag-rounded p-tag-success event-participants-tag">
          {reservationConfirmed}
        </span>
      )}
      {reservationUnconfirmed > 0 && event instanceof Event && !(event as WineEvent)?.paused && (
        <span className="p-tag p-tag-rounded p-tag-warning event-participants-tag">
          {reservationUnconfirmed}
        </span>
      )}
    </div>
  );
};

export default EventReservationCounter;
