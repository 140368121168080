import React, { Component } from 'react';
import { IntlStringProps, withIntlString } from 'components/IntlStringHoc';
import IntlString from 'components/IntlString';

import './Reservations.scss';
import { TabPanel, TabView } from 'primereact/tabview';
import ReservationsTab from './tabs/reservations/ReservationsTab';
import RequestsTab from './tabs/requests/RequestsTab';
import { RootState } from 'state/store';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { setSelectedLocation } from 'state/location/actionCreators';
// import { Place } from 'services/apiService';

type State = {
  activeIndex: number;
};

const connector = connect(
  (state: RootState) => ({
    notification: state.notifications.notification,
    placeId: state.location.selectedLocation?._id,
  }),
  {
    setSelectedLocation,
  }
);

type Props = ConnectedProps<typeof connector> & IntlStringProps & RouteComponentProps;

class Reservations extends Component<Props, State> {
  state = {
    activeIndex: 0,
  };

  render() {
    const { intlString, notification } = this.props;

    return (
      <div className="page-reservations mt-3">
        <header className="d-flex justify-content-between align-items-start mb-3">
          <div>
            <h1 className="h4">
              <IntlString id="reservationsAndRequests.title" />
            </h1>
          </div>
        </header>
        <div className="bg-white p-3">
          <TabView>
            <TabPanel header={intlString('reservationsAndRequests.reservations')}>
              <ReservationsTab />
            </TabPanel>
            <TabPanel
              header={
                <>
                  {intlString('reservationsAndRequests.requests')}
                  <span className="ml-2 badge badge-reservation-requests">
                    {notification.pending}
                  </span>
                </>
              }
            >
              <RequestsTab />
            </TabPanel>
          </TabView>
        </div>
      </div>
    );
  }
}

export default connector(withIntlString(withRouter(Reservations)));
