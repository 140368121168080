import env from '../env';
import { BaseAPI } from './apiService/base';
import { Configuration } from './apiService';
import globalAxios from 'axios';
import store from 'state/store';
import { logout } from 'state/auth/actionCreators';

// This line will be removed once clients will be generated using Swagger
globalAxios.defaults.baseURL = env.API_URL;

globalAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;

    if (response.status === 401 && response.config.url !== 'auth/signin') {
      //@ts-ignore
      store.dispatch(logout());
    }

    return Promise.reject(error);
  }
);

export default function getClient<T extends BaseAPI>(
  apiClientClass: new (config?: Configuration) => T
) {
  return new apiClientClass({
    basePath: env.API_URL,
  });
}

export function setAuthToken(token?: string) {
  globalAxios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : null;
}
