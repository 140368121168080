import { ActionTypes, LocationState } from './types';
import { SET_SELECTED_LOCATION } from './actions';

const initialState: LocationState = {
  selectedLocation: null,
};

export default (state = initialState, action: ActionTypes): LocationState => {
  switch (action.type) {
    case SET_SELECTED_LOCATION:
      return {
        selectedLocation: action.location,
      };

    default: {
      return state;
    }
  }
};
