import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import IntlString from 'components/IntlString';
import { NavLink } from 'react-router-dom';
import './SidebarLink.scss';
import { IntlMessageId } from 'lang/models';

type Props = {
  icon: string | IconProp;
  label: IntlMessageId;
  to: string;
};

const SidebarLink: FunctionComponent<Props> = ({ icon, label, to }) => {
  let iconMarkup = null;

  if (typeof icon !== 'string') {
    iconMarkup = <FontAwesomeIcon icon={icon} />;
  } else {
    iconMarkup = <i className={icon} />;
  }

  return (
    <li>
      <NavLink to={to} className="menu-vertical__item sidebar-link">
        <span className="menu-vertical__icon">{iconMarkup}</span>
        <span className="menu-vertical__label">
          <IntlString id={label} />
        </span>
      </NavLink>
    </li>
  );
};

export default SidebarLink;
