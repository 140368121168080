import { ActionTypes, NotificationState } from './types';
import { SET_NOTIFICATIONS } from './actions';

const initialState: NotificationState = {
  notification: {
    pending: 0,
  },
};

export default (state = initialState, action: ActionTypes): NotificationState => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return {
        notification: action.notification,
      };

    default: {
      return state;
    }
  }
};
